import { NgModule } from '@angular/core';
import { ProfileComponent } from './profile.component';
import { ProfileContactsModule } from './contacts/profile-contacts.module';
import { ProfileAddressesModule } from './addresses/profile-addresses.module';
import { SharedModule } from '../../shared/shared.module';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileUserService } from './contacts/profile-user.service';
import { ChangePasswordModule } from './change-password/change-password.module';

@NgModule({
	imports: [
		SharedModule,
		ProfileContactsModule,
		ProfileAddressesModule,
		ChangePasswordModule,
		ProfileRoutingModule
	],
	declarations: [
		ProfileComponent
	],
	providers: [
		ProfileUserService
	],
	exports: [
		ProfileComponent
	]
})
export class ProfileModule {
}