import { NgModule } from '@angular/core';
import { DropdownsService } from './add-request-form/dropdowns.service';
import { SharedModule } from '../../../../shared/shared.module';
import { BackLinkComponent } from '../../../../shared/back-link.component';
import { AddRequestRoutingModule } from './add-request-routing.module';
import { AddRequestComponent } from './add-request.component';
import { AddRequestFormComponent } from './add-request-form/add-request-form.component';
import { AddRequestFormService } from './add-request-form/add-request-form.service';

@NgModule({
	imports: [
		SharedModule,
		AddRequestRoutingModule
	],
	declarations: [
		AddRequestComponent,
		AddRequestFormComponent
	],
	providers: [
		DropdownsService,
		AddRequestFormService,
		BackLinkComponent
	]
})
export class AddRequestModule {
}