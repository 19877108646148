import { Injectable } from '@angular/core';
import { HttpClient } from '../../../../core/http/http-client';
import { ConfigService } from '../../../../core/config.service';
import { LoggerService } from '../../../../core/logger/logger.service';

@Injectable()
export class PrefixService {

	private prefixes: string[];

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	getPrefixes(): Promise<string[]> {
		if (this.prefixes) {
			return Promise.resolve(this.prefixes);
		}
		return this.loadPrefixes();
	}

	private loadPrefixes(): Promise<string[]> {
		return this.http.get(this.configService.baseUrl + '/api/RegistrationPrefix')
			.toPromise()
			.then(res => {
				this.prefixes = res.json();
				return this.prefixes;
			})
			.catch(error => {
				this.logger.error(error);
				return null;
			});
	}
}
