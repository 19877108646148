import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PagesModule } from './pages/pages.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { LoadingMaskModule } from './shared/loading-mask/loading-mask.module';
import { UserNewsModule } from './pages/view/user-news/user-news.module';

// ===== static ===========

require('../app_assets/styles/styles.scss');

@NgModule({
	imports: [
		BrowserModule,
		CoreModule,
		PagesModule,
		AppRoutingModule,
		LoadingMaskModule,
		UserNewsModule
	],
	declarations: [
		AppComponent
	],
	bootstrap: [
		AppComponent
	]
})

export class AppModule {
}