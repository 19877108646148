import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RegistrationComponent } from './registration.component';
import { WizardStep, getWizardRoutes } from '../../shared/wizard/wizard-step';
import { RegistrationStep1Component } from './steps/step1/registration-step1.component';
import { RegistrationStep2Component } from './steps/step2/registration-step2.component';
import { RegistrationGuard } from './registration-guard.service';
import { BusinessAreasResolve } from './steps/step2/business-areas-resolve.service';
import { RoleResolve } from './steps/step1/role-resolve.service';
import { PrefixResolve } from './steps/step1/prefix-resolve.service';
import { CountryResolve } from './steps/step1/country-resolve.service';
import { CompanyResolve } from './steps/step3/company-resolve.service';
import { RegistrationStep3Component } from './steps/step3/registration-step3.component';
import { RegistrationStep4Component } from './steps/step4/registration-step4.component';
import { RegistrationStep5Component } from './steps/step5/registration-step5.component';
import { StateResolve } from './steps/step1/state-resolve.service';
import { OfficeResolve } from './steps/step3/office-resolve.service';
import { UserActivationComponent } from './user-activation/user-activation.component';
import { NotAuthGuard } from '../../core/auth/not-auth.guard';
import { CopyrightsResolve } from './steps/step4/copyright-resolve.service';
import { RegistrationStepFinalComponent } from './steps/step-final/registration-step-final.component';
import { RegistrationStepFinalGuard } from './steps/step-final/registration-step-final.guard';
import { RegistrationDeactivateGuard } from './registration-deactivate.service';
import { ActivationCodeResolve } from './user-activation/activation-code-resolve.service';
import { RegistrationStepsResolve } from './registration-steps-resolve.service';

export const REGISTRATION_STEPS: WizardStep[] = [
	{
		name: 'Step 1', order: 1,
		route: {
			path: 'step1', component: RegistrationStep1Component,
			resolve: {
				roles: RoleResolve,
				prefixes: PrefixResolve,
				countries: CountryResolve,
				states: StateResolve
			}
		}
	},
	{
		name: 'Step 2', order: 2,
		route: {
			path: 'step2', component: RegistrationStep2Component,
			resolve: {
				businessAreas: BusinessAreasResolve
			}
		}
	},
	// {
	// 	name: 'Step 3', order: 3,
	// 	route: {
	// 		path: 'step3', component: RegistrationStep3Component,
	// 		resolve: {
	// 			companies: CompanyResolve,
	// 			offices: OfficeResolve
	// 		}
	// 	}
	// },
	{
		name: 'Step 3', order: 3,
		route: {
			path: 'step3', component: RegistrationStep4Component,
			resolve: {
				model: CopyrightsResolve
			}
		}
	},
	{name: 'Step 4', order: 4, route: {path: 'step4', component: RegistrationStep5Component}}
];
export const FIRST_STEP = REGISTRATION_STEPS[0];

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'registration',
				component: RegistrationComponent,
				canActivate: [NotAuthGuard],
				canDeactivate: [RegistrationDeactivateGuard],
				children: [
					{path: '', redirectTo: FIRST_STEP.route.path, pathMatch: 'full'},
					{
						path: '',
						canActivateChild: [RegistrationGuard],
						children: getWizardRoutes(REGISTRATION_STEPS, RegistrationStepsResolve)
					}
				]
			},
			{
				path: 'registration/step-final', pathMatch: 'full',
				component: RegistrationStepFinalComponent, canActivate: [RegistrationStepFinalGuard]
			},
			{
				path: 'user-activation',
				component: UserActivationComponent,
				resolve: {
					message: ActivationCodeResolve
				}
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		RegistrationGuard,
		RegistrationDeactivateGuard,
		RoleResolve,
		PrefixResolve,
		CountryResolve,
		StateResolve,
		BusinessAreasResolve,
		CompanyResolve,
		OfficeResolve,
		CopyrightsResolve,
		RegistrationStepFinalGuard,
		ActivationCodeResolve
	]
})
export class RegistrationRoutingModule {
}