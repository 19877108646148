import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RegistrationService } from '../../registration.service';
import { ActivatedRoute } from '@angular/router';
import { BusinesArea, RegistrationUser } from '../../shared/registration-user';

@Component({
	template: require('./registration-step2.component.html')
})

export class RegistrationStep2Component implements OnInit {
	public user: RegistrationUser;
	public businessAreas: BusinesArea[];

	@ViewChild('step2') currentForm: NgForm;

	constructor(private registrationService: RegistrationService,
	            private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: {businessAreas: BusinesArea[]}) => {
			this.user = this.registrationService.user;
			this.businessAreas = data.businessAreas;
			this.registrationService.currentForm = this.currentForm;
		});
	}
}