import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Country, State, BusinesArea, Office } from '../../../registration/shared/registration-user';
import { ProfileUser } from '../../profileUser';
import { ProfileUserService } from '../profile-user.service';
import { LoadingMaskService } from '../../../../shared/loading-mask/loading-mask.service';
import { ArrayUtils, ObjectUtils } from '../../../../core/object-utils';
import { ProfileStateService } from '../profile-state.service';
import { EMAIL_PATTERN } from '../../../registration/steps/step1/registration-step1.component';

export class UserSubmittedMessage {
	constructor(public isUserSavedSuccessfully: boolean,
	            public isProfileChanged: boolean) {}
}

@Component({
	selector: 'vcm-profile-contacts-form',
	template: require('./profile-contacts-form.component.html')
})

export class ProfileContactsFormComponent implements OnInit {
	@Output() public userSubmitted: EventEmitter<UserSubmittedMessage> = new EventEmitter<UserSubmittedMessage>();

	emailPattern = EMAIL_PATTERN;
	user: ProfileUser;
	prefixes: string[];
	countries: Country[] = [];
	states: State[] = [];
	businessAreas: BusinesArea[];
	offices: Office[] = [];
	lastValidatedDate: Date;

	selectedCountry: Country;
	selectedOffice: Office;
	selectedState: State;

	private isProfileChanged: boolean = false;
	private notInListOption = new Office(0, '-- Other - Enter Address --');

	constructor(private route: ActivatedRoute,
	            private profileService: ProfileUserService,
	            private stateService: ProfileStateService,
	            private mask: LoadingMaskService) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: {
			profileUser: ProfileUser,
			prefixes: string[],
			dropdowns: any,
			businessAreas: BusinesArea[]
		}) => {
			this.user = data.profileUser;
			this.prefixes = data.prefixes;
			this.countries = data.dropdowns.Country;
			this.offices = ObjectUtils.deepCopy(data.dropdowns.ActiveOffices);
			if (this.offices.length > 0) {
				this.insertDefaultOption(this.offices, this.notInListOption);
			}
			this.businessAreas = data.businessAreas;
			this.lastValidatedDate = this.user.ValidationDate;

			this.selectedCountry = ArrayUtils.findByProperty(this.countries, 'CountryCode', this.user.AddressCountry);
			this.updateCountryStates(this.selectedCountry, this.user.AddressState);
			this.selectedOffice = this.getSelectedOffice();
		});
	}

	isOtherOfficeOptionSelected(): boolean {
		return this.selectedOffice && this.selectedOffice.ContactId === this.notInListOption.ContactId;
	}

	officeOnChange(office: Office): void {
		if (!this.isOtherOfficeOptionSelected()) {
			this.user.AddressLine1 = office.AdressLine1;
			this.user.AddressLine2 = office.AdressLine2;
			this.user.AddressCity = office.AdressCity;
			this.selectedCountry = ArrayUtils.findByProperty(this.countries, 'CountryCode', office.Country);
			this.user.AddressCountry = this.selectedCountry.CountryCode;
			this.updateCountryStates(this.selectedCountry, office.State);
			this.user.AddressZip = office.Zip;
		}
		this.user.LinkedOffice = office.ContactId;
		this.setFormChanged();
	}

	countryOnChange(country?: Country): void {
		this.selectedState = null;
		if (country) {
			this.user.AddressCountry = country.CountryCode;
			this.updateCountryStates(country);
		}
		this.setFormChanged();
	}

	stateOnChange(state?: State): void {
		this.user.AddressState = state ? state.LocationState : null;
		this.setFormChanged();
	}

	saveUser(): void {
		this.mask.addLoading();
		this.profileService.saveUser(this.user).then((response) => {
			this.mask.removeLoading();
			if (response.ValidationDate) {
				this.user.ValidationDate = response.ValidationDate;
			}
			let userSubmittedMessage = new UserSubmittedMessage(response.Result, this.isProfileChanged);
			this.userSubmitted.emit(userSubmittedMessage);
			window.scrollTo(0, 0);
		});
	}

	setFormChanged(): void {
		this.isProfileChanged = true;
	}

	private updateCountryStates(country: Country, selectedStateName?: string): void {
		this.states = [];
		this.stateService.getStates(country)
			.then(states => {
				this.states = states;
				if (selectedStateName) {
					this.setSelectedState(selectedStateName);
				}
			});
	}

	private setSelectedState(selectedStateName: string): void {
		this.selectedState = ArrayUtils.findByProperty(this.states, 'LocationState', selectedStateName);
		this.user.AddressState = this.selectedState.LocationState;
	}

	private getSelectedOffice(): Office {
		if (this.user.LinkedOffice) {
			return ArrayUtils.findByProperty(this.offices, 'ContactId', this.user.LinkedOffice);
		} else {
			return this.notInListOption;
		}
	}

	private insertDefaultOption(options: any[], defaultOption: any) {
		options.splice(0, 0, defaultOption);
	}
}