import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class AuthGuard implements CanActivate {
	public refUrl: string = 'home';

	constructor(private router: Router,
	            private userService: UserService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		this.refUrl = state.url;

		if (this.userService.hasCurrentUser()) {
			// logged in so return true
			return true;
		}

		// not logged in so redirect to login page
		this.router.navigate(['/login']);
		return false;
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivate(childRoute, state);
	}
}