import { NgModule } from '@angular/core';
import { CertificationComponent } from './certification.component';
import { CertificationService } from './certification.service';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [
		CertificationComponent
	],
	providers: [
		CertificationService
	]
})

export class CertificationModule {
}


