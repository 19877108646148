import * as FileSaver from 'file-saver';
import { Injectable } from '@angular/core';
import { ResourcesService } from '../resources.service';
import { ActiveFiltersService } from '../filters/active-filters.service';
import { HttpClient } from '../../../core/http/http-client';
import { Response, ResponseContentType } from '@angular/http';
import { ConfigService } from '../../../core/config.service';
import { LoggerService } from '../../../core/logger/logger.service';

export class ImgDetails {
	Id: number;
	UrlImageThumbnail: string;
	UrlImage: string;
}

export class ResourceSM {
	Type: string;
	Url: string;
}

export class SocialMedia {
	Id: number;
	ServiceLogos: string[];
	ContentTitle: string;
	Demographics: string[];
	Description: string;
	FacebookPost: string;
	IndFirstPublication: boolean;
	PostWindowFrom: string;
	PostWindowTo: string;
	Priority: number;
	ServiceLongname: string;
	TuneIn: string;
	TwitterPost: string;
	ImagesSM: ImgDetails[];
	UrlResource: string[];
	UrlTypeResource: string[];
	VideoUrl: string[];
	ResourcesSM: ResourceSM[];
}

@Injectable()
export class SocialMediaService {
	constructor(private resourcesService: ResourcesService,
	            private configService: ConfigService,
	            private activeFilters: ActiveFiltersService,
	            private http: HttpClient,
	            private logger: LoggerService) {}

	private url = this.configService.baseUrl;

	getSocialMedia(filter: any = {}): Promise<SocialMedia[]> {
		filter.resourceType = 'SOCMEDIA';
		return this.resourcesService.getResourcesList(filter);
	}

	getSocialMediaById(id: number): Promise<SocialMedia> {
		return this.getSocialMedia(this.activeFilters.getActiveFilters())
			.then((socialMedias: SocialMedia[]) => {
				return socialMedias.find((socialMedia: SocialMedia) => {
					return socialMedia.Id === id;
				});
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	private createMailLink(): string {
		return this.url + '/api/ExportSMEmailDetails'
	}

	private createExcelLink(): string {
		return this.url + '/api/ExportExcelSM'
	}

	sendDetails(Ids: number[]): Promise<Response> {
		return this.http.post(this.createMailLink(), Ids)
			.toPromise()
			.then(res => {
				return res;
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	exportToExcel(idArray: number[]): Promise<Response> {
		return this.http.post(this.createExcelLink(), idArray, {responseType: ResponseContentType.Blob})
			.toPromise()
			.then(res => {
				this.downloadFile(res);
				return res;
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	downloadFile(data: Response) {
		let blob = new Blob([data.blob()], {type: 'application/octet-stream'});
		let filename = 'SocialMedia.xlsx';

		if (data.headers.has('Content-Disposition')) {
			let cdHeader = data.headers.get('Content-Disposition');
			filename = cdHeader.slice(cdHeader.indexOf('"') + 1, -1);
		}

		FileSaver.saveAs(blob, filename);
	}

}