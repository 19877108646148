import { Injectable } from '@angular/core';
import { HttpClient } from '../../../../../core/http/http-client';
import { ConfigService } from '../../../../../core/config.service';
import { LoggerService } from '../../../../../core/logger/logger.service';

export class SignalSource {
	Id: number;
	Name: string;
}
export class SignalService {
	Id: number;
	Name: string;
}
export class SignalDecoder {
	Id: number;
	Name: string;
}

@Injectable()
export class DropdownsService {

	private url = this.configService.baseUrl + '/api/AddMissingSignal';

	private dropdowns: any;

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	getDropdowns(signalSourceCode?: string, decoderType?: string): Promise<any> {
		if (this.dropdowns) {
			return Promise.resolve(this.dropdowns);
		} else {
			return this.loadDropdowns(signalSourceCode, decoderType);
		}
	}

	getUrl(signalSourceCode?: string, decoderType?: string): string {
		let fullUrl = this.url;
		if (signalSourceCode) {
			fullUrl += '?signalSourceCode=' + signalSourceCode;
			if (decoderType) {
				fullUrl += '&decoderType=' + signalSourceCode;
			}
		}
		return fullUrl;
	}

	private loadDropdowns(signalSourceCode?: string, decoderType?: string): Promise<any> {
		return this.http.get(this.getUrl(signalSourceCode, decoderType))
			.toPromise()
			.then(res => {
				let dropdowns = res.json();
				return dropdowns;
			}).catch(error => {
				this.logger.error(error);
				return null;
			});
	}
}