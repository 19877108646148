import { Injectable } from '@angular/core';
import { ConfigService } from '../../core/config.service';
import { HttpClient } from '../../core/http/http-client';
import { LoggerService } from '../../core/logger/logger.service';
import { Response } from '@angular/http';

export interface RelatedUrlsList {
	Title: string;
	Urls: RelatedLink[];
}

export interface RelatedLink {
	Title: string;
	Link: string;
}

@Injectable()
export class RelatedContentService {
	private url = this.configService.baseUrl + '/api/RelatedContent?contentId=';

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private logger: LoggerService) {
	}

	getRelatedContent(id: number): Promise<RelatedUrlsList[]> {
		return this.http.get(this.url + id).toPromise()
			.then((res: Response) => {
				let relatedContent: RelatedUrlsList[] = res.json();
				return relatedContent;
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}

