import { Component, Input, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Landing, Module } from './landing.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SlimScrollDirective } from 'ng2-slimscroll/src/directives/slimscroll.directive';

@Component({
	selector: 'vcm-landing',
	template: require('./landing.component.html')
})

export class LandingComponent {
	landing: Landing;
	swfUrl: SafeResourceUrl = null;

	@Input('landing')
	set setLanding(landing: Landing) {
		this.landing = landing;
		this.swfUrl = landing.SwfUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(landing.SwfUrl) : null;
	}

	@ViewChild('slimScrollOne') slimScroll: SlimScrollDirective;

	constructor(private router: Router,
	            private sanitizer: DomSanitizer) {
	}

	isTitleShow(module: Module): boolean {
		if (!this.landing.Modules || this.landing.Modules.length == 1) {
			return false;
		}

		if (module.ItemType === 'w' && module.Title) {
			return true;
		}

		return !!module.Title;
	}

	isBlockHeightChanged(module: Module, moduleType: string): boolean {
		return module.ItemType === 'l' && module[moduleType] && !!module.Title;
	}

	navigate(e: any, url: string): void {
		e.preventDefault();
		if (!url) {
			return;
		}
		if (!url.includes('://')) {
			this.router.navigateByUrl(url);
		} else {
			window.open(url)
		}
	}

	onSlideChange(): void {
		if (this.slimScroll) {
			this.slimScroll.getBarHeight();
		}
	}
}
