import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/auth/authentication.service';
import { UserService } from '../../../core/auth/user.service';
import { SoundService } from './../../../core/sound/sound.service';

@Component({
	selector: 'vcm-profile-drop',
	template: require('./profile-dropdown.component.html')
})

export class ProfileDropComponent implements OnInit{
	public isOpen: boolean = false;
	public userName: string;
	public repName: string;
	public isMuted: boolean = false;

	constructor(
		private auth: AuthenticationService,
		private userService: UserService,
		private soundService: SoundService
	) {
	}

	ngOnInit(){
		this.userName = this.userService.getCurrentUser().FirstName || 'Profile';
		this.repName = this.userService.getCurrentUser().RepName;
		this.soundService.isButtonMuted().subscribe(muted => this.isMuted = muted);
	}

	singOut() {
		this.auth.logout();
	}

	closeDropDown() {
		this.isOpen = false;
	}

	toggleSound() {
		this.soundService.toggleSound();
	}
}