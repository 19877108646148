import { Component } from '@angular/core';
import { UserSubmittedMessage } from './profile-contacts-form/profile-contacts-form.component';

@Component({
	template: require('./profile-contacts.component.html')
})

export class ProfileContactsComponent {
	isProfileChanged: boolean = false;
	userSubmitted: boolean = false;
	isUserSavedSuccessfully: boolean;

	displayUserSubmittedMessage(userSubmittedMessage: UserSubmittedMessage): void {
		this.isProfileChanged = userSubmittedMessage.isProfileChanged;
		this.isUserSavedSuccessfully = userSubmittedMessage.isUserSavedSuccessfully;
		this.userSubmitted = true;
	}

	back(): void {
		this.userSubmitted = false;
	}
}