import { Injectable } from '@angular/core';
import { ConfigService } from '../../../core/config.service';
import { HttpClient } from '../../../core/http/http-client';
import { ProgramminHighlightsInfo } from '../programming-highlights';
import { LoggerService } from '../../../core/logger/logger.service';

@Injectable()
export class NextAirDatesService {
	private url = this.configService.baseUrl;
	nextAirDates: ProgramminHighlightsInfo[];

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private logger: LoggerService) {
	}

	private createLiveGridLink(brand: string, startDate: string): string {
		return this.url + '/api/LiveGridNextAirDates/?serviceId='
			+ brand
			+ '&startDate='
			+ startDate;
	}

	private createHighlightLink(brands: number[], relatedProgram: number): string {
		return this.url + '/api/PHNextAirDates/?brands='
			+ brands.join('&brands=')
			+ '&relatedProgramm='
			+ relatedProgram;
	}

	getLiveGridNextAirDates(brand: string, startDate: string): Promise<ProgramminHighlightsInfo[]> {
		return this.http.get(this.createLiveGridLink(brand, startDate))
			.toPromise()
			.then(res => {
				this.nextAirDates = res.text() ? res.json() : null;
				return this.nextAirDates;
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	getHighlightNextAirDates(brands: number[], relatedProgram: number): Promise<ProgramminHighlightsInfo[]> {
		return this.http.get(this.createHighlightLink(brands, relatedProgram))
			.toPromise()
			.then(res => {
				this.nextAirDates = res.text() ? res.json() : null;
				return this.nextAirDates;
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}
