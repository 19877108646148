import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Order } from './order';
import { OrdersService } from './orders.service';
import { LoadingMaskService } from '../../shared/loading-mask/loading-mask.service';

@Injectable()
export class OrdersResolve implements Resolve<Order[]> {
	constructor(private service: OrdersService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<Order[]> {
		this.mask.addLoading();
		return this.service.getOrders()
			.then((orders: Order[]) => {
				this.mask.removeLoading();
				return orders;
			});
	}
}
