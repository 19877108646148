import { Component, OnInit } from '@angular/core';
import { Landing, LandingService } from '../../landing/landing.service';

@Component({
	selector: 'vcm-footer-landing',
	template: require('./footer-landing.component.html'),
})

export class FooterLandingComponent implements OnInit {
	landing: Landing;

	constructor(private landingService: LandingService) {
	}

	ngOnInit() {
		this.landingService.getLanding({
			landingType: 'FOOTER'
		}).subscribe(landing => this.landing = landing);
	}
}