import { Component } from '@angular/core';
import { SignalsService } from '../../signals.service';
import { HeadendSummary } from '../shared/headend-summary.service';
import { SwapRequestService, SwappedService } from './swap-request.service';
import { LoadingMaskService } from '../../../../shared/loading-mask/loading-mask.service';
import { HeadendGridRow, Acp } from '../../signals';
import { ActivatedRoute } from '@angular/router';

@Component({
	template: require('./swap-request.component.html')
})

export class SwapRequestComponent {
	isRequestSubmitted: boolean = false;
	headendId: number;
	headendSummary: HeadendSummary;
	acps: HeadendGridRow[];

	constructor(private route: ActivatedRoute,
	            private signalsService: SignalsService,
	            private swapRequestService: SwapRequestService,
	            private mask: LoadingMaskService) {}

	ngOnInit(): void {
		this.headendId = this.signalsService.headendId;
		this.acps = this.signalsService.acps;
		this.swapRequestService.swappedRequest = this.signalsService.acps.map((acp: Acp) => new SwappedService());
		this.route.data.forEach((data: { headendSummary: HeadendSummary }) => {
			this.headendSummary = data.headendSummary;
		});
	}

	displayFeedbackSubmittedMessage(feedback): void {
		this.isRequestSubmitted = true;
	}

	onSubmit(swap: any): void {
		this.mask.addLoading();
		let request = Object.assign({
			Services: this.swapRequestService.swappedRequest,
			HeadendId: this.headendId
		}, this.headendSummary);

		this.swapRequestService.submitRequest(request).then(() => {
			this.mask.removeLoading();
			this.isRequestSubmitted = true;
			window.scrollTo(0, 0);
		});
	}
}
