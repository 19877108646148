import { NgModule } from '@angular/core';
import { LandingComponent } from './landing.component';
import { SharedModule } from '../../../shared/shared.module';
import { LandingService } from './landing.service';
import { LandingResolve } from './landing-resolve.service';
import { VideoModule } from './../../../shared/video/video.module';
import { RouterModule } from '@angular/router';

@NgModule({
	imports: [
		SharedModule,
		VideoModule,
		RouterModule
	],
	declarations: [
		LandingComponent
	],
	exports: [
		LandingComponent
	],
	providers: [
		LandingResolve,
		LandingService
	]
})
export class LandingModule {
}