import { Injectable } from '@angular/core';

import { ConfigService } from '../../core/config.service';
import { HttpClient } from '../../core/http/http-client';
import { LoggerService } from '../../core/logger/logger.service';

@Injectable()
export class LogResourceDownloadService {
    private url = this.configService.baseUrl + '/api/Resources?';

    constructor(private configService: ConfigService,
                private http: HttpClient,
                private logger: LoggerService) {
    }

    log(servicesShortName: string, resourceType: string, downloadUrlEncoded: string) {
        return this.http.get(this.url + 'servicesShortName=' + servicesShortName + '&resourceType=' + resourceType + '&downloadUrl=' + downloadUrlEncoded)
            .toPromise()
            .catch((err) => {
                this.logger.error(err);
            });
    }
}
