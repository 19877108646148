import { Component, Input } from '@angular/core';

@Component({
	template: require('./landing-press.component.html'),
	selector: 'vcm-landing-press'
})

export class LandingPressComponent {
	@Input() resources: any;
}
