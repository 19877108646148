import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SignalsDciiHeadend, DCIIGridPageModel } from '../signals';
import { SlimScrollDirective } from 'ng2-slimscroll/src/directives/slimscroll.directive';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { DciiGridService } from './dcii-grid.service';

@Component({
	selector: 'vcm-dcii-grid',
	template: require('./dcii-grid.component.html')
})

export class DciiGridComponent implements OnInit {
	idMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

	headends: SignalsDciiHeadend[];

	surveyStatusDropdownList: any;
	customerSupportStatusDropdownList: any;
	headendNameDropdownList: string[];

	surveyStatusFilter: any;
	customerStatusFilter: any;
	msoCodeFilter: string;
	headendIdFilter: string;
	headendNameFilter: string;

	dCIIUserRole: string;
	@ViewChild('slimScroll') slimScroll: SlimScrollDirective;

	constructor(private route: ActivatedRoute,
				private mask: LoadingMaskService,
				private service: DciiGridService) {
	};

	ngOnInit() {
		this.route.data.forEach((data: {headends: DCIIGridPageModel}) => {
			this.headends = data.headends.GridData;
			this.surveyStatusDropdownList = data.headends.SurveyStatusIdFilterList;
			this.customerSupportStatusDropdownList = data.headends.CustomerSupportStatusIdFilterList;
			this.headendNameDropdownList = data.headends.HeadendNameFilterList;

			if (this.headends[0]) {
				this.dCIIUserRole = this.headends[0].DCIIUserRole;
			}
		});
	}

	isOpenOnChange(isOpen: boolean): void {
		if (isOpen) {
			this.slimScroll.getBarHeight();
		}
	}

	resetDCIIFilters(): void {
		this.mask.addLoading();
		this.surveyStatusFilter = null;
		this.customerStatusFilter = null;
		this.msoCodeFilter = null;
		this.headendIdFilter = null;
		this.headendNameFilter = null;

		this.service.getDciiGridFiltered(null).then((res) => {
			this.mask.removeLoading();
			this.headends = res['GridData'];
		});
	}

	applyDCIIFilters(): void {
		this.mask.addLoading();

		let filterStr = '';
		if (this.surveyStatusFilter) {
			filterStr += '&surveyStatus=' + this.surveyStatusFilter['Id'];
		}
		if (this.customerStatusFilter) {
			filterStr += '&customerStatus=' + this.customerStatusFilter['Id'];
		}
		if (this.msoCodeFilter) {
			filterStr += '&affiliateId=' + this.msoCodeFilter;
		}
		if (this.headendIdFilter) {
			filterStr += '&accountId=' + this.headendIdFilter;
		}
		if (this.headendNameFilter) {
			filterStr += '&accountName=' + this.headendNameFilter;
		}

		this.service.getDciiGridFiltered(filterStr == '' ? null : filterStr).then((res) => {
			this.mask.removeLoading();
			this.headends = res['GridData'];
		});
	}
}
