import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../../authorization.service';
import { AuthorizationRequest, CommonObject } from '../../shared/authorization-request';
import { AuthorizationDropdowns } from './authorization-dropdowns.service';
import { HeadendSummary } from '../../../shared/headend-summary.service';
import { EMAIL_PATTERN } from '../../../../../registration/steps/step1/registration-step1.component';

@Component({
	template: require('./authorization-step1.component.html')
})

export class AuthorizationStep1Component implements OnInit {
	authorizationRequest: AuthorizationRequest;
	dropdowns: AuthorizationDropdowns;
	summary: HeadendSummary;
	isViacomSource: boolean;
	isFiberfedSource: boolean;
	isNCTCContract: boolean;
	isMotorolaDecoderType: boolean;

	emailPattern = EMAIL_PATTERN;
	anchorMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
	numberMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

	@ViewChild('step1') currentForm: NgForm;

	constructor(private authorizationService: AuthorizationService,
	            private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: {dropdowns: AuthorizationDropdowns, summary: HeadendSummary}) => {
			this.authorizationRequest = this.authorizationService.authorizationRequest;
			this.dropdowns = data.dropdowns;
			this.summary = data.summary;
			this.authorizationService.currentForm = this.currentForm;
		});
		this.authorizationRequest.Action = new CommonObject({
			Description: "Authorize New Service",
			Id: "1"
		});
		this.authorizationRequest.Anchor = this.authorizationRequest.Anchor || '000';
		this.authorizationService.beforeNextStep = this.beforeNextStep.bind(this);
	}

	isDetailsShown(): boolean {
		if (!this.authorizationRequest.NCTC || !this.authorizationRequest.SignalSource) {
			return false;
		}
		return +this.authorizationRequest.SignalSource.Id != 1 || !!this.authorizationRequest.DecoderType;
	};

	signalSourceOnChange(signalSource?: CommonObject): void {
		this.isViacomSource = false;
		this.isFiberfedSource = false;
		if (signalSource) {
			this.isViacomSource = +signalSource.Id == 1;
			this.isFiberfedSource = +signalSource.Id == 3;
		}
	}

	NCTCOnChange(NCTC?: CommonObject): void {
		this.isNCTCContract = false;
		if (NCTC) {
			this.isNCTCContract = +NCTC.Id == 1;
		}
	}

	decoderTypeOnChange(decoderType?: CommonObject): void {
		this.isMotorolaDecoderType = false;
		if (decoderType) {
			this.isMotorolaDecoderType = +decoderType.Id == 0;
		}
	}

	private beforeNextStep(): void {
		this.removeExcessProperties();
	}

	private removeExcessProperties(): void {
		Object.keys(this.authorizationRequest).forEach((property: string) => {
			if (!this.currentForm.value.hasOwnProperty(property) && property != "HeadendId") {
				this.authorizationRequest[property] = null;
			}
		});
	}
}
