import { Component, OnInit, Injectable, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginPageModel } from './login-page-model';

@Component({
	template: require('./login.component.html')
})

@Injectable()
export class LoginComponent implements OnInit {
	@Input() pageModel: LoginPageModel;

	constructor(private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.route.data.forEach((data: {model: LoginPageModel}) => {
			this.pageModel = data.model;
		});
	}
}