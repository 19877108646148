import { Component, Input, ElementRef, OnInit } from '@angular/core';

import { VgAPI } from 'videogular2/src/core/services/vg-api';
import { Video } from '../video';

@Component({
	selector: 'vcm-video-download',
	template: `<a [href]="source.DownloadUrl || source.Src" class="icon" download>Download</a>`
})

export class VideoDownload implements OnInit {
	elem: HTMLElement;
	vgFor: string;
	target: any;
	@Input('source') source: Video;

	constructor(ref: ElementRef, public API: VgAPI) {
		this.elem = ref.nativeElement;
	}

	ngOnInit() {
		this.API.playerReadyEvent.subscribe(() => this.onPlayerReady());
	}

	onPlayerReady() {
		this.target = this.API.getMediaById(this.vgFor);
	}
}