export class LoginPageModel {
	Context: string;
	Image: string;
	SliderItems: SliderItem[] = [];
	Title: string;
}

export class SliderItem {
	Text: string;
	Url: string;
}