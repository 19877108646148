import { Injectable } from '@angular/core';
import { ResourcesService } from '../resources.service';

export class Certification {
	ContentName: string;
	LegalText: string;
}

@Injectable()
export class CertificationService {

	constructor(private resourcesService: ResourcesService) {}

	getCertification(filter: any = {}): Promise<Certification[]> {
		filter.resourceType = 'certification';
		return this.resourcesService.getResourcesList(filter);
	}
}