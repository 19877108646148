import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFilters, BRANDS } from '../../filters/common-filters';
import * as moment from 'moment';
import { SocialMediaFilterOptionsService } from './social-media-filter-options.service';
import { FilterOption } from '../../filters/filter-option';
import { ActiveFiltersService } from '../../filters/active-filters.service';

export const KEYWORD = 'Keyword';
export const PERIOD_FROM = 'PeriodFrom';
export const PERIOD_TO = 'PeriodTo';
export const FRANCHISE = 'Franchise';
export const DEMO = 'Demo';

@Component({
	template: require('./social-media-filter.component.html')
})
export class SocialMediaFilterComponent extends CommonFilters implements OnInit {
	franchiseOptions: FilterOption[] = [];
	demoOptions: FilterOption[] = [];
	keyword: string;
	periodFrom: Date;
	periodTo: Date;

	selectedFranchiseOption: FilterOption;
	selectedDemoOption: FilterOption;

	KEYWORD = KEYWORD;
	PERIOD_FROM = PERIOD_FROM;
	PERIOD_TO = PERIOD_TO;
	FRANCHISE = FRANCHISE;
	DEMO = DEMO;
	DATE_FORMAT = 'YYYY-MM-DD';

	private resourceUrl: string = '/resources/social-media';

	constructor(private route: ActivatedRoute,
	            private router: Router,
	            private activeFilters: ActiveFiltersService,
	            private filterOptionsService: SocialMediaFilterOptionsService) {
		super(activeFilters);
	}

	ngOnInit(): void {
		this.route.data.forEach(() => {
			this.filterOptionsService.getOptions().then((filterOptions: Map<string, FilterOption[]>) => {
				this.franchiseOptions = filterOptions.get(FRANCHISE) || [];
				this.demoOptions = filterOptions.get(DEMO) || [];

				this.changeBrandsAndRelatedFilters(this.selectedBrandFilterOptions);
				this.selectedFranchiseOption = this.findById(this.franchiseOptions, this.activeFilters.getFilter(this.FRANCHISE));
				this.changeFranchiseAndRelatedFilters(this.selectedFranchiseOption);
				this.selectedDemoOption = this.findById(this.demoOptions, this.activeFilters.getFilter(this.DEMO));

				this.keyword = this.activeFilters.getFilter(this.KEYWORD);
				this.periodFrom = this.parseDate(this.activeFilters.getFilter(this.PERIOD_FROM));
				this.periodTo = this.parseDate(this.activeFilters.getFilter(this.PERIOD_TO));
			});
		});
	}

	changeBrandsAndRelatedFilters(selectedBrands: FilterOption[]): void {
		this.selectedFranchiseOption = null;
		this.selectedDemoOption = null;
		this.selectedBrandFilterOptions = selectedBrands;
		this.filterOptionsService.getOptions({
			[BRANDS]: this.selectedBrandFilterOptions
		}).then((filterOptions: Map<string, FilterOption[]>) => {
			this.franchiseOptions = filterOptions.get(FRANCHISE) || [];
			this.demoOptions = filterOptions.get(DEMO) || [];
		});
	}

	changeFranchiseAndRelatedFilters(selectedFranchises: FilterOption): void {
		this.selectedFranchiseOption = selectedFranchises;
		this.selectedDemoOption = null;
		this.filterOptionsService.getOptions({
			[BRANDS]: this.selectedBrandFilterOptions,
			[FRANCHISE]: this.selectedFranchiseOption ? [this.selectedFranchiseOption] : []
		}).then((filterOptions: Map<string, FilterOption[]>) => {
			this.demoOptions = filterOptions.get(DEMO) || [];
		});
	}

	applyFilters() {
		let filters = this.getFilterValues();
		filters[FRANCHISE] = this.selectedFranchiseOption ? this.selectedFranchiseOption.Id : null;
		filters[DEMO] = this.selectedDemoOption ? this.selectedDemoOption.Id : null;
		filters[PERIOD_FROM] = this.periodFrom ? moment(this.periodFrom).format(this.DATE_FORMAT) : null;
		filters[PERIOD_TO] = this.periodTo ? moment(this.periodTo).format(this.DATE_FORMAT) : null;
		filters[KEYWORD] = this.keyword;
		this.removeEmptyParams(filters);
		this.router.navigate([this.resourceUrl, filters]);
	}

	resetFilters() {
		this.resetCommonFilters();
		this.selectedFranchiseOption = null;
		this.selectedDemoOption = null;
		this.periodFrom = null;
		this.periodTo = null;
		this.keyword = null;
		this.router.navigate([this.resourceUrl]);
	}

	private parseDate(date: string): Date {
		//BUG IN NG-2 DATEPICKER, DOESN'T SUPPORT NULL VALUE
		return date ? moment(date).toDate() : undefined;
	}
}