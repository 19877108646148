import {
	Component, Input, ElementRef, Output, EventEmitter, OnInit, OnDestroy, ViewChild,
	OnChanges
} from '@angular/core';
import { Video } from '../video';
import { VgAPI } from 'videogular2/src/core/services/vg-api';
import { Subscription } from 'rxjs';
import { SlimScrollDirective } from 'ng2-slimscroll/src/directives/slimscroll.directive';

@Component({
	selector: 'vcm-video-playlist',
	template: require('./video-playlist.html'),
})

export class VideoPlaylist implements OnInit, OnChanges, OnDestroy {
	@Input('newVideoIndex') newVideoIndex: EventEmitter<number>;
	@Input('playlist') playlist: Video[];
	@Input('playlistStatus') togglePlayList: boolean;

	@Output() sourceChanged: EventEmitter<Video> = new EventEmitter<Video>();
	@Output() playlistStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
	@ViewChild('slimScroll') slimScroll: SlimScrollDirective;

	private elem: HTMLElement;
	private subscription: Subscription;
	private target: any;
	private vgFor: string;

	constructor(ref: ElementRef, public API: VgAPI) {
		this.elem = ref.nativeElement;
	}

	ngOnInit() {
		this.API.playerReadyEvent.subscribe(() => this.onPlayerReady());
		if (this.newVideoIndex) {
			this.subscription = this.newVideoIndex.subscribe((index: number) => this.playVideo(this.playlist[index], index));
		}
	}

	ngOnChanges(changes: any) {
		if (changes && changes.togglePlayList && changes.togglePlayList.currentValue) {
			this.slimScroll.getBarHeight();
		}
	}

	onPlayerReady(): void {
		this.target = this.API.getMediaById(this.vgFor);
	}

	playVideo(video: Video, i: number): void {
		this.sourceChanged.emit(video);
		this.changeVideoStatus(i);
		(this.API.getMasterMedia().elem as HTMLMediaElement).load();
		(this.API.getMasterMedia().elem as HTMLMediaElement).autoplay = true;
		this.playlistStatusChanged.emit(false);
	}

	backToVideo(): void {
		this.playlistStatusChanged.emit(false);
		(this.API.getMasterMedia().elem as HTMLMediaElement).play();
	}

	trackBySrc(index: number, video: Video): string {
		return video ? video.Src : null;
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	private changeVideoStatus(i: number): void {
		this.playlist.forEach((item) => {
			item.NowPlaying = false;
		});

		this.playlist[i].NowPlaying = true;
	}
}