import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './page-not-found.component';
import { SharedModule } from '../../shared/shared.module';
import { ErrorHasOccuredComponent } from './error-has-occured.component';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [
		PageNotFoundComponent,
		ErrorHasOccuredComponent
	],
	providers: []
})

export class ErrorsModule {
}