import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ResourcesComponent } from './resources.component';
import { AuthGuard } from '../../core/auth/auth.guard';
import { ChannelDescriptionComponent } from './channel-description/channel-description.component';
import { ChannelDescriptionResolve } from './channel-description/channel-desciption-resolve.service';
import { CertificationComponent } from './certification/certification.component';
import { CertificationResolve } from './certification/certification-resolve.service';
import { PressComponent } from './press/press.component';
import { PressResolve } from './press/press-resolve.service';
import { OtherResolve } from './other/other-resolve.service';
import { OtherComponent } from './other/other.component';
import { LogoComponent } from './logo/logo.component';
import { LogoResolve } from './logo/logo-resolve.service';
import { AvailAlertsComponent } from './avail-alerts/avail-alerts.component';
import { AvailAlertsResolve } from './avail-alerts/avail-alerts-resolve.service';
import { SocialMediaComponent } from './social-media/social-media.component';
import { SocialMediaResolve } from './social-media/social-media-resolve.service';
import { ResourcesBrandsResolve } from './resources-brands-resolve.service';
import { SocialMediaDetailsComponent } from './social-media/social-media-details/social-media-details.component';
import { SocialMediaDetailsResolve } from './social-media/social-media-details/social-media-details-resolve.service';
import { SocialMediaFilterComponent } from './social-media/social-media-filter/social-media-filter.component';
import { CommonFiltersComponent } from './filters/common-filters/common-filters.component';
import { TechSpecsComponent } from './tech-specs/tech-specs.component';
import { TechSpecsResolve } from './tech-specs/tech-specs-resolve.service';
import { TechSpecsFilterComponent } from './tech-specs/tech-specs-filter/tech-specs-filter.component';
import { ImagesComponent } from './images/images.component';
import { ImagesResolve } from './images/images-resolve.service';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'resources',
				component: ResourcesComponent,
				canActivate: [AuthGuard],
				children: [
					{
						path: '',
						canActivateChild: [AuthGuard],
						children: [
							{
								path: 'channel-description',
								children: [
									{
										path: '',
										component: ChannelDescriptionComponent,
										resolve: {
											brands: ResourcesBrandsResolve,
											channelsDescription: ChannelDescriptionResolve
										}
									},
									{
										path: '',
										component: CommonFiltersComponent,
										outlet: 'filters'
									}
								]
							},
							{
								path: 'press',
								children: [
									{
										path: '',
										component: PressComponent,
										resolve: {
											brands: ResourcesBrandsResolve,
											presses: PressResolve
										}
									},
									{
										path: '',
										component: CommonFiltersComponent,
										outlet: 'filters'
									}
								]
							},
							{
								path: 'logo',
								children: [
									{
										path: '',
										component: LogoComponent,
										resolve: {
											brands: ResourcesBrandsResolve,
											logo: LogoResolve
										}
									},
									{
										path: '',
										component: CommonFiltersComponent,
										outlet: 'filters'
									}
								]
							},
							{
								path: 'research',
								children: [
									{
										path: '',
										component: OtherComponent,
										resolve: {
											brands: ResourcesBrandsResolve,
											others: OtherResolve
										}
									},
									{
										path: '',
										component: CommonFiltersComponent,
										outlet: 'filters'
									}
								]
							},
							{
								path: 'avail-alert',
								children: [
									{
										path: '',
										component: AvailAlertsComponent,
										resolve: {
											brands: ResourcesBrandsResolve,
											availAlerts: AvailAlertsResolve
										}
									},
									{
										path: '',
										component: CommonFiltersComponent,
										outlet: 'filters'
									}
								]
							},
							{
								path: 'social-media',
								children: [
									{
										path: '',
										component: SocialMediaComponent,
										resolve: {
											brands: ResourcesBrandsResolve,
											socialMedia: SocialMediaResolve
										}
									},
									{
										path: '',
										component: CommonFiltersComponent,
										outlet: 'filters'
									},
									{
										path: ':Id',
										component: SocialMediaDetailsComponent,
										resolve: {
											brands: ResourcesBrandsResolve,
											socialMediaElement: SocialMediaDetailsResolve
										},
									}
								]
							},
							{
								path: 'certification',
								children: [
									{
										path: '',
										component: CertificationComponent,
										resolve: {
											brands: ResourcesBrandsResolve,
											certifications: CertificationResolve
										}
									},
									{
										path: '',
										component: CommonFiltersComponent,
										outlet: 'filters'
									}
								]
							},
							{
								path: 'tech-specs',
								children: [
									{
										path: '',
										component: TechSpecsComponent,
										resolve: {
											brands: ResourcesBrandsResolve,
											techSpecs: TechSpecsResolve
										}
									},
									{
										path: '',
										component: TechSpecsFilterComponent,
										outlet: 'filters'
									}
								]
							},
							{
								path: 'images',
								component: ImagesComponent,
								resolve: {
									url: ImagesResolve
								}
							},
						]
					}
				]
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		ResourcesBrandsResolve,
		ChannelDescriptionResolve,
		PressResolve,
		CertificationResolve,
		AvailAlertsResolve,
		OtherResolve,
		LogoResolve,
		SocialMediaResolve,
		SocialMediaDetailsResolve,
		TechSpecsResolve,
		ImagesResolve
	]
})

export class ResourcesRoutingModule {
}
