import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RegistrationService } from '../../registration.service';
import { RegistrationUser, Role, Country, State } from '../../shared/registration-user';
import { StateService } from './state.service';

export const EMAIL_PATTERN = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/;

@Component({
	template: require('./registration-step1.component.html')
})

export class RegistrationStep1Component implements OnInit {
	user: RegistrationUser;
	roles: Role[];
	prefixes: string[];
	countries: Country[];
	states: State[] = [];
	statesLoading: boolean = false;

	emailPattern = EMAIL_PATTERN;
	phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ' ', 'x', ' ', /\d/, /\d/, /\d/, /\d/,];

	@ViewChild('step1') currentForm: NgForm;

	constructor(private registrationService: RegistrationService,
	            private stateService: StateService,
	            private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: {roles: Role[], countries: Country[], states: State[], prefixes: string[]}) => {
			this.user = this.registrationService.user;
			this.roles = data.roles;
			this.countries = data.countries;
			this.states = data.states;
			this.prefixes = data.prefixes;
			this.registrationService.currentForm = this.currentForm;
		});
	}

	public updateCountryStates(country?: Country): void {
		this.states = [];
		this.user.state = null;
		if (!country) {
			return;
		}
		this.statesLoading = true;
		this.stateService.getStates(country)
			.then(states => {
				this.statesLoading = false;
				this.states = states;
			});
	}
}