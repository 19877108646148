import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProgramDetails } from '../programming-highlights';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { ProgramDetailsService } from './program-details.service';
import { LiveGridShowsService } from './../live-grid/live-grid-shows.service';

@Injectable()
export class ProgramDetailsResolve implements Resolve<ProgramDetails> {
	constructor(private service: ProgramDetailsService,
	            private mask: LoadingMaskService,
	            private router: Router,
				private liveGridShowsService: LiveGridShowsService) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<ProgramDetails> {
		let serviceId = route.params['serviceId'];
		this.liveGridShowsService.setLiveGridId(+serviceId);
		let startDate = route.params['startDate'];
		this.mask.addLoading();
		return this.service.getProgramDetails(serviceId, startDate)
			.map((programDetails: ProgramDetails) => {
				this.mask.removeLoading();

				if (!programDetails) {
					this.router.navigate(['404']);
				}

				return programDetails;
			});
	}
}
