import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import {
	AuthorizationDropdownsService,
	AuthorizationDropdowns
} from './authorization-dropdowns.service';
import { LoadingMaskService } from '../../../../../../shared/loading-mask/loading-mask.service';

@Injectable()
export class AuthorizationDropdownsResolve implements Resolve<AuthorizationDropdowns> {
	constructor(private service: AuthorizationDropdownsService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<AuthorizationDropdowns> {
		this.mask.addLoading();
		return this.service.getDropDowns()
			.then((dropdowns: AuthorizationDropdowns) => {
				this.mask.removeLoading();
				return dropdowns;
			});
	}
}