import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserActivationService } from './user-activation.service';

@Injectable()
export class ActivationCodeResolve implements Resolve<string> {
	constructor(private service: UserActivationService, private router: Router){}

	resolve(route: ActivatedRouteSnapshot): Promise<string> {
		let activationCode = route.queryParams['ActivationCode'];
		if(!activationCode){
			this.router.navigate(['error']);
		}

		return this.service.activateUser(activationCode)
			.then(res => {
				let mess = res.json();
				return mess;
			});
	}
}