import { NgModule } from '@angular/core';
import { MultipleSelectComponent } from './multiple-select.component';
import { MdListModule, MdButtonModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '../directives/directives.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		MdListModule.forRoot(),
		MdButtonModule.forRoot(),
		DirectivesModule
	],
	declarations: [
		MultipleSelectComponent
	],
	exports: [
		MultipleSelectComponent
	],
})

export class MultipleSelectModule {
}