import { Injectable } from '@angular/core';
import { HttpClient } from '../../core/http/http-client';
import { Order } from './order';
import { ConfigService } from '../../core/config.service';
import { Response } from '@angular/http';
import { LoggerService } from '../../core/logger/logger.service';
import * as moment from 'moment';

@Injectable()
export class OrdersService {
	periodFrom: Date;
	periodTo: Date;

	private url = this.configService.baseUrl + '/api/OrderHistory';
	private DATE_FORMAT = 'YYYY-MM-DD';
	private ONE_DAY = 24 * 60 * 60 * 1000;
	private ONE_WEEK = 6 * this.ONE_DAY;
	private TODAY: Date = new Date();

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private logger: LoggerService) {
		this.setCurrentDate();
	}

	getOrders(): Promise<Order[]> {
		return this.http.get(this.createUrlDates())
			.toPromise()
			.then((res: Response) => {
				return res.json();
			}).catch((err) => {
				this.logger.error(err);
				return Promise.resolve(null);
			});
	}

	createUrlDates(): string {
		let fromStr: string = moment(this.periodFrom).format(this.DATE_FORMAT);
		let toStr: string = moment(this.periodTo).format(this.DATE_FORMAT);

		let url: string = this.url + '?startDate=' + fromStr + '&endDate=' + toStr;
		return url
	}

	setCurrentDate(): void {
		this.periodTo = this.TODAY;
		this.periodTo.setHours(0, 0, 0, 0);
		this.periodFrom = new Date(this.periodTo.getTime() - this.ONE_WEEK);
		this.periodFrom.setHours(0, 0, 0, 0);
	}
}
