import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './login.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { RecoveryPasswordComponent } from './recovery-password/recovery-password.component';
import { LoginRoutingModule } from './login-routing.module';
import { LoginPageService } from './login-page.service';

@NgModule({
	imports: [
		SharedModule,
		LoginRoutingModule
	],
	declarations: [
		LoginComponent,
		LoginFormComponent,
		RecoveryPasswordComponent
	],
	providers: [
		LoginPageService
	]
})

export class LoginModule {
}