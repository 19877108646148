import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AvailAlerts } from './avail-alerts.service';

export class AvailAlertRow extends AvailAlerts {
	isExpanded?: boolean = false;
}

@Component({
	template: require('./avail-alerts.component.html')
})

export class AvailAlertsComponent {
	availAlerts: AvailAlertRow[] = [];

	constructor(private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.route.data.forEach((data: {availAlerts: AvailAlerts[]}) => {
			this.availAlerts = data.availAlerts;
		});
	}
}