import { Injectable, OnDestroy, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

export enum Device {
	DESKTOP, TABLET, MOBILE
}

export class DeviceChangedEvent {
	constructor(public device: Device,
	            public width: number,
	            public height: number) {}
}

@Injectable()
export class ViewportService implements OnDestroy {
	viewportChanged: EventEmitter<DeviceChangedEvent> = new EventEmitter<DeviceChangedEvent>();
	currentDevice: Device;
	currentWidth: number;
	currentHeight: number;

	private scrollHandler: Subscription;
	private orientationHandler: Subscription;

	constructor() {
		this.handleResize();
		this.scrollHandler = Observable.fromEvent(window, 'resize')
			.debounceTime(100)
			.distinctUntilChanged()
			.subscribe(ev => this.handleResize());
		this.orientationHandler = Observable.fromEvent(document.body, 'orientationchange')
			.subscribe(ev => this.handleResize());
	}

	ngOnDestroy(): any {
		this.scrollHandler.unsubscribe();
		this.orientationHandler.unsubscribe();
		this.viewportChanged.unsubscribe();
	}

	private handleResize(): void {
		this.currentWidth = window.innerWidth;
		this.currentHeight = window.innerHeight;
		let device = ViewportService.determineDevice(window.innerWidth);
		if (this.currentDevice !== device) {
			this.currentDevice = device;
			this.viewportChanged.emit(new DeviceChangedEvent(this.currentDevice, this.currentWidth, this.currentHeight));
		}
	}

	private static determineDevice(deviceWidth: number): Device {
		let result: Device = Device.MOBILE;
		if (deviceWidth >= 1200) {
			result = Device.DESKTOP;
		} else if (deviceWidth >= 769) {
			result = Device.TABLET;
		}
		return result;
	}
}
