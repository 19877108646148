import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '../../../../../../core/http/http-client';
import { AuthenticationService } from '../../../../../../core/auth/authentication.service';
import { ConfigService } from '../../../../../../core/config.service';
import { ObjectUtils } from '../../../../../../core/object-utils';
import { CommonObject } from '../../shared/authorization-request';
import { LoggerService } from '../../../../../../core/logger/logger.service';

export interface AuthorizationDropdowns {
	Actions: CommonObject[];
	DecoderTypes: CommonObject[];
	NCTC: CommonObject[];
	OriginatingHeadendStates: CommonObject[];
	SignalSources: CommonObject[];
	SystemSizes: CommonObject[];
}

@Injectable()
export class AuthorizationDropdownsService {
	private dropdowns: AuthorizationDropdowns;

	constructor(private http: HttpClient,
	            private authService: AuthenticationService,
	            private configService: ConfigService,
	            private logger: LoggerService) {
		this.authService.onLogout.subscribe(() => {
			this.dropdowns = null;
		});
	}

	getDropDowns(): Promise<AuthorizationDropdowns> {
		if (this.dropdowns) {
			return Promise.resolve(ObjectUtils.deepCopy(this.dropdowns));
		}
		return this.loadDropDowns();
	}

	loadDropDowns(): Promise<AuthorizationDropdowns> {
		return this.http.get(this.configService.baseUrl + '/api/AuthorizeSignalDropdowns')
			.toPromise()
			.then((res: Response) => {
				this.dropdowns = res.json();
				return ObjectUtils.deepCopy(this.dropdowns);
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}