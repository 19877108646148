import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({selector: '[checkOverflow]'})
export class CheckOverflowDirective implements AfterViewInit{
	@Input() acceptableOverflow: number = 9;
	@Input() overflowClass: string = 'vcm_overflowed';

	constructor(public el: ElementRef) {}

	ngAfterViewInit(): void {
		if (this.el.nativeElement.clientHeight + this.acceptableOverflow < this.el.nativeElement.scrollHeight) {
			this.el.nativeElement.classList.add(this.overflowClass);
		}
	}
}