import { Serializable } from '../../../shared/serializable';

export class RegistrationUser {
	prefix: string;
	firstName: string;
	lastName: string;
	middleName: string;
	email: string;
	password: string;
	role: Role;
	isEngineer: boolean;
	jobTitle: string;
	suffix: string;
	phone: string;
	country: Country;
	state: State;
	postalCode: string;
	businessAreas: BusinesArea[] = [];
	company: Company;
	companyName: string;
	office: Office;
	address: string;
	address2: string;
	city: string;
	fax: string;
	mobile: string;
	passwordConfirmation: string;
	emailConfirmation: string;
	termsOfUseAccepted: boolean;
}

export class Role {
	RoleId: string;
	RoleDescription: string;
	IndExtranet: boolean;
	LastUpdateDate: string;
	LastUpdateUser: string;

	constructor(data = null) {
		if (data) {
			this.RoleId = data.RoleId;
			this.RoleDescription = data.RoleDescription;
			this.IndExtranet = data.IndExtranet;
			this.LastUpdateDate = data.LastUpdateDate;
			this.LastUpdateUser = data.LastUpdateUser;
		}
	}
}

export class Country {
	CountryCode: string;
	CountryName: string;
	LocationState: string;
	StateName: string;

	constructor(data = null) {
		if (data) {
			this.CountryCode = data.CountryCode;
			this.CountryName = data.CountryName;
			this.LocationState = data.LocationState;
			this.StateName = data.StateName;
		}
	}
}

export class State {
	LocationState: string;
	StateName: string;
	CountryCode: string;
	CountryName: string;

	constructor(data = null) {
		if (data) {
			this.CountryCode = data.CountryCode;
			this.CountryName = data.CountryName;
			this.LocationState = data.LocationState;
			this.StateName = data.StateName;
		}
	}
}

export class Company {
	AccountId: number;
	Name: string;
	AdressCity: string;
	AdressLine1: string;
	AdressLine2: string;
	AdressLine3: string;
	AdressLine4: string;
	MailTo: string;

	constructor(data = null) {
		if (data) {
			this.AccountId = data.AccountId;
			this.Name = data.Name;
			this.AdressCity = data.AdressCity;
			this.AdressLine1 = data.AdressLine1;
			this.AdressLine2 = data.AdressLine2;
			this.AdressLine3 = data.AdressLine3;
			this.AdressLine4 = data.AdressLine4;
			this.MailTo = data.MailTo;
		}
	}
}

export class Office extends Serializable {
	AdressLine1: string;
	AdressLine2?: string;
	AdressLine3?: string;
	AdressLine4?: string;
	AccountId: number;
	Company: string;
	AdressCity: string;
	Country?: string;
	State?: string;
	Zip?: string;

	constructor(public ContactId?: number,
	            public MailTo?: string) {
		super();
	}

	toString(): string {
		return `${this.getValue(this.MailTo)} ${this.getValue(this.AdressLine1)}
				${this.getValue(this.AdressCity)}`;
	}

	private getValue(property: any): string {
		return property ? property : '';
	}
}

export class BusinesArea {
	FAreaCode: number;
	FAreaDescr: string;
	FAreaLongDescr: string;

	constructor(data = null) {
		if (data) {
			this.FAreaCode = data.FAreaCode;
			this.FAreaDescr = data.FAreaDescr;
			this.FAreaLongDescr = data.FAreaLongDescr;
		}
	}
}