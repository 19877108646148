import { Injectable } from '@angular/core';
import { HttpClient } from '../../../../../core/http/http-client';
import { ConfigService } from '../../../../../core/config.service';
import { LoggerService } from '../../../../../core/logger/logger.service';

export class SignalState {
	CountryCode: string;
	StateCode: string;
	Description: string;
}
export class SignalTechnology {
	Id: string;
	Description: string;
}
export class SignalService {
	Id: number;
	Description: string;
}

@Injectable()
export class DropdownsService {

	private url = this.configService.baseUrl + '/api/AddHeadend/GetDropdowns';

	private dropdowns: any;

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	getDropdowns(): Promise<any> {
		if (this.dropdowns) {
			return Promise.resolve(this.dropdowns);
		} else {
			return this.loadDropdowns();
		}
	}

	private loadDropdowns(): Promise<any> {
		return this.http.get(this.url)
			.toPromise()
			.then(res => {
				let dropdowns = res.json();
				return dropdowns;
			}).catch(error => {
				this.logger.error(error);
				return null;
			});
	}
}