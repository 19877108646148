import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { LoadingMaskService } from '../../../../shared/loading-mask/loading-mask.service';
import { ProfileAddress } from '../profile-address';
import { ProfileAddressService } from '../profile-address.service';

@Injectable()
export class ProfileAddressResolve implements Resolve<ProfileAddress> {
	constructor(private service: ProfileAddressService,
	            private mask: LoadingMaskService,
	            private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<ProfileAddress> {
		let id: number = +route.params['addressID'];
		this.mask.addLoading();
		return this.service.getAddressById(id)
			.then((address: ProfileAddress) => {
				this.mask.removeLoading();

				if (!address) {
					this.router.navigate(['404']);
				}

				return address;
			});
	}
}