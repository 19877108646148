import { RouterModule } from '@angular/router';
import { ProfileContactsComponent } from './contacts/profile-contacts.component';
import { PrefixResolve } from '../registration/steps/step1/prefix-resolve.service';
import { ProfileComponent } from './profile.component';
import { AuthGuard } from '../../core/auth/auth.guard';
import { NgModule } from '@angular/core';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { BusinessAreasResolve } from '../registration/steps/step2/business-areas-resolve.service';
import { ProfileUserResolve } from './contacts/profile-user-resolve.service';
import { ProfileDropdownsResolve } from './contacts/profile-dropdowns-resolve.service';
import { ProfileAddressesResolve } from './addresses/profile-addresses-resolve.service';
import { ProfileAddressesListComponent } from './addresses/list/profile-addresses-list.component';
import { ProfileAddressesEditComponent } from './addresses/edit/profile-addresses-edit.component';
import { ProfileAddressResolve } from './addresses/edit/profile-address-resolve.service';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'profile',
				component: ProfileComponent,
				canActivate: [AuthGuard],
				children: [
					{
						path: '', redirectTo: 'contacts', pathMatch: 'full'
					},
					{
						path: 'contacts',
						component: ProfileContactsComponent,
						resolve: {
							profileUser: ProfileUserResolve,
							prefixes: PrefixResolve,
							dropdowns: ProfileDropdownsResolve,
							businessAreas: BusinessAreasResolve
						}
					},
					{
						path: 'addresses',
						children: [
							{
								path: '', pathMatch: 'full',
								component: ProfileAddressesListComponent,
								resolve: {
									addresses: ProfileAddressesResolve
								}
							},
							{
								path: 'new',
								component: ProfileAddressesEditComponent,
								resolve: {
									dropdowns: ProfileDropdownsResolve
								}
							},
							{
								path: 'edit/:addressID',
								component: ProfileAddressesEditComponent,
								resolve: {
									dropdowns: ProfileDropdownsResolve,
									address: ProfileAddressResolve
								}
							}
						]
					},
					{
						path: 'change-password',
						component: ChangePasswordComponent
					}
				]
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		PrefixResolve,
		ProfileDropdownsResolve,
		ProfileUserResolve,
		ProfileAddressesResolve,
		ProfileAddressResolve
	]
})
export class ProfileRoutingModule {
}