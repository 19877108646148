import { Component, Input, OnInit, ViewChild, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { AuthorizationGrigService } from '../authorization-grid.service';
import {
	AuthorizationRequest, AuthorizationServiceUplink,
	AuthorizationGridData, AuthorizationTransponder
} from '../../../shared/authorization-request';
import { AuthorizationService } from '../../../authorization.service';
import { NgForm, NgModel } from '@angular/forms';

@Component({
	template: require('./grid-form.component.html'),
	selector: 'vcm-grid-form'
})

export class GridFormComponent implements OnInit, AfterViewInit {
	@Input('gridData') gridData: AuthorizationGridData;
	@Input('transponderIndex') transponderIndex: number;
	authorizationRequest: AuthorizationRequest;
	transponder: AuthorizationTransponder;
	rowsC: AuthorizationServiceUplink[];
	isCisco: boolean;
	selectedTransponders: any;
	availableTransponders: any;
	hasNew: boolean;
	maxUnique = 0;
	removedUnique: number[];

	public acpMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
	public numberMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
	public iseMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

	@ViewChildren(NgModel) controls: QueryList<NgModel>;
	@ViewChild('gridForm') gridForm: NgForm;

	constructor(private authorizationGrigService: AuthorizationGrigService,
	            private authorizationService: AuthorizationService,
	            private step2: NgForm) {
	}

	ngOnInit() {
		this.authorizationRequest = this.authorizationService.authorizationRequest;
		this.isCisco = this.authorizationRequest.DecoderType && this.authorizationRequest.DecoderType.Id == '1';
		this.transponder = this.gridData.Transponders[this.transponderIndex];
		this.rowsC = this.transponder.Services;

		this.transponder.Services.forEach((row: AuthorizationServiceUplink) => {
			if (row.uniqueNumber >= this.maxUnique) {
				this.maxUnique = row.uniqueNumber + 1;
			}
		});

		this.removedUnique = [];

		this.authorizationGrigService.changeTranspodersStatus(this.transponderIndex);

		if (this.isCisco) {
			this.fillSelectedTransponders();
		}
	}

	ngAfterViewInit() {
		if (this.controls) {
			this.controls.forEach((control: NgModel) => {
				this.step2.addControl(control);
			});
		}
		this.hasNew = false;
	}

	launchService(value: boolean, row: AuthorizationServiceUplink): void {
		if (!value) {
			row.AcpAddress = null;
			row.SubsLaunched = null;
			row.ISE = null;
		}
		this.authorizationGrigService.updateCheckboxesStatus(value, this.transponderIndex);
	}

/*	launchCiscoService(value: boolean, row: AuthorizationServiceUplink): void {
		if (!value) {
			row.ServiceSelected = null;
			row.FeedSelected = null;
			row.ServiceId = null;
			row.ServiceType = null;
			row.FeedCode = null;
		}
		this.authorizationGrigService.updateCheckboxesStatus(value, this.transponderIndex);
		if (value && row.IsNew)
		{
			this.controls.forEach((control: NgModel) => {
				this.step2.addControl(control);
			});
			row.IsNew = false;
		}
	}*/

	dataChanged() {
		return this.authorizationGrigService.checkboxesStatusUpdated;
	}

	isServiceDefined(row: AuthorizationServiceUplink): boolean {
		return (typeof row.Status) === "string";
	}

	addRow() {
		var newRow = new AuthorizationServiceUplink();
		newRow.IsNew = true;
		newRow.Status = true;
		newRow.uniqueNumber = this.maxUnique;
		this.transponder.Services.push(newRow);
		this.rowsC = this.transponder.Services.slice();
		this.gridData.NoOfServicesRemaining--;
		this.authorizationGrigService.updateCheckboxesStatus(true, this.transponderIndex);
		this.hasNew = true;
		this.maxUnique++;
	}

	removeRow(row: AuthorizationServiceUplink) {
		this.removedUnique.push(row.uniqueNumber);
		this.removeControls();

		row.ISE = '---';
		row.LocationSelected = '---';
		row.ServiceSelected = '---';
		row.FeedSelected = '---';

		const i: number = this.transponder.Services.indexOf(row);
		if (i !== -1) {
			this.transponder.Services.splice(i, 1);
		}
		this.rowsC = this.transponder.Services.slice();
		this.gridData.NoOfServicesRemaining++;
		this.fillSelectedTransponders();
		this.authorizationGrigService.updateCheckboxesStatus(false, this.transponderIndex);
	}

	removeControls(){
		if (this.removedUnique.length > 0) {
			this.removedUnique.forEach((uniqueNumber: number) => {
				this.controls.forEach((control: NgModel) => {
					if (control.name.indexOf('-' + this.transponderIndex + '-' + uniqueNumber) !== -1) {
						if (this.step2.controls[control.name] != null) {
							this.step2.controls[control.name].setValue('---');
							var ind = this.removedUnique.indexOf(uniqueNumber);
							if (ind > -1) {
								this.removedUnique.splice(ind, 1);
							}
						}
					}
				})
			});
		}
	}

	ciscoLocationChanged(row: AuthorizationServiceUplink) {
		this.updateValidationControls();

		row.ServiceSelected = null;
		row.ServiceId = null;
		row.ServiceType = null;
		row.FeedSelected = null;
		row.FeedCode = null;
		if (row.LocationSelected) {
			row.SatelliteId = row.LocationSelected.SatelliteId;
			row.TransponderId = row.LocationSelected.TransponderId;
			row.SatelliteTransponderName = row.LocationSelected.Description;
		}
		else {
			row.SatelliteId = null;
			row.TransponderId = null;
			row.SatelliteTransponderName = null;
		}
		this.fillSelectedTransponders();
	}

	ciscoServiceChanged(row: AuthorizationServiceUplink) {
		this.updateValidationControls();

		row.FeedSelected = null;
		row.FeedCode = null;
		if (row.ServiceSelected) {
			row.ServiceId = row.ServiceSelected.ServiceId;
			row.ServiceName = row.ServiceSelected.Description.substr(0, row.ServiceSelected.Description.indexOf('/'));
			row.ServiceType = row.ServiceSelected.ServiceType;
			row.ServiceTypeDescription = row.ServiceSelected.Description.substr(row.ServiceSelected.Description.indexOf('/') + 2);
		}
		else {
			row.ServiceId = null;
			row.ServiceName = null;
			row.ServiceType = null;
			row.ServiceTypeDescription = null;
		}
	}

	ciscoFeedChanged(row: AuthorizationServiceUplink) {
		this.updateValidationControls();

		if (row.FeedSelected) {
			row.FeedCode = row.FeedSelected.Id;
			row.FeedName = row.FeedSelected.Description;
		}
		else {
			row.FeedCode = null;
			row.FeedName = null;
		}
	}

	fillSelectedTransponders() {
		this.selectedTransponders = [];
		for (let transp of this.transponder.AllCiscoServices) {
			if (transp.IsAuthorized && this.selectedTransponders.indexOf(transp) === -1) {
				this.selectedTransponders.push(transp);
			}
		}
		for (let row of this.rowsC) {
			if (row.LocationSelected && this.selectedTransponders.indexOf(row.LocationSelected) === -1) {
				this.selectedTransponders.push(row.LocationSelected);
			}
		}

		if (this.selectedTransponders.length >= this.gridData.NoOfInputs){
			this.availableTransponders = this.selectedTransponders;
		}
		else {
			this.availableTransponders = this.transponder.AllCiscoServices;
		}
	}

	updateValidationControls() {
		if (this.hasNew) {
			this.controls.forEach((control: NgModel) => {
				this.step2.addControl(control);
			});
			this.hasNew = false;
		}

		this.removeControls();
	}
}