import {
	Component, Input, Output, EventEmitter, forwardRef, ChangeDetectionStrategy,
	ChangeDetectorRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { coerceBooleanProperty } from '../boolean-property';
import { TrackByUtils } from '../../track-by-utils';

export const CHECKBOX_GROUP_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CheckboxGroupComponent),
	multi: true
};

export class CheckboxGroupChange {
	source: CheckboxGroupComponent;
	checked: any[];
}

@Component({
	selector: 'vcm-checkbox-group',
	template: require('./checkbox-group.component.html'),
	providers: [CHECKBOX_GROUP_CONTROL_VALUE_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxGroupComponent implements ControlValueAccessor {
	@Input('checkboxes') checkboxes: any[];
	@Input('displayName') displayName: string;
	@Input('trackBy') trackBy: string;
	@Output() change: EventEmitter<CheckboxGroupChange> = new EventEmitter<CheckboxGroupChange>();

	@Input()
	get disabled(): boolean {
		return this._disabled;
	}

	set disabled(value) {
		this._disabled = coerceBooleanProperty(value);
	}

	private selectedCheckboxes: any[];

	private _controlValueAccessorChangeFn: (value: any) => void = (value) => {
	};
	private onTouched: () => any = () => {
	};
	private _disabled: boolean = false;

	constructor(private ref : ChangeDetectorRef) {
	}

	isSelected(checkbox: any) {
		if (this.trackBy) {
			return TrackByUtils.indexOfTrackByProperty(this.selectedCheckboxes, checkbox, this.trackBy) >= 0;
		} else {
			return this.selectedCheckboxes.includes(checkbox);
		}
	}

	toggleCheckbox(checkbox: string) {
		this.toggleElement(this.selectedCheckboxes, checkbox, this.trackBy);
		this._emitChangeEvent();
	}

	getDisplayedName(checkbox: any) {
		return this.displayName ? checkbox[this.displayName] : checkbox;
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	writeValue(checkboxes: any[]) {
		this.selectedCheckboxes = checkboxes;
		this.ref.markForCheck();
		if (this.selectedCheckboxes) {
			this._controlValueAccessorChangeFn(this.selectedCheckboxes);
		}
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	registerOnChange(fn: (value: any) => void) {
		this._controlValueAccessorChangeFn = fn;
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	_onInputBlur() {
		this.onTouched();
	}

	private toggleElement(array: any[], element: any, trackBy: string) {
		let index = this.getIndex(trackBy, array, element);
		if (index > -1) {
			array.splice(index, 1);
		}
		else {
			array.push(element)
		}
	}

	private getIndex(trackBy: string, array: any[], element: any) {
		if (trackBy) {
			return TrackByUtils.indexOfTrackByProperty(array, element, trackBy);
		} else {
			return array.indexOf(element);
		}
	}

	private _emitChangeEvent() {
		let event = new CheckboxGroupChange();
		event.source = this;
		event.checked = this.selectedCheckboxes;

		this._controlValueAccessorChangeFn(this.selectedCheckboxes);
		this.change.emit(event);
	}
}