import { Injectable } from '@angular/core';
import { HttpClient } from '../../../../core/http/http-client';
import { ConfigService } from '../../../../core/config.service';
import { LoggerService } from '../../../../core/logger/logger.service';

@Injectable()
export class EmailValidationService {

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	checkEmail(email: string): Promise<string> {
		return this.http.get(this.configService.baseUrl + '/api/RegistrationCheckEmail/?email=' + email)
			.toPromise()
			.then(res => {
				return res.json();
			}).catch(error => {
				this.logger.error(error);
				return null;
			});
	}
}
