import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DCIIGridPageModel } from '../signals';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { DeviceGridService } from './device-grid.service';

@Injectable()
export class DeviceGridResolve implements Resolve<DCIIGridPageModel> {
	constructor(private service: DeviceGridService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<DCIIGridPageModel> {
		this.mask.addLoading();
		return this.service.getDeviceGrid()
			.map((deviceGrid: DCIIGridPageModel) => {
				this.mask.removeLoading();
				return deviceGrid;
			});
	}
}
