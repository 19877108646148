import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TermsPageModel } from '../+terms/terms-page.model';

@Component({
	template: require('./upload.component.html')

})
export class UploadComponent implements OnInit {
	uploadText: TermsPageModel = new TermsPageModel();
	areFilesSend: boolean = false;
	filesAreLoading: boolean = false;

	constructor(private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.route.data.forEach((data: {uploadText: TermsPageModel}) => {
			this.uploadText.Title = data.uploadText.Title || "Upload page";
		});
	}

	displayFilesLoadingMessage(filesAreLoading: boolean): void {
		this.filesAreLoading = filesAreLoading;
		this.areFilesSend = true;
	}

	back(): void {
		this.areFilesSend = false;
	}
}
