import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
	selector: '[officeValidator]',
	providers: [
		{provide: NG_VALIDATORS, useExisting: forwardRef(() => OfficeExistValidator), multi: true}
	]
})
export class OfficeExistValidator implements Validator {
	constructor() {
	}

	validate(c: AbstractControl): {[key: string]: any} {
		let officeValue: any = c.value['office'];
		let companyName: any = c.value['companyName'];

		if (!officeValue && !companyName) {
			return {officeExist: false}
		}
		return null;
	}
}


