import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { BrandsDetailsRoutingModule } from './brands-details-routing.module';
import { BrandsDetailsComponent } from './brands-details.component';
import { VideoModule } from '../../shared/video/video.module';
import { LandingModule } from '../view/landing/landing.module';
import { LandingGridModule } from '../view/landing-grid/landing-grid.module';

@NgModule({
	imports: [
		SharedModule,
		BrandsDetailsRoutingModule,
		VideoModule,
		LandingModule,
		LandingGridModule
	],
	declarations: [
		BrandsDetailsComponent
	],
	exports: [
		BrandsDetailsComponent
	]
})

export class BrandsModule {
}
