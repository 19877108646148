import { Component, Input, HostBinding, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { RelatedContentService, RelatedUrlsList } from './related-content.service';
import { Router } from '@angular/router';

@Component({
	selector: 'vcm-related-content',
	template: require('./related-content.component.html')
})

export class RelatedContentComponent implements OnChanges {
	@HostBinding('class.vcm_related_component') addClass: boolean = true;

	@Input('contentId') contentId: number;

	disabledButton: boolean = true;
	isOpen: boolean = false;
	relatedUrlsList: RelatedUrlsList[];

	constructor(private ref: ChangeDetectorRef,
	            private router: Router,
	            private service: RelatedContentService) {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes['contentId']) {
			this.getRelatedContent();
		}
	}

	navigate(e: any, url: string): void {
		e.preventDefault();
		if (!url) {
			return;
		}

		this.isOpen = false;
		if (url.includes(';')) {
			this.router.navigateByUrl(url);
		} else {
			this.router.navigate([url]);
		}
	}

	private getRelatedContent(): void {
		this.service.getRelatedContent(this.contentId).then((relatedContent: RelatedUrlsList[]) => {
			this.relatedUrlsList = relatedContent;
			this.disabledButton = this.relatedUrlsList.length == 0;
			this.ref.markForCheck();
		})
	}
}
