import { Injectable } from '@angular/core';
import { HttpClient } from '../../core/http/http-client';
import { ConfigService } from '../../core/config.service';
import { Observable } from 'rxjs';
import { Response } from '@angular/http';
import { LoggerService } from '../../core/logger/logger.service';
import { Video } from '../../shared/video/video';

export class Spot {
	FlightFrom: Date;
	FlightTo: Date;
	Id: number;
	SpotName: string;
	Video: Video;
}

export class SpotPremiere {
	PremiereDateTime: string;
	ScheduledDatetime: string;
	PremiereHeader: string;
}

export class Promotion {
	BrandName: string;
	BrandLogo: string[];
	DetailDescription: string;
	Id: number;
	Objective: string;
	Premiere: SpotPremiere;
	PromotionType: string;
	PromotionPeriodFrom: Date;
	PromotionPeriodTo: Date;
	RecordType: string;
	SelectSpot: Spot[];
	ShortDescription: string;
	TargetRoles: string[];
	Title: string;
	LinkURL: string;
}

@Injectable()
export class PromotionService {
	promotions: Promotion[];
	private url = this.configService.baseUrl + '/api/Promotion';

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private logger: LoggerService) {
	}

	getPromotions(params): Observable<Promotion[]> {
		if (this.promotions) {
			return Observable.of(this.promotions)
		} else {
			return this.loadPromotions(params);
		}
	}

	loadPromotions(params): Observable<Promotion[]> {
		return this.http.post(this.url, params)
			.map((res: Response) => {
				this.promotions = res.json();
				return this.promotions;
			}).catch((err) => {
				this.logger.error(err);
				return Observable.of(null);
			});
	}
}
