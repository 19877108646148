import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UserActivationComponent } from './user-activation.component';
import { UserActivationService } from './user-activation.service';

@NgModule({
	imports: [
		CommonModule,
		RouterModule
	],
	declarations: [
		UserActivationComponent
	],
	providers: [
		UserActivationService
	],
	exports: [
		UserActivationComponent
	]
})

export class UserActivationModule {
}


