import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '@angular/http';
import { DCIIGridPageModel } from '../signals';
import { ConfigService } from '../../../core/config.service';
import { HttpClient } from '../../../core/http/http-client';
import { LoggerService } from '../../../core/logger/logger.service';

@Injectable()
export class DeviceGridService {
	private url = this.configService.baseUrl + '/api/Signals/SignalsList';

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private logger: LoggerService) {
	}

	getDeviceGrid(): Observable<DCIIGridPageModel> {
		return this.http.get(this.url)
			.map((res: Response) => {
				return res.json();
			}).catch((err) => {
				this.logger.error(err);
				return Observable.of(null);
			});
	}
}
