import * as FileSaver from 'file-saver';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../../../core/config.service';
import { HttpClient } from '../../../../core/http/http-client';
import { Response, ResponseContentType } from '@angular/http';
import { LoggerService } from '../../../../core/logger/logger.service';

@Injectable()
export class SocialMediaDetailsService {
	private url = this.configService.baseUrl;
	public currentSocialMediaId: number;

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private logger: LoggerService) {
	}

	private createDownloadLink(id: number): string {
		return this.url + '/api/DownloadImages?ResourceIdSM=' + id
	}

	private createMailLink(): string {
		return this.url + '/api/ExportSMEmailDetails';
	}

	downloadImages(): Promise<Response> {
		return this.http.get(this.createDownloadLink(this.currentSocialMediaId), {responseType: ResponseContentType.Blob})
			.toPromise()
			.then(res => {
				this.downloadFile(res, this.currentSocialMediaId);
				return res;
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	downloadFile(data: Response, id: number) {
		let blob = new Blob([data.blob()], {type: 'application/octet-stream'});
		let filename = 'SocialMedia-' + id + '.zip';

		if (data.headers.has('Content-Disposition')) {
			let cdHeader = data.headers.get('Content-Disposition');
			filename = cdHeader.replace(/.*filename=([^;]+)/, '$1');
		}

		FileSaver.saveAs(blob, filename);
	}

	sendDetails(): Promise<Response> {
		return this.http.post(this.createMailLink(), [this.currentSocialMediaId])
			.toPromise()
			.then(res => {
				return res;
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}
