import { Component, Input } from '@angular/core';
import { Promotion } from '../promotion.service';
import { Router } from '@angular/router';
import { ParamsListService } from '../params.service';

@Component({
	template: require('./promotion-grid.component.html'),
	selector: 'vcm-promotion-grid'
})

export class PromotionGridComponent {
	@Input() promotions: Promotion[];
	private promotionUrl: string = '/promotion';

	constructor(private router: Router,
	            private paramsListService: ParamsListService) {}

	goToPromo(id: string): void {
		this.router.navigate([this.promotionUrl, this.paramsListService.setNewPromo(id)]);
	}
}