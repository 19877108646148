import { Component, OnDestroy } from '@angular/core';
import { ProgramDetails } from '../programming-highlights';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramDetailsService } from './program-details.service';
import { LiveBrand } from '../dropdowns/live-brands.service';

@Component({
	template: require('./program-details.component.html'),
	selector: 'vcm-program-details'
})
export class ProgramDetailsComponent implements OnDestroy {
	programDetails: ProgramDetails = null;
	serviceId: string;
	startDate: string;

	liveBrands: LiveBrand[] = [];

	constructor(private route: ActivatedRoute,
	            private router: Router,
	            private service: ProgramDetailsService) {}

	ngOnInit() {
		this.route.data.forEach((data: {
			liveBrands: LiveBrand[],
			programDetails: ProgramDetails
		}) => {
			this.programDetails = data.programDetails;
			this.serviceId = this.service.serviceId;
			this.startDate = this.service.startDate;

			this.liveBrands = data.liveBrands;
		});
	}

	ngOnDestroy() {
		this.service.clearParams();
	}

	goToLiveGrid(selectedBrand?: LiveBrand): void {
		let id = selectedBrand.ServiceId;
		this.router.navigate(['programming', 'livegrid', id]);
	}

}