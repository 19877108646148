import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LandingService, Landing } from './landing.service';
import { LoadingMaskService } from './../../../shared/loading-mask/loading-mask.service';

@Injectable()
export class LandingResolve implements Resolve<Landing> {

	constructor(private service: LandingService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<Landing> {
		this.mask.addLoading();

		return this.service.getLanding(route.data['landing'])
			.map((landing: Landing) => {
				this.mask.removeLoading();
				return landing;
			}).catch(err => {
				this.mask.removeLoading();
				return Observable.of(null);
			});
	}
}
