import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '../../../../shared/form/boolean-property';
import { TermsPageModel } from '../../../+terms/terms-page.model';

export const COPYRIGHT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CopyrightsComponent),
	multi: true
};

@Component({
	selector: 'vcm-copyrights',
	template: require('./copyrights.component.html'),
	providers: [COPYRIGHT_CONTROL_VALUE_ACCESSOR]
})
export class CopyrightsComponent implements ControlValueAccessor {

	@Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input()
	termsModel: TermsPageModel;

	@Input()
	get disabled(): boolean { return this._disabled; }

	set disabled(value) { this._disabled = coerceBooleanProperty(value); }

	private accepted: boolean = null;
	private _controlValueAccessorChangeFn: (value: any) => void = (value) => {};
	private onTouched: () => any = () => {};
	private _disabled: boolean = false;

	constructor() {}

	setAccepted(accepted: boolean) {
		this.accepted = accepted;
		this._emitChangeEvent(accepted);
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	writeValue(accepted: boolean) {
		this.accepted = accepted;
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	registerOnChange(fn: (value: any) => void) {
		this._controlValueAccessorChangeFn = fn;
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	_onInputBlur() {
		this.onTouched();
	}

	private _emitChangeEvent(accepted: boolean) {
		this._controlValueAccessorChangeFn(this.accepted);
		this.change.emit(this.accepted);
	}
}
