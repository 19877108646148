import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileAddressService } from '../profile-address.service';
import { ProfileAddress } from '../profile-address';

@Component({
	template: require('./profile-addresses-list.component.html')
})

export class ProfileAddressesListComponent implements OnInit {
	public addresses: ProfileAddress[];
	public defaultAddress: ProfileAddress;

	constructor(private route: ActivatedRoute,
	            private profileAddressService: ProfileAddressService) {
	}

	ngOnInit() {
		this.route.data.forEach((data: {addresses: ProfileAddress[]}) => {
			this.updateAddressesList(data.addresses);
		});
	}

	deleteAddress(address: ProfileAddress): void {
		this.profileAddressService.deleteAddress(address.addressID).then(() => {
			this.profileAddressService.getAddresses().then((addressList) => {
				this.updateAddressesList(addressList);
			});
		})
	}

	private updateAddressesList(addressList): void {
		this.addresses = addressList.slice(0, addressList.length - 1);
		this.defaultAddress = addressList[addressList.length - 1];
	}
}