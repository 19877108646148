import { LandingResolve } from '../view/landing/landing-resolve.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/auth/auth.guard';
import { HomeComponent } from './home.component';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'home',
				component: HomeComponent,
				canActivate: [AuthGuard],
				resolve: {
					landing: LandingResolve
				},
				data: {
					landing: {
						landingType: 'HOME'
					}
				}
			}
		])
	],
	exports: [
		RouterModule
	]
})

export class HomeRoutingModule {
}
