import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SignalsHeadend } from '../signals';
import { SlimScrollDirective } from 'ng2-slimscroll/src/directives/slimscroll.directive';

@Component({
	selector: 'vcm-device-grid',
	template: require('./device-grid.component.html')
})

export class DeviceGridComponent implements OnInit {
	headends: SignalsHeadend[];
	@ViewChild('slimScroll') slimScroll: SlimScrollDirective;

	constructor(private route: ActivatedRoute) {
	};

	ngOnInit() {
		this.route.data.forEach((data: {headends: SignalsHeadend[]}) => {
			this.headends = data.headends;
		});
	}

	isOpenOnChange(isOpen: boolean): void {
		if (isOpen) {
			this.slimScroll.getBarHeight();
		}
	}
}
