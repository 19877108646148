import { Component, EventEmitter, Output, Input } from '@angular/core';
import { LoadingMaskService } from '../../../../../shared/loading-mask/loading-mask.service';
import { DropHeadendFormService } from './drop-headend-form.service';
import { DropReason } from './reason.service';
import { HeadendSummary } from '../../shared/headend-summary.service';
import { Acp } from '../../../signals';

@Component({
	selector: 'vcm-drop-headend-form',
	template: require('./drop-headend-form.component.html')
})

export class DropHeadendFormComponent {
	details: string[] = [];

	commentMessage: string = null;
	dropDate: Date = null;
	selectedReason: any = null;

	@Input() headendId: string;
	@Input() headendSummary: HeadendSummary;
	@Input() reasons: DropReason[];
	@Input() services: Acp[];

	@Output() feedbackSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private dropHeadendFormService: DropHeadendFormService,
	            private mask: LoadingMaskService) {
	}

	onSubmit():void {
		this.mask.addLoading();
		let request = Object.assign({
			Reason: this.selectedReason,
			Comments: this.commentMessage,
			DropDate: this.dropDate,
			Services: this.services,
			HeadendId: this.headendId
		}, this.headendSummary);

		this.dropHeadendFormService.submitRequest(request).then((resp) => {
			this.mask.removeLoading();
			this.feedbackSubmitted.emit(resp);
			window.scrollTo(0, 0);
		});
	}
}
