import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { LiveBrand, LiveBrandsService } from '../dropdowns/live-brands.service';
import { LiveGridShowsService } from './live-grid-shows.service';

@Injectable()
export class LiveGridResolve implements Resolve<LiveBrand> {
	constructor(private service: LiveBrandsService,
	            private liveGridShowsService: LiveGridShowsService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<LiveBrand> {
		if (route.params['liveGridId']) {
			let id: number = +route.params['liveGridId'];
			this.liveGridShowsService.setLiveGridId(id);
			let selectedBrand = this.service.getLiveBrandById(id);
			return Promise.resolve(selectedBrand);
		} else {
			return this.service.getFirstLiveBrand().then(selectedBrand => {
				this.liveGridShowsService.setLiveGridId(selectedBrand && selectedBrand.ServiceId);
				return selectedBrand;
			});
		}
	}
}
