import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { Landing, LandingService } from '../view/landing/landing.service';
import { LoadingMaskService } from '../../shared/loading-mask/loading-mask.service';

@Injectable()
export class BrandsLandingResolve implements Resolve<Landing> {
	constructor(private service: LandingService,
	            private mask: LoadingMaskService) {}

	resolve(route: ActivatedRouteSnapshot): Observable<Landing> {
		let serviceId: string = route.params['brandsID'];
		this.mask.addLoading();
		return this.service.getLanding({
			landingType: 'BRAND',
			serviceId: serviceId
		}).map((landing: Landing = null) => {
			this.mask.removeLoading();

			return landing;
		})
	}
}