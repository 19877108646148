import { Component } from '@angular/core';

@Component({
	selector: 'vcm-enter-email',
	template: require('./enter-email.component.html')
})

export class EnterEmailComponent {
	public isEmailValid: boolean = false;
	public emailSubmitted: boolean = false;

	displayEmailSubmittedMessage(isEmailValid: boolean) {
		this.emailSubmitted = true;
		this.isEmailValid = isEmailValid;
	}

	back() {
		this.emailSubmitted = false;
	}
}
