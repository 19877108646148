import { Component } from '@angular/core';
import { SignalsService } from '../../signals.service';
import { ActivatedRoute } from '@angular/router';
import { HeadendSummary } from '../shared/headend-summary.service';
import { SignalTechnology, SignalState, SignalService } from './add-headend-form/dropdowns.service';

@Component({
	template: require('./add-headend.component.html')
})

export class AddHeadendComponent {
	isRequestSubmitted: boolean = false;
	headendId: number;
	headendSummary: HeadendSummary;

	dropdowns: any;

	private signalStates: SignalState[];
	private signalServices: SignalService[];
	private signalTechnologies: SignalTechnology[];

	constructor(private signalsService: SignalsService,
	            private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.headendId = this.signalsService.headendId;
		this.route.data.forEach((data: {
			dropdowns: any
		}) => {
			this.signalStates = data['dropdowns']['States'];
			this.signalServices = data['dropdowns']['Services'];
			this.signalTechnologies = data['dropdowns']['Technologies'];
		});
	}

	displayFeedbackSubmittedMessage(feedback): void {
		this.isRequestSubmitted = true;
	}

	public backClicked() {
		window.history.back();
	}
}
