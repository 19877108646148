import { Injectable } from '@angular/core';
import { DaysRange } from './days-range';
import { Device } from '../../../core/viewport-service/viewport.service';
import * as moment from 'moment';
import Moment = moment.Moment;

export const DESKTOP_RANGE: number = 7;
export const DESKTOP_RANGES_AMOUNT: number = 4;
export const TABLET_RANGE: number = 4;
export const TABLET_RANGES_AMOUNT: number = 7;
export const MOBILE_RANGE: number = 1;
export const MOBILE_RANGES_AMOUNT: number = 28;

@Injectable()
export class DaysRangeService {

	constructor() {
	}

	public getAvailableRanges(device: Device): DaysRange[] {
		switch (device) {
			case Device.MOBILE:
				return this.getRanges(MOBILE_RANGE, MOBILE_RANGES_AMOUNT);
			case Device.TABLET:
				return this.getRanges(TABLET_RANGE, TABLET_RANGES_AMOUNT);
			case Device.DESKTOP:
			default:
				return this.getRanges(DESKTOP_RANGE, DESKTOP_RANGES_AMOUNT);
		}
	}

	private getRanges(range: number, rangesAmount: number) {
		let currentDate: Moment = moment();
		let availableRanges: DaysRange[] = [];
		for (let i = 0; i < rangesAmount; i++) {
			availableRanges.push(new DaysRange(currentDate, moment(currentDate).add(range - 1, 'days')));
			currentDate.add(range, 'days');
		}
		return availableRanges;
	}

}
