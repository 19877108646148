import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { LoadingMaskService } from '../../../../../../shared/loading-mask/loading-mask.service';
import { AuthorizationGrigService } from './authorization-grid.service';
import { AuthorizationGridData } from '../../shared/authorization-request';

@Injectable()
export class AuthorizationGridResolve implements Resolve<AuthorizationGridData> {
	constructor(private service: AuthorizationGrigService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<AuthorizationGridData> {
		this.mask.addLoading();
		return this.service.getGrigData()
			.then((summary: AuthorizationGridData) => {
				this.mask.removeLoading();
				return summary;
			});
	}
}