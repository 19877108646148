import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { OrderService, OrderInfo } from './order.service';
import { LoadingMaskService } from '../../shared/loading-mask/loading-mask.service';

@Injectable()
export class OrderResolve implements Resolve<any> {
	constructor(private service: OrderService,
	            private router: Router,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<OrderInfo> {
		this.mask.addLoading();
		return this.service.getNewOrder(route.params['Id'])
			.then((orderInfo: any) => {
				this.mask.removeLoading();

				if (!orderInfo) {
					this.router.navigate(['404']);
				}

				return orderInfo;
			});
	}
}


