import { Injectable } from '@angular/core';
import { ToastsManager } from 'ng2-toastr';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Response } from '@angular/http';

@Injectable()
export class LoggerService {
	constructor(private toastr: ToastsManager,
	            private router: Router) {}

	log(message: string| any, showToast: boolean = false) {
		if (showToast) {
			this.toastr.info(message, 'Info');
		}
	}

	warn(message: string| any, showToast: boolean = false) {
		console.log(message);
		if (showToast) {
			this.toastr.warning(message, 'Warning');
		}

	}

	error(error: string| any, showToast: boolean = false) {
		this.handleError(error);
		// if (showToast) {
		// 	this.toastr.error(error, 'Error');
		// }
	}

	debug(message: string| any, showToast: boolean = false) {
		if (process.env.ENV === 'localdevelopment') {
			console.debug(message);
			if (showToast) {
				this.toastr.info(message, 'Debug');
			}
		}
	}

	private handleError (error: Response | any) {
		let errMsg: string;

		if (error instanceof Response) {
			const body = error.text() ? error.json() : '';
			const err = body.error || '';
			errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
			if (/^5\d{2}$/.test(error.status + '')) {
				this.router.navigate(['error']);
			}

			if (error.status == 403) {
				this.router.navigate(['404']);
			}
		} else {
			errMsg = error.message ? error.message : error.toString();
		}

		console.error(errMsg);
		return Observable.throw(errMsg);
	}
}