import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RESOURCE_TYPES, ResourceType } from './resrouce-types';
import { Resource, ResourcesService, AvailableResource } from './resources.service';
import { Subscription } from 'rxjs';
import { BRANDS } from './filters/common-filters';
import { LoadingMaskService } from '../../shared/loading-mask/loading-mask.service';
import { ActiveFiltersService } from './filters/active-filters.service';

@Component({
	template: require('./resources.component.html')
})

export class ResourcesComponent implements OnInit, OnDestroy {
	availableResources: ResourceType[] = [];
	allFilters: {[key: string]: string[]|string} = {};
	commonFilters: {[key: string]: string[]|string} = {};

	private availableResourcesSubscription: Subscription;

	constructor(private resourcesService: ResourcesService,
	            private route: ActivatedRoute,
	            private router: Router,
	            private activeFilters: ActiveFiltersService,
	            private mask: LoadingMaskService) {
	}

	ngOnInit(): void {
		this.addResourceChangeSubscription();
		this.route.url.forEach(() => {
			if (this.isRootResourcesRoute()) {
				this.navigateToFirstAvailableResource();
			} else {
				this.setAvailableResourcesAndFilters(this.resourcesService.currentResource);
			}
		});
	}

	changeResource(resourceId: number, isActiveResource: boolean) {
		this.router.navigate(['/resources/' + resourceId, isActiveResource ? this.allFilters : this.commonFilters]);
		return false;
	}

	isFiltersWithHint(): boolean {
		return this.router.url.startsWith('/resources/logo');
	}

	isSeparateResourcesRoute(): boolean {
		return this.router.url.startsWith('/resources/tech-specs')
			|| this.router.url.startsWith('/resources/avail-alert');
	}

	ngOnDestroy(): void {
		this.availableResourcesSubscription.unsubscribe();
	}

	private addResourceChangeSubscription(): void {
		this.availableResourcesSubscription = this.resourcesService.resourcesChanged.subscribe((resource: Resource) => {
			this.setAvailableResourcesAndFilters(resource);
		});
	}

	private setAvailableResourcesAndFilters(resource: Resource): void {
		this.availableResources = this.getAvailableResources(resource.ResourceTypeAvailable);
		this.availableResources.sort((resource1: ResourceType, resource2: ResourceType) =>
			resource1['name'].toLowerCase() > resource2['name'].toLowerCase() ? 1 : -1
		);
		this.allFilters = this.activeFilters.getActiveFilters();
		this.commonFilters = this.getCommonFilters(this.allFilters);
	}

	private getNextAvailableResourceType(currentResource: AvailableResource): ResourceType {
		let nextAvailableResource;
		let availableResourceTypes = this.resourcesService.currentResource.ResourceTypeAvailable;
		for (let i = 0; i < availableResourceTypes.length; i++) {
			if (currentResource.Id !== availableResourceTypes[i].Id) {
				nextAvailableResource = availableResourceTypes[i];
				break;
			}
		}
		return this.getAvailableResources([nextAvailableResource])[0];
	}

	private getAvailableResources(availableResources: AvailableResource[]): ResourceType[] {
		return availableResources.map((resource: AvailableResource) => {
			return RESOURCE_TYPES.get(resource.Id);
		});
	}

	private getCommonFilters(activeFilters: {[key: string]: string[]|string}) {
		let commonFilters = {};
		if (activeFilters[BRANDS]) {
			commonFilters[BRANDS] = activeFilters[BRANDS];
		}
		return commonFilters;
	}

	private navigateToFirstAvailableResource(): void {
		this.mask.addLoading();
		this.resourcesService.getResources().then((resources: Resource) => {
			this.mask.removeLoading();
			this.router.navigate(['/resources/' + this.availableResources[0].id]);
		});
	}

	private isRootResourcesRoute(): boolean {
		return this.router.url === '/resources';
	}
}