import { Component, Input } from '@angular/core';

@Component({
	template: require('./landing-channel-description.component.html'),
	selector: 'vcm-landing-channel-description'
})

export class LandingChannelDescriptionComponent {
	@Input() resources: any;
}
