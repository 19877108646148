import { Component, Input, ElementRef, ViewChild, ChangeDetectionStrategy, HostListener } from '@angular/core';
import * as moment from 'moment';
import Moment = moment.Moment;
import { LiveGridShow } from '../live-grid-show';

export class LiveGridShowElement {
	constructor(public show: LiveGridShow,
	            public  cell: HTMLElement) {}
}

@Component({
	template: require('./show-tooltip.component.html'),
	selector: 'vcm-show-tooltip',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ShowTooltipComponent {
	@Input() set showElement(value: LiveGridShowElement) {
		this._showElement = value;
		if (this._showElement) {
			this.showTooltip();
		} else {
			this.hideTooltip();
		}
	}

	@Input() delay: number = 300;
	@Input() tooltipClass: string = 'vcm_fade_in';
	@Input() showElementClass: string = '.vcm_grid_show';

	@HostListener('mouseleave', ['$event']) onMouseLeave(e) {
		//Don't hide tooltip if cursor is back to selected show
		let closestShow = e.relatedTarget.closest(this.showElementClass);
		if (closestShow !== this._showElement.cell) {
			this.hideTooltip();
		}
	}

	get show(): LiveGridShow {
		return this._showElement ? this._showElement.show : null;
	}

	@ViewChild('tooltip') tooltip: ElementRef;

	private _showElement: LiveGridShowElement;
	private tooltipTimerId: any;

	showTooltip() {
		if (this.tooltipTimerId) {
			return;
		}
		this.tooltipTimerId = setTimeout(() => {
			this.tooltipTimerId = null;
			this.tooltip.nativeElement.style.display = '';

			let cell: HTMLElement = this._showElement.cell;
			let tooltipEl = this.tooltip.nativeElement;

			let cellCoords: ClientRect = cell.getBoundingClientRect();
			this.applyLeftCoords(cellCoords, cell, tooltipEl);
			this.applyTopCoords(cellCoords, tooltipEl, cell);
			tooltipEl.classList.add(this.tooltipClass);
		}, this.delay);
	}

	hideTooltip() {
		this.tooltip.nativeElement.classList.remove(this.tooltipClass);
		this.tooltip.nativeElement.style.display = 'none';
		if (this.tooltipTimerId) {
			clearTimeout(this.tooltipTimerId);
			this.tooltipTimerId = null;
		}
	}

	createProgramDetailsLink(brand: number, date: Moment): string {
		return `/programming/livegrid/${brand}/${date.format('YYYY-MM-DDTHH:mm:ss')}`;
	}

	private applyLeftCoords(cellCoords: ClientRect, cell: HTMLElement, tooltipEl: any) {
		let left: number = cellCoords.left + (cell.offsetWidth - tooltipEl.offsetWidth) / 2;
		// Don't overflow left screen border
		if (left < 0) {
			left = 0;
		}
		tooltipEl.style.left = left + 'px';
	}

	private applyTopCoords(cellCoords: ClientRect, tooltipEl: any, cell: HTMLElement) {
		let top: number = cellCoords.top - tooltipEl.offsetHeight;
		let position: string = 'top';
		// Don't overflow top screen border
		if (top < 0) {
			top = cellCoords.top + Math.min(cell.offsetHeight, cell.clientHeight);
			position = 'bottom';
		}
		tooltipEl.style.top = top + 'px';
		tooltipEl.setAttribute('position', position);
		this.applyArrowPadding(position, tooltipEl);
	}

	private applyArrowPadding(position: string, tooltipEl: any) {
		if (position === 'top') {
			tooltipEl.style.paddingTop = 0;
			tooltipEl.style.paddingBottom = '20px';
		} else {
			tooltipEl.style.paddingTop = '20px';
			tooltipEl.style.paddingBottom = 0;
		}
	}
}