import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { LogoComponent } from './logo.component';
import { LogoService } from './logo.service';
import { LogoDownloadModule } from './logo-download/logo-download.module';

@NgModule({
	imports: [
		SharedModule,
		LogoDownloadModule
	],
	declarations: [
		LogoComponent
	],
	providers: [
		LogoService
	]
})

export class LogoModule {
}


