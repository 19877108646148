import { Component } from '@angular/core';
import { Landing } from '../../view/landing/landing.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	template: require('./signals-landing.component.html'),
	selector: 'vcm-signals-landing'
})

export class SignalsLandingComponent {
	landing: Landing;

	constructor(private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.route.data.forEach((data: {landing: Landing}) => {
			this.landing = data.landing;
		});
	}
}