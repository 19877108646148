import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PageNotFoundComponent } from './pages/errors/page-not-found.component';
import { NgModule } from '@angular/core';
import { AuthGuard } from './core/auth/auth.guard';
import { NotAuthGuard } from './core/auth/not-auth.guard';
import { ErrorHasOccuredComponent } from './pages/errors/error-has-occured.component';

const routes: Routes = [
	{path: '', redirectTo: 'home', pathMatch: 'full'},
	{path: 'terms', loadChildren: () => new Promise(function (resolve) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\pages\\+terms\\terms.module')['TermsModule']);  });})},
	{path: 'legal', loadChildren: () => new Promise(function (resolve) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\pages\\+legal\\legal.module')['LegalModule']);  });})},
	{path: 'privacy', loadChildren: () => new Promise(function (resolve) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\pages\\+privacy\\privacy.module')['PrivacyModule']);  });})},
	{path: 'help', loadChildren: () => new Promise(function (resolve) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\pages\\+help\\help.module')['HelpModule']);  });})},
	{path: 'contact-us', loadChildren: () => new Promise(function (resolve) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\pages\\+contact-us\\contact-us.module')['ContactUsModule']);  });})},
	{path: 'error', component: ErrorHasOccuredComponent,},
	{path: '**', component: PageNotFoundComponent}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})], exports: [RouterModule],
	providers: [AuthGuard, NotAuthGuard]
})

export class AppRoutingModule {
}
