import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { OtherComponent } from './other.component';
import { OtherService } from './other.service';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [
		OtherComponent
	],
	providers: [
		OtherService
	]
})

export class OtherModule {
}


