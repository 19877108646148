import { Component, OnInit } from '@angular/core';
import { ProfileUser } from './profileUser';
import { ActivatedRoute } from '@angular/router';

@Component({
	template: require('./profile.component.html')
})

export class ProfileComponent implements OnInit {
	public user: ProfileUser;

	constructor(private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: {profileUser: ProfileUser}) => {
			this.user = data.profileUser;
		});
	}
}
