import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SocialMedia, SocialMediaService } from './social-media.service';
import { ActiveFiltersService } from '../filters/active-filters.service';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';

@Component({
	template: require('./social-media.component.html')
})

export class SocialMediaComponent {

	socialMedia: SocialMedia[] = [];
	selectedFilters: any;
	idArray: number[];
	isEmailSent: boolean = false;
	sendingEmailStatus: boolean = true;

	constructor(private route: ActivatedRoute,
	            private activeFilters: ActiveFiltersService,
	            private service: SocialMediaService,
	            private mask: LoadingMaskService) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: { socialMedia: SocialMedia[] }) => {
			this.socialMedia = data.socialMedia;
			this.idArray = data.socialMedia.map(element => {return element.Id});
			this.selectedFilters = this.activeFilters.getActiveFilters();
		});
	}

	twitterLink(tweetText: string): string {
		return 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(tweetText);
	}

	hasAssets(element: SocialMedia): boolean {
		let imgLength = element.ImagesSM ? element.ImagesSM.length : 0;
		let videoLength = element.VideoUrl ? element.VideoUrl.length : 0;
		return imgLength + videoLength > 0;
	}

	sendToEmail(): void {
		this.mask.addLoading();
		this.service.sendDetails(this.idArray).then((response) => {
			this.isEmailSent = true;
			if (response.json()) {
				this.sendingEmailStatus = true;
			} else {
				this.sendingEmailStatus = true;
			}
			this.mask.removeLoading();
		}, error => {
			this.sendingEmailStatus = false;
			this.isEmailSent = true;
			this.mask.removeLoading();
		});
	}

	exportToExcel(idArray: number[]): void {
		this.service.exportToExcel(idArray);
	}

	backToSocialMedia(): void {
		this.isEmailSent = false;
	}
}
