import { NgModule } from '@angular/core';
import { ResourcesService } from './resources.service';
import { ChannelDescriptionModule } from './channel-description/channel-description.module';
import { SharedModule } from '../../shared/shared.module';
import { ResourcesRoutingModule } from './resources-routing.module';
import { CertificationModule } from './certification/certification.module';
import { PressModule } from './press/press.module';
import { OtherModule } from './other/other.module';
import { LogoModule } from './logo/logo.module';
import { AvailAlertsModule } from './avail-alerts/avail-alerts.module';
import { SocialMediaModule } from './social-media/social-media.module';
import { ResourcesBrandsService } from './resources-brands.service';
import { ResourcesComponent } from './resources.component';
import { ActiveFiltersService } from './filters/active-filters.service';
import { CommonFiltersComponent } from './filters/common-filters/common-filters.component';
import { TechSpecsModule } from './tech-specs/tech-specs.module';
import { ImagesModule } from './images/images.module';

@NgModule({
	imports: [
		SharedModule,
		ResourcesRoutingModule,
		ChannelDescriptionModule,
		PressModule,
		OtherModule,
		LogoModule,
		AvailAlertsModule,
		SocialMediaModule,
		CertificationModule,
		TechSpecsModule,
		ImagesModule
	],
	declarations: [
		ResourcesComponent,
		CommonFiltersComponent
	],
	providers: [
		ResourcesBrandsService,
		ResourcesService,
		ActiveFiltersService
	],
	exports: [ResourcesComponent]
})

export class ResourcesModule {
}


