import { Injectable } from '@angular/core';
import { State, Country } from '../../shared/registration-user';
import { HttpClient } from '../../../../core/http/http-client';
import { ConfigService } from '../../../../core/config.service';
import { LoggerService } from '../../../../core/logger/logger.service';

@Injectable()
export class StateService {

	private countryStates: Map<string, State[]> = new Map<string, State[]>();

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	getStates(country: Country): Promise<State[]> {
		if (this.countryStates.has(country.CountryCode)) {
			return Promise.resolve(this.countryStates.get(country.CountryCode));
		}
		return this.loadStates(country);
	}

	private loadStates(country: Country): Promise<State[]> {
		return this.http.get(this.configService.baseUrl + '/api/RegistrationCountry/?countryCode=' + country.CountryCode)
			.toPromise()
			.then(res => {
				if (!res) {
					return;
				}
				let states: State[] = res.json().map(state => new State(state));
				this.countryStates.set(country.CountryCode, states);
				return states;
			})
			.catch(error => {
				this.logger.error(error);
				return null;
			});
	}
}
