export class CountryField {
	CountryCode: string;
	CountryName: string;
}
export class StateField {
	LocationState: string;
	StateName: string;
}
export class Market {
	Id: number;
	Name: string;
}

export class AccountField {
	constructor() {}

	AccountID: number;
	AccountName: string;
	Country: CountryField;
	States: StateField[];
	MarketDMA: Market[];
	MarketDMADefault: Market;
	Subscribers: number;
}

export class AccountFieldRequest {
	AccountID: number;
	AccountName: string;
	AccountNameCustom: string;
	Country: CountryField;
	State: StateField;
	MarketDMA: Market;
	MarketDMADefault: Market;
	City: string;
	Subscribers: number;
}


