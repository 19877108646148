import { Injectable } from '@angular/core';
import { HttpClient } from '../../../core/http/http-client';
import { ConfigService } from '../../../core/config.service';
import { Response } from '@angular/http';
import { AuthenticationService } from '../../../core/auth/authentication.service';
import { ArrayUtils } from '../../../core/object-utils';
import { LoggerService } from '../../../core/logger/logger.service';

export class LiveBrand {
	ServiceId: number;
	ServiceLongname: string;
	ExternalUrl: string;
}

@Injectable()
export class LiveBrandsService {
	private url = this.configService.baseUrl + '/api/Livegridbrand?premiereOnly=';
	private liveBrands: LiveBrand[];
	private premiereBrands: LiveBrand[];

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private authService: AuthenticationService,
	            private logger: LoggerService) {
		this.authService.onLogout.subscribe(() => {
			this.liveBrands = null;
		});
	}

	getLiveBrands(): Promise<LiveBrand[]> {
		if (this.liveBrands) {
			return Promise.resolve(this.liveBrands);
		} else {
			return this.loadLiveBrands(false);
		}
	}

	getPremiereBrands(): Promise<LiveBrand[]> {
		if (this.premiereBrands) {
			return Promise.resolve(this.premiereBrands);
		} else {
			return this.loadLiveBrands(true);
		}
	}

	getLiveBrandById(id: number): Promise<LiveBrand> {
		return this.getLiveBrands().then((liveBrands: LiveBrand[]) => {
			return ArrayUtils.findByProperty(liveBrands, 'ServiceId', id);
		}).catch((err) => {
			this.logger.error(err);
			return null;
		});
	}

	getFirstLiveBrand(): Promise<LiveBrand> {
		return this.getLiveBrands().then((liveBrands: LiveBrand[]) => {
			return liveBrands.length ? liveBrands[0] : null;
		}).catch((err) => {
			this.logger.error(err);
			return null;
		});
	}

	private loadLiveBrands(premiereOnly: boolean): Promise<LiveBrand[]> {
		return this.http.get(this.url + premiereOnly).toPromise()
			.then((res: Response) => {
				if (premiereOnly) {
					this.premiereBrands = res.json().sort(this.sortByServiceLongName);
					return this.premiereBrands;
				}
				else {
					this.liveBrands = res.json().sort(this.sortByServiceLongName);
					return this.liveBrands;
				}
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	private sortByServiceLongName(first: LiveBrand, second: LiveBrand) {
		return first.ServiceLongname.localeCompare(second.ServiceLongname);
	}
}