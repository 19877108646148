import { Component, Input } from '@angular/core';
import { ProgrammingHighlights } from '../programming-highlights';

@Component({
	template: require('./program-info.component.html'),
	selector: 'vcm-programminHighlightsInfo'
})
export class ProgramInfoComponent {

	@Input() programminHighlightsInfo: ProgrammingHighlights;

	constructor() {}

}