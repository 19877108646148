import { Injectable } from '@angular/core';
import { Role } from '../../shared/registration-user';
import { HttpClient } from '../../../../core/http/http-client';
import { ConfigService } from '../../../../core/config.service';
import { LoggerService } from '../../../../core/logger/logger.service';

@Injectable()
export class RolesService {
	private roles: Role[];

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	getRoles(): Promise<Role[]> {
		if (this.roles) {
			return Promise.resolve(this.roles);
		}
		return this.loadRoles();
	}

	private loadRoles() {
		return this.http.get(this.configService.baseUrl + '/api/RegistrationRole')
			.toPromise()
			.then(res => {
				this.roles = res.json().map(role => new Role(role));
				return this.roles;
			})
			.catch(error => {
				this.logger.error(error);
				return null;
			});
	}
}
