import { Injectable } from '@angular/core';
import { HttpClient } from '../../../core/http/http-client';
import { ConfigService } from '../../../core/config.service';
import { ProfileUser } from '../profileUser';
import { Response } from '@angular/http';
import { AuthenticationService } from '../../../core/auth/authentication.service';
import { LoggerService } from '../../../core/logger/logger.service';

export interface ProfileSaveUserResponse {
	Result: boolean;
	ValidationDate: Date;
}

@Injectable()
export class ProfileUserService {
	private _user: ProfileUser;

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private authService: AuthenticationService,
	            private logger: LoggerService) {
		this.authService.onLogout.subscribe(() => {
			this._user = null;
		});
	}

	getUser(): Promise<ProfileUser> {
		if (this._user) {
			return Promise.resolve(this._user);
		}
		return this.loadUser();
	}

	saveUser(user: ProfileUser): Promise<ProfileSaveUserResponse> {
		let url = this.configService.baseUrl + '/api/Profile';

		return this.http.post(url, user).toPromise().then((res: Response) => {
			let response: ProfileSaveUserResponse = res.json();
			return response;
		}).catch((err) => {
			this.logger.error(err);
			return null;
		});
	}

	loadUser(): Promise<ProfileUser> {
		return this.http.get(this.configService.baseUrl + '/api/Profile')
			.toPromise()
			.then((res: Response) => {
				this._user = res.json();
				return this._user;
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}