import { Component, OnInit } from '@angular/core';
import { RegistrationService } from '../../registration.service';

@Component({
	template: require('./registration-step-final.component.html')
})

export class RegistrationStepFinalComponent implements OnInit {
	userSaved: boolean;

	constructor(private registrationService: RegistrationService) {
	}

	ngOnInit() {
		this.userSaved = this.registrationService.userSaved;
	}
}
