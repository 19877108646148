import { Component, Input, OnChanges } from '@angular/core';

@Component({
	selector: 'vcm-read-more',
	template: require('./read-more.component.html')
})

export class ReadMoreComponent implements OnChanges {
	@Input('text') text: string;
	@Input('additionalText') additionalText: string;
	@Input() maxLength: number = 350;

	currentText: string;
	showButton: boolean = true;
	isCollapsed: boolean = true;

	showText() {
		this.isCollapsed = !this.isCollapsed;
		this.determineView();
	}

	determineView() {
		if (!this.text) {
			this.showButton = false;
			return;
		}
		if (this.text.length <= this.maxLength && !this.additionalText) {
			this.currentText = this.text;
			this.isCollapsed = false;
			this.showButton = false;
			return;
		}
		if (this.isCollapsed == true) {
			this.showButton = true;
			this.currentText = this.text.substring(0, this.maxLength) + "...";
		} else if (this.isCollapsed == false) {
			this.currentText = this.text;
		}

	}

	ngOnChanges() {
		this.determineView();
	}
}