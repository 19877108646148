import { VideoMuteComponent } from './video-mute/video-mute.component';
import { NgModule } from '@angular/core';
import { VideoComponent } from './video.component';
import { SharedModule } from '../shared.module';
import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { VideoDownload } from './video-download/video-download';
import { VideoPlaylist } from './video-playlist/video-playlist';

@NgModule({
	imports: [
		SharedModule,
		VgCoreModule,
		VgControlsModule,
		VgOverlayPlayModule,
		VgBufferingModule
	],
	declarations: [
		VideoComponent,
		VideoPlaylist,
		VideoDownload,
		VideoMuteComponent
	],
	providers: [
	],
	exports: [
		VideoComponent
	]
})
export class VideoModule {
}
