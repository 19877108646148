import { Injectable } from '@angular/core';
import * as Immutable from 'immutable';

export type SORT_ORDER = 'asc' | 'desc';

export interface GridOptions {
	api?: GridApi;
}

export interface SortConfig {
	key?: string,
	order?: SORT_ORDER,
	type?: SORT_TYPE
}

export type SORT_TYPE = 'string' | 'number' | 'date';

@Injectable()
export class GridApi {
	public sort: SortConfig = {};
	public filter: Immutable.Map<string, string> = Immutable.Map<string, string>();
	public search: string;
}