import { Component, Input, HostBinding, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'vcm-output',
	template: require('./output.component.html'),
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutputComponent {
	@Input('label') label: string;
	@Input('value') value: string;
	@HostBinding('class.vcm-output') addClass: boolean = true;
}