import { Component, Output, EventEmitter } from '@angular/core';
import { LoadingMaskService } from '../../../../shared/loading-mask/loading-mask.service';
import { EnterEmailService } from '../enter-email.service';
import { EMAIL_PATTERN } from '../../../registration/steps/step1/registration-step1.component';

@Component({
	selector: 'vcm-enter-email-form',
	template: require('./enter-email-form.component.html')
})

export class EnterEmailFormComponent {
	@Output() public emailSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

	email: string;
	emailPattern = EMAIL_PATTERN;

	constructor(private mask: LoadingMaskService,
	            private enterEmailService: EnterEmailService) {}

	saveEmail(): void {
		this.mask.addLoading();
		this.enterEmailService.sendEmail(this.email).then((emailResponse) => {
			this.mask.removeLoading();
			this.emailSubmitted.emit(emailResponse);
		})
	}
}