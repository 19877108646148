import { Component, Input, HostBinding, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { START_HOUR, WORKING_HOURS } from '../../live-grid-shows.service';
import { HOUR_HEIGHT } from '../live-grid-table.component';

@Component({
	template: require('./timeline.component.html'),
	selector: 'vcm-timeline',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class TimelineComponent {
	@Input() fromHour: number = START_HOUR;
	@Input() hoursAmout: number = WORKING_HOURS;
	@Input() hourHeight: number = HOUR_HEIGHT;
	@Input() title: string;
	@Input() showTimeline: boolean = true;
	@Input() active: boolean;
	@Output() onSelect: EventEmitter<void> = new EventEmitter<void>();
	@HostBinding('class.vcm-timeline') addClass: boolean = true;

	getHours(): string[] {
		let result: string[] = [];
		for (let i = this.fromHour; i < this.hoursAmout + this.fromHour; i++) {
			let hour = i > 24 ? i - 24 : i;
			let hourStr = (hour <= 12 ? `${hour} ` : `${hour - 12} `) +
				(hour % 24 < 12 ? `AM` : `PM`);
			result.push(hourStr);
		}
		return result;
	}

	onTitleSelect() {
		if (this.active) {
			this.onSelect.emit();
		}
	}
}