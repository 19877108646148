import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'vcm-enter-new-password',
	template: require('./enter-new-password.component.html')
})

export class EnterNewPasswordComponent implements OnInit {
	isPasswordChanged: boolean = false;
	newPasswordSubmitted: boolean = false;
	activationCodeValid: boolean = false;

	constructor(private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: {restoreCodeValid: boolean}) => {
			this.activationCodeValid = data.restoreCodeValid;
		});
	}

	displayNewPasswordSubmittedMessage(paswordChanged: boolean): void {
		this.newPasswordSubmitted = true;
		this.isPasswordChanged = paswordChanged;
	}
}