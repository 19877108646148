import { PromotionFilters } from './filter-option';
import { ConfigService } from '../../../core/config.service';
import { HttpClient } from '../../../core/http/http-client';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../../core/auth/authentication.service';
import { LoggerService } from '../../../core/logger/logger.service';

@Injectable()
export class PromotionFiltersService {
	constructor(private configService: ConfigService,
	            private authService: AuthenticationService,
	            private http: HttpClient,
	            private logger: LoggerService) {
		this.authService.onLogout.subscribe(() => {
			this._filters = null;
		});

	}

	_filters: PromotionFilters;
	private url = this.configService.baseUrl + '/api/PromotionAvailableFilters';

	getFilters(): Observable<PromotionFilters> {
		if (this._filters) {
			return Observable.of(this._filters);
		}
		return this.loadFilters();
	}

	loadFilters(): Observable<PromotionFilters> {
		return this.http.get(this.url)
			.map((res: Response) => {
				this._filters = res.json();
				return this._filters;
			}).catch((err) => {
				this.logger.error(err);
				return Observable.of(null);
			});
	}

}