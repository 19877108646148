import { Component, EventEmitter, Output, Input } from '@angular/core';
import { LoadingMaskService } from '../../../../../shared/loading-mask/loading-mask.service';
import { RemoveHeadendFormService } from './remove-headend-form.service';
import { RemoveReason } from './reason.service';
import { HeadendSummary } from '../../shared/headend-summary.service';

@Component({
	selector: 'vcm-remove-headend-form',
	template: require('./remove-headend-form.component.html')
})

export class RemoveHeadendFormComponent {
	commentMessage: string = null;
	details: string[] = [];
	dropDate: Date = null;
	selectedReason: any = null;

	@Input() reasons: RemoveReason[];
	@Input() headendSummary: HeadendSummary;
	@Input() headendId: string;

	@Output() feedbackSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private removeHeadendFormService: RemoveHeadendFormService,
	            private mask: LoadingMaskService) {
	}

	onSubmit() {
		this.mask.addLoading();
		let request = Object.assign({
			Reason: this.selectedReason,
			Comment: this.commentMessage,
			DropDate: this.dropDate,
			HeadendId: this.headendId
		}, this.headendSummary);
		this.removeHeadendFormService.submitRequest(request)
			.then((resp) => {
				this.mask.removeLoading();
				this.feedbackSubmitted.emit(resp);
				window.scrollTo(0, 0);
			});
	}
}
