import { Injectable } from '@angular/core';
import { ResourcesService } from '../resources.service';

@Injectable()
export class ImagesService {

	constructor(private resourcesService: ResourcesService) {}

	getImages(filter: any = {}): Promise<string> {
		filter.resourceType = 'IMAGES';
		return this.resourcesService.getResourcesList(filter);
	}
}