import { Component, OnInit } from '@angular/core';
import { LiveBrand } from './dropdowns/live-brands.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Highlight } from './dropdowns/highlights.service';
import { ArrayUtils } from '../../core/object-utils';
import { GenericGrid } from './dropdowns/generic-grids.service';
import { Guide } from './dropdowns/guides.service';
import { ProgramDetailsService } from './program-details/program-details.service';
import { LiveGridShowsService } from './live-grid/live-grid-shows.service';
import { ProgrammingHighlightsListService } from './programming-highlights-list/programming-highlights-list.service';

export const DEFAULT_SELECT: string = 'select...';

@Component({
	template: require('./programming.component.html')
})
export class ProgrammingComponent implements OnInit {
	liveBrands: LiveBrand[] = [];
	selectedBrand: LiveBrand = null;
	selectLiveGridValue: string = DEFAULT_SELECT;

	highlights: Highlight[] = [
		{
			Highlights: 'All',
			ServiceId: 0
		}
	];
	selectedHighlight: Highlight;
	selectHighlightsValue: string = DEFAULT_SELECT;

	genericGrids: GenericGrid[] = [];
	selectedGenericGrid: GenericGrid[];
	selectGenericGridsValue: string = DEFAULT_SELECT;

	guides: Guide[] = [];
	selectedGuide: Guide[];
	selectGuidesValue: string = DEFAULT_SELECT;

	constructor(private route: ActivatedRoute,
	            private router: Router,
	            private programDetailsService: ProgramDetailsService,
	            private liveGridShowsService: LiveGridShowsService,
	            private programmingHighlightsListService: ProgrammingHighlightsListService) {}

	ngOnInit() {
		this.route.data.forEach((data: {
			genericGrids: GenericGrid[]
			guides: Guide[]
		}) => {
			this.genericGrids = data.genericGrids;
			this.guides = data.guides;
			this.changeDropdowns();
		});
	}

	changeDropdowns() {

		this.liveGridShowsService.getLiveGridId().subscribe(liveGridId => {
			if (liveGridId) {
				this.selectedBrand = ArrayUtils.findByProperty(this.liveBrands, 'ServiceId', liveGridId);
				this.selectLiveGridValue = null;
			} else {
				this.selectedBrand = null;
				this.selectLiveGridValue = DEFAULT_SELECT;
			}
		});

		let brandId = +this.programDetailsService.serviceId || this.liveGridShowsService.liveGridId;
		if (brandId) {
			this.selectedBrand = ArrayUtils.findByProperty(this.liveBrands, 'ServiceId', brandId);
			this.selectLiveGridValue = null;
		} else {
			this.selectedBrand = null;
			this.selectLiveGridValue = DEFAULT_SELECT;
		}

		this.programmingHighlightsListService.getHighlightId().subscribe(highlightId => {
			if (highlightId) {
				if (highlightId == -1) {
					this.selectedHighlight = this.highlights[0]
				} else {
					this.selectedHighlight = ArrayUtils.findByProperty(this.highlights, 'ServiceId', highlightId)
				}
				this.selectHighlightsValue = null;
			} else {
				this.selectedHighlight = null;
				this.selectHighlightsValue = DEFAULT_SELECT;
			}
		});
	}

	goToProgrammingHighlightsList(selectedHighlight?: Highlight): void {
		if (selectedHighlight) {
			this.selectedBrand = null;
			this.liveGridShowsService.clearParams();
			let id = selectedHighlight.ServiceId;
			this.router.navigate(['programming', 'highlights', id ? {brand: id} : {}]);
		}
		this.selectHighlightsValue = null;
		this.selectLiveGridValue = DEFAULT_SELECT;
		this.selectedBrand = null;
	}

	goToLiveGrid(selectedBrand?: LiveBrand): void {
		if (selectedBrand && this.liveGridShowsService.liveGridId !== selectedBrand.ServiceId) {
			this.selectedHighlight = null;
			this.programDetailsService.serviceId = null;
			let id = selectedBrand.ServiceId;
			this.router.navigate(['programming', 'livegrid', id]);
		}
		this.selectLiveGridValue = null;
		this.selectHighlightsValue = DEFAULT_SELECT;
		this.selectedHighlight = null;
	}

	downloadGenericGrid(genericGrid: GenericGrid): void {
		window.open(genericGrid.LinkFileUrl);
		this.selectGenericGridsValue = DEFAULT_SELECT;
		this.selectedGenericGrid = null;

	}

	downloadGuide(guide: Guide): void {
		window.open(guide.LinkFileUrl);
		this.selectGuidesValue = DEFAULT_SELECT;
		this.selectedGuide = null;
	}
}