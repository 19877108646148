import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadingMaskService } from '../../shared/loading-mask/loading-mask.service';
import { LoginPageService } from './login-page.service';
import { LoginPageModel } from './login-page-model';

@Injectable()
export class LoginPageResolve implements Resolve<LoginPageModel> {
	constructor(private service: LoginPageService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<LoginPageModel> {
		this.mask.addLoading();
		return this.service.getLoginPageModel()
			.map((model: LoginPageModel) => {
				this.mask.removeLoading();
				return model;
			});
	}
}