import { NgModule } from '@angular/core';
import { ChannelDescriptionComponent } from './channel-description.component';
import { ChannelDescriptionService } from './channel-description.service';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [
		ChannelDescriptionComponent
	],
	providers: [
		ChannelDescriptionService
	]
})

export class ChannelDescriptionModule {
}


