import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { PrefixService } from './prefix.service';

@Injectable()
export class PrefixResolve implements Resolve<string[]> {
    constructor(private service: PrefixService) {
    }

    resolve(route: ActivatedRouteSnapshot): Promise<string[]> {
        return this.service.getPrefixes();
    }
}
