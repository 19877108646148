import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicModule } from './dynamic/dynamic.module';
import { SharedFormModule } from './form/shared-form.module';
import { ModalModule, CarouselModule, DatepickerModule, DropdownModule, CollapseModule } from 'ng2-bootstrap';
import { GridModule } from './grid-view/grid.module';
import { HelpPopupComponent } from './help-popup/help-popup.component';
import { SanitizeHtml } from './pipes/sanitize-html.pipe';
import { MultipleSelectModule } from './multiple-select/multiple-select.module';
import { BackLinkComponent } from './back-link.component';
import { ReplaceBaseHrefPipe } from './pipes/replace-link.pipe';
import { RelatedContentModule } from './related-content/related-content.module';
import { SelectModule } from './form/select/select.module';
import { SignalsAddressPipe } from './pipes/address-pipe';
import { ReadMoreComponent } from './read-more/read-more.component';
import { TargetRolesComponent } from './target-roles/target-roles.component';
import { SanitizeUrl } from './pipes/sanitize-url.pipe';
import { DirectivesModule } from './directives/directives.module';

@NgModule({
	imports: [
		CommonModule,
		DynamicModule,
		SharedFormModule,
		ModalModule.forRoot(),
		CarouselModule.forRoot(),
		DatepickerModule.forRoot(),
		DropdownModule.forRoot(),
		CollapseModule.forRoot(),
		GridModule,
		MultipleSelectModule,
		SelectModule,
		RelatedContentModule,
		DirectivesModule
	],
	declarations: [
		HelpPopupComponent,
		SanitizeHtml,
		SanitizeUrl,
		BackLinkComponent,
		ReplaceBaseHrefPipe,
		SignalsAddressPipe,
		ReadMoreComponent,
		TargetRolesComponent
	],
	exports: [
		CommonModule,
		SharedFormModule,
		DynamicModule,
		ModalModule,
		CarouselModule,
		DatepickerModule,
		DropdownModule,
		CollapseModule,
		GridModule,
		HelpPopupComponent,
		SanitizeHtml,
		SanitizeUrl,
		MultipleSelectModule,
		SelectModule,
		BackLinkComponent,
		ReplaceBaseHrefPipe,
		SignalsAddressPipe,
		RelatedContentModule,
		ReadMoreComponent,
		TargetRolesComponent,
		DirectivesModule
	]
})

export class SharedModule {
}
