import { Component, EventEmitter, Output, Input, ViewChildren, QueryList } from '@angular/core';
import { HeadendGridRow } from '../../../signals';
import { SwapRequestService } from '../swap-request.service';
import { NgForm, NgModel } from '@angular/forms';

@Component({
	selector: 'vcm-swap-request-form',
	template: require('./swap-request-form.component.html')
})

export class SwapRequestFormComponent {
	comment: string;
	details: string[] = [];
	newAnchor: string;
	newAcp: string;
	isCisco: boolean;

	public anchorMaskUCS = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
	public anchorMaskSA = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
	public acpMaskSA = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

	@Input() service: HeadendGridRow;
	@Input() index: number;

	@Output() feedbackSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChildren(NgModel) controls: QueryList<NgModel>;

	constructor(private swapRequestService: SwapRequestService,
	            private swap: NgForm) {
	}

	ngOnInit() {
		this.isCisco = this.service.UnitAddress.length == 12;
		this.newAnchor = '';
		this.newAcp = '';
		this.comment = '';
		this.setAcp();
	}

	ngAfterViewInit() {
		this.controls.forEach((control: NgModel) => {
			this.swap.addControl(control);
		});
	}

	private unmask(value?: string): string {
		return value ? value.replace(/\D+/g, '') : null;
	}

	setAcp(): void {
		this.swapRequestService.swappedRequest[this.index].AcpId = this.service.AcpId;
	}

	updateAnchor(anchor: string): void {
		this.swapRequestService.swappedRequest[this.index].NewUnitAddress = this.unmask(anchor);
	}

	updateAcp(acp: string): void {
		this.swapRequestService.swappedRequest[this.index].NewACPAddress = this.unmask(acp);
	}

	addComment(comment: string): void {
		this.swapRequestService.swappedRequest[this.index].Comment = comment;
	}
}
