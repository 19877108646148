import { Injectable } from '@angular/core';
import * as Immutable from 'immutable';
import { ResourcesBrandsService, ResourceBrand } from '../resources-brands.service';

@Injectable()
export class ActiveFiltersService {
	private filter: Immutable.Map<string, any> = Immutable.Map<string, any>();

	constructor(private brandsService: ResourcesBrandsService) {}

	get brandFilterOptions(): ResourceBrand[] {
		return this.brandsService.brandFilterOptions;
	}

	set brandFilterOptions(value: ResourceBrand[]) {
		this.brandsService.brandFilterOptions = value;
	}

	get selectedBrandFilterOptions(): ResourceBrand[] {
		return this.brandsService.selectedBrandFilterOptions;
	}

	set selectedBrandFilterOptions(value: ResourceBrand[]) {
		this.brandsService.selectedBrandFilterOptions = value;
	}

	getActiveFilters(): {[key: string]: string[]|string} {
		let result = this.filter.toObject();
		delete result['Id'];
		return result;
	}

	getBrandsFromIds(brands: string[]): ResourceBrand[] {
		return this.brandsService.getBrandsFromIds(brands);
	}

	clearFilters() {
		this.filter = this.filter.clear();
	}

	setFilter(key: string, value: any) {
		this.filter = this.filter.set(key, value);
	}

	getFilter(key: string): any {
		return this.filter.get(key);
	}
}