import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Landing } from '../view/landing/landing.service';

@Component({
	template: require('./home.component.html')
})

export class HomeComponent implements OnInit {
	landing: Landing;

	constructor(private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.route.data.forEach((data: {landing: Landing }) => {
			this.landing = data.landing;
		});
	}
}
