import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { ProfileAddressService } from './profile-address.service';
import { ProfileAddress } from './profile-address';

@Injectable()
export class ProfileAddressesResolve implements Resolve<ProfileAddress[]> {
	constructor(private service: ProfileAddressService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<ProfileAddress[]> {
		this.mask.addLoading();
		return this.service.getAddresses()
			.then((addresses: ProfileAddress[]) => {
				this.mask.removeLoading();
				return addresses;
			});
	}
}