import * as Immutable from 'immutable';
import { FilterOption } from './filter-option';
import { ConfigService } from '../../../core/config.service';
import { HttpClient } from '../../../core/http/http-client';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { Response } from '@angular/http';

export abstract class FilterOptionsService {
	private url = this.configService.baseUrl + '/api/AvailableFilters?resourceType=';

	private allFilterLines: Immutable.Map<string, FilterOption>[] = [];

	constructor(private resourceType: string,
	            private configService: ConfigService,
	            private http: HttpClient,
	            private mask: LoadingMaskService) {
	}

	abstract parseFilterLine(filterLine: any, allFilterLines: Immutable.Map<string, FilterOption>[]): void;

	getOptions(selectedFilters: {[key: string]: FilterOption[]} = {}): Promise<Map<string, FilterOption[]>> {
		let selectedFiltersMap = Immutable.Map(selectedFilters);
		return this.getAllFilterLines().then((allFilterLines: Immutable.Map<string, FilterOption>[]) => {
			let availableFiltersMap: Map<string, FilterOption[]> = new Map<string, FilterOption[]>();
			let supportingFiltersIdSet: Map<string, Set<string>> = new Map<string, Set<string>>();
			allFilterLines.forEach((filterLine: Immutable.Map<string, FilterOption>) => {
				if (this.isLineMatchesFilters(selectedFiltersMap, filterLine)) {
					filterLine.forEach((value: FilterOption, key: string) => {
						this.addToAvailableFiltersMap(availableFiltersMap, supportingFiltersIdSet, key, value);
					});
				}
			});
			return availableFiltersMap;
		});
	}

	private getAllFilterLines(): Promise<Immutable.Map<string, FilterOption>[]> {
		if (this.allFilterLines.length === 0) {
			return this.loadFilterOptions();
		} else {
			return Promise.resolve(this.allFilterLines);
		}
	}

	private loadFilterOptions(): Promise<any> {
		this.mask.addLoading();
		return this.http.get(this.url + this.resourceType)
			.toPromise()
			.then((res: Response) => {
				this.mask.removeLoading();
				let response: any = res.json();
				this.allFilterLines = this.extractFilterOptions(response.Filters);
				return this.allFilterLines;
			}).catch((err) => {
				this.mask.removeLoading();
				return Promise.resolve({});
			});
	}

	private extractFilterOptions(filterLines: any[]): Immutable.Map<string, FilterOption>[] {
		this.allFilterLines = [];
		filterLines.forEach((filterLine: any) => {
			this.parseFilterLine(filterLine, this.allFilterLines);
		});
		return this.allFilterLines;
	}

	private isLineMatchesFilters(selectedFilters: Immutable.Map<string, FilterOption[]>,
	                             filterLine: Immutable.Map<string, FilterOption>): boolean {
		let selectedFilterKeys: string[] = selectedFilters.keySeq().toArray();
		for (let i = 0; i < selectedFilterKeys.length; i++) {
			let selectedFilterKey = selectedFilterKeys[i];
			let selectedFilterValues: FilterOption[] = selectedFilters.get(selectedFilterKey);
			if (selectedFilterValues.length > 0 && !selectedFilterValues.find(option => String(option.Id) === String(filterLine.get(selectedFilterKey).Id))) {
				return false;
			}
		}
		return true;
	}

	private addToAvailableFiltersMap(availableFiltersMap: Map<string, FilterOption[]>, supportingFiltersIdSet: Map<string, Set<string>>,
	                                 key: string, value: FilterOption) {
		if (!supportingFiltersIdSet.has(key)) {
			supportingFiltersIdSet.set(key, new Set<string>());
			availableFiltersMap.set(key, []);
		}
		if (!supportingFiltersIdSet.get(key).has(value.Id)) {
			supportingFiltersIdSet.get(key).add(value.Id);
			availableFiltersMap.get(key).push(value);
		}
	}
}