import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActiveFiltersService } from '../filters/active-filters.service';
import { TechSpec, TechSpecsService } from './tech-specs.service';
import { LandingService, Landing } from '../../view/landing/landing.service';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';

@Component({
	template: require('./tech-specs.component.html')
})

export class TechSpecsComponent {
	techSpecs: TechSpec[] = [];
	idArray: number[];
	selectedFilters: any;
	landing: Landing;
	isEmailSent: boolean = false;
	sendingEmailStatus: boolean = true;

	constructor(private route: ActivatedRoute,
	            private activeFilters: ActiveFiltersService,
	            private service: TechSpecsService,
	            private landingService: LandingService,
	            private mask: LoadingMaskService) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: {techSpecs: TechSpec[]}) => {
			this.techSpecs = data.techSpecs;
			this.idArray = data.techSpecs.map(element => {return element.id});
			this.selectedFilters = this.activeFilters.getActiveFilters();
		});

		this.landingService.getLanding({
			landingType: 'TECHSPECS'
		}).subscribe(landing => this.landing = landing);
	}

	exportToExcel(techSpecs: TechSpec[]): void {
		let idArray = techSpecs.map((techSpecs) => {return techSpecs.id});
		this.service.exportToExcel(idArray);
	}

	sendToEmail(): void {
		this.mask.addLoading();
		this.service.sendDetails(this.idArray).then((response) => {
			this.isEmailSent = true;
			if (response.json()) {
				this.sendingEmailStatus = true;
			} else {
				this.sendingEmailStatus = true;
			}
			this.mask.removeLoading();
		}, error => {
			this.sendingEmailStatus = false;
			this.isEmailSent = true;
			this.mask.removeLoading();
		});
	}

	backToTechSpecs() {
		this.isEmailSent = false;
	}
}