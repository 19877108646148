import { Injectable } from '@angular/core';
import { FilterOption } from '../../filters/filter-option';
import * as Immutable from 'immutable';
import { FRANCHISE, DEMO } from './social-media-filter.component';
import { BRANDS } from '../../filters/common-filters';
import { ConfigService } from '../../../../core/config.service';
import { HttpClient } from '../../../../core/http/http-client';
import { LoadingMaskService } from '../../../../shared/loading-mask/loading-mask.service';
import { FilterOptionsService } from '../../filters/filter-options.service';

@Injectable()
export class SocialMediaFilterOptionsService extends FilterOptionsService {

	constructor(configService: ConfigService,
	            http: HttpClient,
	            mask: LoadingMaskService) {
		super('SOCMEDIA', configService, http, mask);
	}

	parseFilterLine(filterLine: any, allFilterLines: Immutable.Map<string, FilterOption>[]): void {
		filterLine.Brands.forEach((brand: FilterOption) => {
			let filterMappingLine = Immutable.Map<string, FilterOption>({
				[BRANDS]: brand,
				[FRANCHISE]: filterLine.Franchise,
				[DEMO]: filterLine.Demo
			});
			allFilterLines.push(filterMappingLine);
		});
	}
}