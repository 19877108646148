import { AuthenticationService } from './../auth/authentication.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

const STORAGE_KEY = 'isMuted';

@Injectable()
export class SoundService {
	static defaultMuted = true;

	private muted: boolean;
	private subject: Subject<boolean> = new Subject<boolean>();
	private videoSubject: Subject<boolean> = new Subject<boolean>();

	constructor(
		private authService: AuthenticationService
	) {
		this.muted = localStorage.getItem(STORAGE_KEY) === (!SoundService.defaultMuted).toString() ? !SoundService.defaultMuted : SoundService.defaultMuted;

		this.authService.onLogout.subscribe(() => {
			this.setDefault();
		});
	}

	toggleSound(notifyVideo: boolean = true) {
		this.setMuted(!this.muted, notifyVideo);
	}

	soundOn() {
		this.setMuted(false, false);
	}

	setDefault() {
		this.setMuted(SoundService.defaultMuted, false);
	}

	isButtonMuted(): Observable<boolean> {
		let source = Observable.empty();

		return <Observable<boolean>>source.startWith(this.muted).concat(this.subject);
	}

	isVideoMuted(): Observable<boolean> {
		let source = Observable.empty();

		return <Observable<boolean>>source.startWith(this.muted).concat(this.videoSubject);
	}

	private setMuted(muted, notifyVideo) {
		this.muted = muted;
		localStorage.setItem(STORAGE_KEY, this.muted.toString());
		this.subject.next(this.muted);

		if (notifyVideo) {
			this.videoSubject.next(this.muted);
		}
	}
}