import { Component, Input } from '@angular/core';
import { AssetsService } from '../../../../../core/assets.service';

@Component({
	template: require('./landing-logo.component.html'),
	selector: 'vcm-landing-logo'
})

export class LandingLogoComponent {
	@Input() resources: any;

	constructor(private assetsService: AssetsService) {
	}

	getAssetsURL(assetsPath: string): string {
		return this.assetsService.getAssetUrl(assetsPath);
	}
}
