import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { HeadendSummary, HeadendSummaryService } from './headend-summary.service';
import { LoadingMaskService } from '../../../../shared/loading-mask/loading-mask.service';

@Injectable()
export class HeadendSummaryResolve implements Resolve<HeadendSummary> {
	constructor(private service: HeadendSummaryService,
	            private mask: LoadingMaskService) {}

	resolve(route: ActivatedRouteSnapshot): Promise<HeadendSummary> {
		this.mask.addLoading();
		return this.service.getSummary()
			.then((summary: any) => {
				this.mask.removeLoading();
				return summary;
			});
	}
}