import { Component } from '@angular/core';
import { ProgrammingHighlights } from '../programming-highlights';
import { ActivatedRoute, Router } from '@angular/router';
// import { ProgrammingHighlightsListService } from '../programming-highlights-list/programming-highlights-list.service';
import { Highlight } from '../dropdowns/highlights.service';
import { ArrayUtils } from '../../../core/object-utils';
import {PremieresListService} from "./premieres-list.service";
import {LiveBrand} from "../dropdowns/live-brands.service";
import {LiveGridShow} from "../live-grid/live-grid-show";
import * as moment from 'moment';
import Moment = moment.Moment;

@Component({
	template: require('./premieres-list.component.html'),
	selector: 'vcm-premieres'
})

export class PremieresListComponent {
	serviceId: number;

	brands: LiveBrand[];

	brandsAll: LiveBrand[] = [
		{
			ServiceLongname: 'All',
			ServiceId: 0,
			ExternalUrl: null
		}
	];

	premieresList: LiveGridShow[] = [];
	selectedBrand: LiveBrand;
	// selectedPhDetails: ProgrammingHighlights = null;
	// selectedPhDetailsId: number = null;

	constructor(private route: ActivatedRoute,
	            private router: Router,
	            private service: PremieresListService) {}

	ngOnInit() {
		this.route.data.forEach((data: {
			premieresBrands: LiveBrand[],
			premieresList: LiveGridShow[]}) => {
			this.brands = this.brandsAll.concat(data.premieresBrands);
			this.premieresList = data.premieresList;
			this.serviceId = this.service.serviceId;
			if (this.serviceId == -1) {
				this.selectedBrand = this.brands[0]
			} else {
				this.selectedBrand = ArrayUtils.findByProperty(this.brands, 'ServiceId', this.serviceId)
			}
		});
	}

	// selectPremiere(ph: ProgrammingHighlights): void {
	// 	this.selectedPhDetails = ph;
	// 	this.selectedPhDetailsId = ph.ProgramminghighlightsId;
	// }

	// pageTitle(programmingHighlightsList: ProgrammingHighlights[]): string {
	// 	let s = programmingHighlightsList.length > 1 ? 's' : '';
	// 	return '' + programmingHighlightsList.length + ' PREMIERES' + s + ' FOUND'
	// }

	createIdDetailsLink(serviceId: number, date: Moment): string {
		return `/programming/premieres/${serviceId}/${date}`;
	}

	goToPremieresList(selectedBrand?: LiveBrand): void {
		let id = selectedBrand.ServiceId;
		this.router.navigate(['programming', 'premieres', id ? {brand: id} : {}]);
	}

	exportToFile(fileType: string) {
		this.service.exportToFile(fileType, this.selectedBrand.ServiceId)
	}
}