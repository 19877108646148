import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProgrammingHighlights } from '../programming-highlights';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { ProgrammingHighlightsDetailsService } from './programming-highlights-details.service';

@Injectable()
export class ProgrammingHighlightsDetailsResolve implements Resolve<ProgrammingHighlights> {
	constructor(private service: ProgrammingHighlightsDetailsService,
	            private mask: LoadingMaskService,
	            private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<ProgrammingHighlights> {
		let id: string = route.params['phDetailsId'];
		this.mask.addLoading();
		return this.service.getPhDetails(id)
			.map((phDetails: ProgrammingHighlights) => {
				this.mask.removeLoading();

				if (!phDetails) {
					this.router.navigate(['404']);
				}

				return phDetails;
			});
	}
}
