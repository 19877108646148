import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { ImagesComponent } from './images.component';
import { ImagesService } from './images.service';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [
		ImagesComponent
	],
	providers: [
		ImagesService
	]
})

export class ImagesModule {
}