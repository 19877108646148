import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { RedirectService } from './redirect.service';

@Injectable()
export class RedirectGuard implements CanActivate {
	constructor(private location: Location,
	            private redirectService: RedirectService) {
	}

	canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		this.redirectService.getUrlToRedirect('USSI').then((url: string) => {
			this.navigateToUrl(url);

			if (this.location.path() === '') {
				this.location.back();
			}
		});

		return false;
	}

	private navigateToUrl(url: string): void {
		window.open(url);
	}
}
