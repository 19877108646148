import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/auth/auth.guard';
import { BrandsDetailsComponent } from './brands-details.component';
import { BrandsLandingResolve } from './brands-landing-resolve.service';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'brands', pathMatch: 'full', redirectTo: 'home'
			},
			{
				path: 'brands/:brandsID',
				component: BrandsDetailsComponent,
				canActivate: [AuthGuard],
				resolve: {
					landing: BrandsLandingResolve
				}
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		BrandsLandingResolve
	]
})

export class BrandsDetailsRoutingModule {
}
