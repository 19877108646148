import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { AuthorizationGrigService } from '../steps/step2/authorization-grid.service';

@Directive({
	selector: '[notEmptyCheckboxList]',
	providers: [
		{provide: NG_VALIDATORS, useExisting: forwardRef(() => NotEmptyCheckboxListValidator), multi: true}
	]
})

export class NotEmptyCheckboxListValidator implements Validator {
	constructor(private authorizationGrigService: AuthorizationGrigService) {}

	validate(control: AbstractControl): {[key: string]: any} {
		let value: any[] = control.value;
		let servicesCheckedNumber: number = this.authorizationGrigService.servicesCheckedNumber;

		if (!value || servicesCheckedNumber === 0) {
			return {validateNotEmptyCheckboxList: false}
		}

		return null;
	}
}