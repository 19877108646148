import { Component } from '@angular/core';

@Component({
	selector: 'vcm-modal-transcluded',
	template: `
                <ng-content select=".vcm_modal_link"></ng-content>
                <ng-content select=".vcm_modal_body"></ng-content>
`
})
export class TranscludedComponent {

	constructor() {
	}
}