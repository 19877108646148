import { Injectable } from '@angular/core';
import { FilterOption } from '../../filters/filter-option';
import * as Immutable from 'immutable';
import { BRANDS } from '../../filters/common-filters';
import { ConfigService } from '../../../../core/config.service';
import { HttpClient } from '../../../../core/http/http-client';
import { LoadingMaskService } from '../../../../shared/loading-mask/loading-mask.service';
import { FEED, SATELLITE, TRANSPONDER } from './tech-specs-filter.component';
import { FilterOptionsService } from '../../filters/filter-options.service';

@Injectable()
export class TechSpecsFilterOptionsService extends FilterOptionsService {
	constructor(configService: ConfigService,
	            http: HttpClient,
	            mask: LoadingMaskService) {
		super('TECHSPECS', configService, http, mask);
	}

	parseFilterLine(filterLine: any, allFilterLines: Immutable.Map<string, FilterOption>[]): void {
		filterLine.Brands.forEach((brand: FilterOption) => {
			let filterMappingLine = Immutable.Map<string, FilterOption>({
				[BRANDS]: brand,
				[FEED]: filterLine.Feed[0],
				[SATELLITE]: filterLine.Satellite[0],
				[TRANSPONDER]: filterLine.Transponder[0]
			});
			allFilterLines.push(filterMappingLine);
		});
	}
}