import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { SocialMedia, SocialMediaService } from '../social-media.service';
import { LoadingMaskService } from '../../../../shared/loading-mask/loading-mask.service';
import { CommonFiltersResolve } from '../../filters/common-filters-resolve';
import { ActiveFiltersService } from '../../filters/active-filters.service';
import { SocialMediaDetailsService } from './social-media-details.service';

@Injectable()
export class SocialMediaDetailsResolve extends CommonFiltersResolve implements Resolve<SocialMedia> {
	constructor(private service: SocialMediaService,
	            private detailsService: SocialMediaDetailsService,
	            private mask: LoadingMaskService,
	            private activeFiltersService: ActiveFiltersService,
	            private router: Router) {
		super(activeFiltersService);
	}

	resolve(route: ActivatedRouteSnapshot): Promise<SocialMedia> {
		let id: number = +route.params['Id'];
		this.mask.addLoading();
		this.detailsService.currentSocialMediaId = id;
		this.initActiveFiltersFromURLParams(route);
		return this.service.getSocialMediaById(id)
			.then((socialMedia: SocialMedia) => {
				this.mask.removeLoading();

				if (!socialMedia) {
					this.router.navigate(['404']);
				}
				return socialMedia;
			});
	}
}