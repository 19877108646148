import { Component, OnInit } from '@angular/core';
import { Country, State } from '../../../registration/shared/registration-user';
import { ProfileAddress } from '../profile-address';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileAddressService } from '../profile-address.service';
import { ArrayUtils } from '../../../../core/object-utils';
import { ProfileStateService } from '../../contacts/profile-state.service';

@Component({
	template: require('./profile-addresses-edit.component.html')
})

export class ProfileAddressesEditComponent implements OnInit {
	public address: ProfileAddress;
	public isAddressFormChanged: boolean = false;

	public countries: Country[] = [];
	public states: State[] = [];

	public selectedCountry: Country;
	public selectedState: State;

	constructor(private profileAddressService: ProfileAddressService,
	            private stateService: ProfileStateService,
	            private router: Router,
	            private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: {dropdowns: any, address: ProfileAddress}) => {
			this.address = data.address || new ProfileAddress();
			this.countries = data.dropdowns.Country;
			this.selectedCountry = ArrayUtils.findByProperty(this.countries, 'CountryCode', this.address.country);
			if (this.selectedCountry) {
				this.updateCountryStates(this.selectedCountry, this.address.state);
			}
		});
	}

	setFormChanged(): void {
		this.isAddressFormChanged = true;
	}

	saveAddress(): void {
		this.profileAddressService.saveAddress(this.address).then(() => {
			this.router.navigate(['profile', 'addresses']);
		});
	}

	stateOnChange(state?: State): void {
		this.address.state = state ? state.LocationState : null;
	}

	countryOnChange(country?: Country): void {
		this.selectedState = null;
		if (country) {
			this.address.country = country.CountryCode;
			this.updateCountryStates(country);
		}
	}

	private updateCountryStates(country: Country, selectedStateName?: string): void {
		this.states = [];
		this.stateService.getStates(country)
			.then(states => {
				this.states = states;
				if (selectedStateName) {
					this.setSelectedState(selectedStateName);
				}
			});
	}

	private setSelectedState(selectedStateName: string): void {
		setTimeout(()=> {
			this.selectedState = ArrayUtils.findByProperty(this.states, 'LocationState', selectedStateName);
		}, 0);
	}
}