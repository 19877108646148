import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { BusinesArea } from '../../shared/registration-user';
import { BusinessAreasService } from './business-areas.service';
import { LoadingMaskService } from '../../../../shared/loading-mask/loading-mask.service';

@Injectable()
export class BusinessAreasResolve implements Resolve<BusinesArea[]> {
    constructor(private service: BusinessAreasService,
                private mask: LoadingMaskService) {
    }

    resolve(route: ActivatedRouteSnapshot): Promise<BusinesArea[]> {
        this.mask.addLoading();
        return this.service.getBusinessAreas().then((businessAreas: BusinesArea[]) => {
            this.mask.removeLoading();
            return businessAreas;
        });
    }
}