import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/auth/authentication.service';

@Component({
	selector: 'vcm-login-form-comp',
	template: require('./login-form.component.html')
})

export class LoginFormComponent {
	model: any = {};
	loading = false;
	error = '';

	constructor(private router: Router,
	            private authenticationService: AuthenticationService) {
	}

	toLogin() {
		if (!this.model.login || !this.model.pass) {
			this.error = "Login and password cannot be empty";
			return;
		}

		this.loading = true;
		this.authenticationService.login(this.model.login, this.model.pass, this.model.rememberMe)
			.subscribe((isSuccess: boolean) => {
					if (isSuccess) {
						this.authenticationService.redirect();
					}
				},
				error => {
					this.loading = false;
					if (error.status === 401) {
						this.error = 'Login or password is incorrect';
					} else if (error.status === 403) {
						this.router.navigate(['/login']);
						return false;
					}
					else {
						this.error = 'Server error!';
					}
				});
	}
}