import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UploadService } from './upload.service';
import { TermsPageModel } from '../+terms/terms-page.model';

@Injectable()
export class UploadTextResolve implements Resolve<any> {
	constructor(private service: UploadService) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<any> {
		return this.service.getUploadText()
			.map((model: TermsPageModel) => {
				return model;
			});
	}
}

