import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProgrammingComponent } from './programming.component';
import { AuthGuard } from '../../core/auth/auth.guard';
import { LiveBrandsResolve } from './dropdowns/live-brands-resolve.service';
import { HighlightsResolve } from './dropdowns/highlights-resolve.service';
import { ProgrammingHintsComponent } from './live-grid/programming-hints/programming-hints.component';
import { ProgrammingHighlightsListResolve } from './programming-highlights-list/programming-highlights-list-resolve.service';
import { ProgrammingHighlightsListComponent } from './programming-highlights-list/programming-highlights-list.component';
import { ProgrammingHighlightsDetailsComponent } from './programming-highlights-details/programming-highlights-details.component';
import { ProgrammingHighlightsDetailsResolve } from './programming-highlights-details/programming-highlights-details-resolve.service';
import { ProgramDetailsResolve } from './program-details/program-details-resolve.service';
import { ProgramDetailsComponent } from './program-details/program-details.component';
import { LiveGridComponent } from './live-grid/live-grid.component';
import { LiveGridResolve } from './live-grid/live-grid-resolve.service';
import { GenericGridsResolve } from './dropdowns/generic-grids-resolve.service';
import { GuidesResolve } from './dropdowns/guides-resolve.service';
import {PremieresResolve} from "./dropdowns/premieres-resolve.service";
import {PremieresListResolve} from "./premieres-list/premieres-list-resolve.service";
import {PremieresListComponent} from "./premieres-list/premieres-list.component";
import {PremiereDetailsComponent} from "./premiere-details/premiere-details.component";
import {PremiereDetailsResolve} from "./premiere-details/premiere-details-resolve.service";

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'programming',
				component: ProgrammingComponent,
				canActivate: [AuthGuard],
				resolve: {
					genericGrids: GenericGridsResolve,
					guides: GuidesResolve
				},
				children: [
					{
						path: '',
						component: ProgrammingHintsComponent,
					},
					{
						path: 'highlights',
						component: ProgrammingHighlightsListComponent,
						resolve: {
							highlights: HighlightsResolve,
							programmingHighlightsList: ProgrammingHighlightsListResolve
						}
					},
					{
						path: 'highlights/:phDetailsId',
						component: ProgrammingHighlightsDetailsComponent,
						resolve: {
							highlights: HighlightsResolve,
							phDetails: ProgrammingHighlightsDetailsResolve
						}
					},
					{
						path: 'premieres',
						component: PremieresListComponent,
						resolve: {
							premieresBrands: PremieresResolve,
							premieresList: PremieresListResolve
						}
					},
					{
						path: 'premieres/:serviceId/:startDate',
						component: PremiereDetailsComponent,
						resolve: {
							premieresBrands: PremieresResolve,
							programDetails: PremiereDetailsResolve
						}
					},
					{
						path: 'livegrid',
						component: LiveGridComponent,
						resolve: {
							liveBrands: LiveBrandsResolve,
							selectedBrand: LiveGridResolve
						}
					},
					{
						path: 'livegrid/:liveGridId',
						component: LiveGridComponent,
						resolve: {
							liveBrands: LiveBrandsResolve,
							selectedBrand: LiveGridResolve
						}
					},
					{
						path: 'livegrid/:serviceId/:startDate',
						component: ProgramDetailsComponent,
						resolve: {
							liveBrands: LiveBrandsResolve,
							programDetails: ProgramDetailsResolve
						}
					}
				]
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		LiveBrandsResolve,
		HighlightsResolve,
		GenericGridsResolve,
		GuidesResolve,
		ProgrammingHighlightsDetailsResolve,
		ProgramDetailsResolve,
		ProgrammingHighlightsListResolve,
		LiveGridResolve,
		PremieresListResolve,
		PremieresResolve,
		PremiereDetailsResolve
	]
})

export class ProgrammingRoutingModule {
}
