import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonFilters } from '../common-filters';
import { ActiveFiltersService } from '../active-filters.service';
import { FilterOption } from '../filter-option';

@Component({
	template: require('./common-filters.component.html')
})

export class CommonFiltersComponent extends CommonFilters implements OnInit {
	private resourceUrl: string;

	constructor(private route: ActivatedRoute,
	            private router: Router,
	            private activeFilters: ActiveFiltersService) {
		super(activeFilters);
	}

	ngOnInit(): void {
		this.route.data.forEach(() => {
			this.resourceUrl = this.getResourceUrl(this.router.url);
			this.selectedBrandFilterOptions = this.activeFilters.selectedBrandFilterOptions;
		});
	}

	applyFilters(): void {
		let filters = this.getFilterValues();
		this.removeEmptyParams(filters);
		this.router.navigate([this.resourceUrl, filters]);
	}

	changeSelectedBrands(brands: FilterOption[]): void {
		this.selectedBrandFilterOptions = brands;
	}

	resetFilters(): void {
		this.resetCommonFilters();
		this.router.navigate([this.resourceUrl]);
	}
}