import { Injectable } from '@angular/core';
import { ConfigService } from '../../../core/config.service';
import { Http, Response } from '@angular/http';
import { LoggerService } from '../../../core/logger/logger.service';

export class HeaderLogoResponse {
	BackgroundURL: string;
	ImageURL: string;
	LogoURL: string;

	constructor(obj: any) {
		if (obj) {
			this.BackgroundURL = obj.BackgroundURL;
			this.ImageURL = obj.ImageURL;
			this.LogoURL = obj.LogoURL;
		}
	}
}

@Injectable()
export class HeaderService {
	private headerLogoResponse: HeaderLogoResponse;

	constructor(private http: Http,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	getLogo(): Promise<HeaderLogoResponse> {
		if (this.headerLogoResponse) {
			return Promise.resolve(this.headerLogoResponse);
		} else {
			return this.loadLogo();
		}
	}

	loadLogo(): Promise<HeaderLogoResponse> {
		return this.http.get(this.configService.baseUrl + '/api/HeaderImages')
			.toPromise()
			.then((res: Response) => {
				return new HeaderLogoResponse(res.json());
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}