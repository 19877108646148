import { Injectable } from '@angular/core';
import { HeadendGridRow } from './signals';

@Injectable()
export class SignalsService {
	headendId: number;
	acps: HeadendGridRow[];

	constructor() {
	}
}
