import { Directive, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BackgroundService } from './background.service';
import { UserService } from '../../../core/auth/user.service';

@Directive({
	selector: '[vcmBackground]'
})

export class BackgroundDirective implements OnInit, OnDestroy {
	@Input() backgroundClass: string = 'vcm_bg_image';

	private backgroundSubscription: Subscription;

	constructor(private el: ElementRef,
	            private backgroundService: BackgroundService,
	            private userService: UserService) {
	}

	ngOnInit(): void {
		this.backgroundService.setBackgroundImage(this.userService.getCurrentUser() ? 'user_bg' : 'login_bg');
		this.backgroundSubscription = this.backgroundService.onChange.subscribe((backgroundImage: string) => {
			this.setBackgroundImage(backgroundImage);
		});
	}

	ngOnDestroy(): void {
		this.backgroundSubscription.unsubscribe();
	}

	private setBackgroundImage(backgroundImage: string) {
		if (backgroundImage) {
			this.el.nativeElement.classList.add(this.backgroundClass);
			this.el.nativeElement.style.backgroundImage = `url(${backgroundImage})`;
		}
	}
}