import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '../../../../core/http/http-client';
import { SignalsService } from '../../signals.service';
import { ConfigService } from '../../../../core/config.service';
import { LoggerService } from '../../../../core/logger/logger.service';

export interface HeadendSummary {
	EngineerEmail: string;
	EngineerName: string;
	EngineerPhone: number;
	HeadendAddress: string;
	HeadendCity: string;
	HeadendName: string;
	HeadendMSO: string;
	HeadendState: string;
	HeadendZip: string;
}

@Injectable()
export class HeadendSummaryService {
	public summary: HeadendSummary;
	private url: string = this.configService.baseUrl + '/api/AuthorizeSignalSummary?accountId=';

	constructor(private http: HttpClient,
	            private signalsService: SignalsService,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	clearSummary(): void {
		this.summary = null;
	}

	getSummary(): Promise<HeadendSummary> {
		if (this.summary) {
			return Promise.resolve(this.summary);
		}
		return this.loadSummary();
	}

	private loadSummary(): Promise<HeadendSummary> {
		let headendId: number = this.signalsService.headendId;

		return this.http.get(this.url + headendId)
			.toPromise()
			.then((res: Response) => {
				this.summary = res.json();
				return this.summary;
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}