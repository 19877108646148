import { Component } from '@angular/core';
import { WizardComponent } from '../../shared/wizard/wizard-component';
import { RegistrationService } from './registration.service';
import { Router } from '@angular/router';
import { RegistrationUserService } from './registration-user.service';
import { LoadingMaskService } from '../../shared/loading-mask/loading-mask.service';
import { RegistrationStepsService } from './registration-steps.service';

@Component({
	selector: 'vcm-registration',
	template: require('./registration.component.html')
})
export class RegistrationComponent extends WizardComponent {

	constructor(public registrationService: RegistrationService,
	            private registrationStepsService: RegistrationStepsService,
	            private userService: RegistrationUserService,
	            private router: Router,
	            private mask: LoadingMaskService) {
		super(registrationStepsService);
	}

	public next(): any {
		this.registrationService.currentForm = null;
		this.registrationService.addAvailableStep(this.wizardStepsService.getNextStep());
		return super.next();
	}

	public previous(): any {
		this.registrationService.currentForm = null;
		return super.previous();
	}

	canGoNext(): boolean {
		return this.registrationService.canGoNext;
	}

	canGoPrevious(): boolean {
		return true;
	}

	getTotalStepsCount() {
		return this.getSteps()[this.getSteps().length - 1].order;
	}

	toLoginPage() {
		this.registrationService.clear();
		this.router.navigate(['login']);
	}

	finish() {
		this.mask.addLoading();
		this.userService.saveUser(this.registrationService.user).then(() => {
			this.mask.removeLoading();
			this.registrationService.userSubmitted = true;
			this.router.navigate(['registration', 'step-final']);
		});
	}
}