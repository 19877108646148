import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { NavComponent } from './nav.component';
import { RouterModule } from '@angular/router';
import { NavService } from './nav.service';

@NgModule({
	imports: [
		SharedModule,
		RouterModule
	],
	declarations: [
		NavComponent
	],
	providers: [
		NavService
	],
	exports: [
		NavComponent
	]
})

export class NavModule {
}