import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/auth/auth.guard';
import { OrderComponent } from './order.component';
import { OrderResolve } from './order-resolve.service';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'order/:Id',
				component: OrderComponent,
				canActivate: [AuthGuard],
				resolve: {
					orderInfo: OrderResolve
				}
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		OrderResolve
	]
})
export class OrderRoutingModule {
}