import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Country } from '../pages/registration/shared/registration-user';
import { ConfigService } from './config.service';
import { LoggerService } from './logger/logger.service';

@Injectable()
export class CountryService {
	public countries: Country[] = [];

	constructor(private http: Http,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	getCountries(): Promise<Country[]> {
		if (this.countries.length !== 0) {
			return Promise.resolve(this.countries);
		}

		return this.http.get(this.configService.baseUrl + '/api/RegistrationCountry')
			.toPromise()
			.then(res => {
				this.countries = res.json().map(coutry => new Country(coutry));
				return this.countries;
			})
			.catch(error => {
				this.logger.error(error);
				return null;
			});
	}
}