import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/auth/auth.guard';
import { RedirectComponent } from './redirect.component';
import { RedirectGuard } from './redirect-guard.service';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'redirect/USSI',
				component: RedirectComponent,
				canActivate: [AuthGuard, RedirectGuard]
			}
		])
	],
	providers: [
		RedirectGuard
	],
	exports: [
		RouterModule
	]
})

export class RedirectRoutingModule {
}
