import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '../../../core/http/http-client';
import { ConfigService } from '../../../core/config.service';
import { User } from '../../../core/auth/user';
import { UserService } from '../../../core/auth/user.service';
import { Response } from '@angular/http';
import { LoggerService } from '../../../core/logger/logger.service';

@Injectable()
export class BackgroundService {
	onChange: EventEmitter<string> = new EventEmitter<string>();

	private backgroundImages: Map<string, string> = new Map<string, string>();

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private logger: LoggerService,
	            private userService: UserService) {
		this.updateBackgroundOnUserChanges();
	}

	setBackgroundImage(key: string) {
		if (this.backgroundImages.has(key)) {
			this.onChange.emit(this.backgroundImages.get(key));
			return;
		}

		if (key === 'login_bg') {
			this.setLoginBackgroundImage();
			return;
		}

		if (key === 'user_bg') {
			this.setUserBackgroundImage();
			return;
		}

		this.setBackgroundImageByBrand(key);
	}

	private loadBackgroundImageUrl(requestUrl: string): Promise<string> {
		return this.http.get(requestUrl)
			.toPromise()
			.then((res: Response) => {
				return res.text() ? res.json().ImageURL : null;
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	private setUserBackgroundImage(): void {
		this.loadBackgroundImageUrl(this.configService.baseUrl + '/api/Background')
			.then((backgroundImage: string) => {
				this.backgroundImages.set('user_bg', backgroundImage);
				this.onChange.emit(backgroundImage);
			});
	}

	private setBackgroundImageByBrand(brandId: string): void {
		this.loadBackgroundImageUrl(this.configService.baseUrl + '/api/Background?serviceId=' + brandId)
			.then((backgroundImage: string) => {
				if (backgroundImage) {
					this.backgroundImages.set(brandId, backgroundImage);
					this.onChange.emit(backgroundImage);
				} else {
					this.setBackgroundImage('user_bg');
				}
			});
	}

	private setLoginBackgroundImage(): void {
		this.loadBackgroundImageUrl(this.configService.baseUrl + '/api/LoginBackground')
			.then((backgroundImage: string) => {
				this.backgroundImages.set('login_bg', backgroundImage);
				this.onChange.emit(backgroundImage);
			});
	}

	private updateBackgroundOnUserChanges(): void {
		this.userService.onChange.subscribe((user: User) => {
			if (user) {
				this.setBackgroundImage('user_bg');
			} else {
				this.backgroundImages.delete('user_bg');
				this.setBackgroundImage('login_bg');
			}
		});
	}
}