import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared/shared.module';
import { BackLinkComponent } from '../../../../shared/back-link.component';
import { ValidateHeadendComponent } from './validate-headend.component';
import { ValidateHeadendFormComponent } from './validate-headend-form/validate-headend-form.component';
import { ValidateHeadendFormService } from './validate-headend-form/validate-headend-form.service';
import { ValidateHeadendRoutingModule } from './validate-headend-routing.module';

@NgModule({
	imports: [
		SharedModule,
		ValidateHeadendRoutingModule
	],
	declarations: [
		ValidateHeadendComponent,
		ValidateHeadendFormComponent
	],
	providers: [
		ValidateHeadendFormService,
		BackLinkComponent
	]
})
export class ValidateHeadendModule {
}