import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { FooterComponent } from './footer.component';
import { LastLoginComponent } from './last-login.component';
import { RouterModule } from '@angular/router';
import { LandingModule } from '../landing/landing.module';
import { FooterLandingComponent } from './landing/footer-landing.component';
import { HeaderService } from '../header/header.service';
import { FooterLinkService } from './footer.service';

@NgModule({
	imports: [
		SharedModule,
		RouterModule,
		LandingModule
	],
	declarations: [
		FooterComponent,
		LastLoginComponent,
		FooterLandingComponent
	],
	providers: [HeaderService, FooterLinkService],
	exports: [
		FooterComponent
	]
})

export class FooterModule {
}