import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavService, MenuItem } from './nav.service';
import { UserService } from '../../../core/auth/user.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
	selector: 'vcm-app-nav',
	template: require('./nav.component.html')
})

export class NavComponent implements OnInit, OnDestroy {
	isCollapsed: boolean = true;
	isOpen: boolean = false;
	menu: MenuItem[];
	showSubmenu: boolean[] = [];
	showSubSubmenu: boolean[] = [];
	keyword: string = '';

	private navSubscription: Subscription;

	constructor(private navService: NavService,
	            private userService: UserService,
	            private router: Router) {
		this.router.events.subscribe(() => {
			this.closeMenu();
			this.keyword = '';
		})
	}

	ngOnInit() {
		if (this.userService.hasCurrentUser()) {
			this.getMenu();
		}
		this.navSubscription = this.userService.onChange.subscribe((user) => {
			if (user) {
				this.getMenu();
			}
		});
	}

	getMenu(): void {
		this.navService.getMenu().then(res => {
			this.menu = res;
		});
	}

	navigate(e: any, navItem: MenuItem): void {
		e.preventDefault();
		if (!navItem.Url) {
			return;
		}
		if (!navItem.IsGlobalLink) {
			this.router.navigateByUrl(navItem.Url);
		} else {
			window.open(navItem.Url)
		}
	}

	switchCollapse() {
		this.isCollapsed = !this.isCollapsed;

		if (this.isCollapsed) {
			this.isOpen = false;
		}
	}

	closeDropDown() {
		this.isOpen = false;
	}

	toggleSubmenu(index: number, targetMenu: boolean[]) {
		targetMenu[index] = !targetMenu[index];
		targetMenu.forEach((item, i) => {
			if (i != index) {
				targetMenu[i] = false;
			}
		});
	}

	ngOnDestroy() {
		this.navSubscription.unsubscribe();
	}

	searchKeyword(): void {
		this.router.navigate(['search', {query: this.keyword}])
	}

	onInputChange(text: string): void {
		if (text[0] == ' ') {
			this.keyword.slice(0, 1);
		}
		this.keyword = text.trim();
	}

	private closeMenu(): void {
		this.showSubmenu = [];
		this.showSubSubmenu = [];
		this.isOpen = false;
		this.isCollapsed = true;
	}
}