import { NgModule } from '@angular/core';
import { DropdownsService } from './add-headend-form/dropdowns.service';
import { SharedModule } from '../../../../shared/shared.module';
import { BackLinkComponent } from '../../../../shared/back-link.component';
import { AddHeadendRoutingModule } from './add-headend-routing.module';
import { AddHeadendComponent } from './add-headend.component';
import { AddHeadendFormComponent } from './add-headend-form/add-headend-form.component';
import { AddHeadendFormService } from './add-headend-form/add-headend-form.service';

@NgModule({
	imports: [
		SharedModule,
		AddHeadendRoutingModule
	],
	declarations: [
		AddHeadendComponent,
		AddHeadendFormComponent
	],
	providers: [
		DropdownsService,
		AddHeadendFormService,
		BackLinkComponent
	]
})
export class AddHeadendModule {
}