import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UploadComponent } from './upload.component';
import { UploadTextResolve } from './upload-resolve.service';
import { AuthGuard } from '../../core/auth/auth.guard';
import { UploadUrlIdResolve } from './upload-urlid-resolve.service';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'upload',
				component: UploadComponent,
				canActivate: [AuthGuard],
				resolve: {
					uploadText: UploadTextResolve
				}
			},
			{
				path: 'upload/:urlID',
				component: UploadComponent,
				canActivate: [AuthGuard],
				resolve: {
					uploadText: UploadTextResolve,
					urlID: UploadUrlIdResolve
				}
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		UploadTextResolve,
		UploadUrlIdResolve
	]
})
export class UploadRoutingModule {
}
