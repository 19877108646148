import { Injectable } from '@angular/core';
import { RegistrationUser } from './shared/registration-user';
import { Http, Response } from '@angular/http';
import { ConfigService } from '../../core/config.service';
import { HeadersClient } from '../../core/http/headers-client';
import { RegistrationService } from './registration.service';
import { LoggerService } from '../../core/logger/logger.service';

@Injectable()
export class RegistrationUserService {
	private url = this.configService.baseUrl + '/api/RegistrationFinal';

	constructor(private http: Http,
	            private configService: ConfigService,
	            private registrationService: RegistrationService,
	            private logger: LoggerService,
	            private headers: HeadersClient) {
	}

	saveUser(user: RegistrationUser): Promise<void> {
		return this.http.post(this.url, user, this.headers.getRequestOptions())
			.toPromise().then((res: Response) => {
				this.registrationService.userSaved = res.json();
			}).catch((err) => {
				this.logger.error(err);
			})
	}
}