import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UploadService } from '../upload.service';
import { FileUploader } from './ng2-file-upload/file-uploader.class';
import { FileItem } from 'ng2-file-upload';
//TODO fix bug with progress bar
// import { FileUploader } from 'ng2-file-upload';

@Component({
	selector: 'vcm-upload-box',
	template: require('./upload-box.component.html'),
	host: {
		'(document: dragenter)': 'showDropZone()'
	}
})

export class UploadBoxComponent implements OnInit {
	uploader: FileUploader;
	isDropZoneFullscreen: boolean = false;

	@ViewChild('dropZone') dropZone: ElementRef;

	constructor(private uploadService: UploadService) {
	}

	ngOnInit() {
		this.uploader = this.uploadService.uploader;
		this.dropZone.nativeElement.addEventListener('dragleave', this.hideDropZone.bind(this));
		this.dropZone.nativeElement.addEventListener('drop', this.hideDropZone.bind(this));
	}

	uploadFiles(): void {
		this.validateAndUpload();
	}

	itemRemove(index: number): void {
		this.uploader.queue[index].cancel();
		this.uploader.queue[index].remove();
		this.uploadService.listFileNames.splice(index, 1);
	}

	private showDropZone(): void {
		this.isDropZoneFullscreen = true;
	}

	private hideDropZone(): void {
		this.isDropZoneFullscreen = false;
	}

	private validateAndUpload(): void {
		this.uploader.queue.forEach((item: FileItem) => {
			if (item.file.size / 1024 / 1024 > 64) {
				item.isError = true;
			}
			if (!item.isError && !item.isSuccess && !item.isUploading) {
				item.upload();
			}
		});
	}
}