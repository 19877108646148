import { NgModule } from '@angular/core';
import { HomeComponent } from './home.component';
import { DynamicViewGeneratorComponent } from './dynamic-view-generator.component';
import { VideoModule } from '../../shared/video/video.module';
import { SharedModule } from '../../shared/shared.module';
import { HomeRoutingModule } from './home-routing.module';
import { LandingModule } from '../view/landing/landing.module';
import { LandingGridModule } from '../view/landing-grid/landing-grid.module';

@NgModule({
	imports: [
		SharedModule,
		HomeRoutingModule,
		VideoModule,
		LandingModule,
		LandingGridModule
	],
	declarations: [
		HomeComponent,
		DynamicViewGeneratorComponent
	]
})

export class HomeModule {
}
