import { Component } from '@angular/core';
import { HeaderLogoResponse, HeaderService } from '../header/header.service';
import { FooterLinkResponse, FooterLinkService } from './footer.service';

@Component({
	selector: 'vcm-app-footer',
	template: require('./footer.component.html'),
})

export class FooterComponent {
	headerLogoResponse: HeaderLogoResponse;	
	footerLinkResponse: FooterLinkResponse;
	
	constructor(private headerService: HeaderService, private footerLinkService: FooterLinkService) {
		this.headerService.getLogo().then((res: HeaderLogoResponse) => {
			this.headerLogoResponse = res;
		})

		this.footerLinkService.getFooterLink().then((res: FooterLinkResponse) => {
			console.log(res);
			this.footerLinkResponse = res;
		})
	}
}