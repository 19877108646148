import { NgModule } from '@angular/core';
import { ReasonService } from './remove-headend-form/reason.service';
import { SharedModule } from '../../../../shared/shared.module';
import { BackLinkComponent } from '../../../../shared/back-link.component';
import { RemoveHeadendRoutingModule } from './remove-headend-routing.module';
import { RemoveHeadendComponent } from './remove-headend.component';
import { RemoveHeadendFormComponent } from './remove-headend-form/remove-headend-form.component';
import { RemoveHeadendFormService } from './remove-headend-form/remove-headend-form.service';

@NgModule({
	imports: [
		SharedModule,
		RemoveHeadendRoutingModule
	],
	declarations: [
		RemoveHeadendComponent,
		RemoveHeadendFormComponent
	],
	providers: [
		ReasonService,
		RemoveHeadendFormService,
		BackLinkComponent
	]
})
export class RemoveHeadendModule {
}