export class Video {
	public NowPlaying: boolean = false;
	public Title: string;
	public Src: string;
	public StartTime: number = 0;
	public DownloadUrl: string;
	public Description: string = null;
	public MoreUrl: string = null;

	constructor(data) {
		this.Title = data.Title;
		this.Src = data.Src;
		if (data.StartTime) {
			this.StartTime = data.StartTime;
		}
		this.DownloadUrl = data.DownloadUrl;
		this.Description = data.Description;
		this.MoreUrl = data.MoreUrl;
	}
}