import { Injectable } from '@angular/core';
import { Response, ResponseContentType } from '@angular/http';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { ConfigService } from '../../../core/config.service';
import { HttpClient } from '../../../core/http/http-client';
import { ProgrammingHighlights } from '../programming-highlights';
import { LoggerService } from '../../../core/logger/logger.service';
import * as FileSaver from 'file-saver';

@Injectable()
export class ProgrammingHighlightsListService {
	private subject: Subject<number> = new Subject<number>();
	private _highlightId: number;

	get highlightId(): number {
		return this._highlightId;
	}

	private url = this.configService.baseUrl + '/api/ProgrammingHighlights/?premiereOnly=';

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private logger: LoggerService) {
	}

	setHighlightId(id: number) {
		this._highlightId = id;
		this.subject.next(this._highlightId);
	}

	getHighlightId(): Observable<number> {
		let source = Observable.empty();

		return <Observable<number>>source.startWith(this._highlightId).concat(this.subject);
	}

	getProgrammingHighlightsList(id: string, premiereOnly: boolean): Observable<ProgrammingHighlights[]> {
		this.setHighlightId(+id || -1);
		return this.http.get(this.url + premiereOnly + "&serviceId=" + id)
			.map((res: Response) => {
				return res.text() ? res.json() : null;
			}).catch((err) => {
				this.logger.error(err);
				return Observable.of(null);
			});
	}

	clearParams(): void {
		this.setHighlightId(null);
	}

	private getDownloadUrl(fileType: string, brand: number, premiereOnly: boolean) {
		return this.configService.baseUrl + `/api/Export${fileType}PH?serviceId=${brand}&premiereOnly=${premiereOnly}`;
	}

	exportToFile(fileType: string, brand: number, premiereOnly: boolean): Promise<Response> {
		return this.http.get(this.getDownloadUrl(fileType, brand, premiereOnly), {responseType: ResponseContentType.Blob})
			.toPromise()
			.then(res => {
				this.downloadFile(res);
				return res;
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	downloadFile(data: Response) {
		let blob = new Blob([data.blob()], {type: 'application/octet-stream'});
		let filename = 'livegrid.xlsx';

		if (data.headers.has('Content-Disposition')) {
			let cdHeader = data.headers.get('Content-Disposition');
			filename = cdHeader.slice(cdHeader.indexOf('"') + 1, -1);
		}

		FileSaver.saveAs(blob, filename);
	}

}
