import { Injectable } from '@angular/core';
import { ConfigService } from '../../core/config.service';
import { Observable } from 'rxjs';
import { Response, Http, RequestOptionsArgs } from '@angular/http';
import { HeadersClient } from '../../core/http/headers-client';
import { LoginPageModel } from './login-page-model';
import { LoggerService } from '../../core/logger/logger.service';

@Injectable()
export class LoginPageService {
	private url = this.configService.baseUrl + '/api/loginpage';
	private loginPageModel: LoginPageModel;

	constructor(private configService: ConfigService,
	            private headers: HeadersClient,
	            private http: Http,
	            private logger: LoggerService) {
	}

	getLoginPageModel(): Observable<LoginPageModel> {
		if (this.loginPageModel) {
			return Observable.of(this.loginPageModel);
		} else {
			return this.loadLoginPageModel();
		}
	}

	private loadLoginPageModel(): Observable<LoginPageModel> {
		let options: RequestOptionsArgs = this.headers.getRequestOptions();
		return this.http.get(this.url, options)
			.map((res: Response) => {
				this.loginPageModel = res.json();
				return this.loginPageModel;
			}).catch((err) => {
				this.logger.error(err);
				return Observable.of(null);
			});
	}
}