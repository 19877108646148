import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { GenericGridsService, GenericGrid } from './generic-grids.service';

@Injectable()
export class GenericGridsResolve implements Resolve<GenericGrid[]> {
	constructor(private service: GenericGridsService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<GenericGrid[]> {
		this.mask.addLoading();
		return this.service.getGenericGrids()
			.map((genericGrids: GenericGrid[]) => {
				this.mask.removeLoading();
				return genericGrids ? genericGrids : [];
			});
	}
}
