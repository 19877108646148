import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ReasonService, RemoveReason } from './reason.service';
import { LoadingMaskService } from '../../../../../shared/loading-mask/loading-mask.service';

@Injectable()
export class ReasonResolve implements Resolve<RemoveReason[]> {
	constructor(private service: ReasonService,
	            private loadingMask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<RemoveReason[]> {
		this.loadingMask.addLoading();
		return this.service.getReasons().then((reasons) => {
			this.loadingMask.removeLoading();
			return reasons
		});
	}
}
