import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Office } from '../../shared/registration-user';
import { RegistrationService } from '../../registration.service';
import { OfficeService } from './office.service';
import { NOT_SELECTED_ID } from './registration-step3.component';

@Injectable()
export class OfficeResolve implements Resolve<Office[]> {
	constructor(private registrationService: RegistrationService,
	            private service: OfficeService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<Office[]> {
		let userRegistration = this.registrationService.user;

		if (userRegistration.company && userRegistration.company.AccountId !== NOT_SELECTED_ID) {
			return this.service.getOffices(userRegistration.company, userRegistration.state, userRegistration.postalCode);
		} else {
			return Promise.resolve([]);
		}
	}
}