import { Injectable } from '@angular/core';
import { HttpClient } from '../../../core/http/http-client';
import { ConfigService } from '../../../core/config.service';
import { ProfilePassword } from './profile-password';
import { LoggerService } from '../../../core/logger/logger.service';

@Injectable()
export class ChangePasswordService {
	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	changePassword(password: ProfilePassword): Promise<any> {
		let url = this.configService.baseUrl + '/api/ProfileChangePassword';
		return this.http.put(url, password)
			.toPromise().then((res) => {
				return res.json();
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}