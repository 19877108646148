export class User {
	Login: string;
	AuthorizationToken: string;
	RefreshToken: string;
	LastLoginDate: Date;
	FirstName: string;
	RepName: string;
	StartPageCode: string;
	ContactId: number;

	constructor(login: string, user: any) {
		this.Login = login;
		this.AuthorizationToken = user.AuthorizationToken;
		this.RefreshToken = user.RefreshToken;
		this.LastLoginDate = user.LastLoginDate;
		this.FirstName = user.FirstName;
		this.RepName = user.RepName;
		this.StartPageCode = user.StartPageCode;
		this.ContactId = user.ContactId;
	}
}

export class UserInfo {
	constructor(public userNews: UserNews[] = []) {}
}

export class UserNews {
	ImageURL: string;
}