import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Company } from '../../shared/registration-user';
import { CompanyService } from './company.service';
import { RegistrationService } from '../../registration.service';
import { LoadingMaskService } from '../../../../shared/loading-mask/loading-mask.service';

@Injectable()
export class CompanyResolve implements Resolve<Company[]> {
	constructor(private registrationService: RegistrationService,
	            private mask: LoadingMaskService,
	            private service: CompanyService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<Company[]> {
		let userRegistration = this.registrationService.user;
		this.mask.addLoading();
		return this.service.getCompanies(userRegistration.state, userRegistration.postalCode)
			.then((companies: Company[]) => {
				this.mask.removeLoading();
				return companies;
			})
	}
}