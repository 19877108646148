import { Injectable } from '@angular/core';
import { HttpClient } from '../../../core/http/http-client';
import { ConfigService } from '../../../core/config.service';
import { State, Country } from '../../registration/shared/registration-user';
import { LoggerService } from '../../../core/logger/logger.service';

@Injectable()
export class ProfileStateService {
	private countryStates: Map<string, State[]> = new Map<string, State[]>();

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	getStates(country: Country): Promise<State[]> {
		if (this.countryStates.has(country.CountryCode)) {
			return Promise.resolve(this.countryStates.get(country.CountryCode));
		}
		return this.loadStates(country);
	}

	private loadStates(country: Country) {
		return this.http.get(this.configService.baseUrl + '/api/ProfileDropDowns?countryCode=' + country.CountryCode)
			.toPromise()
			.then(res => {
				let states: State[] = res.json().State.map(state => new State(state));
				this.countryStates.set(country.CountryCode, states);

				return states;
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}