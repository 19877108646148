import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import {LiveBrand, LiveBrandsService} from "./live-brands.service";

@Injectable()
export class PremieresResolve implements Resolve<LiveBrand[]> {
	constructor(private service: LiveBrandsService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<LiveBrand[]> {
		this.mask.addLoading();
		return this.service.getPremiereBrands()
			.then((brands: LiveBrand[]) => {
				this.mask.removeLoading();
				return brands;
			});
	}
}
