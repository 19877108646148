import { SoundService } from '../../../core/sound/sound.service';
import { Component, Input, ElementRef, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { VgAPI } from 'videogular2/src/core/services/vg-api';

@Component({
	selector: 'vcm-video-mute',
	encapsulation: ViewEncapsulation.None,
	template: `<div class="icon"
             [class.vg-icon-volume_up]="getVolume() >= 0.75"
             [class.vg-icon-volume_down]="getVolume() >= 0.25 && getVolume() < 0.75"
             [class.vg-icon-volume_mute]="getVolume() > 0 && getVolume() < 0.25"
             [class.vg-icon-volume_off]="getVolume() === 0">
        </div>`,
	styles: [`
        vcm-video-mute {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            display: flex;
            justify-content: center;
            height: 50px;
            width: 50px;
            cursor: pointer;
            color: white;
            line-height: 50px;
        }

        vcm-video-mute .icon {
            pointer-events: none;
        }
    `]
})

export class VideoMuteComponent implements OnInit {
	@Input() vgFor: string;
	elem: HTMLElement;
	target: any;

	currentVolume: number;

	constructor(ref: ElementRef,
	            public API: VgAPI,
	            private soundService: SoundService) {
		this.elem = ref.nativeElement;
	}

	ngOnInit() {
		if (this.API.isPlayerReady) {
			this.onPlayerReady();
		}
		else {
			this.API.playerReadyEvent.subscribe(() => this.onPlayerReady());
		}
	}

	onPlayerReady() {
		this.target = this.API.getMediaById(this.vgFor);
		this.currentVolume = this.target.volume;

		this.soundService.isVideoMuted().subscribe(muted => {
			let volume = this.getVolume();

			if (!muted) {
				this.target.volume = this.currentVolume;
			} else if (volume !== 0) {
				this.currentVolume = volume;
				this.target.volume = 0;
			}
		});
	}

	@HostListener('click')
	onClick() {
		let volume = this.getVolume();

		if (volume === 0) {
			this.target.volume = this.currentVolume;
		}
		else {
			this.currentVolume = volume;
			this.target.volume = 0;
		}
	}

	getVolume() {
		return this.target ? this.target.volume : 0;
	}
}