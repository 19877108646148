import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { HeadendSummaryService } from '../shared/headend-summary.service';

@Injectable()
export class HeadendSummaryClearGuard implements CanDeactivate<any> {
	constructor(private headendSummaryService: HeadendSummaryService) {
	}

	canDeactivate(component: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
		this.headendSummaryService.clearSummary();

		return true;
	}
}