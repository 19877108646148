import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RegistrationService } from '../../registration.service';
import { ActivatedRoute } from '@angular/router';
import { RegistrationUser } from '../../shared/registration-user';
import { TermsPageModel } from '../../../+terms/terms-page.model';

@Component({
	template: require('./registration-step4.component.html')
})

export class RegistrationStep4Component implements OnInit {
	public user: RegistrationUser;
	public captchaVerified: boolean = null;
	public termsModel: TermsPageModel;

	@ViewChild('step4') currentForm: NgForm;

	constructor(private registrationService: RegistrationService,
	            private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: {model: TermsPageModel}) => {
			this.user = this.registrationService.user;
			this.termsModel = data.model;
			this.registrationService.currentForm = this.currentForm;
		});
	}
}