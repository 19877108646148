import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { SocialMediaComponent } from './social-media.component';
import { SocialMediaService } from './social-media.service';
import { RouterModule } from '@angular/router';
import { SocialMediaDetailsComponent } from './social-media-details/social-media-details.component';
import { SocialMediaFilterComponent } from './social-media-filter/social-media-filter.component';
import { SocialMediaFilterOptionsService } from './social-media-filter/social-media-filter-options.service';
import { VideoModule } from '../../../shared/video/video.module';
import { SocialMediaDetailsService } from './social-media-details/social-media-details.service';

@NgModule({
	imports: [
		SharedModule,
		RouterModule,
		VideoModule
	],
	declarations: [
		SocialMediaComponent,
		SocialMediaDetailsComponent,
		SocialMediaFilterComponent
	],
	providers: [
		SocialMediaService,
		SocialMediaDetailsService,
		SocialMediaFilterOptionsService
	]
})

export class SocialMediaModule {
}