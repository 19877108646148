import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PromotionFilters } from './filter-option';
import { PromotionFiltersService } from './promotion-filters.service';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';

@Injectable()
export class PromotionFiltersResolve implements Resolve<PromotionFilters> {
	constructor(private service: PromotionFiltersService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<PromotionFilters> {
		this.mask.addLoading();
		return this.service.getFilters()
			.map((filters: PromotionFilters) => {
				this.mask.removeLoading();
				return filters;
			});
	}
}
