import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { CommonFiltersResolve } from '../filters/common-filters-resolve';
import { ActiveFiltersService } from '../filters/active-filters.service';
import { TechSpec, TechSpecsService } from './tech-specs.service';
import { FEED, SATELLITE, TRANSPONDER } from './tech-specs-filter/tech-specs-filter.component';

@Injectable()
export class TechSpecsResolve extends CommonFiltersResolve implements Resolve<TechSpec[]> {
	constructor(private service: TechSpecsService,
	            private activeFiltersService: ActiveFiltersService,
	            private mask: LoadingMaskService) {
		super(activeFiltersService, [FEED, SATELLITE, TRANSPONDER]);
	}

	resolve(route: ActivatedRouteSnapshot): Promise<TechSpec[]> {
		this.mask.addLoading();
		let filters: any = this.initActiveFiltersFromURLParams(route);
		return this.service.getTechSpecs(filters)
			.then((socialMedia: TechSpec[]) => {
				this.mask.removeLoading();
				return socialMedia;
			});
	}
}