import { Injectable } from '@angular/core';
import { HttpClient } from '../../../core/http/http-client';
import { ConfigService } from '../../../core/config.service';
import { Observable } from 'rxjs';
import { Response } from '@angular/http';
import { AuthenticationService } from '../../../core/auth/authentication.service';
import { LoggerService } from '../../../core/logger/logger.service';

export class Highlight {
	ServiceId: number;
	Highlights: string
}

@Injectable()
export class HighlightsService {
	private url = this.configService.baseUrl + '/api/PHDropDown?premiereOnly=';
	private highlights: Highlight[];

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private authService: AuthenticationService,
	            private logger: LoggerService) {
		this.authService.onLogout.subscribe(() => {
			this.highlights = null;
		});
	}

	getHighlights(premiereOnly: boolean): Observable<Highlight[]> {
		if (this.highlights) {
			return Observable.of(this.highlights);
		} else {
			return this.loadHighlights(premiereOnly);
		}
	}

	private loadHighlights(premiereOnly: boolean): Observable<Highlight[]> {
		return this.http.get(this.url + premiereOnly)
			.map((res: Response) => {
				this.highlights = res.json().sort(this.sortByServiceLongName);
				return this.highlights;
			}).catch((err) => {
				this.logger.error(err);
				return Observable.of(null);
			});
	}

	private sortByServiceLongName(first: Highlight, second: Highlight) {
		return first.Highlights.localeCompare(second.Highlights);
	}
}
