import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChannelDescription } from './channel-description.service';

@Component({
	template: require('./channel-description.component.html')
})

export class ChannelDescriptionComponent {
	channelsDescription: ChannelDescription[] = [];

	constructor(private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: { channelsDescription: ChannelDescription[] }) => {
			this.channelsDescription = data.channelsDescription;
			this.channelsDescription.sort((item1: ChannelDescription, item2: ChannelDescription) =>
				item1['ServiceLongname'].toLowerCase() > item2['ServiceLongname'].toLowerCase() ? 1 : -1
			);
		});
	}
}
