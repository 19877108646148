import { NgModule } from '@angular/core';
import { ProfileDropComponent } from './profile-dropdown.component';
import { HeaderComponent } from './header.component';
import { SharedModule } from '../../../shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
	imports: [
		SharedModule,
		RouterModule
	],
	declarations: [
		HeaderComponent,
		ProfileDropComponent
	],
	providers: [],
	exports: [
		HeaderComponent
	]
})

export class HeaderModule {
}