import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '@angular/http';
import { ConfigService } from '../../../core/config.service';
import { HttpClient } from '../../../core/http/http-client';
import { ProgrammingHighlights } from '../programming-highlights';
import { LoggerService } from '../../../core/logger/logger.service';

@Injectable()
export class ProgrammingHighlightsDetailsService {
	private url = this.configService.baseUrl + '/api/PHDetails/?phId=';

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private logger: LoggerService) {
	}

	getPhDetails(id: string): Observable<ProgrammingHighlights> {
		return this.http.get(this.url + id)
			.map((res: Response) => {
				return res.text() ? res.json() : null;
			}).catch((err) => {
				this.logger.error(err);
				return Observable.of(null);
			});
	}
}
