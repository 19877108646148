import { Component, EventEmitter, Output, Input } from '@angular/core';
import { LoadingMaskService } from '../../../../../shared/loading-mask/loading-mask.service';
import { SignalService, SignalTechnology, SignalState } from './dropdowns.service';
import { AddHeadendFormService } from './add-headend-form.service';

@Component({
	selector: 'vcm-add-headend-form',
	template: require('./add-headend-form.component.html')
})

export class AddHeadendFormComponent {
	public subsMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
	public anchorMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

	headendName: string;
	headendAddress: string;
	headendCity: string;
	headendZip: string;
	basicSubs: string;
	digitalSubs: string;
	unitAddress: string;

	@Input() signalStates: SignalState[];
	@Input() signalServices: SignalService[];
	@Input() signalTechnologies: SignalTechnology[];

	selectedState: SignalState;
	selectedService: SignalService;
	selectedTechnology: SignalTechnology;

	@Output() feedbackSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private addHeadendFormService: AddHeadendFormService,
	            private mask: LoadingMaskService) {
	}

	ngOnInit(): void {
	}

	onSubmit() {
		this.mask.addLoading();
		this.addHeadendFormService.submitRequest({
			HeadendName: this.headendName,
			HeadendAddress: this.headendAddress,
			HeadendCity: this.headendCity,
			HeadendState: this.selectedState,
			HeadendZip: this.headendZip,
			BasicSubs: this.basicSubs,
			DigitalSubs: this.digitalSubs,
			Technology: this.selectedTechnology,
			UnitAddress: this.unmask(this.unitAddress),
			Service: this.selectedService
		}).then((resp) => {
			this.mask.removeLoading();
			this.feedbackSubmitted.emit(resp);
			window.scrollTo(0, 0);
		});
	}

	private unmask(value?: string): string {
		return value ? value.replace(/\D+/g, '') : null;
	}

	public backClicked() {
		window.history.back();
	}
}
