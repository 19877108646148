import {
	Component, Input, OnInit, ViewChild,
	QueryList, ViewChildren, AfterViewInit
} from '@angular/core';
import {
	AuthorizationRequest,
	AuthorizationServiceUplink
} from '../../../shared/authorization-request';
import { AuthorizationService } from '../../../authorization.service';
import { NgForm, NgModel } from '@angular/forms';

@Component({
	template: require('./grid-confirmation.component.html'),
	selector: 'vcm-grid-confirmation'
})

export class GridConfirmationComponent implements OnInit, AfterViewInit {
	@Input('checkedServices') checkedServices: AuthorizationServiceUplink;
	authorizationRequest: AuthorizationRequest;

	@ViewChildren(NgModel) controls: QueryList<NgModel>;
	@ViewChild('gridForm') gridForm: NgForm;

	constructor(private authorizationService: AuthorizationService,
	            private step3: NgForm) {
	}

	ngOnInit() {
		this.authorizationRequest = this.authorizationService.authorizationRequest;
	}

	ngAfterViewInit() {
		this.controls.forEach((control: NgModel) => {
			this.step3.addControl(control);
		});
	}
}