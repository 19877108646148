import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { DaysRange } from '../days-range';
import { ViewportService, DeviceChangedEvent, Device } from '../../../../core/viewport-service/viewport.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import Moment = moment.Moment;

@Component({
	template: require('./range-selector.component.html'),
	selector: 'vcm-range-selector',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class RangeSelectorComponent implements OnInit, OnDestroy {
	@Input() availableRanges: DaysRange[];
	@Input() selectedRange: DaysRange;
	@Output() onRangeChange: EventEmitter<DaysRange> = new EventEmitter<DaysRange>();

	currentDevice = this.viewportService.currentDevice;

	DESKTOP: Device = Device.DESKTOP;
	TABLET: Device = Device.TABLET;
	MOBILE: Device = Device.MOBILE;

	private viewportChangeSubscription: Subscription;

	constructor(private viewportService: ViewportService) {}

	ngOnInit() {
		this.viewportChangeSubscription = this.viewportService.viewportChanged.subscribe((event: DeviceChangedEvent) => {
			this.currentDevice = event.device;
		});
	}

	selectRange(range: DaysRange): void {
		this.onRangeChange.emit(range);
	}

	ngOnDestroy(): void {
		this.viewportChangeSubscription.unsubscribe();
	}
}