import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { ConfigService } from '../../core/config.service';
import { HttpClient } from '../../core/http/http-client';
import { LoggerService } from '../../core/logger/logger.service';

@Injectable()
export class RedirectService {
	constructor(private configService: ConfigService,
	            private logger: LoggerService,
	            private http: HttpClient) {
	}

	getUrlToRedirect(identifier: string): Promise<string> {
		return this.http.get(this.configService.baseUrl + '/api/RedirectLink?identifier=' + identifier)
			.toPromise()
			.then((res: Response) => {
				return res.json();
			}).catch((err) => {
				this.logger.error(err);
				return false;
			});
	}
}