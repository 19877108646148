import { Component, Input, HostBinding } from '@angular/core';
import { ProgramminHighlightsInfo } from '../programming-highlights';
import { NextAirDatesService } from './next-dates.service';

@Component({
	selector: 'vcm-programming-next-dates',
	template: require('./next-dates.component.html')
})

export class NextAirDatesComponent {
	@Input() Brands: number[] = null;
	@Input() RelatedProgram: number = null;
	@Input() ServiceId: string = null;
	@Input() StartDate: string = null;

	@HostBinding('class.vcm_hidden') elementHidden: boolean = true;

	nextAirDates: ProgramminHighlightsInfo[] = [];

	constructor(private service: NextAirDatesService) {
	}

	ngOnInit() {
		if (this.Brands) {
			this.service.getHighlightNextAirDates(this.Brands, this.RelatedProgram)
				.then(res => {
					this.nextAirDates = res;
					if (res.length != 0) {
						this.elementHidden = false;
					}
				});
		}

		if (this.ServiceId) {
			this.service.getLiveGridNextAirDates(this.ServiceId, this.StartDate)
				.then(res => {
					this.nextAirDates = res;
					if (res.length != 0) {
						this.elementHidden = false;
					}
				})
		}
	}

	createProgramDetailsLink(brand: string, date: string): string {
		this.Brands = null;
		this.RelatedProgram = null;
		this.ServiceId = null;
		this.StartDate = null;
		return '/programming/livegrid/' + brand + '/' + date;
	}
}
