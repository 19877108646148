import { Component, Input } from '@angular/core';

// const MESSAGE_TYPES = ['warning', 'danger', 'success'];

@Component({
	selector: 'vcm-message',
	template: `
		<div class="alert vcm_alert_{{messageType}}" 
			role="alert" 
			[ngSwitch]="messageType" 
			*ngIf='messageType'>
		  <span *ngSwitchCase="'danger'" class="glyphicon glyphicon-exclamation-sign"></span>
		  <span *ngSwitchCase="'warning'" class="glyphicon glyphicon-alert"></span>
		  <span *ngSwitchCase="'success'" class="glyphicon glyphicon-ok"></span>
		  <span [innerHTML]="message" *ngIf='message'></span>
		  <ng-content></ng-content>
		</div>
`
})
export class MessageComponent {
	@Input('messageType') messageType: string;
	@Input('message') message: string;
}
