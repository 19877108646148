import { Component, ViewContainerRef, ViewChild, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router, Event } from '@angular/router';
import { ToastsManager } from 'ng2-toastr';
import { Subscription } from 'rxjs';
import { AuthenticationService } from './core/auth/authentication.service';
import { UserService } from './core/auth/user.service';
import { RedirectService } from './pages/redirect/redirect.service';
import { UserNewsComponent } from './pages/view/user-news/user-news.component';
import { UserNewsDirective } from './pages/view/user-news/user-news.directive';

@Component({
	selector: 'vcm-app',
	template: require('./app.component.html')
})

export class AppComponent implements OnDestroy {
	private isNeedShowNews: boolean;
	private userSubscription: Subscription;

	@ViewChild(UserNewsDirective) userNews: UserNewsDirective;

	constructor(private authService: AuthenticationService,
	            private componentFactoryResolver: ComponentFactoryResolver,
	            private location: Location,
	            private redirectService: RedirectService,
	            private router: Router,
	            private toastr: ToastsManager,
	            private userService: UserService,
	            private vcr: ViewContainerRef) {
		toastr.setRootViewContainerRef(vcr);

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				if ((<any>window).ga) {
					(<any>window).ga('set', 'page', event.urlAfterRedirects);
					(<any>window).ga('send', 'pageview');
				}
				else {
					setTimeout(function () {
						if ((<any>window).ga) {
							(<any>window).ga('set', 'page', event.urlAfterRedirects);
							(<any>window).ga('send', 'pageview');
						}
					}, 1000);
				}
			}
		});
	}

	ngOnInit() {
		this.isNeedShowNews = !this.userService.getCurrentUser();
		this.authService.onLogout.subscribe(() => {
			this.isNeedShowNews = true;
		});

		this.router.events.subscribe((evt: Event) => {
			if (evt instanceof NavigationEnd) {
				this.redirectTo(this.router.url === '/logout');
			}

			if (evt instanceof NavigationEnd && this.isNeedShowNews && this.userService.getCurrentUser()) {
				this.showUserNews();
			}

			if (!(evt instanceof NavigationEnd) || evt.urlAfterRedirects.indexOf('#') >= 0) {
				return;
			}

			window.scrollTo(0, 0);
			document.body.scrollTop = 0;
		});
	}

	private showUserNews() {
		this.isNeedShowNews = false;

		let viewContainerRef = this.userNews.viewContainerRef;
		viewContainerRef.clear();

		let componentFactory = this.componentFactoryResolver.resolveComponentFactory(UserNewsComponent);
		let componentRef = viewContainerRef.createComponent(componentFactory);
	}

	// REDIRECT

	private redirectTo(isLogout: boolean): void {
		if (isLogout) {
			this.authService.logout();
			return;
		}
		if (this.userService.hasCurrentUser() && this.authService.isRewardsInDomain) {
			this.getUrlToRedirect();
			return;
		}

		this.userSubscription = this.userService.onChange.subscribe((user) => {
			if (user && this.authService.isRewardsInDomain) {
				this.getUrlToRedirect();
			}
		});
	}

	private getUrlToRedirect(): void {
		this.redirectService.getUrlToRedirect('REWARDS').then((redirectLink: string) => {
			if (redirectLink) {
				window.location.href = redirectLink;
			}
		})
	}

	ngOnDestroy() {
		this.userSubscription.unsubscribe();
	}
}