import { Component } from '@angular/core';
import { SignalsService } from '../../signals.service';
import { ActivatedRoute } from '@angular/router';
import { RemoveReason } from './remove-headend-form/reason.service';
import { HeadendSummary } from '../shared/headend-summary.service';

@Component({
	template: require('./remove-headend.component.html')
})

export class RemoveHeadendComponent {
	isRequestSubmitted: boolean = false;
	headendId: number;
	reasons: RemoveReason[];
	headendSummary: HeadendSummary;

	constructor(private signalsService: SignalsService,
	            private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.headendId = this.signalsService.headendId;
		this.route.data.forEach((data: {
			reasons: RemoveReason[],
			headendSummary: HeadendSummary
		}) => {
			this.reasons = data.reasons;
			this.headendSummary = data.headendSummary;
		});
	}

	displayFeedbackSubmittedMessage(feedback): void {
		this.isRequestSubmitted = true;
	}
}
