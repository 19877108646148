import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BusinessAreasService } from './step2/business-areas.service';
import { RolesService } from './step1/role.service';
import { PrefixService } from './step1/prefix.service';
import { CompanyService } from './step3/company.service';
import { OfficeService } from './step3/office.service';
import { RegistrationStep1Component } from './step1/registration-step1.component';
import { RegistrationStep2Component } from './step2/registration-step2.component';
import { RegistrationStep3Component } from './step3/registration-step3.component';
import { RegistrationStep4Component } from './step4/registration-step4.component';
import { RegistrationStep5Component } from './step5/registration-step5.component';
import { CopyrightsComponent } from '../shared/copyright/copyrights.component';
import { StateService } from './step1/state.service';
import { SharedModule } from "../../../shared/shared.module";
import { TermsPageService } from '../../+terms/terms-page.service';
import { RegistrationStepFinalComponent } from './step-final/registration-step-final.component';
import { EmailValidationService } from './step1/email-validation.service';
import { EmailValidator } from '../shared/email-validator.directive';
import { StateExistValidator } from '../shared/state-exist-validator.directive';
import { OfficeExistValidator } from '../shared/office-exist-validator.directive';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		SharedModule
	],
	declarations: [
		CopyrightsComponent,
		RegistrationStep1Component,
		RegistrationStep2Component,
		RegistrationStep3Component,
		RegistrationStep4Component,
		RegistrationStep5Component,
		RegistrationStepFinalComponent,
		EmailValidator,
		StateExistValidator,
		OfficeExistValidator
	],
	providers: [
		RolesService,
		PrefixService,
		StateService,
		BusinessAreasService,
		CompanyService,
		OfficeService,
		TermsPageService,
		EmailValidationService
	]
})

export class StepsModule {
}