import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { WizardStep } from '../../shared/wizard/wizard-step';
import { REGISTRATION_STEPS } from './registration-routing.module';
import { RegistrationUser } from './shared/registration-user';
import { RegistrationStepsService } from './registration-steps.service';

@Injectable()
export class RegistrationService {
	public userSubmitted: boolean = false;
	public userSaved: boolean;

	private _user: RegistrationUser = new RegistrationUser();
	private _currentForm: NgForm;
	private _availableSteps: WizardStep[] = [];

	constructor(private stepsService: RegistrationStepsService) {
	}

	clear() {
		this._user = new RegistrationUser();
		this._availableSteps = [];
		this._currentForm = null;
	}

	set currentForm(value: NgForm) {
		this._currentForm = value;
	}

	get availableSteps(): WizardStep[] {
		return this._availableSteps;
	}

	addAvailableStep(stepName: WizardStep) {
		this._availableSteps.push(stepName);
	}

	get user(): RegistrationUser {
		return this._user;
	}

	get canGoNext(): boolean {
		return this._currentForm && this._currentForm.valid;
	}

	manageAdditionalStep(isAdd: boolean, stepName: string, stepOrder: number = -1) {
		if (this.shouldAdd(isAdd, stepName)) {
			let additionalStep = this.findStep(stepName);
			additionalStep.order = stepOrder;
			this.stepsService.insertStep(additionalStep, stepOrder);
		} else if (!isAdd) {
			this.stepsService.removeStep(this.findStep(stepName));
		}
	}

	hasStep(stepName: string): boolean {
		let step = this.findInSteps(this.stepsService.getSteps(), stepName);
		return !!step;
	}

	private findStep(stepName: string): WizardStep {
		return this.findInSteps(REGISTRATION_STEPS, stepName);
	}

	private findInSteps(steps: WizardStep[], stepName: string) {
		return steps.find((step: WizardStep) => {
			return step.name === stepName
		});
	}

	private shouldAdd(isAdd: boolean, stepName: string) {
		return isAdd && !this.hasStep(stepName);
	}
}
