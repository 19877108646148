import { Input, ContentChild, Directive } from '@angular/core';
import { GridCell } from './grid-cell.directive';
import { GridColumnTitle } from './grid-column-title.directive';
import { coerceBooleanProperty } from '../form/boolean-property';
import { SORT_ORDER, SORT_TYPE } from './grid-api.service';

@Directive({
	selector: 'vcm-grid-column'
})

export class GridColumn {
	@Input() title: string;
	@Input() key: string;
	@Input() type: SORT_TYPE;
	@Input() classNames: string = '';
	@Input() sortOrder: SORT_ORDER;

	@ContentChild(GridColumnTitle) titleTemplate: GridColumnTitle;
	@ContentChild(GridCell) cellTemplate: GridCell;

	private _sortable: boolean = false;
	private _filterable: boolean = false;

	@Input() set sortable(sortable: boolean) {
		this._sortable = coerceBooleanProperty(sortable);
	}

	get sortable() {
		return this._sortable;
	}

	@Input() set filterable(filterable: boolean) {
		this._filterable = coerceBooleanProperty(filterable);
	}

	get filterable() {
		return this._filterable;
	}
}