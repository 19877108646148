import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    Country, DeviceTypes, SignalsDciiDropdowns, SignalsDciiHeadend, State, POs, Devices
} from '../signals';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { DciiFormService } from './dcii-form.service';
import { ArrayUtils } from "../../../core/object-utils";

export const EMAIL_PATTERN = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/;
export const PHONE_PATTERN = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
export const PRICE_PATTERN = /^(\d+\.?\d{0,2})$/;
export const QTY_PATTERN = /^([0-9]{1,6})$/;

export const TID_PATTERN = /^([0-9]{12})$/;
export const ISE_PATTERN = /^([0-9]{7})$/;
export const QTY2_PATTERN = /^([1-9]{1,})$/;

@Component({
	selector: 'vcm-dcii-form',
	template: require('./dcii-form.component.html')
})

export class DciiFormComponent implements OnInit {
	emailPattern = EMAIL_PATTERN;
	phonePattern = PHONE_PATTERN;
	pricePattern = PRICE_PATTERN;
	qtyPattern = QTY_PATTERN;

	tidPattern = TID_PATTERN;
	isePattern = ISE_PATTERN;

	dataSubmitted: boolean = false;
	isDataSubmitted: boolean = false;
	isDataSubmittedSuccessfully: boolean = false;

	headend: SignalsDciiHeadend;
	dropdowns: SignalsDciiDropdowns[];
	dciiHeadendID: number;
	dciiHeadendCountry: string;

	shippingCountry;
	shippingsStatesDropdown;

	headendCountry;
	headendStatesDropdown;

	error: string;
	country: string;
	countries: Country[] = [];
	states: State[] = [];

	isFormDisabled;
	isCommentsDisabled;

	isPopupVisible = false;
	popupActionName;
	popupCallback;
	popupParameter;

	siteInfoShown = false;
	devicesShown = false;
	questionsShown = false;
	shippingShown = false;
	commentsShown = false;
	ordersShown = false;

	serviceUnitMask = [/\d/, /\d/];
	tidMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
	iseMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
	qtyMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
	poMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
	moneyMask = [/[0-9]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9]/];

	constructor(private route: ActivatedRoute,
	            private mask: LoadingMaskService,
	            private service: DciiFormService) {
	};

	ngOnInit() {
		this.route.params.forEach((params: { dciiHeadendID: number }) => {
			this.dciiHeadendID = params.dciiHeadendID;
		});

		this.route.data.forEach((data: { dropdowns: SignalsDciiDropdowns[], headend: SignalsDciiHeadend }) => {
			this.dropdowns = data.dropdowns;
            this.headend = data.headend;

			this.headend['HeadendCountry'] = ArrayUtils.findByProperty(this.dropdowns['Countries'], 'Id', this.headend['HeadendCountry']);
			this.headend['IsListAccurate'] = ArrayUtils.findByProperty(this.dropdowns['AccurateQuestion'], 'Id', this.headend['IsListAccurate']);
			this.headend['IsUseArris'] = ArrayUtils.findByProperty(this.dropdowns['YesNo'], 'Id', this.headend['IsUseArris']);
			this.headend['IsAnalogRequired'] = ArrayUtils.findByProperty(this.dropdowns['YesNo'], 'Id', this.headend['IsAnalogRequired']);
			this.headend['IsTranscodeMpeg2'] = ArrayUtils.findByProperty(this.dropdowns['YesNo'], 'Id', this.headend['IsTranscodeMpeg2']);
			this.headend['IsTranscodedOutput'] = ArrayUtils.findByProperty(this.dropdowns['YesNo'], 'Id', this.headend['IsTranscodedOutput']);
			this.headend['IsAnalogCueTones'] = ArrayUtils.findByProperty(this.dropdowns['YesNo'], 'Id', this.headend['IsAnalogCueTones']);
			this.headend['HdMpegVersion'] = ArrayUtils.findByProperty(this.dropdowns['MPEGVersion'], 'Id', this.headend['HdMpegVersion']);
			this.headend['ShippingCountry'] = ArrayUtils.findByProperty(this.dropdowns['Countries'], 'Id', this.headend['ShippingCountry']);

			this.headend['CustomerSupportStatusId'] = ArrayUtils.findByProperty(this.dropdowns['CustomerStatus'], 'Id', this.headend['CustomerSupportStatusId']);
			this.headend['CustomerSupportRepId'] = ArrayUtils.findByProperty(this.dropdowns['CustomerRep'], 'Id', this.headend['CustomerSupportRepId']);

			this.isFormDisabled = (this.headend.SurveyStatusId > 1 && this. headend.DCIIUserRole == 'EXT') || (this.headend['IsUseArris'] != null && this.headend['IsUseArris']["Id"] === false);
			this.isCommentsDisabled = this.headend.SurveyStatusId > 1 && this. headend.DCIIUserRole == 'EXT';

			if (this.headend['HeadendCountry'] != null) {
				this.service.getStates(this.headend['HeadendCountry']).then((res) => {
					if (res) {
						this.headendStatesDropdown = res;
						this.headend['HeadendState'] = ArrayUtils.findByProperty(this.headendStatesDropdown, 'Id', this.headend['HeadendState']);
					}
				})
			} else {
				this.headend['HeadendState'] = null;
			}

			if (this.headend['ShippingCountry'] != null) {
				this.service.getStates(this.headend['ShippingCountry']).then((res) => {
					if (res) {
						this.shippingsStatesDropdown = res;
						this.headend['ShippingState'] = ArrayUtils.findByProperty(this.shippingsStatesDropdown, 'Id', this.headend['ShippingState']);
					}
				})
			} else {
				this.headend['ShippingState'] = null;
			}

			this.headendCountry = this.headend['HeadendCountry'];
			this.shippingCountry = this.headend['ShippingCountry'];

			let deviceTypesDropdown = this.dropdowns['DeviceTypes'];
			let orderTypesDropdown = this.dropdowns['OrderTypes'];
			let irdSourceDropdown = this.dropdowns['IrdSources'];

			if (this.headend.Devices) {
				this.headend.Devices.forEach(
					function (device) {
						device.ConfigLicense = ArrayUtils.findByProperty(deviceTypesDropdown, 'Id', device.ConfigLicense);
					}
				)
			}

			if (this.headend.Orders) {
				this.headend.Orders.forEach(
					function (order) {
						order.OrderType = ArrayUtils.findByProperty(orderTypesDropdown, 'Id', order.OrderType);
						if (order.DeviceTypes) {
							order.DeviceTypes.forEach(
								function (deviceType) {
									deviceType.ConfigLicense = ArrayUtils.findByProperty(deviceTypesDropdown, 'Id', deviceType.ConfigLicense);
									if (deviceType.Devices) {
										deviceType.Devices.forEach(
											function (device) {
												device.IrdSource = ArrayUtils.findByProperty(irdSourceDropdown, 'Id', device.IrdSource);
											}
										)
									}
								}
							)
						}
					}
				)
			}
		});
	}

	getDciiFormUpdate() {
		this.mask.addLoading();
		this.dataSubmitted = false;
		this.service.getDciiFormUpdate(this.headend).then((res) => {
			this.mask.removeLoading();

			this.headend = res;

			this.headend['HeadendCountry'] = ArrayUtils.findByProperty(this.dropdowns['Countries'], 'Id', this.headend['HeadendCountry']);
			if (this.headendStatesDropdown) {
				this.headend['HeadendState'] = ArrayUtils.findByProperty(this.headendStatesDropdown, 'Id', this.headend['HeadendState']);
			}
			this.headend['IsListAccurate'] = ArrayUtils.findByProperty(this.dropdowns['AccurateQuestion'], 'Id', this.headend['IsListAccurate']);
			this.headend['IsUseArris'] = ArrayUtils.findByProperty(this.dropdowns['YesNo'], 'Id', this.headend['IsUseArris']);
			this.headend['IsAnalogRequired'] = ArrayUtils.findByProperty(this.dropdowns['YesNo'], 'Id', this.headend['IsAnalogRequired']);
			this.headend['IsTranscodeMpeg2'] = ArrayUtils.findByProperty(this.dropdowns['YesNo'], 'Id', this.headend['IsTranscodeMpeg2']);
			this.headend['IsTranscodedOutput'] = ArrayUtils.findByProperty(this.dropdowns['YesNo'], 'Id', this.headend['IsTranscodedOutput']);
			this.headend['IsAnalogCueTones'] = ArrayUtils.findByProperty(this.dropdowns['YesNo'], 'Id', this.headend['IsAnalogCueTones']);
			this.headend['HdMpegVersion'] = ArrayUtils.findByProperty(this.dropdowns['MPEGVersion'], 'Id', this.headend['HdMpegVersion']);
			this.headend['ShippingCountry'] = ArrayUtils.findByProperty(this.dropdowns['Countries'], 'Id', this.headend['ShippingCountry']);
			if (this.shippingsStatesDropdown) {
				this.headend['ShippingState'] = ArrayUtils.findByProperty(this.shippingsStatesDropdown, 'Id', this.headend['ShippingState']);
			}
			this.headend['CustomerSupportStatusId'] = ArrayUtils.findByProperty(this.dropdowns['CustomerStatus'], 'Id', this.headend['CustomerSupportStatusId']);
			this.headend['CustomerSupportRepId'] = ArrayUtils.findByProperty(this.dropdowns['CustomerRep'], 'Id', this.headend['CustomerSupportRepId']);

			this.isFormDisabled = (this.headend.SurveyStatusId > 1 && this. headend.DCIIUserRole == 'EXT') || (this.headend['IsUseArris'] != null && this.headend['IsUseArris']["Id"] === false);
			this.isCommentsDisabled = this.headend.SurveyStatusId > 1 && this. headend.DCIIUserRole == 'EXT';

			let deviceTypesDropdown = this.dropdowns['DeviceTypes'];
			let orderTypesDropdown = this.dropdowns['OrderTypes'];
			let irdSourceDropdown = this.dropdowns['IrdSources'];

			if (this.headend.Devices) {
				this.headend.Devices.forEach(
					function (device) {
						device.ConfigLicense = ArrayUtils.findByProperty(deviceTypesDropdown, 'Id', device.ConfigLicense);
					}
				)
			}

			if (this.headend.Orders) {
				this.headend.Orders.forEach(
					function (order) {
						order.OrderType = ArrayUtils.findByProperty(orderTypesDropdown, 'Id', order.OrderType);
						if (order.DeviceTypes) {
							order.DeviceTypes.forEach(
								function (deviceType) {
									deviceType.ConfigLicense = ArrayUtils.findByProperty(deviceTypesDropdown, 'Id', deviceType.ConfigLicense);
									if (deviceType.Devices) {
										deviceType.Devices.forEach(
											function (device) {
												device.IrdSource = ArrayUtils.findByProperty(irdSourceDropdown, 'Id', device.IrdSource);
											}
										)
									}
								}
							)
						}
					}
				)
			}

			window.scrollTo(0, 0);
		})
	}

	saveDciiForm(): void {
		this.mask.addLoading();

		this.convertDataBeforeSend();

		this.service.saveDciiForm(this.headend).then((res) => {
			this.mask.removeLoading();
			this.isDataSubmitted = false;
			this.dataSubmitted = true;

            this.isDataSubmittedSuccessfully = res.status === 200;

			window.scrollTo(0, 0);
		})
	}

	submitDciiFullForm(): void {
		this.mask.addLoading();

		this.convertDataBeforeSend();

		this.service.submitDciiFullForm(this.headend).then((res) => {
			this.mask.removeLoading();
			this.dataSubmitted = true;
			this.isDataSubmitted = true;

            this.isDataSubmittedSuccessfully = res.status === 200;

			window.scrollTo(0, 0);
		})
	}

	submitDciiOrder(order): void {
		this.mask.addLoading();

		this.convertDataBeforeSend();

		this.service.submitDciiOrder(order, this.headend).then((res) => {
			this.mask.removeLoading();
			this.dataSubmitted = true;
			this.isDataSubmitted = true;

            this.isDataSubmittedSuccessfully = res.status === 200;

			window.scrollTo(0, 0);
		})
	}

	approveDciiOrder(order): void {
		this.mask.addLoading();

		this.convertDataBeforeSend();

		this.service.approveDciiOrder(order).then((res) => {
			this.mask.removeLoading();
			this.dataSubmitted = true;
			this.isDataSubmitted = true;

            this.isDataSubmittedSuccessfully = res.status === 200;

			window.scrollTo(0, 0);
		})
	}

	rejectDciiOrder(order): void {
		this.mask.addLoading();

		this.convertDataBeforeSend();

		this.service.rejectDciiOrder(order).then((res) => {
			this.mask.removeLoading();
			this.dataSubmitted = true;
			this.isDataSubmitted = true;

            this.isDataSubmittedSuccessfully = res.status === 200;

			window.scrollTo(0, 0);
		})
	}

	cancelDciiOrder(order): void {
		this.mask.addLoading();

		this.convertDataBeforeSend();

		this.service.cancelDciiOrder(order, this.headend).then((res) => {
			this.mask.removeLoading();
			this.dataSubmitted = true;
			this.isDataSubmitted = true;

            this.isDataSubmittedSuccessfully = res.status === 200;

			window.scrollTo(0, 0);
		})
	}

	fulFillDciiOrder(order): void {
		this.mask.addLoading();

		this.convertDataBeforeSend();

		this.service.fulFillDciiOrder(order, this.headend).then((res) => {
			this.mask.removeLoading();
			this.dataSubmitted = true;
			this.isDataSubmitted = true;

            this.isDataSubmittedSuccessfully = res.status === 200;

			window.scrollTo(0, 0);
		})
	}

	convertDataBeforeSend(): void {

		this.headend['HeadendCountry'] = this.headend['HeadendCountry'] == null ? null : this.headend['HeadendCountry']["Id"];
		this.headend['HeadendState'] = this.headend['HeadendState'] == null ? null : this.headend['HeadendState']["Id"];
		this.headend['IsListAccurate'] = this.headend['IsListAccurate'] == null ? null : this.headend['IsListAccurate']["Id"];
		this.headend['IsUseArris'] = this.headend['IsUseArris'] == null ? null : this.headend['IsUseArris']["Id"];
		this.headend['IsAnalogRequired'] = this.headend['IsAnalogRequired'] == null ? null : this.headend['IsAnalogRequired']["Id"];
		this.headend['IsTranscodeMpeg2'] = this.headend['IsTranscodeMpeg2'] == null ? null : this.headend['IsTranscodeMpeg2']["Id"];
		this.headend['IsTranscodedOutput'] = this.headend['IsTranscodedOutput'] == null ? null : this.headend['IsTranscodedOutput']["Id"];
		this.headend['IsAnalogCueTones'] = this.headend['IsAnalogCueTones'] == null ? null : this.headend['IsAnalogCueTones']["Id"];
		this.headend['HdMpegVersion'] = this.headend['HdMpegVersion'] == null ? null : this.headend['HdMpegVersion']["Id"];

		this.headend['ShippingCountry'] = this.headend['ShippingCountry'] == null ? null : this.headend['ShippingCountry']["Id"];
		this.headend['ShippingState'] = this.headend['ShippingState'] == null ? null : this.headend['ShippingState']["Id"];

		this.headend['CustomerSupportStatusId'] = this.headend['CustomerSupportStatusId'] == null ? null : this.headend['CustomerSupportStatusId']["Id"];
		this.headend['CustomerSupportRepId'] = this.headend['CustomerSupportRepId'] == null ? null : this.headend['CustomerSupportRepId']["Id"];

		if (this.headend.Devices) {
			this.headend.Devices.forEach(
				function (device) {
					device.ConfigLicense = device.ConfigLicense == null ? null : device.ConfigLicense['Id'];
				}
			)
		}

		if (this.headend.Orders) {
			this.headend.Orders.forEach(
				function (order) {
					order.OrderType = order.OrderType == null ? null : order.OrderType['Id'];
					if (order.DeviceTypes) {
						order.DeviceTypes.forEach(
							function (deviceType) {
								deviceType.ConfigLicense = deviceType.ConfigLicense == null ? null : deviceType.ConfigLicense['Id'];
								if (deviceType.Devices) {
									deviceType.Devices.forEach(
										function (device) {
											device.IrdSource = device.IrdSource == null ? null : device.IrdSource['Id'];
										}
									)
								}
							}
						)
					}
				}
			)
		}
	}

	headendCountryOnChange(): void {
		if (this.headend['HeadendCountry'] != null) {
			this.headendStatesDropdown = null;
			this.headend['HeadendState'] = null;

			if (this.headendCountry != this.headend['HeadendCountry']['Id']) {
				this.service.getStates(this.headend['HeadendCountry']).then((res) => {
					this.headendStatesDropdown = res;
				});
			}
		}
		else {
			this.headend['HeadendState'] = null;
			this.headendStatesDropdown = null;
		}
	}

	shippingCountryOnChange(): void {
		if (this.headend['ShippingCountry'] != null) {
			this.headend['ShippingState'] = null;
			this.shippingsStatesDropdown = null;

			if (this.shippingCountry != this.headend['ShippingCountry']['Id']) {
				this.service.getStates(this.headend['ShippingCountry']).then((res) => {
					this.shippingsStatesDropdown = res;
				});
			}
		}
		else {
			this.headend['ShippingState'] = null;
			this.shippingsStatesDropdown = null;
		}
	}

	backClicked(): void {
		window.history.back();
	}

	addOrders(): void {
		let dciiOrders = this.headend["Orders"];

		dciiOrders.push({
			OrderId: null,
			OrderType: null,
			OrderStatusId: 0,
			OrderStatusName: "Pending Equipment Evaluation",
			SurveyId: this.headend.SurveyId,
			IsConfirmed: false,
			IsCancelled: false,
			IsFulFilled: false,
			DeviceTypes: [{
				'ConfigLicense': null,
				'DeviceTypeId': null,
				'Devices': [],
				'Qty': null
			}],
			'POs': [],
			'Shippings': null
		});
	}

	addDevice(device): void {
		let irdSourceDropdown = this.dropdowns['IrdSources'];
        device['Devices'].push({
			'ACPAddress': null,
			'DeviceCode': null,
			'DeviceId': null,
			'IrdSource': ArrayUtils.findByProperty(irdSourceDropdown, 'Id', 0),
			'UnitPrice': null
		});
	}

	addPos(order): void {
		order['POs'].push({
			'POAmount': null,
			'POComments': null,
			'PODate': null,
			'POId': null,
			'PONumber': null,
			'PRNumber': null
		});
	}

    addReciver(order): void {
        order['DeviceTypes'].push({
            'ConfigLicense': null,
            'DeviceTypeId': null,
            'Devices': [],
            'Qty': null
        });
    }

    removeDevice(order, i): void {
        order['Devices'].splice(i, 1);
    }

    removePos(order, i): void {
        order['POs'].splice(i, 1);
    }

    removeReciver(order, i): void {
        order['DeviceTypes'].splice(i, 1);
    }

	removeOrder(i): void {
		this.headend['Orders'].splice(i, 1);
	}

	poDateOnChange(date: Date, pos): void {
		pos['PODate'] = date;
	}

	/*shipReceivedDateOnChange(date: Date, ship): void {
		ship['ReceivedDate'] = date;
	}*/

	shipOutDateOnChange(date: Date, ship): void {
		ship['OutDate'] = date;
	}

	isReadonly(): boolean | null {
		if (this.headend['SurveyStatusId'] > 1 && this.headend['DCIIUserRole'] == 'EXT') {
			return false;
		}
	}

	isEditable(): boolean {
		return false;
	}

    isSelectColumnShown(headend: SignalsDciiHeadend): boolean {
        return headend.DCIIUserRole == 'INT'
            && (
                headend.SurveyStatusId > 2 || (headend.Orders.length != 0 && headend.SurveyStatusId === 2)
            );
    }

	isUseArrisChange(): void {
		this.isFormDisabled = (this.headend.SurveyStatusId > 1 && this.headend.DCIIUserRole == 'EXT') || (this.headend['IsUseArris'] != null && this.headend['IsUseArris']["Id"] === false);
	}

	showPopup(actionName, callback, parameter): void {
		this.popupActionName = actionName;
		this.popupCallback = callback;
		this.popupParameter = parameter;
		this.isPopupVisible = true;
	}

	popupSubmitClicked(): void {
		this.isPopupVisible = false;
		this.popupCallback(this.popupParameter);
	}

	popupCancelClicked(): void {
		this.isPopupVisible = false;
	}

	toggleSiteInfoExpansion(){
		this.siteInfoShown = !this.siteInfoShown;
	}

	toggleDevicesExpansion(){
		this.devicesShown = !this.devicesShown;
	}

	toggleQuestionsExpansion(){
		this.questionsShown = !this.questionsShown;
	}

	toggleShippingExpansion(){
		this.shippingShown = !this.shippingShown;
	}

	toggleCommentsExpansion(){
		this.commentsShown = !this.commentsShown;
	}

	toggleOrdersExpansion(){
		this.ordersShown = !this.ordersShown;
	}
}
