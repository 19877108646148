import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {
	private _baseUrl = process.env.ENV === 'localdevelopment' ? 'http://192.168.1.132:2080' : '';
	private _baseAssetsUrl = this.getBaseAssetsUrl();

	get baseUrl(): string {
		return this._baseUrl;
	}

	get baseAssetsUrl(): string {
		return this._baseAssetsUrl;
	}

	private getBaseAssetsUrl(): string {
		return process.env.ENV === 'localdevelopment' ? 'http://192.168.1.132:2080' : '';
	}
}