import { Injectable } from '@angular/core';
import { RequestOptionsArgs, Headers } from '@angular/http';

@Injectable()
export class HeadersClient {

	constructor() {
	}

	public getRequestOptions(): RequestOptionsArgs {
		return {
			headers: new Headers({
				'Accept': 'application/json, text/plain',
				'Content-Type': 'application/json, text/plain; charset=utf-8',
			})
		}
	}

	public getRequestOptionsWithAuth(authToken: string): RequestOptionsArgs {
		return {
			headers: new Headers({
				'Accept': 'application/json, text/plain',
				'Content-Type': 'application/json, text/plain; charset=utf-8',
				'Authorization': authToken
			})
		}
	}
}