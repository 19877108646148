import { NgModule }      from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LandingModule } from '../../view/landing/landing.module';
import { SignalsLandingComponent } from './signals-landing.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		LandingModule
	],
	declarations: [
		SignalsLandingComponent
	],
	exports: [
		SignalsLandingComponent
	]
})

export class SignalsLandingModule {
}