import { Component, OnInit, HostBinding } from '@angular/core';
import { MdDialogRef } from '@angular/material';
import { UserService } from '../../../core/auth/user.service';
import { UserNews } from '../../../core/auth/user';
import { AssetsService } from '../../../core/assets.service';

@Component({
	template: require('./user-news-dialog.component.html')
})
export class UserNewsDialog implements OnInit {
	public userImages: string[] = [];
	@HostBinding('class.vcm_usernews_block') addClass: boolean = true;

	constructor(public dialogRef: MdDialogRef<UserNewsDialog>,
	            public userService: UserService,
	            public assetsService: AssetsService) { }

	ngOnInit(): void {
		this.userImages = this.getUserImages();
	}

	private getUserImages() {
		return this.userService.getUserInfo().userNews.map((news: UserNews) => {
			return this.assetsService.getAssetUrl(news.ImageURL);
		});
	}
}