import {
	Component, Input, Output, EventEmitter, forwardRef, HostBinding,
	ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { coerceBooleanProperty } from '../boolean-property';

export const INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => DatepickerDropdownComponent),
	multi: true
};

@Component({
	selector: 'vcm-datepicker-dropdown',
	template: require('./datepicker-dropdown.component.html'),
	providers: [INPUT_CONTROL_VALUE_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class DatepickerDropdownComponent implements ControlValueAccessor {
	@Input() date: Date;
	@Input() label: string;
	@Input() minDate: Date;
	@Input() maxDate: Date;
	@HostBinding('class.vcm_datepicker') addClass: boolean = true;

	isOpen: boolean = false;

	@Output() dateChanged: EventEmitter<Date> = new EventEmitter();

	@Input()
	get disabled(): boolean {
		return this._disabled;
	}

	set disabled(value) {
		this._disabled = coerceBooleanProperty(value);
	}

	private _controlValueAccessorChangeFn: (value: any) => void = (value) => {
	};
	private onTouched: () => any = () => {
	};
	private _disabled: boolean = false;

	constructor(private ref: ChangeDetectorRef) {
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	writeValue(date: Date) {
		this.date = date;
		this.ref.markForCheck();
		if (this.date) {
			this._controlValueAccessorChangeFn(this.date);
		}
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	registerOnChange(fn: (value: any) => void) {
		this._controlValueAccessorChangeFn = fn;
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	_onInputBlur() {
		this.onTouched();
	}

	changeSelectedDate(date: Date): void {
		this.isOpen = false;
		this._controlValueAccessorChangeFn(this.date);
		this.dateChanged.emit(date);
	}
}
