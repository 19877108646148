import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/switchMap';
import { AcpAddressValidationService } from '../steps/step2/grid-form/acp-address-validation.service';

@Directive({
	selector: '[acpAddressValidator][formControlName],[acpAddressValidator][formControl],[acpAddressValidator][ngModel]',
	providers: [
		{provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => AcpAddressValidator), multi: true}
	]
})
export class AcpAddressValidator implements Validator {
	observable: Observable<any>;
	subject: Subject<string>;
	resolve: any = null;

	constructor(private acpAddressValidationService: AcpAddressValidationService) {
		this.subject = new Subject();
		this.observable = this.subject
			.debounceTime(300)
			.switchMap((acpAddress: string) => {
				return this.acpAddressValidationService.checkAcpAddressObservable(acpAddress);
			}).flatMap(res => {
				let errorCode: boolean = res;

				if (!errorCode) {
					return Observable.of({acpAddressInvalid: true});
				} else {
					return Observable.of(null);
				}
			});

		this.observable.subscribe((res) => {
			this.resolvePromise(res);
		})
	}

	resolvePromise(result) {
		if (this.resolve) {
			this.resolve(result);
			this.resolve = null;
		}
	}

	validate(c: AbstractControl): Promise<{[key: string]: any}> {
		this.resolvePromise(null);

		return new Promise(resolve => {
			this.subject.next(c.value);
			this.resolve = resolve;
		});
	}
}