import * as Immutable from 'immutable';

export class ResourceType {
	constructor(public id: string,
	            public name: string) {}
}

export const RESOURCE_TYPES: Immutable.Map<string, ResourceType> = Immutable.Map<string, ResourceType>({
	'SOCMEDIA': new ResourceType('social-media', 'Social Media'),
	'AVAILALERT': new ResourceType('avail-alert', 'Avail Alerts'),
	'logo': new ResourceType('logo', 'Logos'),
	'certification': new ResourceType('certification', 'Certifications'),
	'channelDescription': new ResourceType('channel-description', 'Channel descriptions'),
	'press': new ResourceType('press', 'Press'),
	'research': new ResourceType('research', 'Other'),
	'TECHSPECS': new ResourceType('tech-specs', 'Tech Specs'),
	'IMAGES': new ResourceType('images', 'Images')
});