import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { ProfileDropdownsService, ProfileDropdowns } from './profile-dropdowns.service';

@Injectable()
export class ProfileDropdownsResolve implements Resolve<ProfileDropdowns> {
	constructor(private service: ProfileDropdownsService, private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<ProfileDropdowns> {
		this.mask.addLoading();
		return this.service.getDropDowns()
			.then((dropdowns: any) => {
				this.mask.removeLoading();
				return dropdowns;
			});
	}
}


