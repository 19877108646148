import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	template: require('./images.component.html')
})

export class ImagesComponent {
	url: string;

	constructor(private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: { url: string }) => {
			this.url = data.url;
		});
	}
}