import { Injectable } from '@angular/core';
import { ResourcesService } from '../resources.service';

export class FileName {
	FileName: string;
	FileId: string;
}

export class Logo {
	ContentName: string;
	ContentTitle: string;
	FileName: FileName;
	LegalText: string;
	Name: string;
	Origin: string;
	PublishDate: string;
	ResourceId: number;
	ServiceLongname: string;
	Thumbnail: string;
	Type: string;
	UsageDocs: string;
}

@Injectable()
export class LogoService {

	constructor(private resourcesService: ResourcesService) {}

	getLogo(filter: any = {}): Promise<Logo[]> {
		filter.resourceType = 'logo';
		return this.resourcesService.getResourcesList(filter);
	}
}
