import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule, JsonpModule, BaseRequestOptions } from '@angular/http';
import { AuthenticationService } from './auth/authentication.service';
import { HttpClient } from './http/http-client';
import { COMPILER_PROVIDERS } from '@angular/compiler';
import { CountryService } from './country.service';
import { TokenService } from './http/token.service';
import { ConfigService } from './config.service';
import { ToastModule } from 'ng2-toastr';
import { LoggerService } from './logger/logger.service';
import { toastOptions } from './toastr/toastr-options';
import { HeadersClient } from './http/headers-client';
import { ViewportService } from './viewport-service/viewport.service';
import { UserService } from './auth/user.service';
import { AssetsService } from './assets.service';
import { SoundService } from './sound/sound.service';
import { RedirectService } from '../pages/redirect/redirect.service';
import {LogResourceDownloadService} from "../pages/resources/log-resource-download.service";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		HttpModule,
		JsonpModule,
		ToastModule.forRoot(toastOptions)
	],
	exports: [
		HttpModule,
		JsonpModule
	],
	providers: [
		AuthenticationService,
		UserService,
		LoggerService,
		BaseRequestOptions,
		HttpClient,
		HeadersClient,
		TokenService,
		ConfigService,
		AssetsService,
		COMPILER_PROVIDERS,
		CountryService,
		ViewportService,
		SoundService,
		RedirectService,
		LogResourceDownloadService
	]
})
export class CoreModule {
}
