import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { AuthorizationStep1Component } from './step1/authorization-step1.component';
import { AuthorizationStep2Component } from './step2/authorization-step2.component';
import { SharedModule } from "../../../../../shared/shared.module";
import { AuthorizationDropdownsService } from './step1/authorization-dropdowns.service';
import { AuthorizationGrigService } from './step2/authorization-grid.service';
import { SummaryComponent } from '../shared/summary/summary.component';
import { GridFormComponent } from './step2/grid-form/grid-form.component';
import { NotEmptyCheckboxListValidator } from '../shared/not-empty-checkbox-list-validator.directive';
import { AuthorizationStep3Component } from './step3/authorization-step3.component';
import { GridConfirmationComponent } from './step3/grid-confirmation/grid-confirmation.component';
import { NotEmptySelectedServicesValidator } from '../shared/not-empty-selected-services-validator.directive';
import { AuthorizationStepFinalComponent } from './step-final/authorization-step-final.component';
import { AcpAddressValidator } from '../shared/acp-address-validator.directive';
import { AcpAddressValidationService } from './step2/grid-form/acp-address-validation.service';
import { AcpAddressEqualValidator } from '../shared/acp-address-equal-validator.directive';
import { ISEEqualValidator } from '../shared/ise-equal-validator.directive';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		SharedModule
	],
	declarations: [
		AuthorizationStep1Component,
		AuthorizationStep2Component,
		AuthorizationStep3Component,
		AuthorizationStepFinalComponent,
		SummaryComponent,
		GridFormComponent,
		GridConfirmationComponent,
		NotEmptyCheckboxListValidator,
		NotEmptySelectedServicesValidator,
		AcpAddressValidator,
		AcpAddressEqualValidator,
		ISEEqualValidator
	],
	providers: [
		AuthorizationDropdownsService,
		AuthorizationGrigService,
		AcpAddressValidationService
	]
})

export class StepsModule {
}