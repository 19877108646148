import { Component } from '@angular/core';
import { SignalsService } from '../../signals.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HeadendSummary } from '../shared/headend-summary.service';

@Component({
	template: require('./validate-headend.component.html')
})

export class ValidateHeadendComponent {
	isRequestSubmitted: boolean = false;
	headendId: number;
	headendSummary: HeadendSummary;

	constructor(private signalsService: SignalsService,
	            private router: Router,
	            private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.headendId = this.signalsService.headendId;
		if (!this.headendId) {
			this.router.navigate(['signals/device-grid']);
		}
		this.route.data.forEach((data: {
			headendSummary: HeadendSummary
		}) => {
			this.headendSummary = data.headendSummary;
		});
	}

	displayFeedbackSubmittedMessage(feedback): void {
		this.isRequestSubmitted = true;
	}
}
