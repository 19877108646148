import { Injectable } from '@angular/core';
import { ResourcesService } from '../resources.service';

export class ChannelDescription {
	ContentName: string;
	LegalText: string;
}

@Injectable()
export class ChannelDescriptionService {

	constructor(private resourcesService: ResourcesService) {}

	getChannelDescriptions(filter: any = {}): Promise<ChannelDescription[]> {
		filter.resourceType = 'channelDescription';
		return this.resourcesService.getResourcesList(filter);
	}
}
