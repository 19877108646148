import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ResourcesBrandsService, ResourceBrand } from './resources-brands.service';
import { LoadingMaskService } from '../../shared/loading-mask/loading-mask.service';
import { RESOURCE_TYPES } from './resrouce-types';

@Injectable()
export class ResourcesBrandsResolve implements Resolve<ResourceBrand[]> {
	resourceType: string;

	constructor(private service: ResourcesBrandsService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ResourceBrand[]> {
		this.mask.addLoading();

		RESOURCE_TYPES.forEach((resource, index) => {
			if (resource.id === state.url.split('/')[2]) {
				this.resourceType = index;
			}
		});

		return this.service.loadBrands(this.resourceType)
			.then((brands: ResourceBrand[]) => {
				this.mask.removeLoading();
				return brands;
			});
	}
}