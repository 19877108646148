import { NgModule } from '@angular/core';
import { SelectComponent } from './select.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@angular/material';
import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		MaterialModule,
		DirectivesModule
	],
	declarations: [
		SelectComponent
	],
	exports: [
		SelectComponent
	],
})

export class SelectModule {
}