export class AuthorizationRequest {
	HeadendId: number;
	Action: CommonObject;
	DecoderType: CommonObject;
	DecoderModel: CommonObject;
	NCTC: CommonObject;
	SignalSource: CommonObject;
	SystemSize: CommonObject;
	Date: Date;
	BasicSubs: number;
	DigitalSubs: number;
	Name: string;
	Email: string;
	NCTCRepresentative: string;
	Anchor: string;
	TrackingId: string;
	FF_HeadendName: string;
	FF_HeadendCity: string;
	FF_HeadendState: CommonObject;
	FF_HeadendAnchor: string;
	SelectedServices: AuthorizationServiceUplink[];
	Comments: string;

	EngineerEmail: string;
	EngineerName: string;
	EngineerPhone: number;
	HeadendAddress: string;
	HeadendCity: string;
	HeadendMSO: string;
	HeadendName: string;
	HeadendState: string;
	HeadendZip: string;
}

export class AuthorizationGridData {
	ISEs: string[];
	IsCiscoISEsInInventory: boolean;
	Message: string;
	NoOfServicesRemaining: number;
	NoOfInputs: number;
	Status: boolean;
	Transponders: AuthorizationTransponder[];
	UnitAddress: string;
	UnitType: CommonObject;
}

export interface AuthorizationTransponder {
	Services: AuthorizationServiceUplink[];
	AllCiscoServices: any;
	Transponder: string;
	TransponderDisabled: boolean;
	SatelliteName: string;
}

export class AuthorizationServiceUplink {
	AcpAddress: string;
	FeedCode: string;
	FeedName: string;
	ServiceId: number;
	ServiceName: string;
	ServiceType: string;
	ServiceTypeDescription: string;
	Status: boolean | string;
	StatusConfirmed: boolean;
	SubsLaunched: number;
	ISE: string;
	ServiceSelected: any;
	FeedSelected: any;
	LocationSelected: any;
	SatelliteId: string;
	TransponderId: number;
	SatelliteTransponderName: string;
	IsNew: boolean;
	uniqueNumber: number;
}

export class CommonObject {
	Id: string;
	Description: string;

	constructor(data?: any) {
		if(data) {
			this.Id = data.Id;
			this.Description = data.Description;
		}
	}
}