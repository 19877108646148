import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { OrderRoutingModule } from './order-routing.module';
import { OrderComponent } from './order.component';
import { OrderService } from './order.service';
import { OrderFormComponent } from './order-form/order-form.component';
import { OrderGridComponent } from './order-form/order-grid/order-grid.component';

@NgModule({
	imports: [
		SharedModule,
		OrderRoutingModule
	],
	declarations: [
		OrderComponent,
		OrderFormComponent,
		OrderGridComponent
	],
	providers: [
		OrderService
	]
})
export class OrderModule {
}