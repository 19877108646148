import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SignalsDciiDropdowns, SignalsDciiHeadend } from '../signals';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { DciiFormService } from './dcii-form.service';

@Injectable()
export class DciiDropdownsResolve implements Resolve<SignalsDciiDropdowns> {
	constructor(private service: DciiFormService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<any> {
		let dciiHeadendID: string = route.params['dciiHeadendID'];
		let headend = this.service.dciiFormData;
		this.mask.addLoading();

		return this.service.getDciiDropdowns({
			dciiHeadendID: dciiHeadendID
		}).then((dciiDropdowns: SignalsDciiDropdowns = null) => {
			this.mask.removeLoading();

			return dciiDropdowns;
		});
	}
}
