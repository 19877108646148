import { Component, Output, EventEmitter } from '@angular/core';
import { NgForm } from "@angular/forms";
import { ForgotPasswordService } from '../forgot-password.service';

@Component({
	selector: 'vcm-enter-new-password-form',
	template: require('./enter-new-password-form.component.html')
})

export class EnterNewPasswordFormComponent {
	@Output() public newPasswordSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private forgotPasswordService: ForgotPasswordService) {
	}

	saveNewPassword(form: NgForm): void {
		this.forgotPasswordService.saveNewPassword(form.value.password).then((paswordChanged) => {
			this.newPasswordSubmitted.emit(paswordChanged);
		});
	}
}




