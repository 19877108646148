import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Order } from './order';
import { OrdersService } from './orders.service';

@Component({
	template: require('./orders.component.html')
})

export class OrdersComponent {
	orders: Order[] = [];

	constructor(private route: ActivatedRoute,
	            private ordersService: OrdersService) {}

	ngOnInit() {
		this.ordersService.setCurrentDate();
		this.route.data.forEach((data: { orders: Order[] }) => {
			this.orders = data.orders;
		});
	}

	getOrders() {
		this.ordersService.getOrders().then((res: Order[]) => {
			this.orders = res;
		});
	}

	periodOnChange(periodFrom: Date, periodTo: Date): void {
		this.ordersService.periodFrom = periodFrom;
		this.ordersService.periodTo = periodTo;
		this.getOrders();
	}
}
