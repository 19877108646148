import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ChangePasswordComponent } from './change-password.component';
import { ChangePasswordService } from './change-password.service';
import { SharedModule } from '../../../shared/shared.module';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		HttpModule,
		SharedModule
	],
	declarations: [
		ChangePasswordComponent,
		ChangePasswordFormComponent
	],
	providers: [
		ChangePasswordService
	],
	exports: [
		ChangePasswordComponent
	]
})

export class ChangePasswordModule {
}

