import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ProfileUser } from '../profileUser';
import { ProfileUserService } from './profile-user.service';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';

@Injectable()
export class ProfileUserResolve implements Resolve<ProfileUser> {
	constructor(private service: ProfileUserService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<ProfileUser> {
		this.mask.addLoading();
		return this.service.getUser()
			.then((user: ProfileUser) => {
				this.mask.removeLoading();
				return user;
			});
	}
}