import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { SearchComponent } from './search.component';
import { SearchRoutingModule } from './search-routing.module';
import { LandingGridModule } from '../view/landing-grid/landing-grid.module';

@NgModule({
	imports: [
		SharedModule,
		LandingGridModule,
		SearchRoutingModule
	],
	declarations: [
		SearchComponent
	]
})

export class SearchModule {
}


