import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable()
export class AssetsService {

	constructor(private config: ConfigService) {
	}

	getAssetUrl(assetPath: string): string {
		let result = assetPath;
		if (!result.startsWith('http')) {
			result = result.startsWith('/') ? result : '/' + result;
			result = this.config.baseAssetsUrl + result;
		}
		return result;
	}
}