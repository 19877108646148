import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
	selector: '[stateValidator]',
	providers: [
		{provide: NG_VALIDATORS, useExisting: forwardRef(() => StateExistValidator), multi: true}
	]
})
export class StateExistValidator implements Validator {
	constructor() {
	}

	validate(c: AbstractControl): {[key: string]: any} {
		let stateValue: any = c.value['state'];

		if (!stateValue) {
			return {stateExist: false}
		}
		return null;
	}
}

