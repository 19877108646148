import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared/shared.module';
import { AuthorizationRoutingModule } from './authorization-routing.module';
import { AuthorizationComponent } from './authorization.component';
import { AuthorizationService } from './authorization.service';
import { StepsModule } from "./steps/steps.module";
import { AuthorizationStepsResolve } from './authorization-steps-resolve.service';
import { AuthorizationStepsService } from './authorization-steps.service';
import { AuthorizationRequestService } from './authorization-request.service';

@NgModule({
	imports: [
		SharedModule,
		AuthorizationRoutingModule,
		StepsModule
	],
	declarations: [
		AuthorizationComponent,
	],
	providers: [
		AuthorizationStepsService,
		AuthorizationStepsResolve,
		AuthorizationService,
		AuthorizationRequestService
	]
})

export class AuthorizationModule {
}