import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { EmailValidationService } from '../steps/step1/email-validation.service';

@Directive({
	selector: '[emailValidator][formControlName],[emailValidator][formControl],[emailValidator][ngModel]',
	providers: [
		{provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => EmailValidator), multi: true}
	]
})
export class EmailValidator implements Validator {
	emailValidationTimeout: any;

	constructor(private emailValidationService: EmailValidationService) {
	}

	validate(c: AbstractControl): Promise<{[key: string]: any}> {
		return this.validateEmailPromise(c.value);
	}

	private validateEmailPromise(email: string) {
		if (this.emailValidationTimeout) {
			clearTimeout(this.emailValidationTimeout);
		}

		return new Promise(resolve => {
			this.emailValidationTimeout = setTimeout(() => {
				this.emailValidationService.checkEmail(email).then((res) => {
					let errorCode: string = res;

					if (!this.isEmailValid(errorCode)) {
						resolve({emailInvalid: errorCode});
					} else {
						resolve(null);
					}
				})
			}, 1000);
		});
	}

	private isEmailValid(errorCode): boolean {
		return errorCode === 'Allowed';
	}
}
