import { NgModule }     from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/auth/auth.guard';
import { OrdersComponent } from './orders.component';
import { OrdersResolve } from './orders-resolve.service';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'orders',
				component: OrdersComponent,
				canActivate: [AuthGuard],
				resolve: {
					orders: OrdersResolve
				}
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		OrdersResolve
	]
})
export class OrdersRoutingModule {
}
