import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { SignalsService } from '../../signals.service';

@Injectable()
export class HeadendIdGuard implements CanActivate {
	constructor(private router: Router,
	            private signalsService: SignalsService) {
	}

	canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (!this.signalsService.headendId) {
			this.router.navigate(['signals/device-grid']);
			return false;
		}
		return true
	}
}