import { NgModule }      from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@angular/http';
import { PromotionComponent } from './index';
import { PromotionRoutingModule } from './promotion-routing.module';
import { PromotionDetailsComponent } from './details/promotion-details.component';
import { SharedModule } from '../../shared/shared.module';
import { PromotionFiltersComponent } from './filters/promotion-filters.component';
import { PromotionService } from './promotion.service';
import { PromotionFiltersService } from './filters/promotion-filters.service';
import { VideoModule } from '../../shared/video/video.module';
import { PromotionGridComponent } from './grid/promotion-grid.component';
import { ParamsListService } from './params.service';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		PromotionRoutingModule,
		HttpModule,
		VideoModule
	],
	declarations: [
		PromotionComponent,
		PromotionFiltersComponent,
		PromotionDetailsComponent,
		PromotionGridComponent
	],
	providers: [
		PromotionService,
		PromotionFiltersService,
		ParamsListService
	],
	exports: [PromotionComponent]
})

export class PromotionModule {
}

