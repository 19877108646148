import { Injectable } from '@angular/core';
import { Company, State } from '../../shared/registration-user';
import { HttpClient } from '../../../../core/http/http-client';
import { ConfigService } from '../../../../core/config.service';
import { LoggerService } from '../../../../core/logger/logger.service';
import { URLSearchParams } from '@angular/http';

@Injectable()
export class CompanyService {

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	getCompanies(state: State, postalCode: string): Promise<Company[]> {
		let params: URLSearchParams = new URLSearchParams();
		params.set('state', state.LocationState);
		params.set('zip', postalCode);

		return this.http.get(this.configService.baseUrl + '/api/RegistrationAccount', {search: params})
			.toPromise()
			.then(res => {
				return res.json().map(company => new Company(company));
			})
			.catch(error => {
				this.logger.error(error);
				return null;
			});
	}
}
