import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LogoDownloadComponent } from './logo-download.component';
import { SharedModule } from '../../../../shared/shared.module';

@NgModule({
	imports: [
		SharedModule,
		RouterModule
	],
	declarations: [
		LogoDownloadComponent
	],
	exports: [
		LogoDownloadComponent
	]
})

export class LogoDownloadModule {
}