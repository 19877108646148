import { Component } from '@angular/core';
import { OrderInfo } from './order.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	template: require('./order.component.html')
})

export class OrderComponent {
	orderInfo: OrderInfo;
	isRequestSubmitted: boolean = false;

	constructor(private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.route.data.forEach((data: {orderInfo: OrderInfo}) => {
			this.orderInfo = data.orderInfo;
		})
	}

	back(): void {
		this.isRequestSubmitted = false;
	}

	displaySubmittedMessage(feedback): void {
		this.isRequestSubmitted = true;
	}
}