import { Route } from '@angular/router';

export interface WizardStep {
    name: string;
    order: number;
    route: Route;
}

export function getWizardRoutes(wizardSteps: WizardStep[], stepsResolve: any) {
    return wizardSteps.map((step: WizardStep) => {
        let route = step.route;
	    appendCommonResolves(route, stepsResolve);
        return route;
    });
}

function appendCommonResolves(route: Route, stepsResolve: any) {
    route.resolve || (route.resolve = {});
	route.resolve['step'] = stepsResolve;
}