import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { UploadComponent } from './upload.component';
import { UploadBoxComponent } from './shared/upload-box.component';
import { FileUploadModule } from 'ng2-file-upload';
import { UploadRoutingModule } from './upload-routing.module';
import { UploadService } from './upload.service';
import { UploadComponentForm } from './upload-form/upload-form.component';
import { ImagePreview } from './shared/image-preview.directive';

@NgModule({
	imports: [
		SharedModule,
		FileUploadModule,
		UploadRoutingModule
	],
	declarations: [
		UploadComponent,
		UploadBoxComponent,
		UploadComponentForm,
		ImagePreview
	],
	providers: [
		UploadService
	],
	exports: [
		UploadComponent
	]
})
export class UploadModule {
}