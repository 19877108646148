import { Component, Input, HostBinding } from '@angular/core';

@Component({
	selector: 'vcm-target-roles',
	template: require('./target-roles.component.html')
})

export class TargetRolesComponent {
	@HostBinding('class.vcm-target-roles') addClass: boolean = true;
	@Input() targetRoles: string[] = [];
	hideImages: boolean[] = [];

	hideImg(index: number) {
		this.hideImages[index] = true;
	}
}