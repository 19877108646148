import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { LoadingMaskService } from './loading-mask.service';

@Component({
	selector: 'vcm-loading-mask',
	template: require('./loading-mask.component.html'),
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingMaskComponent implements OnInit {
	showMask: boolean;

	constructor(public service: LoadingMaskService,
	            private ref : ChangeDetectorRef) {}

	ngOnInit(): void {
		this.service.onChange.subscribe((showMask) => {
			this.showMask = showMask;
			this.ref.markForCheck();
		});
	}
}