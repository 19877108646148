import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { RegistrationService } from '../../registration.service';

@Injectable()
export class RegistrationStepFinalGuard implements CanActivate {

	constructor(private registrationService: RegistrationService,
	            private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.registrationService.userSubmitted) {
			return true;
		}

		this.router.navigate(['registration', 'step1']);
		return false;
	}
}