export class SignalsHeadend {
	HeadendId: number;
	HeadendName: string;
	Units: Unit[];
}

export class SignalsHeadendDescription {
	HeadendId: string;
	HeadendName: string;
	HeadendCity: string;
}

export class DCIIGridPageModel {
	GridData: SignalsDciiHeadend[];
	SurveyStatusIdFilterList: any;
	CustomerSupportStatusIdFilterList: any;
	HeadendNameFilterList: string[]
}

export class SignalsDciiHeadend {
	AffiliateComments: string;
	BizopsComments: string;
	AffiliateServicesComments: string;
	ContactEmail: string;
	ContactName: string;
	ContactPhone: string;
	CustomerSupportNotes: string;
	CustomerSupportRepId: string;
	CustomerSupportStatusId: string;
	CustomerSupportStatusName: string;
	DCIIUserRole: string;
	DeliveryNotes: string;
	Devices: DciiDevices[];
	DigitalOutputTypes: {};
	DistributorName: string;
    IsListAccurate: boolean;
	HdMpegVersion: boolean;
	HdMpegVersionNonDC2: string;
	HeadendAddress: string;
	HeadendCity: string;
	HeadendCountry: string;
	HeadendCountryName: string;
	HeadendId: number;
	HeadendName: string;
	HeadendRegion: string;
	HeadendSegment: string;
	HeadendState: string;
	HeadendStateName: string;
	HeadendZip: string;
	IsAnalogCueTones: boolean;
	IsAnalogRequired: boolean;
	IsShippingTheSameAdress: boolean;
	IsTranscodeMpeg2: boolean;
	IsTranscodedOutput: boolean;
	IsUseArris: string;
	MSOCode: string;
	Orders: Orders[];
	SatellitesAccessed: SatellitesAccessed[];
	ServicesPerUnit: number;
	ShippingAddress: string;
	ShippingCountry: string;
	ShippingCity: string;
	ShippingState: string;
	ShippingZip: string;
	SurveyStatusId: number;
	SurveyStatusName: string;
	SurveyId: number;
	UssiComments: string;
}

export class SignalsDciiDropdowns {
	Countries: {};
	CustomerRep: {};
	CustomerStatus: {};
	AccurateQuestion: {};
	States: {};
	YesNo: {};
	DeviceTypes: {};
	IrdSources: {};
}

export class DciiDevices {
	DeviceCode: string;
    DeviceTypeId: string;
	ACPAddress: string;
	ServiceName: string;
	FeedName: string;
	ConfigLicense: string;
}

export class DciiParams {
	dciiHeadendId?: number;
}

export class YesNo {
	Id: boolean;
	Description: string;
}

export class AccurateQuestion {
	Id: boolean;
	Description: string;
}

export class MPEGVersion {
	Id: boolean;
	Description: string;
}

export class DevTypesList {
	Id: string;
	Description: string;
}


export class CountriesList {
	Id: string;
	Description: string;
}

export class ShippingAddress {
	AddressId: number;
	City: string;
	Co: string;
	ContactId: number;
	Country: string;
	Name: string;
	PostalCode: string;
	State: string;
	Street1: string;
	Street2: string;
}

export class Country {
	CountryCode: string;
	CountryName: string;
	LocationState: string;
	StateName: string;

	constructor(data = null) {
		if (data) {
			this.CountryCode = data.CountryCode;
			this.CountryName = data.CountryName;
			this.LocationState = data.LocationState;
			this.StateName = data.StateName;
		}
	}
}

export class State {
	LocationState: string;
	StateName: string;
	CountryCode: string;
	CountryName: string;

	constructor(data = null) {
		if (data) {
			this.CountryCode = data.CountryCode;
			this.CountryName = data.CountryName;
			this.LocationState = data.LocationState;
			this.StateName = data.StateName;
		}
	}
}

export class Orders {
	OrderId: number;
	OrderType: number;
	OrderStatusName: string;
	OrderStatusId: number;
	SurveyId: number;
	IsConfirmed: boolean;
	DeviceTypes: DeviceTypes[];
	POs: POs[];
	Shippings: Shippings[];
	IsCancelled: boolean;
	IsFulFilled: boolean;
}

export class Shippings {
	CallId: string;
	ReceivedDate: string;
	SalesOrder: string;
	ShippingCommets: string;
	ShippingId: string;
	TrackingNo: string;
	OutDate: string;
}

export class DeviceTypes {
	ConfigLicense: string;
	DeviceTypeId: number;
	Devices: Devices[];
	Qty: number;
}

export class POs {
	POAmount: number;
	POComments: string;
	PODate: string;
	POId: number;
	PONumber: string;
	PRNumber: string
}

export class Devices {
	ACPAddress: string;
	DeviceCode: string;
	DeviceId: number;
	IrdSource: number;
	UnitPrice: number;
}

export class SatellitesAccessed {
	Id: boolean;
	Description: string;
}

export class Unit {
	UnitAddress: string;
	Acps: Acp[];
}

export class Acp {
	AcpId: number;
	AcpAddress: string;
	Status: number;
	ServiceName: string;
	FeedName: string;
	ActivationDate: string;
	DropDate: string;
	RequestDropDate: string;
}

export class HeadendGridRow extends Acp {
	UnitAddress: string;
	VisibleUnitAddress: boolean;
	rowColor: boolean = false;

	createHeadendGrid(acp: Acp, num: number, unitAddress: string) {
		this.AcpId = acp.AcpId;
		this.AcpAddress = acp.AcpAddress;
		this.Status = acp.Status;
		this.ServiceName = acp.ServiceName;
		this.FeedName = acp.FeedName;
		this.ActivationDate = acp.ActivationDate;
		this.DropDate = acp.DropDate;
		this.RequestDropDate = acp.RequestDropDate;
		this.UnitAddress = unitAddress;
		this.VisibleUnitAddress = (num == 0) ? true : false;
	}

	setRowColor(prevRow: HeadendGridRow, newRow: HeadendGridRow) {
		if (prevRow.UnitAddress == newRow.UnitAddress) {
			this.rowColor = prevRow.rowColor
		} else {
			this.rowColor = !prevRow.rowColor
		}
	}
}

export class SignalsAction {
	shownString: string;
	value: string;
	route: string[];
	needService?: boolean;
	disabled?: boolean;
}
