import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SignalsDciiHeadend } from '../signals';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { DciiGridService } from './dcii-grid.service';

@Injectable()
export class DciiGridResolve implements Resolve<SignalsDciiHeadend[]> {
	constructor(private service: DciiGridService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<SignalsDciiHeadend[]> {
		this.mask.addLoading();
		return this.service.getDciiGrid()
			.map((dciiGrid: SignalsDciiHeadend[]) => {
				this.mask.removeLoading();
				return dciiGrid;
			});
	}
}
