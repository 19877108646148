import { Injectable } from '@angular/core';
import { Response, ResponseContentType } from '@angular/http';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { ConfigService } from '../../../core/config.service';
import { HttpClient } from '../../../core/http/http-client';
// import { ProgrammingHighlights } from '../programming-highlights';
import { LoggerService } from '../../../core/logger/logger.service';
import * as FileSaver from 'file-saver';
import {LiveGridShow} from "../live-grid/live-grid-show";

@Injectable()
export class PremieresListService {
	private subject: Subject<number> = new Subject<number>();
	private _serviceId: number;

	get serviceId(): number {
		return this._serviceId;
	}

	private url = this.configService.baseUrl + '/api/LiveGrid/?premiereOnly=true&chanelCode=';

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private logger: LoggerService) {
	}

	setServiceId(id: number) {
		this._serviceId = id;
		this.subject.next(this._serviceId);
	}
	//
	// getHighlightId(): Observable<number> {
	// 	let source = Observable.empty();
	//
	// 	return source.startWith(this._highlightId).concat(this.subject);
	// }

	getPremieresList(id: string): Observable<LiveGridShow[]> {
		this.setServiceId(+id || -1);
		return this.http.get(this.url + id)
			.map((res: Response) => {
				return res.text() ? res.json() : null;
			}).catch((err) => {
				this.logger.error(err);
				return Observable.of(null);
			});
	}

	clearParams(): void {
		this.setServiceId(null);
	}

	private getDownloadUrl(fileType: string, brand: number) {
		return this.configService.baseUrl + `/api/Export${fileType}Livegrid?chanelCode=${brand}&premiereOnly=true`;
	}

	exportToFile(fileType: string, brand: number): Promise<Response> {
		return this.http.get(this.getDownloadUrl(fileType, brand), {responseType: ResponseContentType.Blob})
			.toPromise()
			.then(res => {
				this.downloadFile(res);
				return res;
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	downloadFile(data: Response) {
		let blob = new Blob([data.blob()], {type: 'application/octet-stream'});
		let filename = 'livegrid.xlsx';

		if (data.headers.has('Content-Disposition')) {
			let cdHeader = data.headers.get('Content-Disposition');
			filename = cdHeader.slice(cdHeader.indexOf('"') + 1, -1);
		}

		FileSaver.saveAs(blob, filename);
	}

}
