import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationService } from '../../authorization.service';

@Component({
	template: require('./authorization-step-final.component.html')
})

export class AuthorizationStepFinalComponent implements OnInit, OnDestroy {
	isRequestSavedSuccessfully: boolean;

	constructor(private authorizationService: AuthorizationService) {
	}

	ngOnInit() {
		this.isRequestSavedSuccessfully = this.authorizationService.isRequestSavedSuccessfully;
	}

	ngOnDestroy() {
		this.authorizationService.authorizationRequestSubmitted = false;
	}
}