import { Injectable } from '@angular/core';
import { BusinesArea } from '../../shared/registration-user';
import { HttpClient } from '../../../../core/http/http-client';
import { ConfigService } from '../../../../core/config.service';
import { LoggerService } from '../../../../core/logger/logger.service';

@Injectable()
export class BusinessAreasService {
	private businessAreas: BusinesArea[];

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	getBusinessAreas(): Promise<BusinesArea[]> {
		if (this.businessAreas) {
			return Promise.resolve(this.businessAreas);
		}
		return this.loadBusinessAreas();
	}

	private loadBusinessAreas(): Promise<BusinesArea[]> {
		return this.http.get(this.configService.baseUrl + '/api/RegistrationBusinessArea')
			.toPromise()
			.then(res => {
				this.businessAreas = res.json().map(businessArea => new BusinesArea(businessArea));
				return this.businessAreas;
			})
			.catch(error => {
				this.logger.error(error);
				return null;
			});
	}
}
