import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
	selector: 'vcm-back-link',
	template: '<a (click)="backLocation()" class="btn vcm_btn btn-default">BACK</a>'
})

export class BackLinkComponent {
	constructor(private location: Location) {
	}

	public backLocation() {
		this.location.back();
	}
}

