import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({selector: 'img[imgPreview]'})

export class ImagePreview implements OnChanges {
	@Input() file: File;
	defaultImagePreview: string = '/app_assets/images/no_img.jpg';

	constructor(private el: ElementRef) { }

	ngOnChanges(changes: SimpleChanges) {
		this.createImagePreview(this.file);
	}

	private createImagePreview(file?: File) {
		if (!file || !file.type.includes("image") || file.type.includes("image/svg")) {
			this.el.nativeElement.src = this.defaultImagePreview;
			return;
		}

		let reader = new FileReader();
		this.readFile(file, reader, (data) => {
			this.el.nativeElement.src = data;
		})
	}

	private readFile(file, reader, callback) {
		reader.onload = () => {
			callback(reader.result);
		};
		reader.readAsDataURL(file);
	}
}