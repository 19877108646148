import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthorizationService, unmask } from '../../authorization.service';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationRequest, AuthorizationServiceUplink } from '../../shared/authorization-request';
import { HeadendSummary } from '../../../shared/headend-summary.service';

@Component({
	template: require('./authorization-step3.component.html')
})

export class AuthorizationStep3Component implements OnInit {
	public authorizationRequest: AuthorizationRequest;
	public summary: HeadendSummary;

	@ViewChild('step3') currentForm: NgForm;

	constructor(private authorizationService: AuthorizationService,
	            private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: {summary: HeadendSummary}) => {
			this.authorizationRequest = this.authorizationService.authorizationRequest;
			this.summary = data.summary;
			this.authorizationService.currentForm = this.currentForm;
		});
		this.authorizationRequest.SelectedServices = this.authorizationRequest.SelectedServices
            .filter((service: AuthorizationServiceUplink) => {
				return service.AcpAddress != null || service.FeedCode != null || service.FeedName != null || service.ServiceId != null || service.ServiceName != null
					|| service.ServiceType != null || service.ServiceTypeDescription != null || service.SubsLaunched != null || service.ISE != null;
			});
		this.authorizationService.beforePreviousStep = this.beforePreviousStep.bind(this);
		this.authorizationService.beforeNextStep = this.beforeNextStep.bind(this);
	}

	private beforePreviousStep(): void {
		this.authorizationRequest.SelectedServices = null;
	}

	private beforeNextStep(): void {
		let selectedServices: AuthorizationServiceUplink[];

		selectedServices = this.authorizationRequest.SelectedServices
			.filter((servise: AuthorizationServiceUplink) => {
				return servise.StatusConfirmed === true;
			});

		this.formatDataRequest(selectedServices);
	}

	private formatDataRequest(selectedServices: AuthorizationServiceUplink[]): void {
		this.authorizationRequest.BasicSubs = +this.authorizationRequest.BasicSubs;
		this.authorizationRequest.DigitalSubs = +this.authorizationRequest.DigitalSubs;

		if (this.authorizationRequest.Anchor) {
			this.authorizationRequest.Anchor = unmask(this.authorizationRequest.Anchor);
		}
		if (this.authorizationRequest.FF_HeadendAnchor) {
			this.authorizationRequest.FF_HeadendAnchor = unmask(this.authorizationRequest.FF_HeadendAnchor);
		}

		this.authorizationRequest.SelectedServices = this.formatServices(selectedServices);

		this.authorizationRequest.EngineerEmail = this.summary.EngineerEmail;
		this.authorizationRequest.EngineerName = this.summary.EngineerName;
		this.authorizationRequest.EngineerPhone = this.summary.EngineerPhone;
		this.authorizationRequest.HeadendAddress = this.summary.HeadendAddress;
		this.authorizationRequest.HeadendCity = this.summary.HeadendCity;
		this.authorizationRequest.HeadendMSO = this.summary.HeadendMSO;
		this.authorizationRequest.HeadendName = this.summary.HeadendName;
		this.authorizationRequest.HeadendState = this.summary.HeadendState;
		this.authorizationRequest.HeadendZip = this.summary.HeadendZip;
	}

	private formatServices(selectedServices: AuthorizationServiceUplink[]): AuthorizationServiceUplink[] {
		selectedServices.forEach((service: AuthorizationServiceUplink) => {
			if (service.AcpAddress) {
				service.AcpAddress = unmask(service.AcpAddress);
			}
			service.SubsLaunched = +service.SubsLaunched;
		});

		return selectedServices;
	}
}