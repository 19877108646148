import { NgModule } from '@angular/core';
import { OrdersService } from './orders.service';
import { OrdersComponent } from './orders.component';
import { SharedModule } from '../../shared/shared.module';
import { OrdersRoutingModule } from './orders-routing.module';

@NgModule({
	imports: [
		SharedModule,
		OrdersRoutingModule
	],
	declarations: [
		OrdersComponent
	],
	providers: [
		OrdersService
	]
})

export class OrdersModule {
}


