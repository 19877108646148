import { Injectable } from '@angular/core';

export class ParamsList {
	Brand: string;
	Type: string;
	Objective: string;
	Keyword: string;
	PeriodFrom: string;
	PeriodTo: string;

	PromoId: string;
	Spot: string;
}

@Injectable()
export class ParamsListService {
	paramsList: ParamsList = new ParamsList();

	getFilters(params: any): ParamsList {
		let filters: ParamsList;
		this.paramsList.Brand = params.Brand || null;
		this.paramsList.Type = params.Type || null;
		this.paramsList.Objective = params.Objective || null;
		this.paramsList.Keyword = params.Keyword || null;
		this.paramsList.PeriodFrom = params.PeriodFrom || null;
		this.paramsList.PeriodTo = params.PeriodTo || null;
		return filters;
	}

	resetFilters(): void {
		this.paramsList.Brand = null;
		this.paramsList.Type = null;
		this.paramsList.Objective = null;
		this.paramsList.Keyword = null;
		this.paramsList.PeriodFrom = null;
		this.paramsList.PeriodTo = null;
	}

	resetPromo(): void {
		this.paramsList.PromoId = null;
		this.paramsList.Spot = null;
	}

	removeEmptyParams(): any {
		let clearedParams: ParamsList = this.paramsList;
		for (let key in clearedParams) {
			if (clearedParams.hasOwnProperty(key) && !clearedParams[key]) {
				delete clearedParams[key];
			}
		}
		return clearedParams;
	}

	setNewPromo(promoId: string): any {
		this.resetPromo();
		this.paramsList.PromoId = promoId;
		return this.removeEmptyParams();
	}
}