import { Component, EventEmitter, Output, Input } from '@angular/core';
import { LoadingMaskService } from '../../../../../shared/loading-mask/loading-mask.service';
import { HeadendSummary } from '../../shared/headend-summary.service';
import { AddRequestFormService } from './add-request-form.service';
import { SignalSource, SignalService, SignalDecoder, DropdownsService } from './dropdowns.service';

@Component({
	selector: 'vcm-add-request-form',
	template: require('./add-request-form.component.html')
})

export class AddRequestFormComponent {
	public details: string[] = [];
	public anchorMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
	public trackingMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

	selectedSource: SignalSource[];
	selectedService: SignalService[];
	selectedDecoder: SignalDecoder[];

	commentMessage: string = null;
	motorollaAnchor: string = '000-';
	trackingId: string;

	address: string;
	headendName: string;
	signalServices: SignalService[];

	@Input() headendSummary: HeadendSummary;
	@Input() headendId: string;
	@Input() signalSources: SignalSource[];
	@Input() signalDecoders: SignalDecoder[];

	@Output() feedbackSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private addRequestFormService: AddRequestFormService,
	            private dropdownsService: DropdownsService,
	            private mask: LoadingMaskService) {
	}

	onSubmit(): void {
		this.mask.addLoading();
		let request = Object.assign({
			HeadendId: this.headendId,
			SignalSource: this.selectedSource,
			Service: this.selectedService,
			DecoderType: this.selectedDecoder,
			UnitAddress: this.unmask(this.trackingId) || this.unmask(this.motorollaAnchor),
			CityState: this.address,
			Comments: this.commentMessage
		}, this.headendSummary);
		request.HeadendName = this.headendName || request.HeadendName;

		this.addRequestFormService.submitRequest(request)
			.then((resp) => {
				this.mask.removeLoading();
				this.feedbackSubmitted.emit(resp);
				window.scrollTo(0, 0);
			});
	}

	getServices(signalSourceCode: string, decoderType?: string) {
		this.selectedService = null;
		this.dropdownsService.getDropdowns(signalSourceCode, decoderType)
			.then(res => {
				this.signalServices = res;
			})
	}

	selectDecoder(signalSourceCode: string, decoderType: string) {
		this.motorollaAnchor = '000-';
		this.trackingId = null;
		this.getServices(signalSourceCode, decoderType);
	}

	private unmask(value?: string): string {
		return value ? value.replace(/\D+/g, '') : null;
	}

}
