import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
	selector: '[acpEqualValidator][formControlName],[acpEqualValidator][formControl],[acpEqualValidator][ngModel]',
	providers: [
		{provide: NG_VALIDATORS, useExisting: forwardRef(() => AcpAddressEqualValidator), multi: true}
	]
})
export class AcpAddressEqualValidator implements Validator {
	@Input('name') private formControlName;

	constructor() {}

	validate(control: AbstractControl): {[key: string]: any} {
		let value = control.value;
		let formValue = control.parent.value;
		let equalAcpAddressNumber = 0;

		for (let prop in formValue) {
			if (value && prop.includes('AcpAddress') && prop != this.formControlName
				&& formValue[prop] === value) {
				equalAcpAddressNumber++;
			}
		}

		if (!value || equalAcpAddressNumber > 0) {
			return {acpAddressEqualExist: true}
		}

		return null;
	}
}