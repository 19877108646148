import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { ConfigService } from '../../../core/config.service';
import { LoggerService } from '../../../core/logger/logger.service';

@Injectable()
export class EnterEmailService {
	constructor(private http: Http,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	sendEmail(email): Promise<boolean> {
		let url = this.configService.baseUrl + '/api/ForgotPassword?email=' + email;

		return this.http.put(url, {})
			.toPromise()
			.then(res => {
				return res.json();
			})
			.catch((err) => {
				this.logger.error(err);
				return false;
			});
	}
}


