import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AUTHORIZATION_STEPS } from './authorization-routing.module';
import { AuthorizationRequest } from './shared/authorization-request';
import { AuthorizationStepsService } from './authorization-steps.service';
import { WizardStep } from '../../../../shared/wizard/wizard-step';

@Injectable()
export class AuthorizationService {
	authorizationRequestSubmitted: boolean = false;
	isRequestSavedSuccessfully: boolean = false;
	private _authorizationRequest: AuthorizationRequest = new AuthorizationRequest();
	private _currentForm: NgForm;
	private _availableSteps: WizardStep[] = [];

	constructor(private stepsService: AuthorizationStepsService) {
	}

	beforeNextStep(): void {
	}

	beforePreviousStep(): void {
	}

	clear() {
		this._authorizationRequest = new AuthorizationRequest();
		this._availableSteps = [];
		this._currentForm = null;
	}

	get currentForm() {
		return this._currentForm;
	}

	set currentForm(value: NgForm) {
		this._currentForm = value;
	}

	get availableSteps(): WizardStep[] {
		return this._availableSteps;
	}

	addAvailableStep(stepName: WizardStep) {
		this._availableSteps.push(stepName);
	}

	get authorizationRequest(): AuthorizationRequest {
		return this._authorizationRequest;
	}

	get canGoNext(): boolean {
		return this._currentForm && this._currentForm.valid;
	}

	manageAdditionalStep(isAdd: boolean, stepName: string, stepOrder: number = -1) {
		if (this.shouldAdd(isAdd, stepName)) {
			let additionalStep = this.findStep(stepName);
			additionalStep.order = stepOrder;
			this.stepsService.insertStep(additionalStep, stepOrder);
		} else if (!isAdd) {
			this.stepsService.removeStep(this.findStep(stepName));
		}
	}

	hasStep(stepName: string): boolean {
		let step = this.findInSteps(this.stepsService.getSteps(), stepName);
		return !!step;
	}

	private findStep(stepName: string): WizardStep {
		return this.findInSteps(AUTHORIZATION_STEPS, stepName);
	}

	private findInSteps(steps: WizardStep[], stepName: string) {
		return steps.find((step: WizardStep) => {
			return step.name === stepName
		});
	}

	private shouldAdd(isAdd: boolean, stepName: string) {
		return isAdd && !this.hasStep(stepName);
	}
}

export function unmask(value?: string): string {
	return value ? value.replace(/\D+/g, '') : null;
}