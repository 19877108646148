import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../../authorization.service';
import {
	AuthorizationRequest, AuthorizationServiceUplink,
	AuthorizationGridData, AuthorizationTransponder
} from '../../shared/authorization-request';
import { HeadendSummary } from '../../../shared/headend-summary.service';
import { AuthorizationGrigService } from './authorization-grid.service';

@Component({
	template: require('./authorization-step2.component.html')
})

export class AuthorizationStep2Component implements OnInit {
	authorizationRequest: AuthorizationRequest;
	gridData: AuthorizationGridData;
	summary: HeadendSummary;

	@ViewChild('step2') currentForm: NgForm;

	constructor(private authorizationService: AuthorizationService,
	            private authorizationGrigService: AuthorizationGrigService,
	            private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: {gridData: AuthorizationGridData, summary: HeadendSummary}) => {
			this.authorizationRequest = this.authorizationService.authorizationRequest;
			this.authorizationRequest.DecoderModel = data.gridData.UnitType;
			this.summary = data.summary;
			this.gridData = data.gridData;
			this.authorizationService.currentForm = this.currentForm;
		});
		this.authorizationGrigService.updateServicesCheckedNumber();
		this.authorizationService.beforePreviousStep = this.beforePreviousStep.bind(this);
		this.authorizationService.beforeNextStep = this.beforeNextStep.bind(this);
	}

	private beforePreviousStep(): void {
		this.authorizationGrigService.clearGrigData();
	}

	private beforeNextStep(): void {
		this.authorizationRequest.SelectedServices = this.getCheckedServices();
	}

	private getCheckedServices(): AuthorizationServiceUplink[] {
		let checkedServices: AuthorizationServiceUplink[] = [];
		this.gridData.Transponders.forEach((transponder: AuthorizationTransponder) => {
			transponder.Services.forEach((service: AuthorizationServiceUplink) => {
				if (typeof service.Status !== "string" && service.Status) {
					checkedServices.push(service);
				}
			})
		});

		return checkedServices;
	}
}
