import { NgModule } from '@angular/core';
import { RelatedContentComponent } from './related-content.component';
import { RelatedContentService } from './related-content.service';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'ng2-bootstrap';
import { RouterModule } from '@angular/router';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		DropdownModule.forRoot()
	],
	declarations: [
		RelatedContentComponent
	],
	providers: [
		RelatedContentService
	],
	exports: [
		RelatedContentComponent
	]
})
export class RelatedContentModule {
}

