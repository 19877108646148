import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadingMaskService } from '../../shared/loading-mask/loading-mask.service';
import { Promotion, PromotionService } from './promotion.service';
import { ParamsListService } from './params.service';
import { ObjectUtils } from '../../core/object-utils';

@Injectable()
export class PromotionResolve implements Resolve<Promotion[]> {
	constructor(private service: PromotionService,
	            private mask: LoadingMaskService,
	            private paramsListService: ParamsListService) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<Promotion[]> {
		let params: any = route.params;
		this.paramsListService.paramsList = ObjectUtils.deepCopy(params);
		this.mask.addLoading();
		return this.service.getPromotions(params)
			.map((promotions: Promotion[]) => {
				this.mask.removeLoading();

				return promotions;
			});
	}
}