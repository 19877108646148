import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ProfileAddress } from '../../profile-address';

@Component({
	selector: 'vcm-address-block',
	template: require('./address-block.component.html')
})

export class AddressesBlockComponent {
	@Input() address: ProfileAddress;
	@Input() showButtons: boolean = true;

	@Output() private delete: EventEmitter<ProfileAddress> = new EventEmitter<ProfileAddress>();

	deleteAction() {
		this.delete.emit(this.address);
	}
}