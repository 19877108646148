import { NgModule } from '@angular/core';
import { PressComponent } from './press.component';
import { PressService } from './press.service';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [
		PressComponent
	],
	providers: [
		PressService
	]
})

export class PressModule {
}


