import { Directive, ElementRef, Input, HostListener, Renderer, AfterViewInit } from '@angular/core';
import { SlimScrollDirective } from 'ng2-slimscroll/src/directives/slimscroll.directive';

const ARROWS_HEIGHT = 30;
const LIST_ITEM_HEIGHT = 30;

@Directive({
	selector: '[slimScrollArrows][slimScroll]'
})

export class SlimscrollArrowsDirective implements AfterViewInit {
	@Input() isOpen: boolean = true;
	@Input('slimScrollArrows') slimScroll: SlimScrollDirective;

	private arrowDown: Node;
	private arrowUp: Node;
	private barDisplay: string;
	private parent: Node;

	constructor(private el: ElementRef,
	            private renderer: Renderer) {
		setTimeout(() => {
			this.getBarHeight();
		}, 0);
	}

	ngAfterViewInit() {
		this.slimScroll.getBarHeight = this.getBarHeight.bind(this);
		this.slimScroll.scrollContent = this.scrollContent.bind(this);
		this.parent = this.el.nativeElement.parentNode;
		this.arrowUp = this.createElement('vcm_arrow_up');
		this.arrowDown = this.createElement('vcm_arrow_down');
	}

	createElement(className: string): Node {
		let divElement = this.renderer.createElement(this.el.nativeElement, 'span');
		this.renderer.setElementClass(divElement, className, true);
		return this.parent.insertBefore(divElement, this.el.nativeElement);
	}

	private isScrollDisabled(event: WheelEvent): boolean {
		let context = this.slimScroll;
		let maxTop = context.el.offsetHeight - context.bar.offsetHeight - ARROWS_HEIGHT;
		let delta = parseInt(getComputedStyle(context.bar).top, 10);

		return (event.deltaY < 0 && delta === 0) || (event.deltaY > 0 && delta === maxTop);
	}

	private getBarHeight(): void {
		let context = this.slimScroll;
		setTimeout(() => {
			let barHeight = ((context.el.offsetHeight - ARROWS_HEIGHT) / context.el.scrollHeight) * context.el.offsetHeight + 'px';
			this.barDisplay = Math.abs(context.el.offsetHeight - ARROWS_HEIGHT - parseInt(barHeight, 10)) <= 1 ? 'none' : 'block';

			this.renderer.setElementStyle(context.bar, 'height', barHeight);
			this.renderer.setElementStyle(context.bar, 'display', this.barDisplay);
			this.renderer.setElementStyle(context.grid, 'display', this.barDisplay);
			this.renderer.setElementStyle(this.arrowUp, 'display', this.barDisplay);
			this.renderer.setElementStyle(this.arrowDown, 'display', this.barDisplay);

			if (this.barDisplay === 'block') {
				context.wrapper.parentElement.classList.add('vcm_scroll_shown');
			} else {
				context.wrapper.parentElement.classList.remove('vcm_scroll_shown');
			}
		}, 1);
	}

	private scrollContent(y: number, isWheel: boolean, isJump: boolean): void {
		let context = this.slimScroll;
		let delta: number;
		let maxTop = context.el.offsetHeight - context.bar.offsetHeight - ARROWS_HEIGHT;
		let percentScroll: number;
		let bar = context.bar;
		let el = context.el;

		if (isWheel) {
			if (y === 0) {
				delta = parseInt(getComputedStyle(bar).top, 10);
				percentScroll = delta / (el.offsetHeight - bar.offsetHeight - ARROWS_HEIGHT) * (el.scrollHeight - el.offsetHeight);
				el.scrollTop = percentScroll;
			} else {
				el.scrollTop = el.scrollTop + y * LIST_ITEM_HEIGHT;
				delta = el.scrollTop / (el.scrollHeight - el.offsetHeight) * (el.offsetHeight - bar.offsetHeight - ARROWS_HEIGHT);
			}
		}

		if (isJump) {
			el.scrollTop = el.scrollTop + LIST_ITEM_HEIGHT * y;
			delta = el.scrollTop / (el.scrollHeight - el.offsetHeight) * (el.offsetHeight - bar.offsetHeight - ARROWS_HEIGHT)
		}

		delta = Math.min(Math.max(delta, 0), maxTop);
		this.renderer.setElementStyle(bar, 'top', delta + 'px');
	}

	@HostListener('document:click', ['$event', '$event.target'])
	onClick(event: MouseEvent, targetElement: HTMLElement) {
		if (!targetElement || !this.parent.contains(targetElement)) {
			return;
		}

		if (targetElement.classList.contains('vcm_arrow_up')) {
			this.scrollContent(-1, false, true)
		}

		if (targetElement.classList.contains('vcm_arrow_down')) {
			this.scrollContent(1, false, true)
		}

		if (targetElement.classList.contains('slimscroll-grid')) {
			if (event.clientY > this.slimScroll.bar.getBoundingClientRect().bottom) {
				this.scrollContent(5, false, true)
			} else {
				this.scrollContent(-5, false, true)
			}
		}
	}

	@HostListener('wheel', ['$event'])
	handleWheelEvent(event: WheelEvent): void {
		if (this.barDisplay === 'block' && !this.isScrollDisabled(event)) {
			this.scrollContent(event.deltaY > 0 ? 1 : -1, true, false);
			event.preventDefault();
			event.stopPropagation();
		}
	}
}
