import { NgModule } from '@angular/core';
import { SlimscrollArrowsDirective } from './slimscroll-arrows.directive';
import { ClickOutsideDirective } from './click-outside.ditective';
import { SlimScrollModule } from 'ng2-slimscroll';
import { DefaultImageValidator } from './default-image.directive';
import { IfNotAuthenticatedDirective } from '../../core/auth/if-not-authenticated.directive';
import { IfAuthenticatedDirective } from '../../core/auth/if-authenticated.directive';

@NgModule({
	imports: [
		SlimScrollModule
	],
	declarations: [
		ClickOutsideDirective,
		SlimscrollArrowsDirective,
		DefaultImageValidator,
		IfAuthenticatedDirective,
		IfNotAuthenticatedDirective,
	],
	exports: [
		ClickOutsideDirective,
		SlimscrollArrowsDirective,
		SlimScrollModule,
		DefaultImageValidator,
		IfAuthenticatedDirective,
		IfNotAuthenticatedDirective,
	]
})

export class DirectivesModule {
}
