import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Country } from '../../shared/registration-user';
import { CountryService } from '../../../../core/country.service';

@Injectable()
export class CountryResolve implements Resolve<Country[]> {
	constructor(private service: CountryService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<Country[]> {
		return this.service.getCountries();
	}
}
