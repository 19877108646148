import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DropdownModule, DatepickerModule } from 'ng2-bootstrap';
import { DatepickerDropdownComponent } from './datepicker-dropdown.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DatepickerModule.forRoot(),
		DropdownModule.forRoot()
	],
	declarations: [
		DatepickerDropdownComponent
	],
	exports: [
		DatepickerDropdownComponent
	]
})
export class DatepickerDropdownModule {
}
