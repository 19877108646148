import {
	Component, Input, Output, EventEmitter, forwardRef, ChangeDetectorRef, ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { coerceBooleanProperty } from '../boolean-property';
import { SlimScrollDirective } from 'ng2-slimscroll/src/directives/slimscroll.directive';

export const SELECT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => SelectComponent),
	multi: true
};

export class SelectChange {
	source: SelectComponent;
	value: any[];
}

@Component({
	selector: 'vcm-select',
	template: require('./select.component.html'),
	providers: [SELECT_CONTROL_VALUE_ACCESSOR],
	host: {
		'(document:keydown)': 'onKeyDown($event)'
	}
	// changeDetection: ChangeDetectionStrategy.OnPush  //Fixed in newest version https://github.com/angular/material2/pull/2894
})

export class SelectComponent implements ControlValueAccessor {
	@Input('defaultValue') defaultValue: string;
	@Input('displayName') displayName: string;
	@Input('label') label: string;
	@Input('name') name: string;
	@Input('options') options: any[];
	@Input('trackBy') trackBy: string;

	@Output() change: EventEmitter<SelectChange> = new EventEmitter<SelectChange>();
	@ViewChild('slimScroll') slimScroll: SlimScrollDirective;

	isFocused: boolean = false;
	isOpen: boolean = false;

	@Input()
	get disabled(): boolean {
		return this._disabled;
	}

	set disabled(value) {
		this._disabled = coerceBooleanProperty(value);
	}

	private _disabled: boolean = false;
	private selectedObject: any;
	private selectScrollTop: number;
	private searchStr: string = '';

	private _controlValueAccessorChangeFn: (value: any) => void = () => {
	};
	private onTouched: () => any = () => {
	};

	constructor(private ref: ChangeDetectorRef) {
	}

	isOptionSelected(option): boolean {
		return this.selectedObject === option;
	}

	getDisplayedName(option: any) {
		return this.displayName ? option[this.displayName] : option;
	}

	getOptionIndex(option: any): number {
		let optionIndex = -1;
		if (option) {
			this.options.forEach((opt, i) => {
				if (this.getOptionValue(opt) === this.getOptionValue(option)) {
					optionIndex = i;
				}
			});
		}
		return optionIndex;
	}

	getOptionValue(option: any) {
		return this.trackBy ? option[this.trackBy] : option;
	}

	getSelectedOptionsText() {
		return this.selectedObject ? this.getDisplayedName(this.selectedObject) : this.defaultValue;
	}

	selectOption(option: any, close: boolean = true) {
		if (option && option.disabled) {
			return;
		}
		this.selectedObject = option;

		if (close) {
			this.closeSelect();
			this.searchStr = '';
		}
		this.onTouched();

		this._emitChangeEvent();
	}

	closeSelect(): void {
		if (this.isOpen) {
			this.isOpen = false;
			this.selectScrollTop = this.slimScroll.el.scrollTop;
		}
	}

	toggleSelect(): void {
		if (!this._disabled) {
			if (this.isOpen) {
				this.closeSelect();
			} else {
				this.isOpen = true;
				this.initScrollContent();
			}
		}
	}

	private initScrollContent(): void {
		if (this.isOpen) {
			this.slimScroll.getBarHeight();
			setTimeout(() => {
				this.slimScroll.el.scrollTop = this.selectScrollTop;
			}, 0);
		}
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	writeValue(selectedObject: any = null) {
		this.selectedObject = selectedObject;
		this.ref.markForCheck();
		if (this.selectedObject) {
			this._controlValueAccessorChangeFn(this.selectedObject);
		}
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	registerOnChange(fn: (value: any) => void) {
		this._controlValueAccessorChangeFn = fn;
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	onKeyDown(event: KeyboardEvent) {
		if (!this.isOpen && !this.isFocused) {
			return;
		}

		event.stopPropagation();

		let optionIndex = this.getOptionIndex(this.selectedObject);

		if (event.key == 'ArrowDown') {
			optionIndex = optionIndex + 1 < this.options.length ? optionIndex + 1 : optionIndex;
			this.selectedObject = this.options[optionIndex];

			if (!this.isOpen) {
				this.selectOption(this.selectedObject);
			}

			return;
		}

		if (event.key == 'ArrowUp') {
			optionIndex = optionIndex > 0 ? optionIndex - 1 : 0;
			this.selectedObject = this.options[optionIndex];

			if (!this.isOpen) {
				this.selectOption(this.selectedObject);
			}

			return;
		}

		if (event.key === 'Enter') {
			this.selectOption(this.selectedObject);
		}

		this.searchStr = this.searchStr + event.key;

		let option = this.options.find((option) => {
			return new RegExp('^' + this.escapeRegExp(this.searchStr), 'i').test(this.getDisplayedName(option));
		});

		if (option) {
			this.selectOption(option, false);
		} else {
			this.searchStr = event.key;
			let option = this.options.find((option) => {
				return new RegExp('^' + this.escapeRegExp(this.searchStr), 'i').test(this.getDisplayedName(option));
			});

			if (option) {
				this.selectOption(option, false);
			}
		}
	}

	private _emitChangeEvent() {
		let event = new SelectChange();
		event.source = this;
		event.value = this.selectedObject;

		this._controlValueAccessorChangeFn(this.selectedObject);
		this.change.emit(event);
	}

	private escapeRegExp(str: string) {
		const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
		const reHasRegExpChar = RegExp(reRegExpChar.source);

		return (str && reHasRegExpChar.test(str))
			? str.replace(reRegExpChar, '\\$&')
			: str;
	}
}
