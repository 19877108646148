import { Injectable } from '@angular/core';
import { WizardStepsService } from '../../../../shared/wizard/wizard-step.service';
import { Router } from '@angular/router';
import { AUTHORIZATION_STEPS } from './authorization-routing.module';

@Injectable()
export class AuthorizationStepsService extends WizardStepsService {

	constructor(router: Router) {
		super(router, 'signals/authorization/', AUTHORIZATION_STEPS);
	}
}