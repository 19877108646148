import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
// import { ProgrammingHighlights } from '../programming-highlights';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { PremieresListService } from "./premieres-list.service";
import {LiveGridShow} from "../live-grid/live-grid-show";

@Injectable()
export class PremieresListResolve implements Resolve<LiveGridShow[]> {

	constructor(private service: PremieresListService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<LiveGridShow[]> {
		let id: string = route.params['brand'];
		this.mask.addLoading();
		return this.service.getPremieresList(id)
			.map((premieres: LiveGridShow[]) => {
				this.mask.removeLoading();
				return premieres;
			});
	}
}
