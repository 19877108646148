import { Component, Input, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';
import { DaysRange } from '../../days-range';
import Moment = moment.Moment;

@Component({
	template: require('./datepicker-selector.component.html'),
	selector: 'vcm-datepicker-selector'
})

export class DatepickerSelectorComponent {
	@Input() set selectedRange(selectedRange: DaysRange) {
		this._selectedRange = selectedRange;
		this.selectedDate = this.getSelectedDate(this._selectedRange);
	}

	@Input() set availableRanges(availableRanges: DaysRange[]) {
		this._availableRanges = availableRanges;
		this.minDate = this.getMinDate(this._availableRanges);
		this.maxDate = this.getMaxDate(this._availableRanges);
		this._selectedRange = this._selectedRange || this._availableRanges[0];
		this.selectedDate = this.getSelectedDate(this._selectedRange);
	}

	@Output() onRangeChange: EventEmitter<DaysRange> = new EventEmitter<DaysRange>();

	selectedDate: Date;
	minDate: Date;
	maxDate: Date;

	isOpen: boolean = false;

	private _selectedRange: DaysRange;
	private _availableRanges: DaysRange[];

	changeSelectedDate(date: Date): void {
		this.isOpen = false;
		this.updateSelectedRange(date);
	}

	private updateSelectedRange(date: Date) {
		let selectedDate = moment(date);
		for (let i = 0; i < this._availableRanges.length; i++) {
			let range = this._availableRanges[i];
			if (selectedDate.isSame(range.firstDay, 'day')) {
				this.onRangeChange.emit(range);
				break;
			}
		}
	}

	private getSelectedDate(selectedRange: DaysRange): Date {
		return selectedRange.firstDay.toDate();
	}

	private getMinDate(ranges: DaysRange[]): Date {
		let firstDay = ranges[0].firstDay;
		return firstDay.toDate();
	}

	private getMaxDate(ranges: DaysRange[]): Date {
		let lastDay = ranges[ranges.length - 1].lastDay;
		return lastDay.toDate();
	}
}