import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../../core/auth/user.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'vcm-last-login',
	template: `<div  *ngIf="loginDate" class="vcm_last_login_block"><div>Last login: <span>{{loginDate|date:"MM/dd/yy"}}</span></div></div>`
})
export class LastLoginComponent implements OnInit, OnDestroy {
	public loginDate: Date;

	private userSubscription: Subscription;

	constructor(private userService: UserService) {
	}

	ngOnInit(): void {
		this.loginDate = this.getLoginDate();
		this.userSubscription = this.userService.onChange.subscribe(() => {
			this.loginDate = this.getLoginDate();
		});
	}

	ngOnDestroy(): void {
		this.userSubscription.unsubscribe();
	}

	private getLoginDate(): Date {
		return this.userService.hasCurrentUser() ? this.userService.getCurrentUser().LastLoginDate : null;
	}
}