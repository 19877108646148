import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
	selector: '[notEmptySelectedServices]',
	providers: [
		{provide: NG_VALIDATORS, useExisting: forwardRef(() => NotEmptySelectedServicesValidator), multi: true}
	]
})

export class NotEmptySelectedServicesValidator implements Validator {
	constructor() {}

	validate(control: AbstractControl): {[key: string]: any} {
		let formValue: any = control.value;
		let selectedServicesNumber: number = 0;

		for (let prop in formValue) {
			if (formValue[prop] && typeof formValue[prop] === "boolean") {
				selectedServicesNumber++;
			}
		}

		if (!formValue || selectedServicesNumber === 0) {
			return {validateNotEmpty: false}
		}

		return null;
	}
}