import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logo } from './logo.service';
import { AssetsService } from '../../../core/assets.service';

@Component({
	template: require('./logo.component.html')
})

export class LogoComponent {

	logos: Logo[] = [];

	constructor(private route: ActivatedRoute,
	            private assetsService: AssetsService) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: { logo: Logo[] }) => {
			this.logos = data.logo;
		});
	}

	getAssetsURL(assetsPath: string): string {
		return this.assetsService.getAssetUrl(assetsPath);
	}
}
