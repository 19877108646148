import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SocialMedia, ImgDetails } from '../social-media.service';
import { ActiveFiltersService } from '../../filters/active-filters.service';
import { Video } from '../../../../shared/video/video';
import { SocialMediaDetailsService } from './social-media-details.service';
import { LoadingMaskService } from '../../../../shared/loading-mask/loading-mask.service';

@Component({
	template: require('./social-media-details.component.html')
})

export class SocialMediaDetailsComponent {
	socialMediaElement: SocialMedia;
	currentSocialMediaId: number;

	selectedImg: string = null;
	selectedVideo: Video = null;
	videoPlayerIsActive: boolean = false;

	selectedFilters: any;

	isImgLoading: boolean = true;
	isEmailSent: boolean = false;
	sendingEmailStatus: boolean = true;
	imagesLoading: boolean = false;

	constructor(private route: ActivatedRoute,
	            private activeFilters: ActiveFiltersService,
	            private service: SocialMediaDetailsService,
	            private mask: LoadingMaskService) {}

	ngOnInit(): void {
		this.route.data.forEach((data: { socialMediaElement: SocialMedia }) => {
			this.selectedFilters = this.activeFilters.getActiveFilters();
			this.socialMediaElement = data.socialMediaElement;
			this.selectedImg = this.socialMediaElement.ImagesSM.length > 0 ? this.socialMediaElement.ImagesSM[0].UrlImage : null;
			this.currentSocialMediaId = this.service.currentSocialMediaId;
		});
	}

	downloadImages(): void {
		this.imagesLoading = true;
		this.service.downloadImages().then(() => this.imagesLoading = false);
	}

	sendDetails(): void {
		this.mask.addLoading();
		this.service.sendDetails().then((response) => {
			this.mask.removeLoading();
			this.isEmailSent = true;
			this.sendingEmailStatus = response.json();
			window.scroll(0, 0);
		});
	}

	selectGalleryVideo(video: Video): void {
		if (this.playHere(video)) {
			this.selectedVideo = video;
			this.selectedImg = null;
			this.videoPlayerIsActive = true;
		}
	}

	selectGalleryImg(image: ImgDetails): void {
		if (this.selectedImg != image.UrlImage) {
			this.isImgLoading = true;
			this.selectedImg = image.UrlImage;
			this.selectedVideo = null;
		}
	}

	twitterLink(tweetText: string): string {
		return 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(tweetText);
	}

	playHere(video: Video): boolean {
		return (video.Src.indexOf('mp4') > -1) || (video.Src.indexOf('mov') > -1);
	}

	checkResourceToShow(array1: any[], array2?: any[]): boolean {
		return array2 ? !!(array1.length + array2.length) : !!(array1.length)
	}

	isOnload(): void {
		this.isImgLoading = false;
	}

	backToSocialMediaDetails(): void {
		this.isEmailSent = false;
	}
}