import { Component, Input } from '@angular/core';

export class LandingAvailAlerts {
	Id: number;
	ServiceLongname: string;
	ContentTitle: string;
	AvailAlertText: string;
	MakegoodText: string;
}

@Component({
	template: require('./landing-avail-alert.component.html'),
	selector: 'vcm-landing-avail-alert'
})

export class LandingAvailAlertComponent {
	@Input() resources: any;
}
