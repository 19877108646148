import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/auth/auth.guard';
import { PromotionComponent } from './promotion.component';
import { PromotionResolve } from './promotion-resolve.service';
import { PromotionFiltersResolve } from './filters/promotion-filters-resolve.service';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'promotion',
				component: PromotionComponent,
				canActivate: [AuthGuard],
				resolve: {
					promotions: PromotionResolve,
					filters: PromotionFiltersResolve
				}
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		PromotionResolve,
		PromotionFiltersResolve
	]
})
export class PromotionRoutingModule {
}
