import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { AvailAlertsComponent } from './avail-alerts.component';
import { AvailAlertsService } from './avail-alerts.service';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [
		AvailAlertsComponent
	],
	providers: [
		AvailAlertsService
	]
})

export class AvailAlertsModule {
}


