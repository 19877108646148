import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class NotAuthGuard implements CanActivate {

	constructor(private router: Router,
	            private userService: UserService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (!this.userService.hasCurrentUser()) {
			// allowed only when user is not logged in
			return true;
		}
		// otherwise redirect to home page
		this.router.navigate(['/home']);
		return false;
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivate(childRoute, state);
	}
}