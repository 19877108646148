import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Certification } from './certification.service';
import {LogResourceDownloadService} from "../log-resource-download.service";

@Component({
	template: require('./certification.component.html')
})

export class CertificationComponent {
	certifications: Certification[] = [];

	constructor(private route: ActivatedRoute, private resourceDownloadLogger: LogResourceDownloadService) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: { certifications: Certification[] }) => {
			this.certifications = data.certifications;
		});
	}

	logDownload(servicesShortName: string, downloadUrl: string): void {
		this.resourceDownloadLogger.log(servicesShortName, 'certification', encodeURIComponent(downloadUrl));
	}
}
