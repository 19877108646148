import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RedirectComponent } from './redirect.component';
import { RedirectRoutingModule } from './redirect-routing.module';

@NgModule({
	imports: [
		SharedModule,
		RedirectRoutingModule
	],
	declarations: [
		RedirectComponent
	],
	exports: [
		RedirectComponent
	]
})

export class RedirectModule {
}