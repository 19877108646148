import { Injectable } from '@angular/core';
import { TermsPageModel } from './terms-page.model';
import { ConfigService } from '../../core/config.service';
import { HeadersClient } from '../../core/http/headers-client';
import { Http, Response, RequestOptionsArgs } from '@angular/http';
import { Observable } from 'rxjs';
import { LoggerService } from '../../core/logger/logger.service';

@Injectable()

export class TermsPageService {
	private url = this.configService.baseUrl + '/api/staticpage?landingType=TERMS_OF_USE';
	private termsPageModel: TermsPageModel;

	constructor(private configService: ConfigService,
	            private headers: HeadersClient,
	            private http: Http,
	            private logger: LoggerService) {
	}

	getTermsPageModel(): Observable<TermsPageModel> {
		if (this.termsPageModel) {
			return Observable.of(this.termsPageModel);
		} else {
			return this.loadTermsPageModel();
		}
	}

	private loadTermsPageModel(): Observable<TermsPageModel> {
		let options: RequestOptionsArgs = this.headers.getRequestOptions();
		return this.http.get(this.url, options)
			.map((res: Response) => {
				this.termsPageModel = res.json();
				return this.termsPageModel;
			}).catch((err) => {
				this.logger.error(err);
				return Observable.of(null);
			});
	}
}