import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { VideoModule } from '../../../shared/video/video.module';
import { TechSpecsComponent } from './tech-specs.component';
import { TechSpecsService } from './tech-specs.service';
import { TechSpecsFilterOptionsService } from './tech-specs-filter/tech-specs-filter-options.service';
import { TechSpecsFilterComponent } from './tech-specs-filter/tech-specs-filter.component';
import { LandingModule } from '../../view/landing/landing.module';

@NgModule({
	imports: [
		SharedModule,
		RouterModule,
		VideoModule,
		LandingModule
	],
	declarations: [
		TechSpecsComponent,
		TechSpecsFilterComponent
	],
	providers: [
		TechSpecsService,
		TechSpecsFilterOptionsService
	]
})

export class TechSpecsModule {
}