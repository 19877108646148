import { NgModule } from '@angular/core';
import { LandingGridComponent } from './landing-grid.component';
import { LandingGridService } from './landing-grid.service';
import { LandingAvailAlertComponent } from './landing-resources/avail-alert/landing-avail-alert.component';
import { LandingPhComponent } from './landing-resources/ph/landing-ph.component';
import { LandingLogoComponent } from './landing-resources/logo/landing-logo.component';
import { LandingLogoDownloadComponent } from './landing-resources/logo/logo-download/landing-logo-download.component';
import { LandingCertificationComponent } from './landing-resources/certification/landing-certification.component';
import { LandingChannelDescriptionComponent } from './landing-resources/channel-description/landing-channel-description.component';
import { LandingPressComponent } from './landing-resources/press/landing-press.component';
import { LandingPromotionComponent } from './landing-resources/promotion/landing-promotion.component';
import { SharedModule } from '../../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { LandingOtherComponent } from './landing-resources/other/landing-other.component';

@NgModule({
	imports: [
		SharedModule,
		RouterModule
	],
	declarations: [
		LandingGridComponent,
		LandingAvailAlertComponent,
		LandingPhComponent,
		LandingLogoComponent,
		LandingLogoDownloadComponent,
		LandingCertificationComponent,
		LandingChannelDescriptionComponent,
		LandingPressComponent,
		LandingPromotionComponent,
		LandingOtherComponent
	],
	providers: [
		LandingGridService
	],
	exports: [
		LandingGridComponent,
		LandingAvailAlertComponent,
		LandingPhComponent,
		LandingLogoComponent,
		LandingLogoDownloadComponent,
		LandingCertificationComponent,
		LandingChannelDescriptionComponent,
		LandingPressComponent,
		LandingPromotionComponent,
		LandingOtherComponent
	]
})

export class LandingGridModule {
}


