import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RegistrationService } from '../../registration.service';
import { ActivatedRoute } from '@angular/router';
import { RegistrationUser, Company, Office } from '../../shared/registration-user';
import { OfficeService } from './office.service';

export const NOT_SELECTED_ID = 99999999;

@Component({
	template: require('./registration-step3.component.html')
})

export class RegistrationStep3Component implements OnInit {
	public user: RegistrationUser;
	public companies: Company[] = [];
	public offices: Office[] = [];
	public canEdit: boolean = false;
	public officesLoading: boolean = false;

	public notListedCompanyOption = new Company({
		AccountId: NOT_SELECTED_ID,
		Name: '-- Other - Enter Details --'
	});
	public notListedOfficeOption = new Office(NOT_SELECTED_ID, '-- Other - Enter Address --');

	@ViewChild('step3') currentForm: NgForm;

	constructor(private registrationService: RegistrationService,
	            private officeService: OfficeService,
	            private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: {companies: Company[], offices: Office[]}) => {
			this.user = this.registrationService.user;
			this.companies = data.companies || [];
			this.insertNotListedOption(this.companies, this.notListedCompanyOption);
			this.offices = data.offices;
			if (this.offices.length > 0) {
				this.insertNotListedOption(this.offices, this.notListedOfficeOption);
			}
			this.registrationService.currentForm = this.currentForm;
		});
	}

	public updateOffices(company?: Company): void {
		this.offices = [];
		this.clearForm();
		if (!company) {
			return;
		}
		if (!this.isNotListedCompanyOptionSelected()) {
			this.user.companyName = company.Name;
			this.getCompanyOffices(company);
			this.canEdit = false;
		} else {
			this.user.companyName = null;
			this.user.office = this.notListedOfficeOption;
			this.canEdit = true;
		}
	}

	public isNotListedCompanyOptionSelected(): boolean {
		return this.user.company && this.user.company.AccountId === this.notListedCompanyOption.AccountId;
	}

	public isNotListedOfficeOptionSelected(): boolean {
		return this.user.office && this.user.office.ContactId === this.notListedOfficeOption.ContactId;
	}

	public officeOnChange(office?: Office) {
		if (!office) {
			this.clearForm();
			return;
		}
		if (this.isNotListedOfficeOptionSelected()) {
			this.user.address = '';
			this.user.city = '';
			this.canEdit = true;
		} else {
			this.user.address = office.AdressLine1;
			this.user.city = office.AdressCity;
			this.canEdit = false;
		}
	}

	private getCompanyOffices(company: Company) {
		this.officesLoading = true;
		this.officeService.getOffices(company, this.user.state, this.user.postalCode)
			.then((offices: Office[]) => {
				this.officesLoading = false;
				if (!offices) {
					return;
				}
				this.offices = offices;
				this.insertNotListedOption(this.offices, this.notListedOfficeOption);
			});
	}

	private insertNotListedOption(options: any[], notListedOption: any) {
		options.push(notListedOption);
	}

	private clearForm() {
		this.user.office = null;
		this.user.address = '';
		this.user.city = '';
	}
}