import { NgModule }     from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/auth/auth.guard';
import { SearchComponent } from './search.component';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'search',
				component: SearchComponent,
				canActivate: [AuthGuard]
			}
		])
	],
	exports: [
		RouterModule
	]
})
export class SearchRoutingModule {
}
