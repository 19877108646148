import { Component } from '@angular/core';
import { HeadendSummary } from '../shared/headend-summary.service';
import { DropReason } from './drop-headend-form/reason.service';
import { SignalsService } from '../../signals.service';
import { ActivatedRoute } from '@angular/router';
import { Acp } from '../../signals';

@Component({
	template: require('./drop-headend.component.html')
})

export class DropHeadendComponent {
	isRequestSubmitted: boolean = false;
	headendId: number;
	headendSummary: HeadendSummary;
	reasons: DropReason[];
	services: Acp[];

	constructor(private signalsService: SignalsService,
	            private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.headendId = this.signalsService.headendId;
		this.services = this.signalsService.acps;
		this.route.data.forEach((data: {
			reasons: DropReason[],
			headendSummary: HeadendSummary
		}) => {
			this.reasons = data.reasons;
			this.headendSummary = data.headendSummary;
		});
	}

	displayFeedbackSubmittedMessage(feedback): void {
		this.isRequestSubmitted = true;
	}
}
