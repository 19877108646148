import { Injectable, EventEmitter } from '@angular/core';
import { User, UserInfo } from './user';

const USER_STORAGE_KEY = 'currentUser';

@Injectable()
export class UserService {
	onChange: EventEmitter<User> = new EventEmitter<User>();

	private currentUser: User;
	private userInfo: UserInfo = new UserInfo();

	getCurrentUser(): User {
		if (!this.currentUser && localStorage.hasOwnProperty(USER_STORAGE_KEY)) {
			this.currentUser = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));
		}
		return this.currentUser ? this.getUserCopy() : null;
	}

	getUserInfo(): UserInfo {
		return this.userInfo;
	}

	hasCurrentUser(): boolean {
		return !!this.currentUser || localStorage.hasOwnProperty(USER_STORAGE_KEY);
	}

	removeCurrentUser(): void {
		this.currentUser = null;
		localStorage.removeItem(USER_STORAGE_KEY);
		this.onChange.emit(this.currentUser);
	}

	setCurrentUser(user: User, userInfo: UserInfo = new UserInfo()): void {
		this.currentUser = user;
		this.userInfo = userInfo;
		localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(this.currentUser));
		this.onChange.emit(this.currentUser);
	}

	private getUserCopy(): User {
		return Object.assign({}, this.currentUser);
	}
}