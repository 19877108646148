import { Component, Input, EventEmitter, Output } from '@angular/core';
import { OrderInfo, OrderService } from '../order.service';
import { ActivatedRoute } from '@angular/router';
import { AccountField, StateField, Market, AccountFieldRequest } from './accounts.service';
import { ArrayUtils } from '../../../core/object-utils';
import * as moment from 'moment';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';

@Component({
	selector: 'vcm-order-form',
	template: require('./order-form.component.html')
})

export class OrderFormComponent {
	public TWO_WEEKS = 13 * 24 * 60 * 60 * 1000;
	public subscribersMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,];

	@Input() orderInfo: OrderInfo;
	@Output() requestSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();
	accountRequest: AccountFieldRequest = new AccountFieldRequest();

	accounts: AccountField[];
	selectedAccount: AccountField;
	states: StateField[];
	markets: Market[];
	marketName: string;

	FlightStart: Date;
	FlightEnd: Date;
	flightEndMax: Date;
	flightStartMin: Date;
	flightStartMax: Date;

	constructor(private route: ActivatedRoute,
	            private orderService: OrderService,
	            private mask: LoadingMaskService) {
	}

	ngOnInit(): void {
		this.orderService.orderRequest.setAuto(this.orderInfo);

		this.accounts = this.orderInfo.Accounts;
		this.updateAccount();

		this.FlightStart = moment(this.orderInfo.FlightStart).toDate();
		this.flightStartMin = this.FlightStart;
		this.flightStartMax = moment(this.orderInfo.FlightEnd).toDate();
		this.flightEndMax = new Date(this.FlightStart.getTime() + this.TWO_WEEKS);
		this.FlightEnd = this.flightEndMax;
	}

	updateFlightEnd(date: Date): void {
		this.FlightStart = date;
		this.flightEndMax = new Date(this.FlightStart.getTime() + this.TWO_WEEKS);
		this.FlightEnd = this.flightEndMax;
	}

	updateAccount(selectedAccount?: AccountField): void {
		if (!selectedAccount) {
			return
		}
		this.selectedAccount = ArrayUtils.findByProperty(this.accounts, 'AccountID', this.selectedAccount.AccountID);
		this.accountRequest.AccountID = this.selectedAccount.AccountID;
		this.accountRequest.AccountName = this.selectedAccount.AccountName;
		this.accountRequest.MarketDMA = this.accountRequest.MarketDMADefault;

		this.updateCountry();
		this.updateStates();
		this.updateMarkets();
		this.updateSubscribers();
	}

	updateCountry(): void {
		this.accountRequest.Country = this.selectedAccount.Country;
	}

	updateStates(): void {
		this.states = this.selectedAccount.States;
	}

	updateSubscribers(): void {
		this.accountRequest.Subscribers = this.selectedAccount.Subscribers || null;
	}

	updateMarkets(): void {
		this.markets = this.selectedAccount.MarketDMA;
		this.accountRequest.MarketDMADefault = this.selectedAccount.MarketDMADefault;
	}

	MarketNameOnChange(name: string): void {
		let nullMarket: Market = {
			Id: -1,
			Name: ''
		};
		this.accountRequest.MarketDMA = nullMarket;
		this.accountRequest.MarketDMA.Name = name;
	}

	submit() {
		this.orderService.orderRequest.Account = this.accountRequest;
		this.mask.addLoading();
		this.orderService.sendOrder().then((resp) => {
			this.mask.removeLoading();
			this.requestSubmitted.emit(resp.ok);
			window.scrollTo(0, 0);
		});
	}
}
