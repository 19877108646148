import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class UploadUrlIdResolve implements Resolve<string> {
	resolve(route: ActivatedRouteSnapshot): string {
		let urlId: string = route.params['urlID'];
		return urlId;
	}
}
