import { Component, Input } from '@angular/core';

@Component({
	template: require('./landing-ph.component.html'),
	selector: 'vcm-landing-ph'
})

export class LandingPhComponent {
	@Input() resources: any;

	createIdDetailsLink(program: number): string {
		return '/programming/highlights/' + program;
	}
}
