import { Component } from '@angular/core';
import { ProgrammingHighlights } from '../programming-highlights';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgrammingHighlightsListService } from './programming-highlights-list.service';
import { Highlight } from '../dropdowns/highlights.service';
import { ArrayUtils } from '../../../core/object-utils';

@Component({
	template: require('./programming-highlights-list.component.html'),
	selector: 'vcm-highlights'
})

export class ProgrammingHighlightsListComponent {
	highlightId: number;

	highlights: Highlight[];

	highlightsAll: Highlight[] = [
		{
			Highlights: 'All',
			ServiceId: 0
		}
	];

	programmingHighlightsList: ProgrammingHighlights[] = [];
	selectedHighlight: Highlight;
	selectedPhDetails: ProgrammingHighlights = null;
	selectedPhDetailsId: number = null;

	constructor(private route: ActivatedRoute,
	            private router: Router,
	            private service: ProgrammingHighlightsListService) {}

	ngOnInit() {
		this.route.data.forEach((data: {
			programmingHighlightsList: ProgrammingHighlights[],
			highlights: Highlight[]}) => {
			this.highlights = this.highlightsAll.concat(data.highlights);
			this.programmingHighlightsList = data.programmingHighlightsList;
			this.highlightId = this.service.highlightId;
			if (this.highlightId == -1) {
				this.selectedHighlight = this.highlights[0]
			} else {
				this.selectedHighlight = ArrayUtils.findByProperty(this.highlights, 'ServiceId', this.highlightId)
			}
		});
	}

	selectPh(ph: ProgrammingHighlights): void {
		this.selectedPhDetails = ph;
		this.selectedPhDetailsId = ph.ProgramminghighlightsId;
	}

	pageTitle(programmingHighlightsList: ProgrammingHighlights[]): string {
		let s = programmingHighlightsList.length > 1 ? 's' : '';
		return '' + programmingHighlightsList.length + ' PROGRAM' + s + ' FOUND'
	}

	createIdDetailsLink(program: number): string {
		return '/programming/highlights/' + program;
	}

	goToProgrammingHighlightsList(selectedHighlight?: Highlight): void {
		let id = selectedHighlight.ServiceId;
		this.router.navigate(['programming', 'highlights', id ? {brand: id} : {}]);
	}

	exportToFile(fileType: string) {
		this.service.exportToFile(fileType, this.selectedHighlight.ServiceId, false)
	}
}