import { Injectable } from '@angular/core';
import { HttpClient } from '../../core/http/http-client';
import { ConfigService } from '../../core/config.service';
import { Response } from '@angular/http';
import { AccountField, AccountFieldRequest, StateField, Market } from './order-form/accounts.service';
import { LoggerService } from '../../core/logger/logger.service';

export class OrderSpot {
	SpotID: number;
	SpotName: string;
	StartDate: Date;
	EndDate: Date;
	SpotFormat: string;
	SpotType: string;

}
export class DeliveryField {
	Code: string;
	Description: string;
}

export class ShippingAddress {
	addressID: number;
	name: any;
	co: string;
	street1: string;
	street2: string;
	city: string;
	state: string;
	country: string;
	postalCode: string;
}

export class OrderInfo {
	ExtPromotionId: number;
	PromotionId: number;
	FName: string;
	LName: string;
	Company: string;
	Accounts: AccountField[];
	Country: string;
	FlightStart: Date;
	FlightEnd: Date;
	PrimaryPhone: string;
	PromoSpots: any[];
	Delivery: DeliveryField[];
	CurrentShippingAddress: ShippingAddress;
	ShippingAddresses: ShippingAddress[];
}

export class OrderRequest {
	ExtPromotionId: number;
	PromotionId: number;
	FName: string;
	LName: string;
	Company: string;
	Account: AccountFieldRequest;
	FlightStart: Date;
	FlightEnd: Date;
	PrimaryPhone: string;
	PromoSpots: number[];
	Delivery: DeliveryField;
	CurrentShippingAddress: ShippingAddress;
	Information: string;

	setAuto(orderInfo: OrderInfo): void {
		this.ExtPromotionId = orderInfo.ExtPromotionId;
		this.PromotionId = orderInfo.PromotionId;
		this.FName = orderInfo.FName;
		this.LName = orderInfo.LName;
		this.Company = orderInfo.Company;
		this.FlightStart = orderInfo.FlightStart;
		this.FlightEnd = orderInfo.FlightEnd;
		this.PrimaryPhone = orderInfo.PrimaryPhone;
		this.Delivery = orderInfo.Delivery[0];
		this.CurrentShippingAddress = orderInfo.CurrentShippingAddress;
		this.PromoSpots = [];
	}

	setAccounts(selectedAccount: AccountField,
	            selectedState?: StateField) {
		this.setAccount(selectedAccount);
		this.setCountry(selectedAccount);
	}

	setAccount(selectedAccount: AccountField) {
		this.Account.AccountID = selectedAccount.AccountID;
		this.Account.AccountName = selectedAccount.AccountName;
	}

	setCountry(selectedAccount: AccountField) {
		this.Account.Country = selectedAccount.Country;
	}

	checkSpot(spotID) {
		let promoInList = this.PromoSpots.indexOf(spotID);
		(promoInList != -1) ?
			this.PromoSpots.slice(promoInList, 1) :
			this.PromoSpots.push(spotID);
	}

}

@Injectable()
export class OrderService {
	private urlGet = this.configService.baseUrl + '/api/PromotionSignUP?promotionId=';
	private urlPost = this.configService.baseUrl + '/api/PromotionSignUP';

	orderInfo: OrderInfo;
	checkedSpots: boolean[] = [];
	orderRequest: OrderRequest = new OrderRequest();
	isSubmitted: boolean = null;

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private logger: LoggerService) {
	}

	createSpotList(): void {
		let spotList: number[] = [];
		for (let i = 0; i < this.checkedSpots.length; i++) {
			if (this.checkedSpots[i]) {
				spotList.push(this.orderInfo.PromoSpots[i].SpotID)
			}
		}
		this.orderRequest.PromoSpots = spotList;
	}

	getNewOrder(id): Promise<OrderInfo> {
		return this.http.get(this.urlGet + id)
			.toPromise()
			.then(res => {
				this.orderInfo = res.json();
				return res.json();
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	sendOrder(): Promise<Response> {
		this.createSpotList();
		return this.http.post(this.urlPost, this.orderRequest)
			.toPromise()
			.then(res => {
				this.orderRequest = new OrderRequest();
				if (res.json()) {
					this.isSubmitted = true
				}
				return res;
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

}
