import { Input, Component, OnInit } from '@angular/core';
import { MdDialog, MdDialogRef } from '@angular/material';

import { UserService } from '../../../core/auth/user.service';
import { AuthenticationService } from './../../../core/auth/authentication.service';
import { UserNewsDialog } from './user-news-dialog.component';

@Component({
	selector: 'vcm-user-news',
	template: ''
})
export class UserNewsComponent implements OnInit {
	dialogRef: MdDialogRef<UserNewsDialog>;

	constructor(
		public dialog: MdDialog,
		private userService: UserService,
		private authService: AuthenticationService
	) {
		this.authService.onLogout.subscribe(() => {
			if (this.dialogRef) {
				this.dialogRef.close();
			}
		});
	}

	ngOnInit() {
		if (this.shouldShowNews()) {
			this.openDialog();
		}
	}

	private shouldShowNews() {
		let userInfo = this.userService.getUserInfo();

		return userInfo && userInfo.userNews && userInfo.userNews.length > 0;
	}

	private openDialog() {
		setTimeout(() => {
			this.dialogRef = this.dialog.open(UserNewsDialog, {
				disableClose: false,
				position: { top: '0px', left: '0px' } 
			});
		})

	}
}