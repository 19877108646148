import { Injectable } from '@angular/core';
import { URLSearchParams, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './../../../core/auth/authentication.service';
import { HttpClient } from './../../../core/http/http-client';
import { ConfigService } from './../../../core/config.service';
import { LoggerService } from '../../../core/logger/logger.service';
import { Video } from '../../../shared/video/video';

export class Link {
	Text: string;
	Url: string;
}

export class Block {
	ImageUrl: string;
	Links: Link[];
	Text: string;
	Title1: string;
	Title2: string;
}

export class Module {
	Blocks: Block[];
	CarouselItems: CarouselItem[];
	DynamicModule: string;
	Html: string;
	IsCarousel: boolean;
	IsDynamicModule: boolean;
	IsHtml: boolean;
	IsVideo: boolean;
	ItemType: string;
	Position: number;
	Rotation: string;
	Title: string;
	VideoItems: Video[]
}

export class Landing {
	ImageUrl: string;
	SwfUrl: string;
	Title: string;
	Modules: Module[];
}

interface LandingParams {
	landingType: string,
	serviceId?: string
}

export class CarouselItem {
	RedirectUrl: string;
	Text: string;
	ImageUrl: string;
}

@Injectable()
export class LandingService {
	private url = this.configService.baseUrl + '/api/LandingPage/GetLandingPage';
	private cache: Map<string, Landing> = new Map<string, Landing>();

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private authService: AuthenticationService,
	            private logger: LoggerService) {
		this.authService.onLogout.subscribe(() => {
			this.cache.clear();
		});
	}

	getLanding(data: any): Observable<Landing> {
		let params: LandingParams = {
			landingType: data.landingType,
			serviceId: data.serviceId
		};

		let searchParams: URLSearchParams = new URLSearchParams();
		searchParams.set('landingType', params.landingType);
		searchParams.set('serviceId', params.serviceId);

		if (this.cache.has(searchParams.toString())) {
			return Observable.of(this.cache.get(searchParams.toString()));
		} else {
			return this.loadLanding(searchParams);
		}
	}

	private loadLanding(searchParams: URLSearchParams): Observable<Landing> {
		return this.http.get(this.url, {
			search: searchParams
		}).map((res: Response) => {
			let result = res.text() ? res.json() : null;
			this.cache.set(searchParams.toString(), result);
			return result;
		}).catch((err) => {
			this.logger.error(err);
			return Observable.of(null);
		});
	}
}