import * as FileSaver from 'file-saver';
import { Injectable } from '@angular/core';
import { ResourcesService } from '../resources.service';
import { Response, ResponseContentType } from '@angular/http';
import { HttpClient } from '../../../core/http/http-client';
import { ConfigService } from '../../../core/config.service';
import { LoggerService } from '../../../core/logger/logger.service';

export class TechSpec {
	id: number;
	Brands: string;
	EquipmentCompatibility: string;
	Feed: string;
	Satellite: string;
	Transponder: string;
}

@Injectable()
export class TechSpecsService {

	constructor(private resourcesService: ResourcesService,
	            private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {}

	private url = this.configService.baseUrl;

	private createExcelLink(): string {
		return this.url + '/api/ExportExcelTS'
	}

	private createMailLink(): string {
		return this.url + '/api/ExportTSEmail'
	}

	getTechSpecs(filter: any = {}): Promise<TechSpec[]> {
		filter.resourceType = 'TECHSPECS';
		return this.resourcesService.getResourcesList(filter);
	}

	exportToExcel(idArray: number[]): Promise<Response> {
		return this.http.post(this.createExcelLink(), idArray, {responseType: ResponseContentType.Blob})
			.toPromise()
			.then(res => {
				this.downloadFile(res);
				return res;
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	sendDetails(Ids: number[]): Promise<Response> {
		return this.http.post(this.createMailLink(), Ids)
			.toPromise()
			.then(res => {
				return res;
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	downloadFile(data: Response) {
		let blob = new Blob([data.blob()], {type: 'application/octet-stream'});
		let filename = 'TechSpecs.xlsx';

		if (data.headers.has('Content-Disposition')) {
			let cdHeader = data.headers.get('Content-Disposition');
			filename = cdHeader.slice(cdHeader.indexOf('"') + 1, -1);
		}

		FileSaver.saveAs(blob, filename);
	}

}