import { Component, Input } from '@angular/core';
import { SignalsHeadend, HeadendGridRow, SignalsAction } from '../../signals';
import { Router } from '@angular/router';
import { ArrayUtils } from '../../../../core/object-utils';
import { SignalsService } from '../../signals.service';

@Component({
	template: require('./headend-grid.component.html'),
	selector: 'vcm-headend-grid'
})

export class HeadendGridComponent {
	@Input() headend: SignalsHeadend;

	acpWasChecked: boolean[] = [];
	checkedAcps: HeadendGridRow[] = [];
	headendGrid: HeadendGridRow[] = [];
	isHeadendGridExpanded: boolean = true;
	selectedAction: SignalsAction;


	actions: SignalsAction[] = [
		{
			shownString: "AUTHORIZATION REQUEST",
			value: "3",
			route: ['signals', 'authorization']
		},
		{
			shownString: "DEAUTHORIZE ONE OR MORE SERVICES",
			value: "DROP",
			route: ['signals', 'drop'],
			needService: true
		},
		{
			shownString: "VALIDATE HEADEND",
			value: "1",
			route: ['signals', 'validate']
		},
		{
			shownString: "REMOVE HEADEND",
			value: "2",
			route: ['signals', 'remove']
		},
		{
			shownString: "SWAP REQUEST",
			value: "-1",
			route: ['signals', 'swap'],
			needService: true
		},
		{
			shownString: "ADD MISSING SERVICE",
			value: "4",
			route: ['signals', 'add-service']
		}
	];

	constructor(private router: Router,
	            private service: SignalsService) {};

	ngOnInit() {
		this.checkDropdowns();
		this.headend.Units.forEach((unit) => {
			unit.Acps.forEach((acp, num) => {
				let headendGridRow: HeadendGridRow = new HeadendGridRow();
				headendGridRow.createHeadendGrid(acp, num, unit.UnitAddress);
				if (this.headendGrid.length) {
					headendGridRow.setRowColor(this.headendGrid[this.headendGrid.length - 1], headendGridRow);
				}
				this.headendGrid.push(headendGridRow);
			})
		});
	}

	toggleHeadendGridExpansion(): void {
		this.isHeadendGridExpanded = !this.isHeadendGridExpanded;
	}

	goToSignalsActionPage(action?: SignalsAction): void {
		if (!action || action.disabled) {
			return;
		}
		this.service.headendId = this.headend.HeadendId;
		this.service.acps = this.checkedAcps;
		if (!action.needService || !!this.service.acps.length) {
			this.router.navigate(action.route);
		} else {
			this.selectedAction = null;
		}
	}

	checkAcp(acpId: number): void {
		let acp = ArrayUtils.findByProperty(this.headendGrid, 'AcpId', acpId);
		if (this.checkedAcps.indexOf(acp) > -1) {
			this.checkedAcps.splice(this.checkedAcps.indexOf(acp), 1)
		} else {
			this.checkedAcps.push(acp)
		}
		this.checkDropdowns();
	}

	checkDropdowns(): void {
		this.actions.forEach((action) => {
			action.disabled = action.needService && !this.checkedAcps.length;
		})
	}
}