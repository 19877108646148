import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '../../../../../core/http/http-client';
import { ConfigService } from '../../../../../core/config.service';
import { LoggerService } from '../../../../../core/logger/logger.service';

@Injectable()
export class AddRequestFormService {
	private url = this.configService.baseUrl + '/api/AddMissingSignal';

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	submitRequest(request: any): Promise<any> {
		return this.http.post(this.url, request)
			.toPromise()
			.then((res: Response) => {
				return res.json();
			}).catch((err) => {
				this.logger.error(err);
				return false;
			});
	}
}
