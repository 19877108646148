import { Injectable } from '@angular/core';
import { ResourcesService } from '../resources.service';

export class AvailAlerts {
	ServiceLongname: string;
	ContentName: string;
	ContentTitle: string;
	Date: string;
	MakegoodText: string;
	AvailAlertText: string;
	TargetRoles: string[];
}

@Injectable()
export class AvailAlertsService {

	constructor(private resourcesService: ResourcesService) {}

	getAvailAlerts(filter: any = {}): Promise<AvailAlerts[]> {
		filter.resourceType = 'AVAILALERT';
		return this.resourcesService.getResourcesList(filter);
	}
}