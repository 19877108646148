import { Component, Input } from '@angular/core';
import { AssetsService } from '../../../../../../core/assets.service';

export class FileName {
	FileName: string;
	FileId: string;
}

@Component({
	selector: 'vcm-landing-logo-download',
	template: require('./landing-logo-download.component.html')
})

export class LandingLogoDownloadComponent {
	@Input() set logoImages(logoImages: FileName[]) {
		this._logoImages = this.getLogoImagesWithAbsolutePath(logoImages);
		this.selectedLogoImage = this._logoImages[0];
	}

	get logoImages(): FileName[] {
		return this._logoImages;
	}

	selectedLogoImage: FileName;

	private _logoImages: FileName[];

	constructor(private assetsService: AssetsService) {}

	private getLogoImagesWithAbsolutePath(logoImages: FileName[]): FileName[] {
		logoImages.forEach((image: FileName) => {
			image.FileName = this.assetsService.getAssetUrl(image.FileName);
		});
		return logoImages;
	}
}