import { Injectable } from '@angular/core';
import { HttpClient } from '../../../core/http/http-client';
import { ConfigService } from '../../../core/config.service';
import { Observable } from 'rxjs';
import { Response } from '@angular/http';
import { AuthenticationService } from '../../../core/auth/authentication.service';
import { LoggerService } from '../../../core/logger/logger.service';

export class GenericGrid {
	LinkFileUrl: string;
	ContentTitle: string;
}

@Injectable()
export class GenericGridsService {
	private url = this.configService.baseUrl + '/api/ProgrammingGenericGrids';
	private genericGrids: GenericGrid[];

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private authService: AuthenticationService,
	            private logger: LoggerService) {
		this.authService.onLogout.subscribe(() => {
			this.genericGrids = null;
		});
	}

	getGenericGrids(): Observable<GenericGrid[]> {
		if (this.genericGrids) {
			return Observable.of(this.genericGrids);
		} else {
			return this.loadGenericGrids();
		}
	}

	private loadGenericGrids(): Observable<GenericGrid[]> {
		return this.http.get(this.url)
			.map((res: Response) => {
				this.genericGrids = res.json();
				return this.genericGrids;
			}).catch((err) => {
				this.logger.error(err);
				return Observable.of(null);
			});
	}
}
