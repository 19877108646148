import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared/shared.module';
import { BackLinkComponent } from '../../../../shared/back-link.component';
import { SwapRequestService } from './swap-request.service';
import { SwapRequestRoutingModule } from './swap-request-routing.module';
import { SwapRequestComponent } from './swap-request.component';
import { SwapRequestFormComponent } from './add-request-form/swap-request-form.component';

@NgModule({
	imports: [
		SharedModule,
		SwapRequestRoutingModule
	],
	declarations: [
		SwapRequestComponent,
		SwapRequestFormComponent
	],
	providers: [
		SwapRequestService,
		BackLinkComponent
	]
})
export class SwapRequestModule {
}