import { Injectable } from '@angular/core';
import { HttpClient } from '../../../core/http/http-client';
import { ConfigService } from '../../../core/config.service';
import { Response } from '@angular/http';
import { ProfileAddress } from './profile-address';
import { AuthenticationService } from '../../../core/auth/authentication.service';
import { ObjectUtils } from '../../../core/object-utils';
import { LoggerService } from '../../../core/logger/logger.service';

@Injectable()
export class ProfileAddressService {
	private _addressList: ProfileAddress[];

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private authService: AuthenticationService,
	            private logger: LoggerService) {
		this.authService.onLogout.subscribe(() => {
			this.clearLoadedAddresses();
		});
	}

	getAddresses(): Promise<ProfileAddress[]> {
		if (this._addressList) {
			return Promise.resolve(ObjectUtils.deepCopy(this._addressList));
		}
		return this.loadAddresses();
	}

	getAddressById(id: number): Promise<ProfileAddress> {
		return this.getAddresses()
			.then((addressList: ProfileAddress[]) => {
				return addressList.find((address: ProfileAddress) => {
					address = new ProfileAddress(address);
					return address.addressID === id;
				});
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	saveAddress(address: ProfileAddress): Promise<any> {
		this.clearLoadedAddresses();
		if (address.addressID) {
			return this.editAddress(address);
		} else {
			return this.addAddress(address);
		}
	}

	deleteAddress(addressID: number): Promise<any> {
		this.clearLoadedAddresses();
		return this.http.delete(this.configService.baseUrl + '/api/ShippingAddress?addressID=' + addressID)
			.toPromise();
	}

	private loadAddresses(): Promise<ProfileAddress[]> {
		return this.http.get(this.configService.baseUrl + '/api/ShippingAddress')
			.toPromise()
			.then((res: Response) => {
				this._addressList = res.json().map(address => new ProfileAddress(address));
				return this._addressList;
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	private addAddress(address: ProfileAddress): Promise<any> {
		return this.http.post(this.configService.baseUrl + '/api/AddShippingAddress', address)
			.toPromise();
	}

	private editAddress(address: ProfileAddress): Promise<any> {
		return this.http.post(this.configService.baseUrl + '/api/EditShippingAddress', address)
			.toPromise();
	}

	private clearLoadedAddresses() {
		this._addressList = null;
	}
}