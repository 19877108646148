import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RegistrationRoutingModule } from './registration-routing.module';
import { RegistrationComponent } from './registration.component';
import { RegistrationService } from './registration.service';
import { StepsModule } from './steps/steps.module';
import { RegistrationUserService } from './registration-user.service';
import { UserActivationModule } from './user-activation/user-activation.module';
import { RegistrationStepsService } from './registration-steps.service';
import { RegistrationStepsResolve } from './registration-steps-resolve.service';

@NgModule({
	imports: [
		SharedModule,
		StepsModule,
		RegistrationRoutingModule,
		UserActivationModule
	],
	declarations: [
		RegistrationComponent,
	],
	providers: [
		RegistrationStepsService,
		RegistrationStepsResolve,
		RegistrationService,
		RegistrationUserService
	]
})
export class RegistrationModule {
}