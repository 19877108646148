import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReasonResolve } from './remove-headend-form/reason-resolve.service';
import { AuthGuard } from '../../../../core/auth/auth.guard';
import { RemoveHeadendComponent } from './remove-headend.component';
import { HeadendSummaryResolve } from '../shared/headend-summary-resolve.service';
import { HeadendIdGuard } from '../shared/headend-id-guard.service';
import { HeadendSummaryClearGuard } from '../shared/headend-summary-clear.guard';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'signals/remove',
				component: RemoveHeadendComponent,
				canActivate: [AuthGuard, HeadendIdGuard],
				canDeactivate: [HeadendSummaryClearGuard],
				resolve: {
					reasons: ReasonResolve,
					headendSummary: HeadendSummaryResolve
				}
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		ReasonResolve,
		HeadendSummaryResolve,
		HeadendIdGuard,
		HeadendSummaryClearGuard
	]
})

export class RemoveHeadendRoutingModule {
}