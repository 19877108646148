import { Component, OnDestroy, OnInit } from '@angular/core';
import { Promotion, PromotionService, Spot } from './promotion.service';
import { ActivatedRoute } from '@angular/router';
import { ArrayUtils } from '../../core/object-utils';
import { ParamsListService } from './params.service';
import { PromotionFilters } from './filters/filter-option';

@Component({
	template: require('./promotion.component.html')
})

export class PromotionComponent implements OnInit, OnDestroy {
	promotions: Promotion[] = [];
	promotionDetails: Promotion;
	spotList: Spot[];

	private routeParams: any;

	constructor(private paramsListService: ParamsListService,
	            private promotionService: PromotionService,
	            private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.route.data.forEach((data: { promotions: Promotion[], filters: PromotionFilters }) => {
			this.promotions = data.promotions;
			this.setPromotionDetails();
		});

		this.route.params.subscribe(params => {
				if (this.isParameterChanged(params, 'mainmenugroup')) {
					this.promotionService.loadPromotions(params).subscribe((promotions: Promotion[]) => {
						this.promotions = promotions;
						this.setPromotionDetails();
					});
				}

				this.routeParams = params;
			}
		);
	}

	isParameterChanged(params: any, name: string): boolean {
		return this.routeParams && this.routeParams[name] !== params[name];
	}

	setPromotionDetails(): void {
		if (this.promotions) {
			this.promotionDetails = this.paramsListService.paramsList.PromoId ?
				ArrayUtils.findByProperty(this.promotions, 'Id', +this.paramsListService.paramsList.PromoId) :
				this.promotions[0];
		}
		this.setSpots();
	}

	setSpots(): void {
		this.spotList = this.promotionDetails ? this.promotionDetails.SelectSpot : [];
	}

	ngOnDestroy() {
		this.promotionService.promotions = null;
	}
}
