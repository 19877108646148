import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { OtherService, Other } from './other.service';
import { CommonFiltersResolve } from '../filters/common-filters-resolve';
import { ActiveFiltersService } from '../filters/active-filters.service';

@Injectable()
export class OtherResolve extends CommonFiltersResolve implements Resolve<Other[]> {
	constructor(private service: OtherService,
	            private activeFiltersService: ActiveFiltersService,
	            private mask: LoadingMaskService) {
		super(activeFiltersService);
	}

	resolve(route: ActivatedRouteSnapshot): Promise<Other[]> {
		this.mask.addLoading();
		let filters: any = this.initActiveFiltersFromURLParams(route);
		return this.service.getOther(filters)
			.then((other: Other[]) => {
				this.mask.removeLoading();
				return other;
			});
	}
}
