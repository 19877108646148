import { GRID_DAY_FORMAT } from './live-grid-shows.service';
import * as moment from 'moment';
import Moment = moment.Moment;

export class DaysRange {
	private _firstDay: Moment;
	private _lastDay: Moment;
	private _id: number;

	constructor(firsDay: Moment, lastDay: Moment) {
		this._firstDay = moment(firsDay);
		this._lastDay = moment(lastDay);
		this._id = this.firstDay.valueOf() + this.lastDay.valueOf();
	}

	get id(): number {
		return this._id;
	}

	get firstDay(): moment.Moment {
		return moment(this._firstDay);
	}

	get lastDay(): moment.Moment {
		return moment(this._lastDay);
	}

	toString(): string {
		return `${this.firstDay.format(GRID_DAY_FORMAT)} - ${this.lastDay.format(GRID_DAY_FORMAT)}`;
	}
}