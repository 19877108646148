import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { BRANDS } from './common-filters';
import { ActiveFiltersService } from './active-filters.service';

export class CommonFiltersResolve {
	private activeFilters: ActiveFiltersService;
	private arrayFilterValues: string[];

	constructor(activeFilters: ActiveFiltersService,
	            arrayFilterValues?: string[]) {
		this.activeFilters = activeFilters;
		this.arrayFilterValues = arrayFilterValues || [];
		this.arrayFilterValues.push(BRANDS);
	}

	initActiveFiltersFromURLParams(route: ActivatedRouteSnapshot) {
		this.activeFilters.clearFilters();
		for (let key in route.params) {
			let value: string | string[] = this.getValue(route.params, key);
			this.activeFilters.setFilter(key, value);
		}
		this.activeFilters.selectedBrandFilterOptions = this.activeFilters.getBrandsFromIds(this.activeFilters.getFilter(BRANDS) || []);
		return this.activeFilters.getActiveFilters();
	}

	private getValue(params: Params, key): string | string[] {
		let value = params[key];
		if (this.isArrayValue(key)) {
			value = value.split(',');
		}
		return value;
	}

	private isArrayValue(key: string): boolean {
		return this.arrayFilterValues.indexOf(key) >= 0;
	}
}