import { Injectable } from '@angular/core';
import { HttpClient } from '../../../core/http/http-client';
import { ConfigService } from '../../../core/config.service';
import { Response } from '@angular/http';
import { Office, Country, State } from '../../registration/shared/registration-user';
import { ObjectUtils } from '../../../core/object-utils';
import { AuthenticationService } from '../../../core/auth/authentication.service';
import { LoggerService } from '../../../core/logger/logger.service';

export interface ProfileDropdowns {
	ActiveOffices: Office[];
	Country: Country[];
	State: State[];
}

@Injectable()
export class ProfileDropdownsService {
	private dropdowns: any;

	constructor(private http: HttpClient,
	            private authService: AuthenticationService,
	            private configService: ConfigService,
	            private logger: LoggerService) {
		this.authService.onLogout.subscribe(() => {
			this.dropdowns = null;
		});
	}

	getDropDowns(): Promise<ProfileDropdowns> {
		if (this.dropdowns) {
			return Promise.resolve(ObjectUtils.deepCopy(this.dropdowns));
		}
		return this.loadDropDowns();
	}

	loadDropDowns(): Promise<ProfileDropdowns> {
		return this.http.get(this.configService.baseUrl + '/api/ProfileDropDowns')
			.toPromise()
			.then((res: Response) => {
				this.dropdowns = res.json();
				let activeOffices = [];
				this.deserializeArray(this.dropdowns.ActiveOffices, activeOffices);
				this.dropdowns.ActiveOffices = activeOffices;
				this.dropdowns.Country = this.dropdowns.Country.map(country => new Country(country));

				return ObjectUtils.deepCopy(this.dropdowns);
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	private deserializeArray(from: any[], to: any[]) {
		from.forEach((json: any) => {
			to.push(new Office().fromJSON(json))
		});
	}
}