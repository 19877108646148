import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { Highlight, HighlightsService } from './highlights.service';

@Injectable()
export class HighlightsResolve implements Resolve<Highlight[]> {
	constructor(private service: HighlightsService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<Highlight[]> {
		this.mask.addLoading();
		return this.service.getHighlights(false)
			.map((highlights: Highlight[]) => {
				this.mask.removeLoading();
				return highlights;
			});
	}
}
