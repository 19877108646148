import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Country, State } from '../../shared/registration-user';
import { StateService } from './state.service';
import { RegistrationService } from '../../registration.service';

@Injectable()
export class StateResolve implements Resolve<State[]> {
	constructor(private registrationService: RegistrationService,
	            private service: StateService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<State[]> {
		let selectedCountry: Country = this.registrationService.user.country;
		if (selectedCountry) {
			return this.service.getStates(selectedCountry);
		} else {
			return Promise.resolve([]);
		}
	}
}
