import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Other } from './other.service';

@Component({
	template: require('./other.component.html')
})

export class OtherComponent {

	others: Other[] = [];

	constructor(private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: { others: Other[] }) => {
			this.others = data.others;
		});
	}
}
