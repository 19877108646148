import * as moment from 'moment';
import Moment = moment.Moment;

/**
 * key - day in format MM/DD/YYYY
 * value - shows for this day
 */
export class LiveGrid {
	schedule: Map<string, LiveGridShow[]> = new Map<string, LiveGridShow[]>();
}

export class LiveGridShow {
	Channel: number;
	private _Datetime: Moment;
	Description: string;
	Duration: number;
	Episode: string;
	EpisodeDesc: string;
	EpisodeNum: string;
	OrigAirDate: string;
	Premiere: boolean;
	RatingType: string;
	ScheduleitemId: number;
	SeriesCde: number;
	ServiceId: number;
	ServiceName: string;
	Title: string;

	constructor(options: any) {
		this.Channel = options.Channel;
		this._Datetime = moment(options.Datetime);
		this.Description = options.Description;
		this.Duration = options.Duration;
		this.Episode = options.Episode;
		this.EpisodeDesc = options.EpisodeDesc;
		this.EpisodeNum = options.EpisodeNum;
		this.OrigAirDate = options.OrigAirDate;
		this.Premiere = options.Premiere;
		this.RatingType = options.RatingType;
		this.ScheduleitemId = options.ScheduleitemId;
		this.ServiceId = options.ServiceId;
		this.SeriesCde = options.SeriesCde;
		this.Title = options.Title;
	}

	get Datetime(): moment.Moment {
		return moment(this._Datetime);
	}
}