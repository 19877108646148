import { SORT_TYPE } from '../grid-api.service';
export interface Comparator {
	(first: any, second: any, key: string): number;
}

export class ComparatorUtils {
	static getComparatorFromType(type: SORT_TYPE = 'string'): Comparator {
		switch (type) {
			case 'number':
				return numberComparator;
			case 'date':
				return dateComparator;
			case 'string':
			default:
				return stringComparator;
		}
	}
}

export function stringComparator(first: any, second: any, key: string): number {
	return (second[key] || '').localeCompare(first[key]);
}

export function numberComparator(first: any, second: any, key: string): number {
	return second[key] - first[key];
}

export function dateComparator(first: any, second: any, key: string): number {
	let firstDate: Date = getAsDate(first[key]);
	let secondDate: Date = getAsDate(second[key]);
	return secondDate.getTime() - firstDate.getTime();

	function getAsDate(first: string|Date) {
		return first instanceof Date ? first : new Date(first);
	}
}