import { NgModule } from '@angular/core';
import { MdDialogModule } from '@angular/material';
import { UserNewsDirective } from './user-news.directive';
import { UserNewsDialog } from './user-news-dialog.component';
import { SharedModule } from '../../../shared/shared.module';
import { UserNewsComponent } from './user-news.component';

@NgModule({
	imports: [
		SharedModule,
		MdDialogModule.forRoot()
	],
	declarations: [
		UserNewsDirective,
		UserNewsDialog,
		UserNewsComponent
	],
	entryComponents: [
		UserNewsDialog,
		UserNewsComponent
	],
	exports: [
		UserNewsDirective
	]
})

export class UserNewsModule {
}


