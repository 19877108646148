import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '@angular/http';
import { ConfigService } from '../../../core/config.service';
import { HttpClient } from '../../../core/http/http-client';
import { ProgramDetails } from '../programming-highlights';
import { LoggerService } from '../../../core/logger/logger.service';

@Injectable()
export class ProgramDetailsService {
	serviceId: string;
	startDate: string;

	private url = this.configService.baseUrl + '/api/LiveGridProgramDetails/?';

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private logger: LoggerService) {
	}

	getProgramDetails(serviceId: string, startDate: string): Observable<ProgramDetails> {
		this.serviceId = serviceId;
		this.startDate = startDate;
		return this.http.get(this.url + 'serviceId=' + serviceId + '&startDate=' + startDate)
			.map((res: Response) => {
				return res.text() ? res.json() : null;
			}).catch((err) => {
				this.logger.error(err);
				return Observable.of(null);
			});
	}

	getProgramDetailsPremiere(phId: string): Observable<ProgramDetails> {
		return this.http.get(this.url + 'phId=' + phId)
			.map((res: Response) => {
				return res.text() ? res.json() : null;
			}).catch((err) => {
				this.logger.error(err);
				return Observable.of(null);
			});
	}

	clearParams(): void {
		this.serviceId = null;
		this.startDate = null;
	}
}
