import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '@angular/http';

import { ConfigService } from '../../../core/config.service';
import { HttpClient } from '../../../core/http/http-client';
import { LoggerService } from '../../../core/logger/logger.service';
import { DciiParams, Orders, SignalsDciiDropdowns, SignalsDciiHeadend } from '../signals';

@Injectable()
export class DciiFormService {
	public dciiFormData: SignalsDciiHeadend;
	public dciiFormDropdowns: SignalsDciiDropdowns[];
	public dciiHeadendID: number;
	public shippingCountryId: string;
	public dropdowns: {};

	shippingCountries;
	headendCountries;

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private logger: LoggerService) {
	}

	getDciiForm(data: any): Observable<SignalsDciiHeadend> {
		let url = this.configService.baseUrl + '/api/DCIIForm?headendId=' + data.dciiHeadendID;
		let params: DciiParams = {
			dciiHeadendId: data.dciiHeadendId
		};

		this.dciiHeadendID = data.dciiHeadendId;

		return this.http.get(url).map((res: Response) => {
			this.dciiFormData = res.text() ? res.json() : null;
			this.shippingCountryId = this.dciiFormData.ShippingCountry;

			return this.dciiFormData;
		}).catch((err) => {
			this.logger.error(err);
			return Observable.of(null);
		});
	}

	getDciiFormUpdate(data): Promise<any> {
		let url = this.configService.baseUrl + '/api/DCIIForm?headendId=' + data.HeadendId;
		let params: DciiParams = {
			dciiHeadendId: data.HeadendId
		};

		this.dciiHeadendID = data.HeadendId;

		return this.http.get(url)
			.toPromise().then((res) => {
				this.dciiFormData = res.text() ? res.json() : null;
				return this.dciiFormData;

			}).catch((err) => {
				err = 'Something wrong. Try again later';
				this.logger.error(err);
				return null;
			});

	}

	getDciiDropdowns(data): Promise<any> {
		let url = this.configService.baseUrl + '/api/DCIIDropdowns';

		return this.http.get(url, data)
			.toPromise().then((res) => {
				this.dciiFormDropdowns = res.text() ? res.json() : null;
				this.shippingCountries = this.dciiFormDropdowns['Countries'];
				this.headendCountries = this.dciiFormDropdowns['Countries'];

				return this.dciiFormDropdowns;

			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	submitDciiFullForm(headend: SignalsDciiHeadend): Promise<any> {
		let url = this.configService.baseUrl + '/api/DCIIForm?isSubmit=true';
		return this.http.post(url, headend)
			.toPromise().then((res) => {
				return res;
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	submitDciiOrder(order: Orders, headend: SignalsDciiHeadend): Promise<any> {
		let url = this.configService.baseUrl + '/api/DCIIForm?isSubmit=false';

		order['IsSubmitThisOrder'] = true;

		return this.http.post(url, headend)
			.toPromise().then((res) => {
				order['IsSubmitThisOrder'] = false;
				return res;

			}).catch((err) => {
				order['IsSubmitThisOrder'] = false;
				err = 'Something wrong. Try again later';
				this.logger.error(err);
				return null;
			});
	}

	approveDciiOrder(order: Orders): Promise<any> {
		let url = this.configService.baseUrl + '/api/DCIIOrder?orderId=' + order.OrderId + '&isApprove=true';

		return this.http.get(url)
			.toPromise().then((res) => {
				return res;

			}).catch((err) => {
				err = 'Something wrong. Try again later';
				this.logger.error(err);
				return null;
			});
	}

	rejectDciiOrder(order: Orders): Promise<any> {
		let url = this.configService.baseUrl + '/api/DCIIOrder?orderId=' + order.OrderId + '&isApprove=false';

		return this.http.get(url)
			.toPromise().then((res) => {
				return res;

			}).catch((err) => {
				err = 'Something wrong. Try again later';
				this.logger.error(err);
				return null;
			});
	}

	cancelDciiOrder(order: Orders, headend: SignalsDciiHeadend): Promise<any> {
		let url = this.configService.baseUrl + '/api/DCIIForm?isSubmit=false';

		order['IsSubmitThisOrder'] = true;
		order['IsCancelled'] = true;

		return this.http.post(url, headend)
			.toPromise().then((res) => {
				return res;

			}).catch((err) => {
				err = 'Something wrong. Try again later';
				this.logger.error(err);
				return null;
			});
	}

	fulFillDciiOrder(order: Orders, headend: SignalsDciiHeadend): Promise<any> {
		let url = this.configService.baseUrl + '/api/DCIIForm?isSubmit=false';

		order['IsSubmitThisOrder'] = true;
		order['IsFulFilled'] = true;

		return this.http.post(url, headend)
			.toPromise().then((res) => {
				return res;

			}).catch((err) => {
				err = 'Something wrong. Try again later';
				this.logger.error(err);
				return null;
			});
	}

	saveDciiForm(headend: SignalsDciiHeadend): Promise<any> {
		let url = this.configService.baseUrl + '/api/DCIIForm?isSubmit=false';
		return this.http.post(url, headend)
			.toPromise().then((res) => {
				return res;
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	getStates(CountryId): Promise<any> {
		let url = this.configService.baseUrl + '/api/DCIIStateDropdown?countryCode=' + CountryId.Id;

		return this.http.get(url)
			.toPromise().then((res) => {
				return res.json();
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}

