import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { CaptchaModule } from './captcha/captcha.module';
import { CheckboxGroupValidator } from './checkbox-group-validator.directive';
import { NotEmptyValidator } from './not-empty-validator.directive';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { EqualValidator } from './equal-validator.directive';
import { InputComponent } from './input/input.component';
import { MaskedInputComponent } from './masked-input/masked-input.component';
import { OutputComponent } from './output/output.component';
import { DatepickerDropdownModule } from './datepicker-popup/datepicker-popup.module';
import { TextareaComponent } from './textarea/textarea.component';
import { MessageComponent } from './message/message.component';
import { NotSpaceValidator } from './not-space-validator.directive';

@NgModule({
	imports: [
		CommonModule,
		CaptchaModule,
		FormsModule,
		TextMaskModule
	],
	declarations: [
		CheckboxGroupValidator,
		NotEmptyValidator,
		NotSpaceValidator,
		CheckboxGroupComponent,
		EqualValidator,
		InputComponent,
		MaskedInputComponent,
		OutputComponent,
		TextareaComponent,
		MessageComponent
	],
	exports: [
		CheckboxGroupValidator,
		NotEmptyValidator,
		EqualValidator,
		NotSpaceValidator,
		CheckboxGroupComponent,
		CommonModule,
		FormsModule,
		CaptchaModule,
		TextMaskModule,
		InputComponent,
		MaskedInputComponent,
		OutputComponent,
		DatepickerDropdownModule,
		TextareaComponent,
		MessageComponent
	]
})
export class SharedFormModule {
}
