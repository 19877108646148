import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Role } from '../../shared/registration-user';
import { RolesService } from './role.service';

@Injectable()
export class RoleResolve implements Resolve<Role[]> {
    constructor(private service: RolesService) {
    }

    resolve(route: ActivatedRouteSnapshot): Promise<Role[]> {
        return this.service.getRoles();
    }
}
