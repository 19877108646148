import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { TrackByUtils } from '../track-by-utils';
import { SlimScrollDirective } from 'ng2-slimscroll/src/directives/slimscroll.directive';

@Component({
	selector: 'vcm-multiple-select',
	template: require('./multiple-select.component.html'),
	host: {class: 'vcm_multiselect_component'},
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class MultipleSelectComponent {
	@Input() options: any[] = [];
	@Input() label: string;
	@Input() displayName: string;
	@Input() trackBy: string;

	@Input()
	set selectedOptions(selectedOptions: any[]) {
		this._selectedOptions = typeof selectedOptions === 'string' ? [selectedOptions] : selectedOptions;
		this.isAllSelected = this.options.length > 0 && this.selectedOptions.length === this.options.length;
	}

	get selectedOptions(): any[] {
		return this._selectedOptions;
	}

	@Output() changeSelectedOptions: EventEmitter<any[]> = new EventEmitter();
	@ViewChild('slimScroll') slimScroll: SlimScrollDirective;

	isAllSelected: boolean = false;
	isOpen: boolean = false;

	private _selectedOptions: any[] = [];

	toggleMultiselect(): void {
		if (this.options.length !== 0) {
			if (this.isOpen) {
				this.isOpen = false;
			} else {
				this.isOpen = true;
				this.slimScroll.getBarHeight();
			}
		}
	}

	toggleOptionsSelection(option: any): void {
		let optionIndex = this.getIndex(this.trackBy, this._selectedOptions, option);
		if (optionIndex >= 0) {
			this._selectedOptions.splice(optionIndex, 1);
			this.isAllSelected = false;
		} else {
			this._selectedOptions.push(option);
			this.isAllSelected = this._selectedOptions.length === this.options.length;
		}
		this.changeSelectedOptions.emit(this._selectedOptions);
	}

	toggleSelectAll(): void {
		this.isAllSelected = !this.isAllSelected;
		if (this.isAllSelected) {
			this._selectedOptions = this.options.slice();
		} else {
			this._selectedOptions = [];
		}
		this.changeSelectedOptions.emit(this._selectedOptions);
	}

	isOptionSelected(option: any): boolean {
		return this.getIndex(this.trackBy, this._selectedOptions, option) >= 0;
	}

	getSelectedOptionsText(): string {
		if (this.isAllSelected && this.options.length !== 1) {
			return 'All selected';
		} else if (this._selectedOptions.length === 0) {
			return '';
		} else if (this._selectedOptions.length >= 4) {
			return `${this._selectedOptions.length} of ${this.options.length}`;
		} else {
			return this._selectedOptions
				.map((option) => {return this.displayName ? option[this.displayName] : option})
				.join(', ')
		}
	}

	private getIndex(trackBy: string, array: any[], element: any): any {
		if (trackBy) {
			return TrackByUtils.indexOfTrackByProperty(array, element, trackBy);
		} else {
			return array.indexOf(element);
		}
	}
}
