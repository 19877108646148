import { Injectable } from '@angular/core';
import { ConfigService } from '../../core/config.service';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';
import { HttpClient } from '../../core/http/http-client';
import { TermsPageModel } from '../+terms/terms-page.model';
import { UserService } from '../../core/auth/user.service';
import { TokenService } from '../../core/http/token.service';
import { LoggerService } from '../../core/logger/logger.service';
import { UploadRequest } from './upload-form/upload-form.component';
import { FileUploader } from './shared/ng2-file-upload/file-uploader.class';
//TODO fix bug with progress bar
// import { FileUploader } from 'ng2-file-upload';

export class UploadResponse {
	Result: boolean;
	Message: string;
	ListFileNames: string[];
}

@Injectable()

export class UploadService {
	file: any;
	uploader: FileUploader = new FileUploader({
		url: this.configService.baseUrl + '/api/UploadFilesToServer',
		authToken: this.userService.getCurrentUser().AuthorizationToken
	});
	listFileNames: string[] = [];
	private uploadText: TermsPageModel;

	constructor(private configService: ConfigService,
	            private userService: UserService,
	            private tokenService: TokenService,
	            private logger: LoggerService,
	            private http: HttpClient) {
		this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
		this.uploader.onErrorItem = (item: any, response: any, status: any, headers: any) => {
			if (status == 401) {
				this.uploadWithNewToken(item);
			}
		};
		this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
			if (!response) {
				return;
			}
			let res: UploadResponse = JSON.parse(response);
			if (status == 200) {
				this.listFileNames.push(res.ListFileNames[0]);
			}
		};
	}

	clearForm(): void {
		this.listFileNames = [];
		this.uploader.clearQueue();
	}

	getUploadText(): Observable<any> {
		if (this.uploadText) {
			return Observable.of(this.uploadText);
		} else {
			return this.loadUploadText();
		}
	}

	uploadWithNewToken(item: any): void {
		this.refreshToken().then(() => {
			item.upload();
		})
	}

	uploadToMediaSilo(uploadRequest: UploadRequest): Promise<any> {
		let url = this.configService.baseUrl + '/api/UploadFilesToMediaSilo';

		return this.http.post(url, uploadRequest)
			.toPromise()
			.then((res: Response) => {
				return res.json();
			})
			.catch((err) => {
				this.logger.error(err);
				return false;
			});
	}

	private refreshToken(): Promise<void> {
		return this.tokenService.doRefreshToken().toPromise().then((token) => {
			this.uploader.authToken = token;
		});
	}

	private loadUploadText(): Observable<any> {
		let url = this.configService.baseUrl + '/api/landingpage?landingType=UPLOAD';

		return this.http.get(url)
			.map((res: Response) => {
				this.uploadText = res.json();
				return this.uploadText;
			});
	}
}