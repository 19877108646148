import { Injectable, EventEmitter } from '@angular/core';
import { ConfigService } from '../../core/config.service';
import { HttpClient } from '../../core/http/http-client';
import { AuthenticationService } from '../../core/auth/authentication.service';
import * as Immutable from 'immutable';
import { Response } from '@angular/http';
import { ObjectUtils } from '../../core/object-utils';
import { LoggerService } from '../../core/logger/logger.service';

export class AvailableResource {
	public Id: string;
	public Description: string;
}

export class Resource {
	ResourceType: AvailableResource;
	ResourceTypeAvailable: AvailableResource[];
	Resources: any;
}

@Injectable()
export class ResourcesService {
	private url = this.configService.baseUrl + '/api/Resources';
	private resourcesCache: Map<string, Resource> = new Map<string, Resource>();

	currentResource: Resource;
	resourcesChanged: EventEmitter<Resource> = new EventEmitter<Resource>();

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private authService: AuthenticationService,
	            private logger: LoggerService) {
		this.authService.onLogout.subscribe(() => {
			this.resourcesCache.clear();
		});
	}

	getResourcesList(params: any = {}): Promise<any> {
		return this.getResources(params)
			.then((resource: Resource) => resource  ? resource.Resources : null);
	}

	getResources(params: any = {}): Promise<Resource> {
		this.removeEmptyParams(params);
		let paramsMap = Immutable.Map<string, any>(params);
		let paramsKey: string = this.stringify(paramsMap);
		if (this.resourcesCache.has(paramsKey)) {
			let resource: Resource = ObjectUtils.deepCopy(this.resourcesCache.get(paramsKey));
			this.setCurrentResource(resource);
			return Promise.resolve(resource);
		} else {
			return this.loadResources(paramsMap);
		}
	}

	private loadResources(params: Immutable.Map<string, any>): Promise<any> {
		return this.http.post(this.url, params.toObject())
			.toPromise()
			.then((res: Response) => {
				let result: Resource = res.json();
				this.setCache(params, result);
				this.setCurrentResource(result);
				return ObjectUtils.deepCopy(result);
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	private setCache(params: Immutable.Map<string, any>, result: any): void {
		this.resourcesCache.set(this.stringify(params), result);
		if (params.size === 0) {
			params = params.set('resourceType', result.ResourceTypeAvailable[0].Id);
			this.resourcesCache.set(this.stringify(params), result);
		}
	}

	private setCurrentResource(resource: Resource) {
		this.currentResource = resource;
		this.resourcesChanged.emit(resource);
	}

	private stringify(key: Immutable.Map<any, any>) {
		return JSON.stringify(key.sort());
	}

	removeEmptyParams(params: any) {
		for (let key in params) {
			if (params.hasOwnProperty(key) && !params[key]) {
				delete params[key];
			}
		}
	}
}