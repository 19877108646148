import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { LiveGrid, LiveGridShow } from '../live-grid-show';
import { WORKING_HOURS, START_HOUR, GRID_DAY_FORMAT } from '../live-grid-shows.service';
import * as moment from 'moment';
import { DaysRange } from '../days-range';
import { LiveGridShowElement } from '../show-tooltip/show-tooltip.component';
import Moment = moment.Moment;
import DateTimeFormat = Intl.DateTimeFormat;
import { ViewportService, Device } from '../../../../core/viewport-service/viewport.service';

export const MINUTE_HEIGHT: number = 3;
export const HOUR_HEIGHT: number = 60 * MINUTE_HEIGHT;

@Component({
	template: require('./live-grid-table.component.html'),
	selector: 'vcm-live-grid-table',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiveGridTableComponent {
	@Input() liveGrid: LiveGrid;
	@Input() availableRanges: DaysRange[];
	@Input() selectedRange: DaysRange;
	@Input() tooltipId: string = '#vcm-live-grid-tooltip';

	@Output() onRangeChange: EventEmitter<DaysRange> = new EventEmitter<DaysRange>();

	DAY_FORMAT: string = GRID_DAY_FORMAT;
	selectedShowElement: LiveGridShowElement;

	constructor(private viewportService: ViewportService) {}

	getDayShows(day: Moment): LiveGridShow[] {
		return this.liveGrid.schedule.get(day.format(this.DAY_FORMAT)) || [];
	}

	selectNextRange(): void {
		if (!this.isLastRange(this.selectedRange)) {
			this.onRangeChange.emit(this.availableRanges[this.availableRanges.indexOf(this.selectedRange) + 1]);
		}
	}

	selectPrevRange(): void {
		if (!this.isFirstRange(this.selectedRange)) {
			this.onRangeChange.emit(this.availableRanges[this.availableRanges.indexOf(this.selectedRange) - 1]);
		}
	}

	isFirstRange(range: DaysRange) {
		return this.availableRanges.indexOf(range) === 0;
	}

	isLastRange(range: DaysRange) {
		return this.availableRanges.indexOf(range) === this.availableRanges.length - 1;
	}

	isDisplayStartTime(date: Moment): boolean {
		return this.isMobileDevice() || !this.isStartOfHour(date);
	}

	isLongerThanQuarterOfAnHour(show: LiveGridShow): boolean {
		return show.Duration > 15;
	}

	getDisplayedRange(): Moment[] {
		let rangeAmount = this.selectedRange.lastDay.diff(this.selectedRange.firstDay, 'days');
		let result: Moment[] = [];
		for (let i = 0; i <= rangeAmount; i++) {
			result.push(this.selectedRange.firstDay.add(i, 'days'));
		}
		return result;
	}

	showTooltip(show: LiveGridShow, cell: HTMLElement) {
		if (this.isMobileDevice()) {
			return;
		}
		this.selectedShowElement = new LiveGridShowElement(show, cell);
	}

	hideTooltip(event) {
		if (this.isMobileDevice()) {
			return;
		}
		//Don't hide tooltip if cursor is moved to tooltip
		if (!event.relatedTarget || !event.relatedTarget.closest(this.tooltipId)) {
			this.selectedShowElement = null;
		}
	}

	getShowTopPosition(show: LiveGridShow): number {
		let hourPosition: number = show.Datetime.get('hour');
		if (hourPosition < START_HOUR) {
			hourPosition += WORKING_HOURS;
		}
		hourPosition -= START_HOUR;
		return hourPosition * HOUR_HEIGHT + show.Datetime.get('minute') * MINUTE_HEIGHT;
	}

	getShowHeight(show: LiveGridShow): number {
		return show.Duration * MINUTE_HEIGHT - 1;
	}

	getGridHeight(): number {
		return WORKING_HOURS * HOUR_HEIGHT;
	}

	trackByTimestamp(index: number, day: Moment) {
		return day.valueOf();
	}

	trackByShowId(index: number, show: LiveGridShow) {
		return show.ScheduleitemId;
	}

	createProgramDetailsLink(brand: number, date: Moment): string {
		return `/programming/livegrid/${brand}/${date.format('YYYY-MM-DDTHH:mm:ss')}`;
	}

	isMobileDevice() {
		return this.viewportService.currentDevice === Device.MOBILE;
	}

	private isStartOfHour(date: moment.Moment): boolean {
		return date.minute() === 0;
	}
}