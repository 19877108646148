import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DropdownsResolve } from './add-headend-form/dropdowns-resolve.service';
import { AuthGuard } from '../../../../core/auth/auth.guard';
import { HeadendSummaryResolve } from '../shared/headend-summary-resolve.service';
import { HeadendIdGuard } from '../shared/headend-id-guard.service';
import { AddHeadendComponent } from './add-headend.component';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'signals/add-headend',
				component: AddHeadendComponent,
				canActivate: [AuthGuard],
				resolve: {
					dropdowns: DropdownsResolve
				}
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		DropdownsResolve,
		HeadendSummaryResolve,
		HeadendIdGuard
	]
})

export class AddHeadendRoutingModule {
}