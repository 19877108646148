import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFilters, BRANDS } from '../../filters/common-filters';
import { FilterOption } from '../../filters/filter-option';
import { ActiveFiltersService } from '../../filters/active-filters.service';
import { TechSpecsFilterOptionsService } from './tech-specs-filter-options.service';

export const FEED = 'Feed';
export const SATELLITE = 'Satellite';
export const TRANSPONDER = 'Transponder';
export const KEYWORD = 'Keyword';

@Component({
	template: require('./tech-specs-filter.component.html')
})
export class TechSpecsFilterComponent extends CommonFilters implements OnInit {
	feedOptions: FilterOption[] = [];
	satelliteOptions: FilterOption[] = [];
	transponderOptions: FilterOption[] = [];
	keyword: string;

	selectedFeedOptions: FilterOption[] = [];
	selectedSatelliteOptions: FilterOption[] = [];
	selectedTransponderOptions: FilterOption[] = [];

	KEYWORD = KEYWORD;
	FEED = FEED;
	SATELLITE = SATELLITE;
	TRANSPONDER = TRANSPONDER;

	private resourceUrl: string = '/resources/tech-specs';

	constructor(private route: ActivatedRoute,
	            private router: Router,
	            private activeFilters: ActiveFiltersService,
	            private filterOptionsService: TechSpecsFilterOptionsService) {
		super(activeFilters);
	}

	ngOnInit(): void {
		this.route.data.forEach(() => {
			this.filterOptionsService.getOptions().then((filterOptions: Map<string, FilterOption[]>) => {
				this.feedOptions = filterOptions.get(FEED) || [];
				this.satelliteOptions = filterOptions.get(SATELLITE) || [];
				this.transponderOptions = filterOptions.get(TRANSPONDER) || [];

				this.changeBrandsAndRelatedFilters(this.selectedBrandFilterOptions);
				this.selectedFeedOptions = this.findByIds(this.feedOptions, this.activeFilters.getFilter(this.FEED) || []);
				this.changeFeedsAndRelatedFilters(this.selectedFeedOptions);
				this.selectedSatelliteOptions = this.findByIds(this.satelliteOptions, this.activeFilters.getFilter(this.SATELLITE) || []);
				this.changeSatelliteAndRelatedFilters(this.selectedSatelliteOptions);
				this.selectedTransponderOptions = this.findByIds(this.transponderOptions, this.activeFilters.getFilter(this.TRANSPONDER) || []);

				this.keyword = this.activeFilters.getFilter(this.KEYWORD);
			});
		});
	}

	changeBrandsAndRelatedFilters(selectedBrands: FilterOption[]): void {
		this.selectedBrandFilterOptions = selectedBrands;
		this.selectedFeedOptions = [];
		this.selectedSatelliteOptions = [];
		this.selectedTransponderOptions = [];
		this.filterOptionsService.getOptions({
			[BRANDS]: this.selectedBrandFilterOptions
		}).then((filterOptions: Map<string, FilterOption[]>) => {
			this.feedOptions = filterOptions.get(FEED) || [];
			this.satelliteOptions = filterOptions.get(SATELLITE) || [];
			this.transponderOptions = filterOptions.get(TRANSPONDER) || [];
		});
	}

	changeFeedsAndRelatedFilters(selectedFeeds: FilterOption[]): void {
		this.selectedFeedOptions = selectedFeeds;
		this.selectedSatelliteOptions = [];
		this.selectedTransponderOptions = [];
		this.filterOptionsService.getOptions({
			[BRANDS]: this.selectedBrandFilterOptions,
			[FEED]: this.selectedFeedOptions
		}).then((filterOptions: Map<string, FilterOption[]>) => {
			this.satelliteOptions = filterOptions.get(SATELLITE) || [];
			this.transponderOptions = filterOptions.get(TRANSPONDER) || [];
		});
	}

	changeSatelliteAndRelatedFilters(selectedSatellites: FilterOption[]): void {
		this.selectedSatelliteOptions = selectedSatellites;
		this.selectedTransponderOptions = [];
		this.filterOptionsService.getOptions({
			[BRANDS]: this.selectedBrandFilterOptions,
			[FEED]: this.selectedFeedOptions,
			[SATELLITE]: this.selectedSatelliteOptions
		}).then((filterOptions: Map<string, FilterOption[]>) => {
			this.transponderOptions = filterOptions.get(TRANSPONDER) || [];
		});
	}

	applyFilters() {
		let filters = this.getFilterValues();
		filters[FEED] = this.extractIds(this.selectedFeedOptions);
		filters[SATELLITE] = this.extractIds(this.selectedSatelliteOptions);
		filters[TRANSPONDER] = this.extractIds(this.selectedTransponderOptions);
		filters[KEYWORD] = this.keyword;
		this.removeEmptyParams(filters);
		this.router.navigate([this.resourceUrl, filters]);
	}

	resetFilters() {
		this.resetCommonFilters();
		this.selectedFeedOptions = [];
		this.selectedSatelliteOptions = [];
		this.selectedTransponderOptions = [];
		this.keyword = null;
		this.router.navigate([this.resourceUrl]);
	}
}