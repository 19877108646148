import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'vcm-help-popup',
	template: require('./help-popup.component.html'),
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class HelpPopupComponent {
	isOpen: boolean;
	@Output() isOpenChanged = new EventEmitter();

	toggleDropdown(value: boolean): void {
		this.isOpen = value;
		this.isOpenChanged.emit(this.isOpen);
	}
}
