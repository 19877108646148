import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { DciiFormService } from './dcii-form.service';
import { SignalsDciiHeadend } from '../signals';

@Injectable()
export class DciiFormResolve implements Resolve<SignalsDciiHeadend> {
	constructor(private service: DciiFormService,
	            private mask: LoadingMaskService,
	            private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<SignalsDciiHeadend> {
		let dciiHeadendID: string = route.params['dciiHeadendID'];
		let dciiHeadendCountry: string = route.params['dciiHeadendCountry'];
		this.mask.addLoading();


		return this.service.getDciiForm({
			dciiHeadendID: dciiHeadendID,
			dciiHeadendCountry: dciiHeadendCountry,
		}).map((dciiForm: SignalsDciiHeadend = null) => {
			this.mask.removeLoading();
			return dciiForm;
		});
	}

}
