import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthorizationService } from '../../authorization.service';

@Injectable()
export class AuthorizationStepFinalGuard implements CanActivate {

	constructor(private authorizationService: AuthorizationService,
	            private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.authorizationService.authorizationRequestSubmitted) {
			return true;
		}

		this.router.navigate(['signals/device-grid']);
		return false;
	}
}