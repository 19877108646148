import { Directive, TemplateRef, ViewContainerRef, OnDestroy, OnInit } from '@angular/core';
import { UserService } from './user.service';
import { Subscription } from 'rxjs';
import { User } from './user';

@Directive({selector: '[vcmIfAuthenticated]'})
export class IfAuthenticatedDirective implements OnInit, OnDestroy {
	private userSubscription: Subscription;
	private authenticated: boolean = false;

	constructor(private userService: UserService,
	            private templateRef: TemplateRef<any>,
	            private viewContainer: ViewContainerRef) { }

	ngOnInit(): void {
		this.manageVisibility(this.authenticated, this.userService.getCurrentUser());
		this.userSubscription = this.userService.onChange.subscribe((user: User) => {
			this.manageVisibility(this.authenticated, user);
		});
	}

	private manageVisibility(isAuthenticated: boolean, user: User) {
		if (!isAuthenticated && user) {
			this.authenticated = true;
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else if (isAuthenticated && !user) {
			this.authenticated = false;
			this.viewContainer.clear();
		}
	}

	ngOnDestroy(): void {
		this.userSubscription.unsubscribe();
	}
}