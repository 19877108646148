import { Component, EventEmitter, Output } from '@angular/core';
import { ProfilePassword } from '../profile-password';
import { ChangePasswordService } from '../change-password.service';
import { LoadingMaskService } from '../../../../shared/loading-mask/loading-mask.service';

@Component({
	selector: 'vcm-change-password-form',
	template: require('./change-password-form.component.html')
})

export class ChangePasswordFormComponent {
	password: ProfilePassword = new ProfilePassword();
	verifyPassword: string;
	error: string;

	@Output()
	passwordChanged: EventEmitter<void> = new EventEmitter<void>();

	constructor(private mask: LoadingMaskService,
	            private changePasswordService: ChangePasswordService) {
	}

	public changePassword() {
		this.mask.addLoading();
		this.changePasswordService.changePassword(this.password).then((res) => {
			this.mask.removeLoading();
			if (res) {
				this.passwordChanged.emit(null);
			} else {
				this.error = 'Old password is not correct';
			}
			window.scrollTo(0, 0);
		})
	}
}