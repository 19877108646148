import { Component, OnInit, OnDestroy } from '@angular/core';
import { WizardComponent } from '../../../../shared/wizard/wizard-component';
import { AuthorizationService } from './authorization.service';
import { Router } from '@angular/router';
import { AuthorizationStepsService } from './authorization-steps.service';
import { AuthorizationRequestService } from './authorization-request.service';
import { LoadingMaskService } from '../../../../shared/loading-mask/loading-mask.service';
import { SignalsService } from '../../signals.service';
import { AuthorizationGrigService } from './steps/step2/authorization-grid.service';

@Component({
	selector: 'vcm-authorization',
	template: require('./authorization.component.html')
})

export class AuthorizationComponent extends WizardComponent implements OnInit, OnDestroy {

	constructor(public wizardStepsService: AuthorizationStepsService,
	            public authorizationService: AuthorizationService,
	            private authorizationRequestService: AuthorizationRequestService,
	            private authorizationGrigService: AuthorizationGrigService,
	            private signalsService: SignalsService,
	            private mask: LoadingMaskService,
	            private router: Router) {
		super(wizardStepsService);
	}

	ngOnInit() {
		this.authorizationService.authorizationRequest.HeadendId = this.signalsService.headendId;
	}

	public next(): any {
		this.authorizationService.beforeNextStep();
		this.authorizationService.addAvailableStep(this.wizardStepsService.getNextStep());
		return super.next();
	}

	public previous(): any {
		this.authorizationService.beforePreviousStep();
		this.authorizationService.currentForm = null;
		return super.previous();
	}

	canGoNext(): boolean {
		return this.authorizationService.canGoNext;
	}

	canGoPrevious(): boolean {
		return true;
	}

	getTotalStepsCount() {
		return this.getSteps()[this.getSteps().length - 1].order;
	}

	finish() {
		this.authorizationService.beforeNextStep();
		this.mask.addLoading();
		this.authorizationRequestService.saveUser(this.authorizationService.authorizationRequest)
			.then((response) => {
				this.mask.removeLoading();
				this.authorizationService.isRequestSavedSuccessfully = response;
				this.authorizationService.authorizationRequestSubmitted = true;
				this.router.navigate(['signals', 'authorization', 'step-final']);
			});
	}

	ngOnDestroy() {
		this.authorizationGrigService.clearGrigData();
	}
}