import { Component, Input, HostBinding } from '@angular/core';
import { AuthorizationRequest } from '../authorization-request';
import { HeadendSummary } from '../../../shared/headend-summary.service';

@Component({
	template: require('./summary.component.html'),
	selector: 'vcm-summary'
})

export class SummaryComponent {
	@Input('summary') summary: HeadendSummary;
	@Input('addInfo') addInfo: AuthorizationRequest;
	@HostBinding('class.vcm_summary') addClass: boolean = true;
}