import { Http, Response, RequestOptionsArgs } from '@angular/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { Observable } from 'rxjs';
import { HeadersClient } from './headers-client';
import { UserService } from '../auth/user.service';
import { LoggerService } from '../logger/logger.service';

@Injectable()

export class TokenService {

	public refreshUrl = this.config.baseUrl + '/api/Login/Refresh/?refreshToken=';
	private tokenRefreshObservable: Observable<string>;

	constructor(public http: Http,
	            private config: ConfigService,
	            private headers: HeadersClient,
	            private userService: UserService,
	            private logger: LoggerService) {
	}

	getAuthorizationHeader(): Observable<string> {
		if (!this.tokenRefreshObservable) {
			return Observable.of(this.getStoredAuthorizationHeader());
		} else {
			return this.tokenRefreshObservable;
		}
	}

	doRefreshToken(): Observable<string> {
		if (this.tokenRefreshObservable) {
			return this.tokenRefreshObservable;
		}
		let currentUser = this.userService.getCurrentUser();
		if (!currentUser) {
			return Observable.throw('User not found');
		}

		let refreshToken = currentUser.RefreshToken;

		let options: RequestOptionsArgs = this.headers.getRequestOptionsWithAuth(this.getStoredAuthorizationHeader());

		this.tokenRefreshObservable = this.http.put(this.refreshUrl + refreshToken, {}, options)
			.flatMap((response: Response) => {
				this.storeUpdatedAuthorizationToken(response);
				this.tokenRefreshObservable = null;
				return Observable.of(this.getStoredAuthorizationHeader());
			})
			.catch(error => {
				this.tokenRefreshObservable = null;
				return Observable.throw(error);
			})
			.share();
		return this.tokenRefreshObservable;
	}

	private storeUpdatedAuthorizationToken(response) {
		let currentUser = this.userService.getCurrentUser();
		currentUser.AuthorizationToken = response.text().substr(1, response.text().length - 2);
		this.userService.setCurrentUser(currentUser);
	}

	private getStoredAuthorizationHeader(): string {
		let result = null;
		let currentUser = this.userService.getCurrentUser();
		if (currentUser) {
			let authToken = currentUser.AuthorizationToken;

			if (authToken) {
				result = 'Bearer ' + authToken;
			}
		}
		return result;
	}

	deleteRefreshToken() {
		// delete refresh token on server
		let currentUser = this.userService.getCurrentUser();
		if (!currentUser || !currentUser.RefreshToken) {
			return;
		}

		return this.http.delete(this.config.baseUrl + '/api/Login/LogOut/?refreshToken=' + currentUser.RefreshToken + '&contactId=' + currentUser.ContactId)
			.subscribe(
				res => res.json(),
				err => this.logger.error(err));
	}
}