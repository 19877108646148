import { Component } from '@angular/core';
import { SignalsService } from '../../signals.service';
import { ActivatedRoute } from '@angular/router';
import { HeadendSummary } from '../shared/headend-summary.service';
import { SignalSource, SignalDecoder } from './add-request-form/dropdowns.service';

@Component({
	template: require('./add-request.component.html')
})

export class AddRequestComponent {
	isRequestSubmitted: boolean = false;
	headendId: number;
	headendSummary: HeadendSummary;

	dropdowns: any;
	private signalSources: SignalSource[];
	private signalDecoders: SignalDecoder[];

	constructor(private signalsService: SignalsService,
	            private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.headendId = this.signalsService.headendId;
		this.route.data.forEach((data: {
			dropdowns: any,
			headendSummary: HeadendSummary
		}) => {
			this.signalSources = data['dropdowns']['SignalSources'];
			this.signalDecoders = data['dropdowns']['DecoderTypes'];
			this.headendSummary = data.headendSummary;
		});
	}

	displayFeedbackSubmittedMessage(feedback): void {
		this.isRequestSubmitted = true;
	}
}
