import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
	template: require('./search.component.html')
})

export class SearchComponent {

	query: string;
	keyword: string;

	constructor(private route: ActivatedRoute,
	            private router: Router) {}

	ngOnInit() {
		this.route.params.subscribe((params: Params) => {
			this.query = params['query'];
		});
	}

	searchKeyword(): void {
		this.router.navigate(['search', {query: this.keyword}])
	}

	onInputChange(text: string): void {
		if (text[0] == ' ') {
			this.keyword.slice(0, 1);
		}
		this.keyword = text.trim();
	}
}
