import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../../../core/auth/auth.guard';
import { ValidateHeadendComponent } from './validate-headend.component';
import { HeadendSummaryResolve } from '../shared/headend-summary-resolve.service';
import { HeadendIdGuard } from '../shared/headend-id-guard.service';
import { HeadendSummaryClearGuard } from '../shared/headend-summary-clear.guard';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'signals/validate',
				component: ValidateHeadendComponent,
				canActivate: [AuthGuard, HeadendIdGuard],
				canDeactivate: [HeadendSummaryClearGuard],
				resolve: {headendSummary: HeadendSummaryResolve}
			}
		])
	],
	exports: [RouterModule],
	providers: [
		HeadendIdGuard,
		HeadendSummaryClearGuard
	]
})

export class ValidateHeadendRoutingModule {
}