import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FileName } from '../logo.service';
import { AssetsService } from '../../../../core/assets.service';
import {LogResourceDownloadService} from "../../log-resource-download.service";

@Component({
	selector: 'vcm-logo-download',
	template: require('./logo-download.component.html'),
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class LogoDownloadComponent {
	@Input() set logoImages(logoImages: FileName[]) {
		this._logoImages = this.getLogoImagesWithAbsolutePath(logoImages);
		this.selectedLogoImage = this._logoImages[0];
	}

	@Input() set serviceShortName(serviceShortName: string) {
		this._serviceShortName = serviceShortName;
	}

	get logoImages(): FileName[] {
		return this._logoImages;
	}

	selectedLogoImage: FileName;

	private _logoImages: FileName[];
	private _serviceShortName: string;

	constructor(private assetsService: AssetsService, private resourceDownloadLogger: LogResourceDownloadService) {}

	private getLogoImagesWithAbsolutePath(logoImages: FileName[]): FileName[] {
		logoImages.forEach((image: FileName) => {
			image.FileName = this.assetsService.getAssetUrl(image.FileName);
		});
		return logoImages;
	}

	logDownload(downloadUrl: string): void {
		this.resourceDownloadLogger.log(this._serviceShortName, 'logo', encodeURIComponent(downloadUrl));
	}
}