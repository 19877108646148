import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptionsArgs, Response } from '@angular/http';
import { Router } from '@angular/router';
import { TokenService } from './token.service';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../auth/authentication.service';
import { LoggerService } from '../logger/logger.service';

const EXPIRED_STATUS_TEXT = 'expired';
const UNAUTHORIZED_CODE = 401;

@Injectable()
export class HttpClient {

	constructor(public http: Http,
	            private router: Router,
	            private authService: AuthenticationService,
	            private tokenService: TokenService,
	            private logger: LoggerService) {
	}

	get(url: string, options?: RequestOptionsArgs): Observable<Response> {
		let requestExecutor: RequestExecutor = (authToken: string): Observable<Response> => {
			let authOptions: RequestOptionsArgs = this.getRequestOptionsWithHeaders(authToken);
			for (let key in options) {
				if (options.hasOwnProperty(key)) {
					authOptions[key] = options[key];
				}
			}
			return this.http.get(url, authOptions);
		};
		return this.doRequest(requestExecutor);
	};

	post(url, obj, options?: RequestOptionsArgs): Observable<Response> {
		let requestExecutor: RequestExecutor = (authToken: string): Observable<Response> => {
			let data = JSON.stringify(obj);
			let authOptions: RequestOptionsArgs = this.getRequestOptionsWithHeaders(authToken);
			for (let key in options) {
				if (options.hasOwnProperty(key)) {
					authOptions[key] = options[key];
				}
			}
			return this.http.post(url, data, authOptions);
		};
		return this.doRequest(requestExecutor);
	}

	put(url, body): Observable<Response> {
		let requestExecutor: RequestExecutor = (authToken: string): Observable<Response> => {
			let options: RequestOptionsArgs = this.getRequestOptionsWithHeaders(authToken);
			return this.http.put(url, body, options);
		};
		return this.doRequest(requestExecutor);
	}

	delete(url: string): Observable<Response> {
		let requestExecutor: RequestExecutor = (authToken: string): Observable<Response> => {
			let options: RequestOptionsArgs = this.getRequestOptionsWithHeaders(authToken);
			return this.http.delete(url, options);
		};
		return this.doRequest(requestExecutor);
	};

	private doRequest<T>(requestExecutor: RequestExecutor): Observable<Response> {
		return this.tokenService.getAuthorizationHeader()
			.flatMap((authToken: string) => {
					return requestExecutor(authToken)
						.map((resp: any) => {
							return resp;
						}).catch((error) => {
							return this.handleError(error, requestExecutor)
						});
				}
			);
	};

	private handleError(initialError, requestExecutor: RequestExecutor) {
		if (initialError.status === UNAUTHORIZED_CODE) {
			return this.handleUnauthorizedError(initialError, requestExecutor);
		} else {
			this.logger.error(`Server error occurred = ${initialError}`, true);
			return Observable.throw(initialError);
		}
	}

	private handleUnauthorizedError(initialError, requestExecutor: RequestExecutor): Observable<any> {
		if (this.isTokenExpired(initialError)) {
			return this.refreshTokenAndRetry(requestExecutor, initialError);
		} else {
			this.navigateToLogin();
			return Observable.throw(initialError);
		}
	}

	private refreshTokenAndRetry(requestCallback: RequestExecutor, initialError) {
		return this.tokenService.doRefreshToken()
			.flatMap(() => {
				return this.doRequest(requestCallback);
			}).catch(error => {
				this.navigateToLogin();
				return Observable.throw(initialError);
			})
	}

	private navigateToLogin() {
		this.authService.logout();
	}

	private navigateTo404() {
		this.router.navigate(['404']);
	}

	private getRequestOptionsWithHeaders(authToken: string): RequestOptionsArgs {
		return {
			headers: new Headers({
				'Accept': 'application/json, text/plain',
				'Content-Type': 'application/json, text/plain; charset=utf-8',
				'Authorization': authToken
			})
		}
	}

	private isTokenExpired(error: any) {
		let body = JSON.parse(error._body);
		return body === EXPIRED_STATUS_TEXT;
	}
}

interface RequestExecutor {
	(authToken: String): Observable<Response>;
}