import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	template: require('./user-activation.component.html')
})
export class UserActivationComponent implements OnInit {
	public userStatus: string;

	constructor(private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.route.data.forEach((data: {message: string}) => {
			this.userStatus = data.message;
		});
	}
}
