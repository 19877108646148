import { Component } from '@angular/core';
import { ProgrammingHighlights } from '../programming-highlights';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { Highlight } from '../dropdowns/highlights.service';

@Component({
	template: require('./programming-highlights-details.component.html'),
	selector: 'vcm-ph-details'
})

export class ProgrammingHighlightsDetailsComponent {
	brandsList: number[] = null;
	phDetails: ProgrammingHighlights = null;
	relatedProgram: number = null;
	selectedHighlight: Highlight;

	highlights: Highlight[] = [
		{
			Highlights: 'All',
			ServiceId: 0
		}
	];

	constructor(private route: ActivatedRoute,
	            private router: Router) {
		router.events.subscribe(event => {
			if (event instanceof RoutesRecognized) {
				this.relatedProgram = null;
			}
		})
	}

	ngOnInit() {
		this.route.data.forEach((data: {
			phDetails: ProgrammingHighlights,
			highlights: Highlight[]
		}) => {
			this.phDetails = data.phDetails;
			this.brandsList = this.createBrandsList(this.phDetails);
			this.relatedProgram = this.phDetails ? this.phDetails.RelatedProgramm : null;
			this.highlights = this.highlights.concat(data.highlights);
		});
	}

	createBrandsList(phDetails: ProgrammingHighlights): number[] {
		if (phDetails) {
			return phDetails.Brands.map(function (ph) {return ph.ServiceId})
		}
	}

	goToProgrammingHighlightsList(selectedHighlight?: Highlight): void {
		let id = selectedHighlight.ServiceId;
		this.router.navigate(['programming', 'highlights', id ? {brand: id} : {}]);
	}
}