import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { RegistrationComponent } from './registration.component';
import { RegistrationService } from './registration.service';

@Injectable()
export class RegistrationDeactivateGuard implements CanDeactivate<RegistrationComponent> {

	constructor(private registrationService: RegistrationService) {}

	canDeactivate(component: RegistrationComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
		this.registrationService.clear();
		return true;
	}
}