import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { FilterOption, PromotionFilters } from './filter-option';
import { ArrayUtils } from '../../../core/object-utils';
import {} from '../params.service';
import { PromotionFiltersService } from './promotion-filters.service';
import { ParamsListService } from '../params.service';

export const ALL_OPTION: FilterOption = {
	Id: null,
	Name: 'ALL'
};

@Component({
	template: require('./promotion-filters.component.html'),
	selector: 'vcm-promotion-filters'

})
export class PromotionFiltersComponent implements OnInit {
	brandOptions: FilterOption[];
	typeOptions: FilterOption[];
	objectiveOptions: FilterOption[];

	selectedBrandOption: FilterOption;
	selectedTypeOption: FilterOption;
	selectedObjectiveOption: FilterOption;
	keyword: string;
	periodFrom: Date;
	periodTo: Date;

	filters: PromotionFilters;
	DATE_FORMAT = 'YYYY-MM-DD';

	private promotionUrl: string = '/promotion';

	constructor(private route: ActivatedRoute,
	            private router: Router,
	            private paramsListService: ParamsListService) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: {filters: PromotionFilters}) => {
			this.brandOptions = [ALL_OPTION].concat(data.filters.Brands);
			this.typeOptions = [ALL_OPTION].concat(data.filters.Types);
			this.objectiveOptions = [ALL_OPTION].concat(data.filters.Objectives);
			this.applyParams(this.paramsListService.paramsList);
		});
	}

	resetFilters(): void {
		this.selectedBrandOption = this.brandOptions[0];
		this.selectedTypeOption = this.typeOptions[0];
		this.selectedObjectiveOption = this.objectiveOptions[0];
		this.keyword = null;
		this.periodFrom = null;
		this.periodTo = null;
		this.paramsListService.resetFilters();
		this.router.navigate(['promotion']);
	}

	applyParams(params: any): void {
		this.selectedBrandOption = params['Brand'] ?
			ArrayUtils.findByProperty(this.brandOptions, 'Id', +params['Brand']) :
			this.brandOptions[0];
		this.selectedTypeOption = params['Type'] ?
			ArrayUtils.findByProperty(this.typeOptions, 'Id', params['Type']) :
			this.typeOptions[0];
		this.selectedObjectiveOption = params['Objective'] ?
			ArrayUtils.findByProperty(this.objectiveOptions, 'Id', +params['Brand']) :
			this.objectiveOptions[0];
		this.keyword = params['Keyword'];
		this.periodFrom = params['PeriodFrom'] ? moment(params['PeriodFrom']).toDate() : null;
		this.periodTo = params['PeriodTo'] ? moment(params['PeriodTo']).toDate() : null;
	}

	useFilter() {
		this.paramsListService.paramsList.Brand = this.selectedBrandOption && this.selectedBrandOption.Id;
		this.paramsListService.paramsList.Type = this.selectedTypeOption && this.selectedTypeOption.Id;
		this.paramsListService.paramsList.Objective = this.selectedObjectiveOption && this.selectedObjectiveOption.Id;
		this.paramsListService.paramsList.PeriodFrom = this.periodFrom ? moment(this.periodFrom).format(this.DATE_FORMAT) : null;
		this.paramsListService.paramsList.PeriodTo = this.periodTo ? moment(this.periodTo).format(this.DATE_FORMAT) : null;
		this.paramsListService.paramsList.Keyword = this.keyword || null;
		this.paramsListService.resetPromo();

		this.router.navigate([this.promotionUrl, this.paramsListService.removeEmptyParams()]);
	}

	getFilterValues(): {[key: string]: string| string[]} {
		let filter: {[key: string]: string| string[]} = {};
		return filter;
	}

	removeEmptyParams(params: any) {
		for (let key in params) {
			if (params.hasOwnProperty(key) && !params[key]) {
				delete params[key];
			}
		}
	}
}