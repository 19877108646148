import { NgModule } from '@angular/core';
import { ModalComponent } from '../../../shared/modal/modal.component';
import { TranscludedComponent } from '../../../shared/modal/transcluded.component';
import { ConfirmModalComponent } from '../../../shared/modal/confirm-modal.component';
import { ModalModule } from 'ng2-bootstrap';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ProfileAddressService } from './profile-address.service';
import { ProfileAddressesListComponent } from './list/profile-addresses-list.component';
import { ProfileAddressesEditComponent } from './edit/profile-addresses-edit.component';
import { SharedModule } from '../../../shared/shared.module';
import { AddressesBlockComponent } from './list/address-block/address-block.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		HttpModule,
		ModalModule,
		SharedModule
	],
	declarations: [
		ProfileAddressesListComponent,
		ProfileAddressesEditComponent,
		ModalComponent,
		TranscludedComponent,
		ConfirmModalComponent,
		AddressesBlockComponent
	],
	providers: [
		ProfileAddressService
	]
})

export class ProfileAddressesModule {
}