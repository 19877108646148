import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
	selector: '[iseEqualValidator][formControlName],[iseEqualValidator][formControl],[iseEqualValidator][ngModel]',
	providers: [
		{provide: NG_VALIDATORS, useExisting: forwardRef(() => ISEEqualValidator), multi: true}
	]
})
export class ISEEqualValidator implements Validator {
	@Input('name') private formControlName;

	constructor() {}

	validate(control: AbstractControl): {[key: string]: any} {
		let value = control.value;
		let formValue = control.parent.value;

		if (value === '---')
			return null;

		let equalISENumber = 0;

				for (let prop in formValue) {
					if (value && prop.includes('ISE') && prop != this.formControlName
						&& formValue[prop] === value) {
						equalISENumber++;
					}
				}

		if (!value || equalISENumber > 0) {
			return {acpAddressEqualExist: true}
		}

		return null;
	}
}