import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response, RequestOptionsArgs } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserInfo, User } from './user';
import { ConfigService } from '../config.service';
import { TokenService } from '../http/token.service';
import { UserService } from './user.service';
import { AuthGuard } from './auth.guard';
import { HeadersClient } from '../http/headers-client';

@Injectable()
export class AuthenticationService {
	isRewardsInDomain: boolean = window.location.hostname.includes('rewards');
	onLogout: EventEmitter<void> = new EventEmitter<void>();
	private startPageCode: string;

	constructor(private http: Http,
	            private config: ConfigService,
	            private router: Router,
	            private token: TokenService,
	            private headers: HeadersClient,
	            private userService: UserService,
	            private authGuard: AuthGuard) {
	}

	login(login, pass, rememberMe): Observable<boolean> {
		const isRewardsParam: string = this.isRewardsInDomain ? '&isRewards=true' : '';
		const rememberMeParam: string = !!rememberMe ? '&rememberMe=true' : '';
		const options: RequestOptionsArgs = this.headers.getRequestOptions();

		return this.http.put(this.config.baseUrl + '/api/Login/?login=' + login + '&pass=' + pass + rememberMeParam + isRewardsParam, {}, options)
			.map((response: Response) => {
				const userResponse = response.json();
				if (userResponse[0]) {
					const currentUser: User = new User(login, userResponse[0]);
					const userInfo: UserInfo = new UserInfo(userResponse[1]);
					this.userService.setCurrentUser(currentUser, userInfo);
					this.startPageCode = currentUser.StartPageCode;
				}

				return this.userService.hasCurrentUser();
			});
	}

	logout(): void {
		this.authGuard.refUrl = '/home';
		this.token.deleteRefreshToken();
		this.userService.removeCurrentUser();
		this.onLogout.emit();
		this.router.navigate(['/login']);
	}

	redirect(): void {
		switch (this.startPageCode) {
			case 'PROFILE':
				this.router.navigate(['/profile']);
				break;
			default:
				this.router.navigate(['/' + this.authGuard.refUrl]);
		}
	}
}