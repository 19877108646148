import { LandingModule } from './landing/landing.module';
import { NgModule } from '@angular/core';
import { NavModule } from './navigation/nav.module';
import { HeaderModule } from './header/header.module';
import { FooterModule } from './footer/footer.module';
import { BackgroundModule } from './background/background.module';
import { LandingGridModule } from './landing-grid/landing-grid.module';

@NgModule({
	imports: [
		HeaderModule,
		NavModule,
		FooterModule,
		BackgroundModule,
		LandingModule,
		LandingGridModule
	],
	exports: [
		HeaderModule,
		NavModule,
		FooterModule,
		BackgroundModule,
		LandingModule,
		LandingGridModule
	]
})

export class ViewModule {
}