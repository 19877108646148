import { Injectable } from '@angular/core';
import { HttpClient } from '../../../core/http/http-client';
import { ConfigService } from '../../../core/config.service';
import { Response } from '@angular/http';
import { LoggerService } from '../../../core/logger/logger.service';

export class MenuItem {
	SubItems: MenuItem[] = null;
	Text: string;
	Url: string;
	IsGlobalLink: boolean;

	constructor(data = null) {
		if (data) {
			this.Text = data.Text;
			this.Url = data.Url;
			this.IsGlobalLink = this.isGlobal(data.Url);
			if (data.SubItems) {
				this.SubItems = [];
				data.SubItems.forEach((item) => {
					this.SubItems.push(new MenuItem(item));
				})
			}
		}
	}

	private isGlobal(link: string): boolean {
		return link ? link.includes('://') : false;
	}
}

@Injectable()
export class NavService {
	private menu: any;

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	getMenu(): Promise<any> {
		return this.http.get(this.configService.baseUrl + '/api/MainMenu')
			.toPromise()
			.then((res: Response) => {
				this.menu = [];
				res.json().forEach((item) => {
					this.menu.push(new MenuItem(item))
				});
				return this.menu;
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}

