export class ProfileAddress {
	addressID: number;
	city: string;
	co: string;
	country: string;
	name: string;
	postalCode: string;
	state: string;
	street1: string;
	street2: string;

	constructor(data = null) {
		if (data) {
			this.addressID = data.addressID;
			this.city = data.city;
			this.co = data.co;
			this.country = data.country;
			this.name = data.name;
			this.postalCode = data.postalCode;
			this.state = data.state;
			this.street1 = data.street1;
			this.street2 = data.street2;
		}
	}
}