import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthorizationComponent } from './authorization.component';
import { WizardStep, getWizardRoutes } from '../../../../shared/wizard/wizard-step';
import { AuthorizationStep1Component } from './steps/step1/authorization-step1.component';
import { AuthorizationStep2Component } from './steps/step2/authorization-step2.component';
import { AuthorizationGuard } from './authorization-guard.service';
import { AuthorizationDeactivateGuard } from './authorization-deactivate.service';
import { AuthGuard } from '../../../../core/auth/auth.guard';
import { AuthorizationStepsResolve } from './authorization-steps-resolve.service';
import { AuthorizationDropdownsResolve } from './steps/step1/authorization-dropdowns-resolve.service';
import { AuthorizationSummaryResolve } from './steps/step1/authorization-summary-resolve.service';
import { AuthorizationGridResolve } from './steps/step2/authorization-grid-resolve.service';
import { AuthorizationStep3Component } from './steps/step3/authorization-step3.component';
import { AuthorizationStepFinalGuard } from './steps/step-final/authorization-step-final.guard';
import { AuthorizationStepFinalComponent } from './steps/step-final/authorization-step-final.component';

export const AUTHORIZATION_STEPS: WizardStep[] = [
	{
		name: 'Step 1', order: 1,
		route: {
			path: 'step1', component: AuthorizationStep1Component,
			resolve: {
				dropdowns: AuthorizationDropdownsResolve,
				summary: AuthorizationSummaryResolve
			}
		}
	},
	{
		name: 'Step 2', order: 2,
		route: {
			path: 'step2', component: AuthorizationStep2Component,
			resolve: {
				gridData: AuthorizationGridResolve,
				summary: AuthorizationSummaryResolve
			}
		}
	},
	{
		name: 'Step 3', order: 3,
		route: {
			path: 'step3', component: AuthorizationStep3Component,
			resolve: {
				summary: AuthorizationSummaryResolve
			}
		}
	}
];
export const FIRST_STEP = AUTHORIZATION_STEPS[0];

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'signals/authorization',
				component: AuthorizationComponent,
				canActivate: [AuthGuard],
				canDeactivate: [AuthorizationDeactivateGuard],
				children: [
					{path: '', redirectTo: FIRST_STEP.route.path, pathMatch: 'full'},
					{
						path: '',
						canActivateChild: [AuthorizationGuard],
						children: getWizardRoutes(AUTHORIZATION_STEPS, AuthorizationStepsResolve)
					}
				]
			},
			{
				path: 'signals/authorization/step-final', pathMatch: 'full',
				component: AuthorizationStepFinalComponent, canActivate: [AuthGuard, AuthorizationStepFinalGuard]
			}
		])
	],
	exports: [
		RouterModule
	],
	providers: [
		AuthorizationGuard,
		AuthorizationDeactivateGuard,
		AuthorizationDropdownsResolve,
		AuthorizationSummaryResolve,
		AuthorizationGridResolve,
		AuthorizationStepFinalGuard
	]
})

export class AuthorizationRoutingModule {
}