import { Component, Input, OnInit } from '@angular/core';
import { OrderSpot, OrderService } from '../../order.service';

@Component({
	selector: 'vcm-order-grid',
	template: require('./order-grid.component.html')
})

export class OrderGridComponent implements OnInit {
	@Input() spots: OrderSpot[];

	checkedSpots: boolean[] = [];
	selectAllCheckboxValue: boolean;

	constructor(private orderService: OrderService) {
	}

	ngOnInit(){
		this.spots.forEach(() => this.checkedSpots.push(false));
	}

	selectAll(): void {
		this.checkedSpots = this.checkedSpots.map((value) => value = this.selectAllCheckboxValue);
		this.orderService.checkedSpots = this.checkedSpots;
	}

	checkSpot(index, id): void {
		this.selectAllCheckboxValue = false;
		this.orderService.checkedSpots = this.checkedSpots;

		if (this.checkedSpots.every((value: boolean) => value == true)) {
			this.selectAllCheckboxValue = true;
		}
	}
}
