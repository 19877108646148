import { Component, EventEmitter, Output, Input } from '@angular/core';
import { LoadingMaskService } from '../../../../../shared/loading-mask/loading-mask.service';
import { ValidateHeadendFormService } from './validate-headend-form.service';
import { HeadendSummary } from '../../shared/headend-summary.service';

@Component({
	selector: 'vcm-validate-headend-form',
	template: require('./validate-headend-form.component.html')
})

export class ValidateHeadendFormComponent {
	commentMessage: string = null;
	details: string[] = [];

	@Input() headendId: string;
	@Input() headendSummary: HeadendSummary;
	@Output() feedbackSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private validateHeadendFormService: ValidateHeadendFormService,
	            private mask: LoadingMaskService) {
	}

	onSubmit(): void {
		this.mask.addLoading();
		let request = Object.assign({
			Comments: this.commentMessage,
			HeadendId: this.headendId
		}, this.headendSummary);
		this.validateHeadendFormService.submitRequest(request)
			.then((resp) => {
				this.mask.removeLoading();
				this.feedbackSubmitted.emit(resp);
				window.scrollTo(0, 0);
			});
	}
}
