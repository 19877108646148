import { NgModule } from '@angular/core';
import { BackgroundService } from './background.service';
import { BackgroundDirective } from './background.directive';

@NgModule({
	declarations: [
		BackgroundDirective
	],
	exports: [
		BackgroundDirective
	],
	providers: [
		BackgroundService
	]
})

export class BackgroundModule {
}