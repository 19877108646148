import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DaysRange } from './days-range';
import { LiveBrand } from '../dropdowns/live-brands.service';
import { LiveGrid } from './live-grid-show';
import { LiveGridShowsService } from './live-grid-shows.service';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { ViewportService, DeviceChangedEvent } from '../../../core/viewport-service/viewport.service';
import { DaysRangeService } from './days-range-service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
	template: require('./live-grid.component.html'),
	selector: 'vcm-live-grid'
})
export class LiveGridComponent {
	liveBrands: LiveBrand[] = [];

	selectedBrand: LiveBrand;
	liveGrid: LiveGrid = new LiveGrid();

	currentDevice = this.viewportService.currentDevice;
	availableRanges: DaysRange[] = this.rangeService.getAvailableRanges(this.currentDevice);
	selectedRange: DaysRange = this.availableRanges[0];

	isIFrame = false;
	externalUrl: any;

	private viewportChangeSubscription: Subscription;

	constructor(private route: ActivatedRoute,
	            private router: Router,
	            private gridShowsService: LiveGridShowsService,
	            private mask: LoadingMaskService,
	            private viewportService: ViewportService,
	            private rangeService: DaysRangeService,
				private sanitizer: DomSanitizer) {}

	ngOnInit() {
		this.handleViewportChanges();
		this.route.data.forEach((data: {
			liveBrands: LiveBrand[],
			selectedBrand: LiveBrand
		}) => {
			this.liveBrands = data.liveBrands;
			this.selectedBrand = data.selectedBrand;
			this.getBrandChannels(this.selectedBrand, this.selectedRange);
		});
	}

	selectRange(range: DaysRange): void {
		this.selectedRange = range;
		this.getBrandChannels(this.selectedBrand, this.selectedRange)
	}

	getBrandChannels(brand: LiveBrand, range: DaysRange = this.selectedRange) {
		if (!brand || !range) {
			return;
		}
		this.mask.addLoading();
		this.gridShowsService.getLiveGridShows(brand, range.firstDay, range.lastDay)
			.then((liveGrid: LiveGrid) => {
				this.mask.removeLoading();
				this.liveGrid = liveGrid;
			})
	}

	private handleViewportChanges() {
		this.viewportChangeSubscription = this.viewportService.viewportChanged.subscribe((event: DeviceChangedEvent) => {
			this.currentDevice = event.device;
			this.availableRanges = this.rangeService.getAvailableRanges(this.currentDevice);
			this.selectRange(this.availableRanges[0]);
		});
	}

	exportToFile(fileType: string) {
		this.gridShowsService.exportToFile(fileType, this.selectedBrand, this.selectedRange.firstDay, this.selectedRange.lastDay)
	}

	goToLiveGrid(selectedBrand?: LiveBrand): void {
		let id = selectedBrand.ServiceId;

		if (selectedBrand.ExternalUrl == null || selectedBrand.ExternalUrl == '') {
			this.isIFrame = false;
			this.externalUrl = null;
			this.router.navigate(['programming', 'livegrid', id]);
		}
		else
		{
			this.externalUrl = this.sanitizer.bypassSecurityTrustResourceUrl(selectedBrand.ExternalUrl);
			this.isIFrame = true;
		}
	}
}