import { Injectable } from '@angular/core';
import { ResourcesService } from '../resources.service';

export class Other {
	ContentName: string;
	LegalText: string;
}

@Injectable()
export class OtherService {

	constructor(private resourcesService: ResourcesService) {}

	getOther(filter: any = {}): Promise<Other[]> {
		filter.resourceType = 'research';
		return this.resourcesService.getResourcesList(filter);
	}
}
