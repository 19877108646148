import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Promotion, Spot } from '../promotion.service';
import { ArrayUtils } from '../../../core/object-utils';
import { ActivatedRoute, Router } from '@angular/router';
import { ParamsList, ParamsListService } from '../params.service';

@Component({
	selector: 'vcm-promotion-details',
	template: require('./promotion-details.component.html')
})

export class PromotionDetailsComponent implements OnInit, OnChanges {
	@Input() promotionDetails: Promotion;
	@Input() spotList: Spot[];

	isDescrVisible: boolean = false;
	isSpotEmpty: boolean = true;
	headerText: string;
	selectedSpot: Spot = new Spot();
	private promotionUrl: string = '/promotion';

	constructor(private paramsListService: ParamsListService,
	            private route: ActivatedRoute,
	            private router: Router) {
	}

	ngOnInit() {
		this.initialize();
		this.headerText = this.route.snapshot.params['mainmenugroup'] || 'PROMOTIONS';
	}

	ngOnChanges() {
		this.initialize();
	}

	applyDropdown(params: ParamsList): void {
		if (params.Spot) {
			this.selectedSpot = ArrayUtils.findByProperty(this.spotList, 'Id', +params.Spot);
			if (!this.selectedSpot) {
				this.paramsListService.paramsList.Spot = null;
			}
		} else {
			this.selectedSpot = this.spotList[0];
		}
	}

	selectSpot(spot: Spot): void {
		this.paramsListService.paramsList.PromoId = this.promotionDetails.Id.toString();
		this.paramsListService.paramsList.Spot = spot.Id.toString();
		if (!this.isSpotEmpty) {
			this.router.navigate([this.promotionUrl, this.paramsListService.removeEmptyParams()]);
		} else {
			this.isSpotEmpty = false;
		}
	}

	toggleDescr(): void {
		this.isDescrVisible = !this.isDescrVisible;
	}

	private initialize(): void {
		this.applyDropdown(this.paramsListService.paramsList);
		this.isSpotEmpty = true;
	}
}
