import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { DropdownsService } from './dropdowns.service';
import { LoadingMaskService } from '../../../../../shared/loading-mask/loading-mask.service';

@Injectable()
export class DropdownsResolve implements Resolve<any> {
	constructor(private service: DropdownsService,
	            private loadingMask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<any> {
		this.loadingMask.addLoading();
		return this.service.getDropdowns().then((dropdowns) => {
			this.loadingMask.removeLoading();
			return dropdowns
		});
	}
}
