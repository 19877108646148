import { NgModule }      from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { SignalsComponent } from './signals.component';
import { DeviceGridComponent } from './device-grid/device-grid.component';
import { HeadendGridComponent } from './device-grid/headend/headend-grid.component';
import { DeviceGridService } from './device-grid/device-grid.service';
import { SharedModule } from '../../shared/shared.module';
import { SignalsRoutingModule } from './signals-routing.module';
import { SignalsService } from './signals.service';
import { DropHeadendModule } from './requests/drop-headend/drop-headend.module';
import { RemoveHeadendModule } from './requests/remove-headend/remove-headend.module';
import { ValidateHeadendModule } from './requests/validate-headend/validate-headend.module';
import { AuthorizationModule } from './requests/authorization/authorization.module';
import { HeadendSummaryService } from './requests/shared/headend-summary.service';
import { AddRequestModule } from './requests/add-request/add-request.module';
import { SwapRequestModule } from './requests/swap-request/swap-request.module';
import { SignalsLandingModule } from './signals-landing/signals-landing.module';
import { AddHeadendModule } from './requests/add-headend/add-headend.module';
import { DciiGridComponent } from './dcii/dcii-grid.component';
import { DciiGridService } from './dcii/dcii-grid.service';
import { DciiFormComponent } from './dcii/dcii-form.component';
import { DciiFormService } from './dcii/dcii-form.service';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports: [
		SharedModule,
		CommonModule,
		RouterModule,
		HttpModule,
		SignalsRoutingModule,
		DropHeadendModule,
		ValidateHeadendModule,
		AddRequestModule,
		AddHeadendModule,
		SwapRequestModule,
		RemoveHeadendModule,
		AuthorizationModule,
		SignalsLandingModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule
	],
	declarations: [
		SignalsComponent,
		DeviceGridComponent,
		DciiGridComponent,
		DciiFormComponent,
		HeadendGridComponent
	],
	exports: [
		SignalsComponent
	],
	providers: [
		DeviceGridService,
		DciiGridService,
		DciiFormService,
		SignalsService,
		HeadendSummaryService
	]
})

export class SignalsModule {
}