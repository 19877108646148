import { Injectable } from '@angular/core';
import { HttpClient } from '../../../core/http/http-client';
import { ConfigService } from '../../../core/config.service';
import { Response, ResponseContentType } from '@angular/http';
import { LiveBrand } from '../dropdowns/live-brands.service';
import { LiveGridShow, LiveGrid } from './live-grid-show';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import Moment = moment.Moment;
import { AuthenticationService } from '../../../core/auth/authentication.service';
import { LoggerService } from '../../../core/logger/logger.service';

export const GRID_DAY_FORMAT: string = 'MM/DD/YY';
export const START_HOUR: number = 6;
export const WORKING_HOURS: number = 24;

@Injectable()
export class LiveGridShowsService {
	private subject: Subject<number> = new Subject<number>();
	private _liveGridId: number;

	get liveGridId(): number {
		return this._liveGridId;
	}

	private liveGridCache: Map<string, LiveGrid> = new Map<string, LiveGrid>();
	private DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSS';

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private authService: AuthenticationService,
	            private logger: LoggerService) {
		this.authService.onLogout.subscribe(() => {
			this.liveGridCache.clear();
		});
	}

	setLiveGridId(id: number) {
		this._liveGridId = id;
		this.subject.next(this._liveGridId);
	}

	getLiveGridId(): Observable<number> {
		let source = Observable.empty();

		return <Observable<number>>source.startWith(this._liveGridId).concat(this.subject);
	}

	getLiveGridShows(brand: LiveBrand, startDate: Moment | Date, endDate: Moment | Date): Promise<LiveGrid> {
		let from: string = moment(startDate).hour(6).startOf('hour').format(this.DATE_FORMAT);
		let to: string = moment(endDate).add(1, 'days').hour(5).minute(59).second(59).milliseconds(999).format(this.DATE_FORMAT);

		let key = this.getCacheKey(brand, from, to);

		if (this.liveGridCache.has(key)) {
			return Promise.resolve(this.liveGridCache.get(key));
		}
		return this.loadLiveGridShows(brand, from, to);
	}

	private loadLiveGridShows(brand: LiveBrand, from: string, to: string): Promise<LiveGrid> {
		return this.http.get(this.getRequestUrl(brand, from, to))
			.toPromise()
			.then((res: Response) => {
				let liveGrid: LiveGrid = this.parseToLiveGrid(res.json());
				this.liveGridCache.set(this.getCacheKey(brand, from, to), liveGrid);
				return liveGrid;
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	private parseToLiveGrid(resp: any[]): LiveGrid {
		let result: LiveGrid = new LiveGrid();
		resp.forEach((json: any) => {
			let gridShow: LiveGridShow = new LiveGridShow(json);
			this.addToLiveGrid(gridShow, result);
		});
		return result;
	}

	private addToLiveGrid(gridShow: LiveGridShow, result: LiveGrid) {
		let dayKey: string = this.getDayKey(gridShow);
		if (!result.schedule.has(dayKey)) {
			result.schedule.set(dayKey, []);
		}
		result.schedule.get(dayKey).push(gridShow);
	}

	private getDayKey(gridShow: LiveGridShow) {
		if (gridShow.Datetime.hour() < START_HOUR) {
			return gridShow.Datetime.subtract(1, 'day').format(GRID_DAY_FORMAT);
		}
		return gridShow.Datetime.format(GRID_DAY_FORMAT);
	}

	private getCacheKey(brand: LiveBrand, fromStr: string, toStr: string) {
		return `${brand.ServiceId}_${fromStr}_${toStr}`;
	}

	private getRequestUrl(brand: LiveBrand, fromStr: string, toStr: string) {
		return this.configService.baseUrl + `/api/Livegrid/?premiereOnly=false&chanelCode=${brand.ServiceId}&startDate=${fromStr}&endDate=${toStr}`;
	}

	private getDownloadUrl(fileType: string, brand: LiveBrand, fromStr: string, toStr: string) {
		return this.configService.baseUrl + `/api/Export${fileType}LiveGrid?premiereOnly=false&chanelCode=${brand.ServiceId}&startDate=${fromStr}&endDate=${toStr}`;
	}

	clearParams(): void {
		this.setLiveGridId(null);
	}

	exportToFile(fileType: string, brand: LiveBrand, startDate: Moment | Date, endDate: Moment | Date): Promise<Response> {
		let fromStr: string = moment(startDate).hour(6).startOf('hour').format(this.DATE_FORMAT);
		let toStr: string = moment(endDate).add(1, 'days').hour(5).minute(59).second(59).milliseconds(999).format(this.DATE_FORMAT);

		return this.http.get(this.getDownloadUrl(fileType, brand, fromStr, toStr), {responseType: ResponseContentType.Blob})
			.toPromise()
			.then(res => {
				this.downloadFile(res);
				return res;
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	downloadFile(data: Response) {
		let blob = new Blob([data.blob()], {type: 'application/octet-stream'});
		let filename = 'livegrid.xlsx';

		if (data.headers.has('Content-Disposition')) {
			let cdHeader = data.headers.get('Content-Disposition');
			filename = cdHeader.slice(cdHeader.indexOf('"') + 1, -1);
		}

		FileSaver.saveAs(blob, filename);
	}

}
