import { Component, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'vcm-confirm-modal',
	template: require('./confirm-modal.component.html')
})
export class ConfirmModalComponent {
	@Output()
	private confirm: EventEmitter<any> = new EventEmitter<any>();

	public confirmAction(){
		this.confirm.emit(null);
	}

	public modalButtonName: string = 'DELETE';
	public modalTitle: string = 'Are you sure?';
	public modalBody: string = 'This shipping address will be deleted';
	public modalPrimaryButton: string = 'Yes, DELETE';
}