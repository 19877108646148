import { Injectable } from '@angular/core';
import { ConfigService } from '../../core/config.service';
import { HttpClient } from '../../core/http/http-client';
import { AuthenticationService } from '../../core/auth/authentication.service';
import { FilterOption } from './filters/filter-option';
import { Response } from '@angular/http';
import { LoggerService } from '../../core/logger/logger.service';

export class ResourceBrand extends FilterOption {
	constructor(id: string, name: string) {
		super(id, name);
	}
}

@Injectable()
export class ResourcesBrandsService {
	brandFilterOptions: ResourceBrand[] = [];
	selectedBrandFilterOptions: ResourceBrand[] = [];

	private url = this.configService.baseUrl + '/api/AvailableBrands?contentType=';

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private authService: AuthenticationService,
	            private logger: LoggerService) {
		this.authService.onLogout.subscribe(() => {
			this.brandFilterOptions = [];
			this.selectedBrandFilterOptions = [];
		});
	}

	getBrandByName(brandName: string): ResourceBrand {
		return this.brandFilterOptions.find(brand => brand.Name === brandName);
	}

	getBrandsFromIds(brands: string[]): ResourceBrand[] {
		return this.brandFilterOptions.filter(brand => brands.indexOf(String(brand.Id)) >= 0);
	}

	loadBrands(resourceType: string): Promise<ResourceBrand[]> {
		return this.http.get(this.url + resourceType)
			.toPromise()
			.then((res: Response) => {
				this.brandFilterOptions = res.json()
					.map(brandResponse => new ResourceBrand(brandResponse.Id, brandResponse.Description));
				return this.brandFilterOptions;
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}