import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { LiveBrandsService, LiveBrand } from './live-brands.service';

@Injectable()
export class LiveBrandsResolve implements Resolve<LiveBrand[]> {
	constructor(private service: LiveBrandsService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<LiveBrand[]> {
		this.mask.addLoading();
		return this.service.getLiveBrands()
			.then((liveBrands: LiveBrand[]) => {
				this.mask.removeLoading();
				return liveBrands;
			});
	}
}
