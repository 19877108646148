import { Directive, Input, ElementRef, Renderer } from '@angular/core';

@Directive({
	selector: '[vcmDefaultImage]',
	host: {
		'(error)': 'updateUrl()',
		'[src]': 'src'
	}
})
export class DefaultImageValidator {
	@Input('src') src: string;

	constructor(private elRef: ElementRef,
	            private renderer: Renderer) {
	}

	private updateUrl() {
		this.renderer.setElementAttribute(this.elRef.nativeElement, 'old-src', this.src);
		this.src = '/app_assets/images/no_img.jpg';
	}
}
