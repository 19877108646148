import { Injectable } from '@angular/core';
import { ConfigService } from '../../../core/config.service';
import { HttpClient } from '../../../core/http/http-client';
import { LoggerService } from '../../../core/logger/logger.service';

export class RecourseType {
	Id: string;
	Description: string;

	constructor(data?) {
		if (data) {
			this.Id = data.Id;
			this.Description = data.Description;
		}
	}
}

@Injectable()
export class LandingGridService {
	private url = this.configService.baseUrl + '/api/LandingPageResourceGrid';
	private urlSearch = this.configService.baseUrl + '/api/Search';

	constructor(private configService: ConfigService,
	            private http: HttpClient,
	            private logger: LoggerService) {
	}

	getLandingGrid(params?: any): Promise<any> {
		return this.http.post(this.url, params || {})
			.toPromise()
			.then(res => {
				return res.json();
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}

	getSearchGrid(params: any): Promise<any> {
		return this.http.post(this.urlSearch, params)
			.toPromise()
			.then(res => {
				return res.json();
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}