import {
	Component, Input, Output, EventEmitter, forwardRef, HostBinding,
	ChangeDetectionStrategy, ChangeDetectorRef, NgZone
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { coerceBooleanProperty } from '../boolean-property';

export const TEXTAREA_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => TextareaComponent),
	multi: true
};

export class TextareaChange {
	source: TextareaComponent;
	value: any[];
}

@Component({
	selector: 'vcm-textarea',
	template: require('./textarea.component.html'),
	providers: [TEXTAREA_CONTROL_VALUE_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextareaComponent implements ControlValueAccessor {
	@Input('name') name: string;
	@Input('label') label: string;
	@Output() change: EventEmitter<TextareaChange> = new EventEmitter<TextareaChange>();
	@HostBinding('class.vcm-textarea') addClass: boolean = true;
	public modelValue: any;

	@Input()
	get disabled(): boolean {
		return this._disabled;
	}

	set disabled(value) {
		this._disabled = coerceBooleanProperty(value);
	}

	private _controlValueAccessorChangeFn: (value: any) => void = (value) => {
	};
	private onTouched: () => any = () => {
	};
	private _disabled: boolean = false;

	constructor(private ref : ChangeDetectorRef ) {
	}

	updateModel(modelValue: any) {
		this.modelValue = modelValue;
		this._emitChangeEvent();
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	writeValue(value: any) {
		this.modelValue = value;
		this.ref.markForCheck();
		if (this.modelValue) {
			this._controlValueAccessorChangeFn(this.modelValue);
		}
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	registerOnChange(fn: (value: any) => void) {
		this._controlValueAccessorChangeFn = fn;
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	/**
	 * Implemented as part of ControlValueAccessor.
	 */
	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	_onInputBlur() {
		this.onTouched();
	}

	private _emitChangeEvent() {
		let event = new TextareaChange();
		event.source = this;
		event.value = this.modelValue;

		this._controlValueAccessorChangeFn(this.modelValue);
		this.change.emit(event);
	}
}
