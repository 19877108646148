import { Injectable } from '@angular/core';
import { WizardStepsService } from '../../shared/wizard/wizard-step.service';
import { Router } from '@angular/router';
import { REGISTRATION_STEPS } from './registration-routing.module';

@Injectable()
export class RegistrationStepsService extends WizardStepsService {

	constructor(router: Router) {
		super(router, 'registration/', REGISTRATION_STEPS);
	}
}