import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Press } from './press.service';
import {LogResourceDownloadService} from "../log-resource-download.service";

@Component({
	template: require('./press.component.html')
})

export class PressComponent {

	presses: Press[] = [];

	constructor(private route: ActivatedRoute, private resourceDownloadLogger: LogResourceDownloadService) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: { presses: Press[] }) => {
			this.presses = data.presses;
		});
	}

	logDownload(servicesShortName: string, downloadUrl: string): void {
		this.resourceDownloadLogger.log(servicesShortName, 'press', encodeURIComponent(downloadUrl));
	}
}
