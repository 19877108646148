import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Landing } from '../view/landing/landing.service';
import { BackgroundService } from '../view/background/background.service';

@Component({
	template: require('./brands-details.component.html')
})

export class BrandsDetailsComponent implements OnInit, OnDestroy {
	brandID: string;
	landing: Landing;

	constructor(private backgroundService: BackgroundService,
	            private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.route.data.forEach((data: { landing: Landing }) => {
			this.landing = data.landing;
		});

		this.route.params.forEach((params: { brandsID: string }) => {
			this.brandID = params.brandsID;
			this.backgroundService.setBackgroundImage(this.brandID);
		});
	}

	ngOnDestroy() {
		this.backgroundService.setBackgroundImage('user_bg')
	}
}
