import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RegistrationService } from '../../registration.service';
import { ActivatedRoute } from '@angular/router';
import { RegistrationUser } from '../../shared/registration-user';

@Component({
	template: require('./registration-step5.component.html')
})

export class RegistrationStep5Component implements OnInit {
	public user: RegistrationUser;

	@ViewChild('step5') currentForm: NgForm;

	constructor(private registrationService: RegistrationService,
	            private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.route.data.forEach((data: {}) => {
			this.user = this.registrationService.user;
			this.registrationService.currentForm = this.currentForm;
		});
	}
}