import { Injectable } from '@angular/core';
import { HttpClient } from '../../../../core/http/http-client';
import { ConfigService } from '../../../../core/config.service';
import { AuthorizationRequest } from './shared/authorization-request';
import { LoggerService } from '../../../../core/logger/logger.service';

@Injectable()
export class AuthorizationRequestService {
	private url = this.configService.baseUrl + '/api/AuthorizeSignalStep3Submit';

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	saveUser(request: AuthorizationRequest): Promise<boolean> {
		return this.http.post(this.url, request)
			.toPromise()
			.then(res => {
				return res.json();
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}