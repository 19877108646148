import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthorizationService } from './authorization.service';
import { AuthorizationStepsService } from './authorization-steps.service';
import { SignalsService } from '../../signals.service';

@Injectable()
export class AuthorizationGuard implements CanActivateChild {
	constructor(private router: Router,
	            private authorizationService: AuthorizationService,
	            private signalsService: SignalsService,
	            private stepsService: AuthorizationStepsService) {
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (!this.signalsService.headendId) {
			this.router.navigate(['signals/device-grid']);
			return false;
		}
		if (this.isFirstStep(childRoute) || this.isStepAvailable(childRoute)) {
			return true;
		}
		this.router.navigate([this.stepsService.baseUrl + this.getFirstStep().route.path]);
		return false;
	}

	private isFirstStep(childRoute: ActivatedRouteSnapshot) {
		let firstStep = this.getFirstStep();
		return firstStep.route.component === childRoute.component;
	}

	private isStepAvailable(childRoute: ActivatedRouteSnapshot) {
		let availableSteps = this.authorizationService.availableSteps;
		for (let i = 0; i < availableSteps.length; i++) {
			let step = availableSteps[i];
			if (step.route.component === childRoute.component) {
				return true;
			}
		}
		return false;
	}

	private getFirstStep() {
		return this.stepsService.getSteps()[0];
	}
}