import {
	Directive, ElementRef, Output, EventEmitter, Input, HostListener
} from '@angular/core';

@Directive({
	selector: '[clickOutside]'
})

export class ClickOutsideDirective {
	@Input() isOpen: boolean = true;
	@Output() clickOutside = new EventEmitter<MouseEvent>();

	constructor(private _elementRef: ElementRef) {
	}

	@HostListener('document:mousedown', ['$event', '$event.target'])
	public onClick(event: MouseEvent, targetElement: HTMLElement): void {
		if (!targetElement || !this.isOpen) {
			return;
		}

		const clickedInside = this._elementRef.nativeElement.contains(targetElement);
		if (!clickedInside) {
			this.clickOutside.emit(event);
		}
	}
}
