import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/auth/auth.guard';
import { SignalsComponent } from './signals.component';
import { DeviceGridComponent } from './device-grid/device-grid.component';
import { DeviceGridResolve } from './device-grid/device-grid-resolve.service';
import { SignalsLandingComponent } from './signals-landing/signals-landing.component';
import { LandingResolve } from '../view/landing/landing-resolve.service';
import { DciiGridResolve } from './dcii/dcii-grid-resolve.service';
import { DciiGridComponent } from './dcii/dcii-grid.component';
import { DciiFormComponent } from './dcii/dcii-form.component';
import { DciiFormResolve } from './dcii/dcii-form-resolve.service';
import { DciiDropdownsResolve } from './dcii/dcii-form-dropdowns-resolve.service';

@NgModule({
	imports: [
/*		RouterModule.forChild([
			{
				path: 'signals',
				component: SignalsComponent,
				canActivate: [AuthGuard],
				resolve: {},
				children: [
					{
						path: '',
						component: SignalsLandingComponent,
						resolve: {
							landing: LandingResolve
						},
						data: {
							landing: {
								landingType: 'ENGINEER'
							}
						}
					},
					{
						path: 'device-grid',
						component: DeviceGridComponent,
						resolve: {
							headends: DeviceGridResolve
						}
					},
					{
						path: 'powervumigration',
						component: DciiGridComponent,
						resolve: {
							headends: DciiGridResolve
						}
					},
					{
						path: 'powervumigration/:dciiHeadendID',
						component: DciiFormComponent,
						resolve: {
							headend: DciiFormResolve,
							dropdowns: DciiDropdownsResolve
						}
					}
				]
			}
		])*/
	],
	exports: [
		RouterModule
	],
	providers: [
		DeviceGridResolve,
		DciiGridResolve,
		DciiFormResolve,
		DciiDropdownsResolve,
		LandingResolve
	]
})
export class SignalsRoutingModule {
}
