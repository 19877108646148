import { FilterOption } from './filter-option';
import { ActiveFiltersService } from './active-filters.service';

export const BRANDS = 'Brands';

export class CommonFilters {
	BRANDS = BRANDS;

	brandFilterOptions: FilterOption[] = [];
	selectedBrandFilterOptions: FilterOption[] = [];

	constructor(private activeFiltersService: ActiveFiltersService) {
		this.brandFilterOptions = activeFiltersService.brandFilterOptions;
		this.selectedBrandFilterOptions = activeFiltersService.selectedBrandFilterOptions;
	}

	getFilterValues(): {[key: string]: string| string[]} {
		let filter: {[key: string]: string| string[]} = {};
		if (this.selectedBrandFilterOptions && this.selectedBrandFilterOptions.length > 0) {
			filter[this.BRANDS] = this.selectedBrandFilterOptions.map(option => option.Id);
		}

		return filter;
	}

	resetCommonFilters(): void {
		this.activeFiltersService.selectedBrandFilterOptions = [];
		this.selectedBrandFilterOptions = [];
	}

	findByIds(filterOptions: FilterOption[], ids: string[]): FilterOption[] {
		return filterOptions.filter(option => ids.indexOf(String(option.Id)) >= 0);
	}

	findById(filterOptions: FilterOption[], id: string): FilterOption {
		return id ? filterOptions.find(option => String(id) === String(option.Id)) : null;
	}

	removeEmptyParams(params: any): void {
		for (let key in params) {
			if (params.hasOwnProperty(key) && !params[key]) {
				delete params[key];
			}
		}
	}

	getResourceUrl(currentUrl: string): string {
		let result = currentUrl;
		if (currentUrl.indexOf(';') >= 0) {
			result = result.slice(0, currentUrl.indexOf(';'));
		}
		return result;
	}

	extractIds(filterOptions: FilterOption[]) {
		return filterOptions.length > 0 && filterOptions.map(option => option.Id) || null;
	}
}