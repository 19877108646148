import { NgModule }      from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { ProfileContactsComponent } from "./profile-contacts.component";
import { SharedModule } from '../../../shared/shared.module';
import { ProfileDropdownsService } from './profile-dropdowns.service';
import { ProfileContactsFormComponent } from './profile-contacts-form/profile-contacts-form.component';
import { ProfileStateService } from './profile-state.service';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		HttpModule,
		SharedModule
	],
	declarations: [
		ProfileContactsComponent,
		ProfileContactsFormComponent
	],
	providers: [
		ProfileDropdownsService,
		ProfileStateService
	],
	exports: [ProfileContactsComponent]
})

export class ProfileContactsModule {
}


