import { Injectable, EventEmitter } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '../../../../../../core/http/http-client';
import { ConfigService } from '../../../../../../core/config.service';
import { AuthorizationService, unmask } from '../../authorization.service';
import { SignalsService } from '../../../../signals.service';
import {
	AuthorizationServiceUplink,
	AuthorizationGridData,
	AuthorizationTransponder
} from '../../shared/authorization-request';
import { LoggerService } from '../../../../../../core/logger/logger.service';

@Injectable()
export class AuthorizationGrigService {
	checkboxesStatusUpdated: EventEmitter<void> = new EventEmitter<void>();
	servicesCheckedNumber: number = 0;
	private transpondersCheckedNumber: number;
	private transpondersCheckedStatus: boolean[] = [];
	private _currentGridData: AuthorizationGridData;

	constructor(private http: HttpClient,
	            private signalsService: SignalsService,
	            private signalsRequestService: AuthorizationService,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	clearGrigData(): void {
		this._currentGridData = null;
	}

	getGrigData(): Promise<AuthorizationGridData> {
		let url = this.getGridUrl();
		if (this._currentGridData) {
			return Promise.resolve(this._currentGridData);
		}

		return this.loadGrigData(url);
	}

	updateCheckboxesStatus(value: boolean, index: number): void {
		this.changeServicesStatus(value);
		this.changeTranspodersStatus(index);
		this.checkboxesStatusUpdated.emit(null);
	}

	updateServicesCheckedNumber(): void {
		this.servicesCheckedNumber = 0;
		this.transpondersCheckedNumber = 0;
		this.transpondersCheckedStatus = [];

		if (!this._currentGridData.Transponders) {
			return;
		}

		this._currentGridData.Transponders
			.forEach((transponder: AuthorizationTransponder, index) => {
				transponder.Services.forEach((service: AuthorizationServiceUplink) => {
					if (typeof service.Status !== "string" && service.Status) {
						this.servicesCheckedNumber++;
						this.transpondersCheckedStatus[index] = true;
					}
				});

				if (this.transpondersCheckedStatus[index]) {
					this.transpondersCheckedNumber++;
				}
			});
	}

	changeTranspodersStatus(index: number): void {
		this.transpondersCheckedStatus[index] = this.getServicesCheckedInTransponderNumber(index) !== 0 ||
			this.ifTransponderHasSubmittedSevices(index);
		this.transpondersCheckedNumber = this.getTranspondersCheckedNumber();
		this._currentGridData.Transponders
			.forEach((transponder: AuthorizationTransponder, index: number) => {
				transponder.TransponderDisabled = !this.isServiceInTransponderAvailable(index);
			});
	}

	private changeServicesStatus(checkboxValue: boolean): void {
		if (checkboxValue) {
			this.servicesCheckedNumber++
		} else {
			this.servicesCheckedNumber--
		}
	}

	private getTranspondersCheckedNumber(): number {
		let transpondersCheckedArray: boolean[];
		transpondersCheckedArray = this.transpondersCheckedStatus.filter((value) => value === true);

		return transpondersCheckedArray.length;
	}

	private getServicesCheckedInTransponderNumber(transponderIndex: number): number {
		let servicesCheckedArray: AuthorizationServiceUplink[];
		servicesCheckedArray = this._currentGridData.Transponders[transponderIndex].Services.filter(
			(service: AuthorizationServiceUplink) => typeof service.Status !== "string" && service.Status
		);

		return servicesCheckedArray.length;
	}

	private ifTransponderHasSubmittedSevices(transponderIndex: number): boolean {
		let servicesSubmittedArray: AuthorizationServiceUplink[];
		servicesSubmittedArray = this._currentGridData.Transponders[transponderIndex].Services.filter(
			(service: AuthorizationServiceUplink) => typeof service.Status === "string"
		);

		return servicesSubmittedArray.length > 0;
	}

	private isServiceInTransponderAvailable(index: number): boolean {
		return (this.isServiceAvailable() && (this.transpondersCheckedStatus[index] || this.isAnotherTransponderAvailable()))
	}

	private isServiceAvailable(): boolean {
		return this._currentGridData.NoOfServicesRemaining > this.servicesCheckedNumber;
	}

	private isAnotherTransponderAvailable(): boolean {
		return this._currentGridData.NoOfInputs > this.transpondersCheckedNumber;
	}

	private getGridUrl(): string {
		let headendId: number = this.signalsService.headendId;
		let form = this.signalsRequestService.authorizationRequest;
		let decoderTypeSection = form.DecoderType ? '&decoderType=' + form.DecoderType.Id : '';
		let unitAddress = unmask(form.Anchor) || form.TrackingId;
		let unitAddressSection = unitAddress ? '&unitAddress=' + unitAddress : '';
		return this.configService.baseUrl + '/api/AuthorizeSignalStep2?accountId=' + headendId +
			'&signalSourceCode=' + form.SignalSource.Id +
			unitAddressSection + decoderTypeSection;
	}

	private loadGrigData(url: string): Promise<AuthorizationGridData> {
		return this.http.get(url)
			.toPromise()
			.then((res: Response) => {
				if (!res) {
					return;
				}
				this._currentGridData = res.json();
				return this._currentGridData;
			})
			.catch((err) => {
				this.logger.error(err);
				return null;
			})
	}
}