import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TermsPageModel } from '../../+terms/terms-page.model';
import { UploadService, UploadResponse } from '../upload.service';
import { ArrayUtils } from '../../../core/object-utils';

export class UploadRequest {
	AttentionTo: string;
	UploadedNotes: string;
	Project: string;
	FileNames: string[];
	UrlID: string;
}

@Component({
	selector: 'vcm-upload-form',
	template: require('./upload-form.component.html')
})

export class UploadComponentForm implements OnInit, OnDestroy {
	notes: string;
	attention: string;
	uploadText: TermsPageModel = new TermsPageModel();
	formDisabled: boolean = false;
	private urlID: string;

	@Output()
	filesAreLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
	private uploadRequest: UploadRequest;

	constructor(private route: ActivatedRoute,
	            private uploadService: UploadService) {
	}

	ngOnInit() {
		this.route.data.forEach((data: {uploadText: TermsPageModel, urlID: string}) => {
			this.uploadText.Title = data.uploadText.Title || "Upload page";
			this.uploadText.Context = data.uploadText.Context;
			this.urlID = data.urlID;
		});
	}

	cancelAllRequest(): void {
		this.uploadService.clearForm();
	}

	isReadyCollection(): boolean {
		let uploadingItems = ArrayUtils.findByProperty(this.uploadService.uploader.queue, "isUploading", true);
		return this.uploadService.listFileNames.length !== 0 && !uploadingItems;
	}

	uploadFiles(): void {
		this.uploadRequest = {
			FileNames: this.uploadService.listFileNames,
			AttentionTo: this.attention,
			UploadedNotes: this.notes,
			Project: this.uploadText.Title,
			UrlID: this.urlID
		};
		this.formDisabled = true;
		this.uploadService.uploadToMediaSilo(this.uploadRequest).then((res: UploadResponse) => {
			this.formDisabled = false;
			this.clearForm();
			this.filesAreLoading.emit(res.Result);
		});
	}

	ngOnDestroy() {
		this.clearForm();
	}

	private clearForm(): void {
		this.notes = null;
		this.attention = null;
		this.cancelAllRequest();
	}
}