import { Injectable } from '@angular/core';
import { Company, Office, State } from '../../shared/registration-user';
import { HttpClient } from '../../../../core/http/http-client';
import { ConfigService } from '../../../../core/config.service';
import { LoggerService } from '../../../../core/logger/logger.service';
import { URLSearchParams } from '@angular/http';

@Injectable()
export class OfficeService {

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	getOffices(company: Company, state: State, postalCode: string): Promise<Office[]> {
		let params: URLSearchParams = new URLSearchParams();
		params.set('accountId', company.AccountId.toString());
		params.set('state', state.LocationState);
		params.set('zip', postalCode);

		return this.http.get(this.configService.baseUrl + '/api/RegistrationOffice', {search: params})
			.toPromise()
			.then(res => {
				let offices: Office[] = [];
				this.deserializeArray(res.json(), offices);
				return offices;
			})
			.catch(error => {
				this.logger.error(error);
				return null;
			});
	}

	private deserializeArray(from: any[], to: any[]) {
		from.forEach((json: any) => {
			to.push(new Office().fromJSON(json))
		});
	}
}
