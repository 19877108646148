import { NgModule } from '@angular/core';
import { HomeModule } from './home/home.module';
import { ProgrammingModule } from './programming/programming.module';
import { PromotionModule } from './promotion/promotion.module';
import { ResourcesModule } from './resources/resources.module';
import { LoginModule } from './login/login.module';
import { RegistrationModule } from './registration/registration.module';
import { OrdersModule } from './orders/orders.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { ProfileModule } from './profile/profile.module';
import { ErrorsModule } from './errors/errors.module';
import { ViewModule } from './view/view.module';
import { UploadModule } from './upload/upload.module';
import { BrandsModule } from './brands/brands.module';
import { SignalsModule } from './signals/signals.module';
import { OrderModule } from './order-request/order.module';
import { SearchModule } from './search/search.module';
import { RedirectModule } from './redirect/redirect.module';

@NgModule({
	imports: [
		ErrorsModule,
		HomeModule,
		ProfileModule,
		ProgrammingModule,
		PromotionModule,
		ResourcesModule,
		SignalsModule,
		LoginModule,
		RegistrationModule,
		OrdersModule,
		OrderModule,
		ForgotPasswordModule,
		UploadModule,
		BrandsModule,
		SearchModule,
		RedirectModule
	],
	exports: [
		ViewModule
	]
})

export class PagesModule {
}
