import { Injectable } from '@angular/core';
import { ConfigService } from '../../../core/config.service';
import { Http, Response } from '@angular/http';
import { LoggerService } from '../../../core/logger/logger.service';

export class FooterLinkResponse {	
	Links: string[];

	constructor(obj: any) {
		if (obj) {
			this.Links = obj.Links;
		}
	}
}

@Injectable()
export class FooterLinkService {
	private footerLinkResponse: FooterLinkResponse;

	constructor(private http: Http,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	getFooterLink(): Promise<FooterLinkResponse> {
		if (this.footerLinkResponse) {
			return Promise.resolve(this.footerLinkResponse);
		} else {
			return this.loadFooterLink();
		}
	}

	loadFooterLink(): Promise<FooterLinkResponse> {
		return this.http.get(this.configService.baseUrl + '/api/FooterLinks')
			.toPromise()
			.then((res: Response) => {
				return new FooterLinkResponse(res.json());
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}
