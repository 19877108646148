import { Injectable } from '@angular/core';
import { ResourcesService } from '../resources.service';

export class Press {
	ContentName: string;
	LegalText: string;
}

@Injectable()
export class PressService {
	constructor(private resourcesService: ResourcesService) {}

	getPress(filter: any = {}): Promise<Press[]> {
		filter.resourceType = 'press';
		return this.resourcesService.getResourcesList(filter);
	}
}
