import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ProgrammingHighlights } from '../programming-highlights';
import { ProgrammingHighlightsListService } from './programming-highlights-list.service';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';

@Injectable()
export class ProgrammingHighlightsListResolve implements Resolve<ProgrammingHighlights[]> {

	constructor(private service: ProgrammingHighlightsListService,
	            private mask: LoadingMaskService) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<ProgrammingHighlights[]> {
		let id: string = route.params['brand'];
		this.mask.addLoading();
		return this.service.getProgrammingHighlightsList(id, false)
			.map((programmingHighlightsList: ProgrammingHighlights[]) => {
				this.mask.removeLoading();
				return programmingHighlightsList;
			});
	}
}
