import { Directive, TemplateRef, ViewContainerRef, OnDestroy, OnInit } from '@angular/core';
import { UserService } from './user.service';
import { Subscription } from 'rxjs';
import { User } from './user';

@Directive({selector: '[vcmIfNotAuthenticated]'})
export class IfNotAuthenticatedDirective implements OnInit, OnDestroy {
	private userSubscription: Subscription;
	private notAuthenticated: boolean = false;

	constructor(private userService: UserService,
	            private templateRef: TemplateRef<any>,
	            private viewContainer: ViewContainerRef) { }

	ngOnInit(): void {
		this.manageVisibility(this.notAuthenticated, this.userService.getCurrentUser());
		this.userSubscription = this.userService.onChange.subscribe((user: User) => {
			this.manageVisibility(this.notAuthenticated, user);
		});
	}

	private manageVisibility(notAuthenticated: boolean, user: User) {
		if (!notAuthenticated && !user) {
			this.notAuthenticated = true;
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else if (notAuthenticated && user) {
			this.notAuthenticated = false;
			this.viewContainer.clear();
		}
	}

	ngOnDestroy(): void {
		this.userSubscription.unsubscribe();
	}
}