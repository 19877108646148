import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
	selector: '[notSpaceValidator]',
	providers: [
		{provide: NG_VALIDATORS, useExisting: forwardRef(() => NotSpaceValidator), multi: true}
	]
})

export class NotSpaceValidator implements Validator {
	constructor() {}

	validate(control: AbstractControl): {[key: string]: any} {
		let formValue: any = control.value || '';
		if (!formValue.trim()) {
			return {validateNoSpaces: false}
		}
		return null;
	}
}