import { NgModule } from '@angular/core';
import { ProgrammingComponent } from './programming.component';
import { SharedModule } from '../../shared/shared.module';
import { ProgrammingRoutingModule } from './programming-routing.module';
import { LiveBrandsService } from './dropdowns/live-brands.service';
import { LiveGridShowsService } from './live-grid/live-grid-shows.service';
import { LiveGridTableComponent } from './live-grid/live-grid-table/live-grid-table.component';
import { TimelineComponent } from './live-grid/live-grid-table/timeline/timeline.component';
import { RangeSelectorComponent } from './live-grid/range-selector/range-selector.component';
import { DaysRangeService } from './live-grid/days-range-service';
import { DatepickerSelectorComponent } from './live-grid/range-selector/datepicker-selector/datepicker-selector.component';
import { ProgrammingHintsComponent } from './live-grid/programming-hints/programming-hints.component';
import { CheckOverflowDirective } from './live-grid/live-grid-table/check-overflow.directive';
import { ShowTooltipComponent } from './live-grid/show-tooltip/show-tooltip.component';
import { HighlightsService } from './dropdowns/highlights.service';
import { ProgrammingHighlightsListService } from './programming-highlights-list/programming-highlights-list.service';
import { ProgrammingHighlightsListComponent } from './programming-highlights-list/programming-highlights-list.component';
import { ProgrammingHighlightsDetailsComponent } from './programming-highlights-details/programming-highlights-details.component';
import { ProgrammingHighlightsDetailsService } from './programming-highlights-details/programming-highlights-details.service';
import { ProgramDetailsComponent } from './program-details/program-details.component';
import { ProgramDetailsService } from './program-details/program-details.service';
import { NextAirDatesComponent } from './next-dates/next-dates.component';
import { NextAirDatesService } from './next-dates/next-dates.service';
import { LiveGridComponent } from './live-grid/live-grid.component';
import { ProgramInfoComponent } from './program-info/program-info.component';
import { GenericGridsService } from './dropdowns/generic-grids.service';
import { GuidesService } from './dropdowns/guides.service';
import {PremieresListComponent} from "./premieres-list/premieres-list.component";
import {PremiereDetailsComponent} from "./premiere-details/premiere-details.component";
import {PremieresListService} from "./premieres-list/premieres-list.service";

@NgModule({
	imports: [
		SharedModule,
		ProgrammingRoutingModule
	],
	declarations: [
		ProgrammingComponent,
		ProgramDetailsComponent,
		LiveGridTableComponent,
		ProgrammingHighlightsListComponent,
		ProgrammingHighlightsDetailsComponent,
		TimelineComponent,
		RangeSelectorComponent,
		DatepickerSelectorComponent,
		ProgrammingHintsComponent,
		CheckOverflowDirective,
		NextAirDatesComponent,
		LiveGridComponent,
		ShowTooltipComponent,
		ProgramInfoComponent,
		PremieresListComponent,
		PremiereDetailsComponent
	],
	providers: [
		LiveBrandsService,
		HighlightsService,
		GenericGridsService,
		GuidesService,
		LiveGridShowsService,
		ProgramDetailsService,
		DaysRangeService,
		ProgrammingHighlightsListService,
		ProgrammingHighlightsDetailsService,
		NextAirDatesService,
		PremieresListService
	]
})

export class ProgrammingModule {
}
