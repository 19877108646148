import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { LandingGridService, RecourseType } from './landing-grid.service';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { Router } from '@angular/router';

@Component({
	template: require('./landing-grid.component.html'),
	selector: 'vcm-landing-grid'
})

export class LandingGridComponent {
	public serviceId: number;

	@Input('serviceId')
	set setServiceId(serviceId: number) {
		this.serviceId = serviceId;
		if (serviceId) {
			this.getLandingGrid({Brand: this.serviceId || null});
		}
	}

	@Input() keyword: string;

	availableTabs: RecourseType[] = null;
	activeTab: RecourseType = null;
	resources: any[];
	brand: RecourseType;
	isGridLoading: boolean = true;

	constructor(private landingGridService: LandingGridService,
	            private detector: ChangeDetectorRef,
	            private mask: LoadingMaskService,
	            private router: Router) {
	}

	ngOnInit() {
		if (!this.serviceId && !this.keyword) {
			this.getLandingGrid({});
		}
	}

	ngOnChanges(changes: any): void {
		if (changes.keyword && changes.keyword.currentValue) {
			this.availableTabs = null;
			this.mask.addLoading();
			this.getSearchGrid({
				Keyword: this.keyword
			}).then(() => {
				this.mask.removeLoading();
			});
		}
	}

	selectNewTab(tab: RecourseType): void {
		if (tab.Id == "LIVEGRID") {
			this.activeTab = tab;
			this.router.navigate(['/programming/livegrid/' + this.serviceId]);
			return;
		}
		if (this.keyword) {
			this.getSearchGrid({
				ResourceType: tab.Id,
				Keyword: this.keyword
			})
		} else {
			this.getLandingGrid({
				ResourceType: tab.Id,
				Brand: this.serviceId
			})
		}
	}

	getLandingGrid(params?: any): Promise<any> {
		this.resources = null;
		this.isGridLoading = true;
		this.activeTab = new RecourseType({Id: params['ResourceType']});
		return this.landingGridService.getLandingGrid(params)
			.then((res: any) => {
				this.availableTabs = res ? res['ResourceTypeAvailable'] : [];
				this.activeTab = res ? res['ResourceType'] : null;
				this.resources = res ? res['Resources'] : [];
				this.brand = res ? res['Brand'] : null;
				this.detector.markForCheck();
				this.isGridLoading = false;
			})
	}

	getSearchGrid(params: any): Promise<any> {
		this.resources = null;
		this.isGridLoading = true;
		this.activeTab = new RecourseType({Id: params['ResourceType']});
		return this.landingGridService.getSearchGrid(params)
			.then((res: any) => {
				this.availableTabs = res ? res['ResourceTypeAvailable'] : [];
				this.activeTab = res ? res['ResourceType'] : null;
				this.resources = res ? res['Resources'] : [];
				this.detector.markForCheck();
				this.isGridLoading = false;
			})
	}
}