import { URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';
import { HttpClient } from '../../../../../../../core/http/http-client';
import { ConfigService } from '../../../../../../../core/config.service';
import { AuthorizationService } from '../../../authorization.service';
import { Observable } from 'rxjs';

@Injectable()
export class AcpAddressValidationService {

	constructor(private http: HttpClient,
	            private configService: ConfigService,
	            private signalsRequestService: AuthorizationService) {
	}

	checkAcpAddressObservable(acpAddress: string): Observable<boolean> {
		let form = this.signalsRequestService.authorizationRequest;
		let unitAddress: string = this.unmask(form.Anchor) || form.TrackingId;
		let params: URLSearchParams = new URLSearchParams();
		params.set('acpAddress', this.unmask(acpAddress));
		params.set('unitAddress', unitAddress);

		if (acpAddress === '---')
			return Observable.of(true);

		return this.http.get(this.configService.baseUrl + '/api/AuthorizeSignalStep2CheckAcp', {search: params})
			.map((res) => {
				return res.json();
			});
	}

	private unmask(value?: string): string {
		return value ? value.replace(/\D+/g, '') : null;
	}
}