import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { ConfigService } from '../../../core/config.service';
import { LoggerService } from '../../../core/logger/logger.service';

@Injectable()
export class UserActivationService {
	constructor(private http: Http,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	activateUser(activationCode: string): Promise<any> {
		let url = this.configService.baseUrl + '/api/RegistrationConfirm/?activationCode=';
		let headers = new Headers({'Content-Type': 'application/json'});
		let options = new RequestOptions({headers: headers});

		return this.http.put(url + activationCode, {}, options)
			.toPromise()
			.catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}