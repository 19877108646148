import { Component, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ng2-bootstrap';

@Component({
	selector: 'vcm-modal-comp',
	template: require('./modal.component.html')
})

export class ModalComponent {
	@Output()
	private confirm: EventEmitter<any> = new EventEmitter<any>();

	public confirmAction(lgModal:ModalDirective){
		this.confirm.emit(null);
		lgModal.hide();
	}
}