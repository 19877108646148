export class BrandDescription {
	ImageURL: string;
	ServiceLongname: string;
	ServiceId: number;
}

export class ProgrammingHighlights {
	ProgramminghighlightsId: number;
	BodyText: string;
	ImageURL: string;
	Brands: BrandDescription[];
	Title: string;
	Description: string;
	FinaleDate: string;
	FinaleTime: string;
	PremiereDate: string;
	PremiereTime: string;
	Scheduled: string;
	NumberOfEpisodes: number;
	RelatedProgramm: number;
}

export class ProgramminHighlightsInfo {
	Channel: string;
	Duration: number;
	Title: string;
	Episode: string;
	Description: string;
	Premiere: boolean;
	SeriesCde: number;
	RatingType: string;
	EpisodeNum: string;
	EpisodeDesc: string;
	ScheduleitemId: number;
	Datetime: any;
}

export class ProgramDetails {
	PhList: ProgrammingHighlights[];
	ProgrammInfo: ProgramminHighlightsInfo;
	EpisodeList: ProgramminHighlightsInfo[]
}