import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { ConfigService } from '../../../core/config.service';
import { Observable } from 'rxjs';
import { LoggerService } from '../../../core/logger/logger.service';

@Injectable()
export class ForgotPasswordService {
	private restoreCode: string;

	constructor(private http: Http,
	            private configService: ConfigService,
	            private logger: LoggerService) {
	}

	validateRestoreCode(restoreCode: string): Observable<any> {
		this.restoreCode = restoreCode;

		return this.http.get(this.configService.baseUrl + '/api/ForgotPassword?RestoreCode=' + restoreCode)
			.map(res => res.json());
	}

	saveNewPassword(password): Promise<any> {
		let url = this.configService.baseUrl + '/api/ForgotPassword?RestoreCode='
			+ this.restoreCode + '&newPassword=' + password;

		return this.http.put(url, {})
			.toPromise()
			.then(res => {
				let passwordChanged: string = res.json();
				return passwordChanged == 'Pass saved';
			}).catch((err) => {
				this.logger.error(err);
				return null;
			});
	}
}

