import { Component } from '@angular/core';

@Component({
	template: require('./change-password.component.html')
})

export class ChangePasswordComponent {
	passwordChanged: boolean = false;

	displayChangePasswordMessage() {
		this.passwordChanged = true;
	}

	back() {
		this.passwordChanged = false;
	}
}