import { NgModule } from '@angular/core';
import { ReasonService } from './drop-headend-form/reason.service';
import { SharedModule } from '../../../../shared/shared.module';
import { DropHeadendComponent } from './drop-headend.component';
import { DropHeadendFormComponent } from './drop-headend-form/drop-headend-form.component';
import { DropHeadendFormService } from './drop-headend-form/drop-headend-form.service';
import { BackLinkComponent } from '../../../../shared/back-link.component';
import { DropHeadendRoutingModule } from './drop-headend-routing.module';

@NgModule({
	imports: [
		SharedModule,
		DropHeadendRoutingModule
	],
	declarations: [
		DropHeadendComponent,
		DropHeadendFormComponent
	],
	providers: [
		ReasonService,
		DropHeadendFormService,
		BackLinkComponent
	]
})
export class DropHeadendModule {
}