import { Component, Input } from '@angular/core';

@Component({
	template: require('./landing-other.component.html'),
	selector: 'vcm-landing-other'
})

export class LandingOtherComponent {
	@Input() resources: any;
}
