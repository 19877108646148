import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { AuthorizationComponent } from './authorization.component';
import { AuthorizationService } from './authorization.service';
import { HeadendSummaryService } from '../shared/headend-summary.service';

@Injectable()
export class AuthorizationDeactivateGuard implements CanDeactivate<AuthorizationComponent> {

	constructor(private authorizationService: AuthorizationService,
	            private headendSummaryService: HeadendSummaryService) {}

	canDeactivate(component: AuthorizationComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
		this.authorizationService.clear();
		this.headendSummaryService.clearSummary();

		return true;
	}
}