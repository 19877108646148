import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { Video } from './video';
import { VgAPI } from 'videogular2/src/core/services/vg-api';
import { IPlayable } from 'videogular2/src/core/vg-media/i-playable';
import { SoundService } from '../../core/sound/sound.service';

@Component({
	selector: 'vcm-video-player',
	template: require('./video.component.html')
})

export class VideoComponent implements OnInit, OnChanges {
	source: Video;
	togglePlayList: boolean = false;
	api: VgAPI;
	disableVideoComponent: boolean = false;
	disabledErrorMessage: string;

	@Input('playlist') playlist: Video[];
	@Input('newVideoIndex') newVideoIndex: EventEmitter<number>;
	@Input('autoplay') autoplay: boolean = false;

	@Output() playerReady: EventEmitter<IPlayable> = new EventEmitter<IPlayable>();

	constructor(private soundService: SoundService) {
	}

	ngOnInit() {
		this.playlist[0] = this.playlist[0] || new Video({Src: null});

		this.playlist[0].NowPlaying = true;
		this.source = this.playlist[0];

		this.validateVideo(null, this.source);
	}

	ngOnChanges(changes: any) {
		if (changes.playlist) {
			this.disableVideoComponent = false;
			this.source = changes.playlist.currentValue[0] || new Video({Src: null});
		}
	}

	displayPlaylist() {
		this.togglePlayList = !this.togglePlayList;
	}

	changeSource(source: Video) {
		this.source = source;
	}

	changePlayListStatus(playlistStatus: boolean) {
		this.togglePlayList = playlistStatus;
	}

	validateVideo(error?: any, source?: Video): void {
		if (!source || !source.Src) {
			this.disableVideoComponent = true;
			this.disabledErrorMessage = 'No video';
			return;
		}

		if (error) {
			this.disableVideoComponent = true;
			this.disabledErrorMessage = 'This video cannot be played';
		}
	}

	onPlayerReady(api: VgAPI) {
		this.api = api;
		this.playerReady.emit(this.api.getDefaultMedia());

		this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe(
			() => {
				// Set the video start time
				this.api.getDefaultMedia().currentTime = this.source.StartTime;
			}
		);
		this.api.getDefaultMedia().subscriptions.pause.subscribe(
			() => {
				this.togglePlayList = true;
			}
		);
		this.api.getDefaultMedia().subscriptions.play.subscribe(
			() => {
				this.togglePlayList = false;
			}
		);
		this.api.getDefaultMedia().subscriptions.volumeChange.subscribe(
			() => {
				let target: any = this.api.getDefaultMedia();
				if (target.volume > 0) {
					this.soundService.soundOn();
				}
			}
		);
	}
}