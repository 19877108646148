import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { LoadingMaskService } from '../../../shared/loading-mask/loading-mask.service';
import { CommonFiltersResolve } from '../filters/common-filters-resolve';
import { ActiveFiltersService } from '../filters/active-filters.service';
import { ImagesService } from './images.service';

@Injectable()
export class ImagesResolve extends CommonFiltersResolve implements Resolve<string> {
	constructor(private service: ImagesService,
	            private activeFiltersService: ActiveFiltersService,
	            private mask: LoadingMaskService) {
		super(activeFiltersService);
	}

	resolve(route: ActivatedRouteSnapshot): Promise<string> {
		this.mask.addLoading();
		let filters: any = this.initActiveFiltersFromURLParams(route);
		return this.service.getImages(filters)
			.then((url: string) => {
				this.mask.removeLoading();
				return url;
			});
	}
}